import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterDataService {

  constructor() { }

  setFilter(data: any){
    sessionStorage.setItem('busquedaPortal', JSON.stringify(data));
  }

  getFilter(){
    const busqueda = sessionStorage.getItem('busquedaPortal');
    return (busqueda ? JSON.parse(busqueda) : null);
  }

  resetFilter(){
    sessionStorage.removeItem('busquedaPortal');
  }
}
