<div class="main-content text-center">
  <div class="container-fluid text-center">

    <div class="row main-card">
      <div class="col-md-3 col-lg-4">
      </div>
      <div class="col-md-6 col-lg-4">

        <div class="card mt-5">
          <div class="card-body">
            <div style="text-align: center;">
              <div style="padding-top: 10px;">
                <fa name="building" size="3x"></fa>
              </div>
              <div>
                <div style="padding-top: 10px; font-size: 25px;"> <b> <u>Acceso
                  Vendedor</u></b>
                </div>
              </div>
            </div>
            <form [formGroup]="loginForm" (ngSubmit)="login()" style="margin: 10px 10px 10px;">
              <div class="form-group">
                <label>Ingrese Usuario</label>
                <input type="text" class="form-control" placeholder="Usuario" formControlName="userAccount">
              </div>
              <div class="form-group">
                <label>Ingrese Contraseña</label>
                <input [type]="hide ? 'password' : 'text'" class="form-control" placeholder="Contraseña"
                       formControlName="userPassword">
              </div>
              <div class="btn-actions">
                <div class="row justify-content-between">
                  <div class="col-8">
                    <!--<a style="cursor: pointer; font-size: 17px;" (click)="openClient()">
                      <u>¿Eres un cliente?</u>
                    </a>--> <!--comentado porque se quita boton de codigo invitado-->
                  </div>
                  <div class="col-4">
                    <button type="submit" class="btn btn-light btn-block btn-custom" style="width: auto;float: right;"
                            *ngIf="!isLoad">Aceptar</button>
                    <button type="submit" class="btn btn-light btn-block btn-custom" style="width: auto;float: right;"
                            *ngIf="isLoad">
                      Cargando...
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="progress" *ngIf="isLoad">
          <div class="indeterminate"></div>
        </div>
      </div>
      <div class="col-md-3 col-lg-4">
      </div>
    </div>

  </div>
</div>
