import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-pax-form-confirmation',
  templateUrl: './pax-form-confirmation.component.html',
  styleUrls: ['./pax-form-confirmation.component.scss']
})
export class PaxFormConfirmationComponent implements OnInit {

  constructor(private providerDialog: MatDialogRef<PaxFormConfirmationComponent>, ) { }

  ngOnInit() {
  }

  cancel() {
    this.providerDialog.close();
  }
}
