import { DialogDetailBankTransferComponent } from './../dialog-detail-bank-transfer/dialog-detail-bank-transfer.component';
import { UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { BookingService } from 'src/app/shared/service/booking.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import { LayoutService } from 'src/app/shared/service/layout.service';
import { log } from 'console';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent implements OnInit {
  public idBooking: number;
  public paymentHistoryForm: UntypedFormGroup;
  public paymentHistory: any;
  public noDues: any;
  public pageActual = 1;
  public randomBKA: string;
  public index: number;
  public paymentPost: any;
  public startDate: any;
  public time: any;
  public observations: string;
  public totalPriceBooking: number;
  public status: string;
  public balance: number;
  public refund: number;

  constructor(
    private bookingService: BookingService,
    private providerDialog: MatDialogRef<PaymentHistoryComponent>,
    private notificationService: NotificationsService,
    public dialog: MatDialog,
    private router: Router,
    private layoutService: LayoutService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.idBooking = this.data.idBooking || this.data.bookings.idBooking;
    this.index = this.data.index;
    this.startDate = this.data.detail.startDate || this.data.detail[this.index].layout.startDate;
  }

  ngOnInit() {
    this.randomBKA = 'id' + this.getRandom();

    this.bookingService.getHistoryPayment(this.idBooking).subscribe(resp => {
      this.paymentHistory = resp.data;
      this.totalPriceBooking = this.paymentHistory.totalPriceBooking;
      this.status = this.paymentHistory.statusBooking;
      this.balance = this.paymentHistory.balance;
      this.refund = this.paymentHistory.refund;
    });
    this.layoutService.getTime().subscribe(resp => {
      this.time = resp.data;
    });
  }

  cancel() {
    this.providerDialog.close();
  }

  getRandom() {
    return Math.random();
  }

  getQuotes(detail: any) {
    if (detail !== null) {
      if (detail.noDues !== 0) {
        this.noDues = detail.noDues;
      } else {
        this.noDues = 'Otros';
      }
      return this.noDues;
    }
  }

  detailTransfer(detail: any, idPaymentDetail: number, payment: string) {
    const bankTransferDialogConfig = new MatDialogConfig();
    bankTransferDialogConfig.disableClose = true;
    bankTransferDialogConfig.autoFocus = true;
    bankTransferDialogConfig.data = {
      detail: detail,
      idPaymentDetail: idPaymentDetail,
      payment: payment
    };
    const paymentDialogRef = this.dialog.open(
      DialogDetailBankTransferComponent,
      bankTransferDialogConfig
    );
    paymentDialogRef.afterClosed().subscribe((resp: any) => {
    });
  }
}
