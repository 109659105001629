<!--<nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark" style="z-index: 1000;">
  <a class="navbar-brand">Central de Reservas</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavDropdown">

     <ul class="navbar-nav">
        <li class="nav-item px-2">
          <a routerLink="/" class="nav-link">Inicio</a>
        </li>
      </ul>

    <ul class="navbar-nav ml-auto" style="font-size: 17px;">

      <li class="nav-item">
        <a style="cursor: pointer;" class="nav-link">
          <i class="fas fa-user"></i> Bienvenido
        </a>

      </li>

      <li class="nav-item"
        *ngIf="currentUser?.rol === 'Admin' || currentUser?.rol === 'Ofic' || currentUser?.rol === 'Recep'">
        <a style="cursor: pointer;" class="nav-link" (click)="openCodeDialog()">
          <i class="fas fa-sync-alt"></i> Generar Código
        </a>
      </li>

      <li class="nav-item">
        <a style="cursor: pointer;" class="nav-link" (click)="logout()">
          <i class="fas fa-sign-out-alt"></i> Cerrar Sesión
        </a>
      </li>

    </ul>
  </div>
</nav>
-->
<div class="container-lg" style="position: relative" >
<div class="col text-ico-salir" *ngIf="currentUser?.rol != null" >
  <a style="font-size: 19px; position: absolute; top: 0px;
    right: 0px; z-index: 11" class="nav-link p-0 pt-1" >
    <i class="fas fa-sign-out-alt" style="cursor: pointer" (click)="logout()"></i>
  </a>
</div>
</div>
<nav class="navbar navbar-expand-lg p-2 container-lg" style="z-index: 10; margin-bottom: 15px;">
  <div class="row" style="margin: auto">
    <div class="col-6 col-lg-6" style="text-align: left">
      <a class="navbar-item" (click)="redirectPortalCliente()">
        <img src="assets/img/logo-adn.svg" />
      </a>

    </div>
  </div>


    <h5 class="text-header">
      <img _ngcontent-ioh-c4="" _ngcontent-mow-c4="" src="assets/img/logo-adn-letras.svg" style="
    width: 118px;
    padding-top: 0;">
    </h5>

  <button class="navbar-toggler" *ngIf="(currentUser?.rol === 'Admin' || currentUser?.rol === 'Ofic' || currentUser?.rol === 'Recep') && userDataType == 0" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <i class="fa-solid fa-bars"></i>
  </button>

  <div class="collapse navbar-collapse mt-4" id="navbarNavDropdown" style="width: 100%" >
    <ul class="navbar-nav" *ngIf="(currentUser?.rol === 'Admin' || currentUser?.rol === 'Ofic' || currentUser?.rol === 'Recep') && userDataType == 0" >
      <li class="nav-item px-2 letter-size" *ngFor="let menuItem of menuItems" routerLinkActive #rla="routerLinkActive" [routerLinkActiveOptions]="{ exact: true }">
        <a [routerLink]="menuItem.path" class="nav-link" [ngStyle]="{ color: rla.isActive ? 'black' : '#007bff' }" >
          <span matBadge="{{ layoutService.getOpenExcursionsTotal() }}" matBadgePosition="after" matBadgeOverlap="false" matBadgeColor="warn"
          [matBadgeHidden]="menuItem.title !== 'Excursiones'" >
            <mat-icon>{{ menuItem.icon }}</mat-icon> {{ menuItem.title }}
          </span>
        </a>
      </li>
      <li class="nav-item px-2 letter-size">
        <a style="cursor: pointer" class="nav-link" (click)="redirectPortalCliente()" [ngStyle]="{ color: '#007bff' }">
          <!--<i class="fas fa-sync-alt"></i>--> Portal Cliente
        </a>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto" style="font-size: 17px">
      <li class="nav-item"></li>
    </ul>


  </div>

</nav>
