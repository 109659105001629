<div class="dialog-body">
  <div class="row justify-content-between">
    <div class="col" *ngIf="payment === 'transfer'">
      <h5 mat-dialog-title>Datos de la transferencia bancaria </h5>
    </div>
    <div class="col" *ngIf="payment === 'posnet'">
      <h5 mat-dialog-title>Datos de pago por Posnet </h5>
    </div>

    <div class="col-1">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>

  <hr style="margin-top: -10px;" />
  <div class="mat-typography" style="padding-top: 1px;">
    <p style="font-size: 16px;margin-left: 10px;margin-top: 20px;" *ngIf="payment === 'transfer'">Fecha de
      transferencia: <strong
        style="font-size: 16px;margin-left: 10px;margin-top: 20px;">{{detail.bankTransferDate | date:'dd/MM/yyyy'}}</strong>
    </p>
    <p style="font-size: 16px;margin-left: 10px;margin-top: 20px;" *ngIf="payment === 'transfer'">Responsable de
      pago: <strong style="font-size: 16px;margin-left: 10px;margin-top: 20px;">{{detail.responsiblePayment}}</strong>
    </p>
    <p style="font-size: 16px;margin-left: 10px;margin-top: 20px;" *ngIf="payment === 'transfer'">Banco: <strong
        style="font-size: 16px;margin-left: 10px;margin-top: 20px;">{{detail.bank}}</strong>
    </p>
    <p style="font-size: 16px;margin-left: 10px;margin-top: 20px;" *ngIf="payment === 'transfer'">Cbu: <strong
        style="font-size: 16px;margin-left: 10px;margin-top: 20px;">{{detail.cbu}}</strong>
    </p>

    <p style="font-size: 16px;margin-left: 10px;margin-top: 20px;" *ngIf="payment === 'posnet'">N° de lote:
      <strong style="font-size: 16px;margin-left: 10px;margin-top: 20px;">{{detail.lot}}</strong>
    </p>
    <p style="font-size: 16px;margin-left: 10px;margin-top: 20px;" *ngIf="payment === 'posnet'">N° de cupón:
      <strong style="font-size: 16px;margin-left: 10px;margin-top: 20px;">{{detail.coupon}}</strong>
    </p>
    <p style="font-size: 16px;margin-left: 10px;margin-top: 20px;"
      *ngIf="payment === 'posnet' && detail.typeCard !== null">Tipo de tarjeta:
      <strong style="font-size: 16px;margin-left: 10px;margin-top: 20px;">{{detail.typeCard}}</strong>
    </p>
    <p style="font-size: 16px;margin-left: 10px;margin-top: 20px;"
      *ngIf="payment === 'posnet' && detail.cardIssuer !== null">Tarjeta: <strong
        style="font-size: 16px;margin-left: 10px;margin-top: 20px;">{{detail.cardIssuer}}</strong>
    </p>
    <p style="font-size: 16px;margin-left: 10px;margin-top: 20px;"
      *ngIf="payment === 'posnet' && detail.cardIssuer === null">Nº de devolución: <strong
        style="font-size: 16px;margin-left: 10px;margin-top: 20px;">{{detail.refundNumber}}</strong>
    </p>
  </div>
</div>
