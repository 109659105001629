import { DialogPaymentComponent } from './../dialog-payment/dialog-payment.component';
import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Tours } from 'src/app/shared/class/tours';
import { BookingService } from 'src/app/shared/service/booking.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { ToursService } from 'src/app/shared/service/tours.service';
import { LayoutService } from 'src/app/shared/service/layout.service';
import { PaymentHistoryComponent } from '../payment-history/payment-history.component';
import { DialogReturnsComponent } from '../dialog-returns/dialog-returns.component';
import { CancelConfirmationsComponent } from '../cancel-confirmations/cancel-confirmations.component';
import { Router } from '@angular/router';
import { LoginAuthService } from 'src/app/auth/providers/login-auth.service';

@Component({
  selector: 'app-previous-bookings',
  templateUrl: './previous-bookings.component.html',
  styleUrls: ['./previous-bookings.component.scss']
})
export class PreviousBookingsComponent implements OnInit {
  public origin: any;
  public ending: any;
  public tours: Tours[];
  public booking: any;
  public seller: any;
  public default: any = [];
  public pageActual = 1;
  public id: number;
  public details: any;
  isPaxs = true;
  previousBooking: UntypedFormGroup;
  public totalBooking: number;
  public dateStart: any;
  public users: string;
  public time: any;
  public randomBLB: string;
  public allowRefund: any;
  public user: any;
  public alertToPending: boolean;
  public alertToPartial: boolean;
  public IdTourB: any;
  public activity: any;

  public statusB = [{ valor: 'PAGADO', muestraValor: 'PAGADO', id: 1 }, { valor: 'P. PAGO', muestraValor: 'P. PAGO', id: 2 },
  { valor: 'P. PARCIAL', muestraValor: 'P. PARCIAL', id: 3 }, { valor: 'CANCELADO', muestraValor: 'CANCELADO', id: 4 }];

  public payMethod = [
    { valor: 'Mercado Pago', muestraValor: 'Mercado Pago', id: 1 },
    { valor: 'Transferencia Bancaria', muestraValor: 'Transferencia Bancaria', id: 2 },
    { valor: 'Postnet', muestraValor: 'Postnet', id: 3 },
    { valor: 'Postnet - Mercado Pago', muestraValor: 'Postnet - Mercado Pago', id: 5 },
    { valor: 'Efectivo', muestraValor: 'Efectivo', id: 4 },
  ];

  public moviment = [{ valor: 'Descuento', muestraValor: 'Descuento', id: 3 },
  { valor: 'Devolución', muestraValor: 'Devolución', id: 2 },
  { valor: 'Pago', muestraValor: 'Pago', id: 1 },
  { valor: 'Recargo', muestraValor: 'Recargo', id: 4 },
  ];

  constructor(
    private bookingService: BookingService,
    public formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private notificationService: NotificationsService,
    private tourService: ToursService,
    private layoutService: LayoutService,
    private loginAuthService: LoginAuthService
  ) {
  }

  ngOnInit() {
    this.user = this.loginAuthService.getUser();
    this.randomBLB = 'id' + this.getRandom();
    this.tourService.getLocations().subscribe(resp => {
      this.origin = resp.data;
      this.ending = resp.data;
    });
    this.bookingService.getSellers().subscribe(resp => {
      this.seller = resp.data;
    });
    this.previousBooking = this.getFormGroup();
    this.getDefaulBooking();
    this.layoutService.getTime().subscribe(resp => {
      this.time = resp.data;
    });
    this.tourService.getTypeActivity().subscribe(resp => {
      this.activity = resp.data;
    });

  }

  getDefaulBooking() {
    this.bookingService.getDefaultBooking(this.user.idUser).subscribe(resp => {
      this.default = [];
      this.default = resp.data;
    });
  }

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      IdTour: [''],
      IdSeller: [''],
      StatusBooking: [''],
      IdPaymentMethod: [''],
      IdTransactionType: [''],
      StartDateLayout: [''],
      EndDateLayout: [''],
      StartDateBooking: [''],
      EndDateBooking: [''],
      startingPlace: [''],
      endingPlace: [''],
      IdTypeActivity: [''],
      IdUserLogged: ['']
    });
  }

  /** DESCARGAR VOUCHER */
  downloadVoucher(index: number, title: string) {
    this.bookingService.printVoucher(index).subscribe(resp => {

      var newBlob = new Blob([resp], { type: 'application/pdf' });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      const nav = (window.navigator as any);
      if (nav && nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(newBlob);
        return;
      }
      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data;
      link.download = index + '-' + title + '-' + 'VOUCHER' + '.pdf';
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    },
      error => {
        console.log('response error ', error);
      });
  }

  /** HISTORIAL DE PAGOS */
  paymentHistory(idBooking: number, index: number) {
    const paymentDialogConfig = new MatDialogConfig();
    paymentDialogConfig.disableClose = true;
    paymentDialogConfig.autoFocus = true;
    paymentDialogConfig.data = {
      idBooking: idBooking,
      detail: this.default[index].layout,
    };
    const paymentDialogRef = this.dialog.open(
      PaymentHistoryComponent,
      paymentDialogConfig
    );
    paymentDialogRef.afterClosed().subscribe((resp: any) => {
    });
  }

  /** open payment form pop-up */
  openPaymentDialog(idBooking: number, idPaymentHeader: number, totalPrice: number, balance: number, allowD: boolean, startDate: any, contactEmail: string) {
    const paymentDialogConfig = new MatDialogConfig();
    paymentDialogConfig.disableClose = true;
    paymentDialogConfig.autoFocus = true;
    paymentDialogConfig.maxHeight = '95vh';
    paymentDialogConfig.data = {
      idBooking: idBooking,
      idPaymentHeader: idPaymentHeader,
      totalPrice: totalPrice,
      balance: balance,
      allowDiscount: allowD,
      totalBooking: this.totalBooking,
      startDate: startDate,
      time: this.time,
      contactEmail: contactEmail,
    };
    const paymentDialogRef = this.dialog.open(
      DialogPaymentComponent,
      paymentDialogConfig
    );
    paymentDialogRef.afterClosed().subscribe((resp: any) => {
      this.getDefaulBooking();
    });
  }

  getRandom() {
    return Math.random();
  }

  openReturnsDialog(idPaymentHeader: number, totalPrice: number, maxRefund: number) {
    const paymentDialogConfig = new MatDialogConfig();
    paymentDialogConfig.disableClose = true;
    paymentDialogConfig.autoFocus = true;
    paymentDialogConfig.data = {
      idPaymentHeader: idPaymentHeader,
      totalPrice: totalPrice,
      maxRefund: maxRefund,
    };
    const paymentDialogRef = this.dialog.open(
      DialogReturnsComponent,
      paymentDialogConfig
    );
    paymentDialogRef.afterClosed().subscribe((resp: any) => {
      this.totalBooking = resp.totalBooking;
      this.getDefaulBooking();
    });
  }

  /** EDITAR UNA RESERVA*/
  openBookingByTour(idBooking: number, idLayout: number, status: string, dropOff?: string, pickUp?: string) {
    this.router.navigate(['/admin-page/forms-admin'],
      {
        queryParams: {
          idL: idLayout, idB: idBooking, page: 'previous-forms', status, dropOff: dropOff,
          pickUp: pickUp
        }
      }
    );
  }

  /** CANCELAR RESERVA */
  onCancelOrPayLaterTheReservation(type: string, idBooking: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    const cancel = 'Está a punto de cancelar la reserva. ¿Desea continuar?';
    const payLater = 'Su reserva no se ha pagado aún, por lo que deberá comunicarse con la oficina para concretar el pago.¿Desea continuar?';
    dialogConfig.data = {
      title: 'Atención',
      type: type,
      question: type === 'cancel' ? cancel : payLater
    };
    this.dialog.open(CancelConfirmationsComponent, dialogConfig).afterClosed().subscribe((result: any) => {
      if (result !== false) {
        if (type === 'cancel') {
          const booking = {
            IdBooking: idBooking,
            Observations: result,
          };
          this.bookingService.bookingCancel(booking).subscribe(() => {
            this.notificationService.alert('Atención', 'La reserva fue cancelada.');
            this.getDefaulBooking();
          }, error => console.error(error));
        }
      }
    });
  }

  getDestino(event) {
    if (event.target.value) {
      this.tourService.getTourByTitle(event.target.value).subscribe(resp => {
        this.tours = resp.data;
      });
    } else {
      // this.clear();
      this.previousBooking.get('IdTour').setValue('');
      this.getDefaulBooking();
    }
  }

  displayFnTitle(tour?: Tours): string | undefined {
    return tour ? `${tour.title}` : undefined;
  }

  filterBookings(filtro?: boolean) {
    if (this.previousBooking.value.IdTour) {
      if (this.previousBooking.value.IdTour.idTour !== undefined) {
        this.IdTourB = this.previousBooking.value.IdTour.idTour;
      } else {
        this.IdTourB = 0;
      }
    }
    const filter = {
      ...this.previousBooking.value,
      IdTour: this.IdTourB,
      IdUserLogged: this.user.idUser,
    };
    this.bookingService.postFilterBookings(filter).subscribe(resp => {
      this.default = resp.data;
      this.pageActual = 1;
    });
  }

  clear() {
    Object.keys(this.previousBooking.controls).forEach(control => {
      this.previousBooking.get(control).setValue(null, { emitEvent: false });
    });
    this.getDefaulBooking();
    this.pageActual = 1;
  }

  openDetail(rowIndex: number) {
    if (this.user.rol === 'Admin') {
      this.router.navigate(['/admin-page/excursions-detail-admin'], { queryParams: { id: rowIndex, page: 'previousBooking' } });
    } else {
      if (this.user.rol === 'Ofic') {
        this.router.navigate(['/ofic-page/excursions-detail-ofic'],
          { queryParams: { id: rowIndex, page: 'previousBooking' } });
      } else {
        this.router.navigate(['/consult-page/excursions-detail-consult'],
          { queryParams: { id: rowIndex, page: 'previousBooking' } });
      }
    }
  }

  pad(num: number, size: number): string {
    let s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  }
}
