<div class="container">
    <app-header></app-header>
    <div class="card" style="margin-top: 30px;">
        <strong style="font-size: 30px;margin-left: 260px;margin-top: 15px;">Realiza el pago para
            finalizar tu
            compra</strong>

        <strong style="font-size: 15px;margin-left: 200px;margin-top: 20px; margin-bottom: 20px;">Recordá
            que debes
            hacer el depósito
            antes de las 48 horas. De lo contrario, tu solicitud
            será cancelada.</strong>

        <strong style="font-size: 15px;margin-left: 200px;margin-top: 20px; margin-bottom: 20px;">Los
            datos para poder realizar la transferencia bancaria serán enviados al siguiente correo: mari@gmail.com
        </strong>

        <button type="button" class="btn btn-primary"
            style="background-color: #1cac67; color: white;border: #1cac67;width: 100px; margin: 0px 0px 20px 500px;"
            [routerLink]="['/excursions-admin']">Inicio</button>
    </div>
</div>