<!-- <nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark" style="z-index: 1000;">
  <a class="navbar-brand">Central de Reservas</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavDropdown">

    <!--   <ul class="navbar-nav">
        <li class="nav-item px-2">
          <a routerLink="/" class="nav-link">Inicio</a>
        </li>
      </ul>
    --
    <ul class="navbar-nav ml-auto" style="font-size: 17px;">
      <!-- margin-rigth 3 --
      <li class="nav-item">
        <a style="cursor: pointer;" class="nav-link">
          <i class="fas fa-user"></i> Bienvenido
        </a>

      </li>

      <li class="nav-item"
        *ngIf="currentUser?.rol === 'Admin' || currentUser?.rol === 'Ofic' || currentUser?.rol === 'Recep'">
        <a style="cursor: pointer;" class="nav-link" (click)="openCodeDialog()">
          <i class="fas fa-sync-alt"></i> Generar Código
        </a>
      </li>

      <li class="nav-item">
        <a style="cursor: pointer;" class="nav-link" (click)="logout()">
          <i class="fas fa-sign-out-alt"></i> Cerrar Sesión
        </a>
      </li>

    </ul>
  </div>
</nav> -->


<div class="container-fluid contendedor-actividades container-lg mt-4" style="padding-top: 0rem; height: 87px;">
  <span class="title-actividades">Actividades
    <span class="separador"> {{ currentActivity == "" ? "" : currentActivity == undefined ? "" : ">"}}</span>
    <span class="btn-todas" (click)="cleanAllFilters()"> {{ currentActivity == "" ? "" : currentActivity == undefined ? "" : "TODAS"}}</span>
  <span class="separador"> {{ currentActivity == "" ? "" : currentActivity == undefined ? "" : ">"}} </span> <span class="text-uppercase text-rojo font-weight-bold">{{ currentActivity }}</span>
  </span>



  <div id="carouselActividades" class="carousel slide" data-interval="false" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="row m-0">
          <div class="col-2 text-center p-0 pointer" *ngFor="let act of activities | slice : 0 : 6; let k = index"
            (click)="onSelectActivity(act)" [ngClass]="{ currentActivity: isCurrentActivity(act) }" style="margin-top: 1rem; margin-bottom: 1rem;">
            <img *ngIf="isCurrentActivity(act) " class=" " src="assets/img/{{ act.idTypeActivity }}.svg" alt="{{ act.typeActivityDescription }}" style="margin-top: 5px;"/>
            <img *ngIf="!isCurrentActivity(act) " class=" " src="assets/img/{{ act.idTypeActivity }}-inactive.svg" alt="{{ act.typeActivityDescription }}" style="margin-top: 5px;"/>

            <p class="text-center" style="margin-block-end: 0; margin-bottom: 5px;">{{ act.typeActivityDescription }}</p>
          </div>
          <!--<div class="col-2 text-center p-0">
          <img class=" " src="assets/img/ciclismo.svg" alt="First slide">
          <p class="text-center">Ciclismo</p>
        </div>
        <div class="col-2 text-center p-0">
          <img class=" " src="assets/img/excursion.svg" alt="First slide">
          <p class="text-center">Excursión</p>
        </div>
        <div class="col-2 text-center p-0">
          <img class=" " src="assets/img/montanismo.svg" alt="First slide">
          <p class="text-center">Montanismo</p>
        </div>
        <div class="col-2 text-center p-0">
          <img class=" " src="assets/img/paquete.svg" alt="First slide">
          <p class="text-center">Paquete</p>
        </div>
        <div class="col-2 text-center p-0">
          <img class=" " src="assets/img/transfer.svg" alt="First slide">
          <p class="text-center">Transfer</p>
        </div>
        <div class="col-2 text-center p-0">
          <img class=" " src="assets/img/cabalgadas.svg" alt="First slide">
          <p class="text-center">Cabalgada</p>
        </div>-->
        </div>
      </div>
      <div class="carousel-item">
        <div class="row m-0">
          <div class="col-2 text-center p-0 pointer" *ngFor="let act of activities | slice : 6 : 12; let k = index"
            (click)="onSelectActivity(act)" [ngClass]="{ currentActivity: isCurrentActivity(act) }" style="margin-top: 1rem; margin-bottom: 1rem;">
            <img *ngIf="isCurrentActivity(act) " class=" "src="assets/img/{{ act.idTypeActivity }}.svg" alt="{{ act.typeActivityDescription }}" style="margin-top: 5px;"/>
            <img *ngIf="!isCurrentActivity(act) " class=" " src="assets/img/{{ act.idTypeActivity }}-inactive.svg" alt="{{ act.typeActivityDescription }}" style="margin-top: 5px;"/>
            <p class="text-center" style="margin-block-end: 0; margin-bottom: 5px;">{{ act.typeActivityDescription }}</p>
          </div>
          <!--<div class="col-2 text-center p-0">
          <img class=" " src="assets/img/travesia4x4.svg" alt="First slide">
          <p class="text-center">Travesia 4x4</p>
        </div>
        <div class="col-2 text-center p-0">
          <img class=" " src="assets/img/ciclismo.svg" alt="First slide">
          <p class="text-center">Ciclismo</p>
        </div>
        <div class="col-2 text-center p-0">
          <img class=" " src="assets/img/excursion.svg" alt="First slide">
          <p class="text-center">Excursión</p>
        </div>
        <div class="col-2 text-center p-0">
          <img class=" " src="assets/img/montanismo.svg" alt="First slide">
          <p class="text-center">Montanismo</p>
        </div>
        <div class="col-2 text-center p-0">
          <img class=" " src="assets/img/paquete.svg" alt="First slide">
          <p class="text-center">Paquete</p>
        </div>
        <div class="col-2 text-center p-0">
          <img class=" " src="assets/img/transfer.svg" alt="First slide">
          <p class="text-center">Transfer</p>
        </div>
      -->
        </div>
      </div>
    </div>
    <div *ngIf="this.activities.length !== 0">
      <a class="carousel-control-prev" href="#carouselActividades" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true" style="left: -10px"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselActividades" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true" style="right: -10px"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</div>
