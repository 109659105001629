<simple-notifications></simple-notifications>
<div class="dialog-body">
  <div class="row justify-content-between">
    <div class="col">
      <h2 mat-dialog-title>Devoluciones </h2>
    </div>

    <div class="col">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

  </div>

  <hr />
  <div class="mat-typography" style="padding-top: 1px;">
    <form [formGroup]="paymentForm">
      <!-- <div class="card"> -->
      <p style="font-size: 14px;margin-left: 10px;margin-top: 20px;">Fecha: <strong
          style="font-size: 14px;margin-left: 10px;margin-top: 20px;">{{date | date:'dd/MM/yyyy'}}</strong></p>

      <p style="font-size: 14px;margin-left: 10px;margin-top: 20px;"> Total: <strong
          style="font-size: 14px;margin-left: 10px;margin-top: 20px;">$
          {{this.maxRefund}}</strong></p>
      <div class="row">
        <div class="form-group" style="margin-top: 15px;">
          <label for="card" style="font-size: 14px;margin-left: 25px">Forma de pago:</label>

          <mat-form-field style="margin-left: 10px;">
            <mat-select formControlName="IdPaymentMethod">
              <mat-option *ngFor="let op of payMethod" [value]="op.id">
                {{op.muestraValor}}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="paymentForm.controls['IdPaymentMethod'].invalid && (paymentForm.controls['IdPaymentMethod'].dirty || paymentForm.controls['IdPaymentMethod'].touched)">
              <span *ngIf="paymentForm.controls['IdPaymentMethod'].errors?.required">{{ getErrorMessage() }}</span>
            </mat-error>
          </mat-form-field>
        </div>

        <p style="font-size: 14px;margin-left: 25px;margin-top: 30px;">Monto: $</p>
        <mat-form-field class="col-sm-2" style="margin-left: 10px;">
          <input matInput type="number" formControlName="Refund" min="0" maxlength="this.new.length"
            style="margin-top: 15px;"
            onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 || event.keyCode === 188 || event.keyCode === 190 || event.keyCode === 110 ? true : !isNaN(Number(event.key));">
          <mat-error
            *ngIf="paymentForm.controls['Refund'].invalid && (paymentForm.controls['Refund'].dirty || paymentForm.controls['Refund'].touched)">
            <span *ngIf="paymentForm.controls['Refund'].errors?.required">{{ getErrorMessage() }}</span>
            <span *ngIf="this.paymentForm.value.Refund > this.maxRefund">Monto ingresado
              inválido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <!-- </div> -->
    </form>

    <form [formGroup]="bankTransferForm" *ngIf="this.paymentForm.value.IdPaymentMethod === 2"
      style="margin-top: -10px;">
      <div class="row">
        <p style="font-size: 14px;margin-left: 25px;margin-top: 10px;">Fecha de transferencia: </p>
        <mat-form-field class="col-sm-4" style="margin-left: 10px;margin-top: -10px;">
          <input matInput [matDatepicker]="picker1" formControlName="BankTransferDate">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <mat-error
            *ngIf="bankTransferForm.controls['BankTransferDate'].invalid && bankTransferForm.controls['BankTransferDate'].touched">
            <span *ngIf="bankTransferForm.controls['BankTransferDate'].errors['required']">Por favor
              rellene el campo</span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row">
        <p style="font-size: 14px;margin-left: 25px;margin-top: 10px;">Responsable de pago: </p>
        <mat-form-field class="col-sm-6" style="margin-left: 5px;margin-top: -10px;">
          <input matInput formControlName="ResponsiblePayment">
          <mat-error
            *ngIf="bankTransferForm.controls['ResponsiblePayment'].invalid && bankTransferForm.controls['ResponsiblePayment'].touched">
            <span *ngIf="bankTransferForm.controls['ResponsiblePayment'].errors['required']">Por favor
              rellene el campo</span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row">
        <p style="font-size: 14px;margin-left: 25px;margin-top: 10px;">Banco: </p>
        <mat-form-field class="col-sm-8" style="margin-left: 5px;margin-top: -10px;">
          <input matInput formControlName="Bank">
          <mat-error *ngIf="bankTransferForm.controls['Bank'].invalid && bankTransferForm.controls['Bank'].touched">
            <span *ngIf="bankTransferForm.controls['Bank'].errors['required']">Por favor
              rellene el campo</span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row">
        <p style="font-size: 14px;margin-left: 25px;margin-top: 10px;">Cbu: </p>
        <mat-form-field class="col-sm-10" style="margin-left: 5px;margin-top: -10px;">
          <input matInput formControlName="Cbu" mask="0000000000000000000000"
            onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 || event.keyCode === 37 || event.keyCode === 39 ? true : !isNaN(Number(event.key));">
          <mat-error *ngIf="bankTransferForm.controls['Cbu'].invalid && bankTransferForm.controls['Cbu'].touched">
            <span *ngIf="bankTransferForm.controls['Cbu'].errors['required']">Por favor
              rellene el campo</span>
          </mat-error>
        </mat-form-field>
      </div>
    </form>


    <form [formGroup]="paymentPosnetForm"
      *ngIf="this.paymentForm.value.IdPaymentMethod === 3 || this.paymentForm.value.IdPaymentMethod === 5">
      <div class="row">
        <p style="font-size: 14px;margin-left: 25px;margin-top: 20px;">N° de lote: </p>
        <mat-form-field class="col-sm-2" style="margin-left: 10px;">
          <input matInput formControlName="lot" maxlength="3" min="0" mask="000"
            onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))">
          <mat-error *ngIf="paymentPosnetForm.controls['lot'].invalid && paymentPosnetForm.controls['lot'].touched">
            <span *ngIf="paymentPosnetForm.controls['lot'].errors['required']">Por favor
              rellene el
              campo</span>
          </mat-error>
        </mat-form-field>

        <p style="font-size: 14px;margin-left: 25px;margin-top: 20px;">N° de cupón: </p>
        <mat-form-field class="col-sm-2" style="margin-left: 10px;">
          <input matInput formControlName="coupon" min="0" mask="0000"
            onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))"
            maxlength="4">
          <mat-error
            *ngIf="paymentPosnetForm.controls['coupon'].invalid && paymentPosnetForm.controls['coupon'].touched">
            <span *ngIf="paymentPosnetForm.controls['coupon'].errors['required']">Por favor
              rellene el
              campo</span>
          </mat-error>
        </mat-form-field>

        <p style="font-size: 14px;margin-left: 25px;margin-top: 20px;">N° de devolución: </p>
        <mat-form-field class="col-sm-3" style="margin-left: 10px;">
          <input matInput formControlName="RefundNumber" type="text" maxlength="30">
          <mat-error
            *ngIf="paymentPosnetForm.controls['coupon'].invalid && paymentPosnetForm.controls['coupon'].touched">
            <span *ngIf="paymentPosnetForm.controls['coupon'].errors['required']">Por favor
              rellene el
              campo</span>
          </mat-error>
        </mat-form-field>
      </div>
    </form>

    <form [formGroup]="paymentForm">
      <div class="row">
        <p style="font-size: 14px;margin-left: 25px;margin-top: 20px;">
          Observaciones:
        </p>
        <mat-form-field class="col-sm-6">
          <textarea matInput formControlName="Observations"></textarea>
        </mat-form-field>
      </div>
    </form>


    <hr />
    <div style="text-align: right;">
      <!-- <button type="button" class="btn btn-primary col-sm-3"
        style="background-color: #1cac67; float: right; color: white;border: #1cac67;margin-top: 15px;font-size: 14px;"
        (click)="saveReasons(this.paymentForm.value.IdPaymentMethod)" *ngIf="!isLoad"
        [disabled]="this.paymentForm.value.Refund < 0 ">Aplicar</button> -->

      <button mat-raised-button class="btn btn-primary" style="background-color: #1cac67;color: white;"
        (click)="saveReasons(this.paymentForm.value.IdPaymentMethod)" cdkFocusInitial
        [disabled]="this.paymentForm.value.Refund <= 0.0 || this.paymentForm.value.Refund > this.maxRefund"
        *ngIf="!isLoad">Aplicar
      </button>

      <button mat-raised-button class="btn btn-primary" style="background-color: #1cac67;color: white;" cdkFocusInitial
        *ngIf="isLoad" [disabled]="isLoad">
        <span class="spinner-border" style="width: 1rem; height: 1rem; border-width: 0.15em;" role="status"
          aria-hidden="true"></span> Cargando...
      </button>
    </div>
  </div>