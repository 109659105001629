import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-excursions-container',
  templateUrl: './excursions-container.component.html',
  styleUrls: ['./excursions-container.component.scss']
})
export class ExcursionsContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
