import { RolPerson } from './rol-person';

export class Pax {
    public idPax: number;
    public idRolPersonNavigation: RolPerson;
    public language: string;
    public observations: string;

    constructor() { }
}
