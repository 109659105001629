<simple-notifications></simple-notifications>
<div class="modal-header" style="padding: 0; padding-bottom: 10px;">
  <h5 class="modal-title">{{ configuration.title }}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeButton(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{ configuration.question }}</p>
</div>

<div class="row" [formGroup]="cancelForm" *ngIf="this.type === 'cancel'">
  <p style="font-size: 14px;margin-left: 30px;margin-top: 10px;">
    Motivo:
  </p>
  <mat-form-field class="col-sm-9">
    <textarea matInput formControlName="Observations"></textarea>
    <mat-error *ngIf="cancelForm.controls['Observations'].invalid && cancelForm.controls['Observations'].touched">
      <span *ngIf="cancelForm.controls['Observations'].errors['required']">Por favor
        rellene el
        campo</span>
    </mat-error>
  </mat-form-field>
</div>


<div class="modal-footer" style="padding: 0; padding-top: 10px;">
  <button type="button" class="btn btn-success" (click)="onButtonAction(true)" style="background-color: #1cac67;"
    cdkFocusInitial>Aceptar</button>
</div>
