import { DialogDiscountComponent } from './../dialog-discount/dialog-discount.component';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { BookingService } from 'src/app/shared/service/booking.service';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { DialogPaymentComponent } from '../dialog-payment/dialog-payment.component';
import { LoginAuthService } from 'src/app/auth/providers/login-auth.service';
import { ConfirmationComponent } from 'src/app/shared/confirmation/confirmation.component';
import { NotificationsService } from 'angular2-notifications';
import { LayoutService } from 'src/app/shared/service/layout.service';
import { PaymentHistoryComponent } from '../payment-history/payment-history.component';
import { DialogReturnsComponent } from '../dialog-returns/dialog-returns.component';
import { CancelConfirmationsComponent } from '../cancel-confirmations/cancel-confirmations.component';

@Component({
  selector: 'app-booking-admin',
  templateUrl: './booking-admin.component.html',
  styleUrls: ['./booking-admin.component.scss']
})
export class BookingAdminComponent implements OnInit {
  public detail: any;
  public id: number;
  public rowsDetails: UntypedFormArray = this.formBuilder.array([]);
  public pageActual = 1;
  public randomBKA: string;
  user: any;
  now = new Date();
  isPaxs = true;
  public time: any;
  public totalBooking: number;
  public allowRefund: boolean;
  public startDate: any;
  public payment: string;
  public idBooking: number;
  public active: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private bookingService: BookingService,
    private router: Router,
    private dialog: MatDialog,
    private loginAuthService: LoginAuthService,
    private notificationService: NotificationsService,
    private layoutService: LayoutService
  ) { }

  ngOnInit() {
    this.randomBKA = 'id' + this.getRandom();

    this.layoutService.getTime().subscribe(resp => {
      this.time = resp.data;
    });
    this.user = this.loginAuthService.getUser();
    this.activatedRoute.queryParams.subscribe(params => {
      this.id = params.id;
      this.active = params.active;
      this.bookingService.getBookingReport(this.id, this.user.idUser).subscribe(resp => {
        if (resp.data) {
          this.detail = resp.data;
          this.isPaxs = this.detail && this.detail.bookings.length !== 0 ? false : true;
          this.startDate = this.detail.startDate;
        }
      });
    });
  }

  /** form booking */
  getFormGroupDetail(): UntypedFormGroup {
    return this.formBuilder.group({
      id: ['', [Validators.required]],
      bookingDate: [null, [Validators.required]],
      pickUp: ['', [Validators.required]],
      dropOff: ['', [Validators.required]],
      userName: ['', [Validators.required]],
      paxName: ['', [Validators.required]],
      paxMobile: ['', [Validators.required]],
      totalPax: ['', [Validators.required]],
      statusBooking: ['', [Validators.required]],
    });
  }

  /** add the data in the table  */
  addRowDetailToday(event) {
    for (let detail of event) {
      let rowDetail: UntypedFormGroup = this.getFormGroupDetail();
      rowDetail.patchValue({
        id: detail.id,
        bookingDate: detail.bookingDate,
        pickUp: detail.pickUp,
        dropOff: detail.dropOff,
        userName: detail.userName,
        paxName: detail.paxName,
        paxMobile: detail.paxMobile,
        totalPax: detail.totalPax,
        statusBooking: detail.statusBooking,
      });
      this.rowsDetails.push(rowDetail);
    }
  }

  /** directs to main screen */
  openExcusrion() {
    if (this.user.rol === 'Admin') {
      this.router.navigate(['/admin-page/excursions']);
    } else {
      this.router.navigate(['/ofic-page/excursions']);
    }
  }

  /** open payment form pop-up */
  openPaymentDialog(idBooking: number, idPaymentHeader: number, totalPrice: number, balance: number, allowD: boolean, contactEmail: string) {
    const paymentDialogConfig = new MatDialogConfig();
    paymentDialogConfig.disableClose = true;
    paymentDialogConfig.autoFocus = true;
    paymentDialogConfig.maxHeight = '95vh';
    paymentDialogConfig.data = {
      idBooking: idBooking,
      idPaymentHeader: idPaymentHeader,
      totalPrice: totalPrice,
      balance: balance,
      allowDiscount: allowD,
      totalBooking: this.totalBooking,
      startDate: this.startDate,
      time: this.time,
      contactEmail: contactEmail,
    };
    const paymentDialogRef = this.dialog.open(
      DialogPaymentComponent,
      paymentDialogConfig
    );
    paymentDialogRef.afterClosed().subscribe((resp: any) => {
      if (resp === undefined) {
        this.bookingService.getBookingReport(this.id, this.user.idUser).subscribe(resp => {
          if (resp.data) {
            this.detail = resp.data;
            this.isPaxs = this.detail && this.detail.bookings.length !== 0 ? false : true;
          }
        });
      } else {
        this.bookingService.getBookingReport(this.id, this.user.idUser).subscribe(resp => {
          if (resp.data) {
            this.detail = resp.data;
            this.isPaxs = this.detail && this.detail.bookings.length !== 0 ? false : true;
          }
        });
      }
    });
  }

  /** print booking report */
  print() {
    this.bookingService.getBookingResumePrintReport(this.id, this.user.idUser).subscribe(resp => {

      var newBlob = new Blob([resp], { type: 'application/pdf' });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      const nav = (window.navigator as any);
      if (nav && nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data;
      link.download = this.detail.startDate + '-' + this.detail.title + '.pdf';
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    },
      error => {
        console.log('response error ', error);
      });
  }

  getRandom() {
    return Math.random();
  }

  downloadVoucher(index: number) {

    this.bookingService.printVoucher(index).subscribe(resp => {

      var newBlob = new Blob([resp], { type: 'application/pdf' });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      const nav = (window.navigator as any);
      if (nav && nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data;
      link.download = index + '-' + this.detail.title + '-' + 'VOUCHER' + '.pdf';
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    },
      error => {
        console.log('response error ', error);
      });
  }

  onCancelOrPayLaterTheReservation(type: string, idBooking: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    const cancel = 'Está a punto de cancelar la reserva. ¿Desea continuar?';
    const payLater = 'Su reserva no se ha pagado aún, por lo que deberá comunicarse con la oficina para concretar el pago.¿Desea continuar?';
    dialogConfig.data = {
      title: 'Atención',
      type: type,
      question: type === 'cancel' ? cancel : payLater
    };
    this.dialog.open(CancelConfirmationsComponent, dialogConfig).afterClosed().subscribe((result: any) => {
      if (result !== false) {
        if (type === 'cancel') {
          const booking = {
            IdBooking: idBooking,
            Observations: result,
          };
          this.bookingService.bookingCancel(booking).subscribe(() => {
            this.notificationService.alert('Atención', 'La reserva fue cancelada.');
            this.router.navigate(['/admin-page/excursions']);
          }, error => console.error(error));
        }
      } else {
        this.bookingService.getBookingReport(this.id, this.user.idUser).subscribe(resp => {
          if (resp.data) {
            this.detail = resp.data;
            this.isPaxs = this.detail && this.detail.bookings.length !== 0 ? false : true;
            this.startDate = this.detail.startDate;
          }
        });
      }
    });
  }

  editbooking(index: number, idBooking: number, dropOff: string, pickUp: string) {
    if (this.user.rol === 'Admin') {
      this.router.navigate(['/admin-page/forms-admin'], {
        queryParams: {
          idB: idBooking,
          id: this.id, page: 'booking-forms-admin',
          dropOff: dropOff,
          pickUp: pickUp,
        }
      });
    } else {
      this.router.navigate(['/ofic-page/forms-ofic'], {
        queryParams: {
          idB: idBooking,
          id: this.id, page: 'booking-forms-ofic'
        }
      });
    }
  }

  paymentHistory(idBooking: number) {
    const paymentDialogConfig = new MatDialogConfig();
    paymentDialogConfig.disableClose = true;
    paymentDialogConfig.autoFocus = true;
    paymentDialogConfig.maxHeight = '95vh';
    paymentDialogConfig.data = {
      idBooking: idBooking,
      detail: this.detail,
    };
    const paymentDialogRef = this.dialog.open(
      PaymentHistoryComponent,
      paymentDialogConfig
    );
    paymentDialogRef.afterClosed().subscribe((resp: any) => {
    });
  }

  openReturnsDialog(idPaymentHeader: number, totalPrice: number, maxRefund: number) {
    const paymentDialogConfig = new MatDialogConfig();
    paymentDialogConfig.disableClose = true;
    paymentDialogConfig.autoFocus = true;
    paymentDialogConfig.data = {
      idPaymentHeader: idPaymentHeader,
      totalPrice: totalPrice,
      maxRefund: maxRefund,
    };
    const paymentDialogRef = this.dialog.open(
      DialogReturnsComponent
      ,
      paymentDialogConfig
    );
    paymentDialogRef.afterClosed().subscribe((resp: any) => {
      this.totalBooking = resp.totalBooking;
      // this.idBooking ? this.totalUpdate = resp.balance : this.balance = resp.balance;
      this.bookingService.getBookingReport(this.id, this.user.idUser).subscribe(resp => {
        if (resp.data) {
          this.detail = resp.data;
          this.isPaxs = this.detail && this.detail.bookings.length !== 0 ? false : true;
        }
      });
    });
  }

  openBookingN() {
   
    if (this.user.rol === 'Admin') {
      this.router.navigate(['/admin-page/forms-admin'], { queryParams: { id: this.id, number: 0, sheet: 1 } });
    } else if (this.user.rol === 'Ofic') {
      this.router.navigate(['/ofic-page/forms-ofic'], { queryParams: { id: this.id, number: 0 } });
    } else {
      
      this.router.navigate(['/recep-page/forms-recep'], { queryParams: { id: this.id, number: 0 } });
    }
  }

  disableBooking(){
    if(this.active == "true"){
      return true;
    }else{
      return false;
    }
  }
}
