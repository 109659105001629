import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pax-forms-admin',
  templateUrl: './pax-forms-admin.component.html',
  styleUrls: ['./pax-forms-admin.component.scss']
})
export class PaxFormsAdminComponent implements OnInit {

  @ViewChild('stepper') stepper;
  //userType = 0;
  idLayout: number;
  typeSite = 2;

  constructor(
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.idLayout = params.id;
    });
  }

}

