import { Router } from '@angular/router';
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { PaymentDetails } from 'src/app/shared/class/paymentDetails';
import { BookingService } from 'src/app/shared/service/booking.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { CreditcardTicket } from 'src/app/shared/class/creditcard-ticket';
import { NotificationsService } from 'angular2-notifications';
import { AdnValidators } from 'src/app/shared/validators/adn-validators';
import { ConfirmationComponent } from 'src/app/shared/confirmation/confirmation.component';
import { LayoutService } from 'src/app/shared/service/layout.service';
import { LoginAuthService } from 'src/app/auth/providers/login-auth.service';
import { BankTransfers } from 'src/app/shared/class/bank-transfers';

@Component({
  selector: 'app-dialog-payment',
  templateUrl: './dialog-payment.component.html',
  styleUrls: ['./dialog-payment.component.scss']
})
export class DialogPaymentComponent implements OnInit {
  public paymentForm: UntypedFormGroup;
  public paymentPosnetForm: UntypedFormGroup;
  public date = new Date();
  public paymentDetail: PaymentDetails = new PaymentDetails();
  public idpayment: number;
  public total: number;
  public saldo: number;
  public disabled = true;
  public idB: number;
  cardTypes = ['Crédito', 'Débito'];
  cards = ['Visa', 'Master', 'Naranja'];
  public isCheck = false;
  public isLoad: boolean;
  public user: any;
  public userType: number;
  quotes = [{ show: '2', value: '2' }, { show: '3', value: '3' }, { show: '6', value: '6' },
  { show: '12', value: '12' }, { show: 'Otros', value: '0' }];
  public totalBooking: number;
  public totalPrice: number;
  public isLoadD: boolean;
  public checkControl = new UntypedFormControl();
  public paymentDiscountForm: UntypedFormGroup;
  public isCheckDisc: boolean;
  public allowDiscount: boolean;
  public message: string;
  public isDiscount = true;
  public startDate: any;
  public time: any;
  public email: string;
  view = false;
  public mp: any;
  public bankTransferForm: UntypedFormGroup;
  public prueba: any;

  public reasons = [{ show: 'Convenios', value: 'convenios' }, { show: 'Devolución', value: 'devolución' }, { show: 'Discapacidad', value: 'discapacidad' },
  { show: 'Gremios', value: 'gremios' }, { show: 'Grupo familiar', value: 'grupo familiar' }, { show: 'Jubilados', value: 'jubilados' }, { show: 'Menores', value: 'menores' },
  { show: 'Otros', value: 'otros' }, { show: 'Pago en efectivo', value: 'pago en efectivo' }];

  public payMethod = [
    { valor: 'Mercado Pago', muestraValor: 'Mercado Pago', id: 1 },
    { valor: 'Transferencia Bancaria', muestraValor: 'Transferencia Bancaria', id: 2 },
    { valor: 'Posnet', muestraValor: 'Posnet', id: 3 },
    { valor: 'Posnet - Mercado Pago', muestraValor: 'Posnet - Mercado Pago', id: 5 },
    { valor: 'Efectivo', muestraValor: 'Efectivo', id: 4 },
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private bookingService: BookingService,
    private providerDialog: MatDialogRef<DialogPaymentComponent>,
    private notificationService: NotificationsService,
    public dialog: MatDialog,
    private router: Router,
    private layoutService: LayoutService,
    private loginAuthService: LoginAuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.idB = this.data.idBooking; // idBooking
    this.idpayment = this.data.idPaymentHeader; // idPaymentHeader
    this.total = this.data.balance;
    this.totalPrice = this.data.totalPrice;
    this.allowDiscount = this.data.allowDiscount;
    this.totalBooking = this.data.totalBooking;
    this.startDate = this.data.startDate;
    this.time = this.data.time;
    this.email = this.data.contactEmail;
  }

  ngOnInit() {
    this.user = this.loginAuthService.getUser();
    if (this.user.rol === 'Admin' || this.user.rol === 'Ofic') {
      this.userType = 0;
    } else {
      if (this.user.rol === 'User') {
        this.userType = 1;
      } else {
        this.userType = 2;
      }
    }
    this.paymentForm = this.formBuilder.group({
      Amount: ['', [Validators.required, AdnValidators.validateAmount(this.total)]],
      IdPaymentMethod: ['', [Validators.required]],
      Vps: [''],
      PostPaymentRemarks: [''],
    });
    this.paymentPosnetForm = this.formBuilder.group({
      lot: [''],
      coupon: [''],
      card: ['', [Validators.required]],
      typeCard: ['', [Validators.required]],
      NoDues: [''],
      Surcharges: [''],
      Observations: [''],
      NumberOperation: [''],
    });
    this.bankTransferForm = this.formBuilder.group({
      Bank: ['', [Validators.required]],
      ResponsiblePayment: ['', [Validators.required]],
      BankTransferDate: ['', [Validators.required]],
      Cbu: ['', [Validators.required]],
    });
    this.paymentDiscountForm = this.formBuilder.group({
      Discount: ['', [Validators.required, AdnValidators.validateDiscount(this.total, this.userType)]],
      DiscountReason: ['', [Validators.required]],
      Observations: ['']
    });
    this.paymentDiscountForm.get('DiscountReason').valueChanges.subscribe(resp => {
      this.paymentDiscountForm.get('Observations').setValidators(resp === 'Otros' ? [Validators.required] : []);
      this.paymentDiscountForm.get('Observations').updateValueAndValidity();
      this.paymentDiscountForm.get('Observations').setValue('');
    });
    this.paymentForm.get('PostPaymentRemarks').setValidators(this.startDate < this.time ? [Validators.required] : []);

    this.bookingService.getMercado(this.idB).subscribe(resp => {
      this.view = true;
      this.mp = resp.data;
      this.isLoad = false;
    });
  }

  cancel() {
    this.providerDialog.close();
  }

  getSaldo(event): number {
    if (event === 5 || event === 3) {
      this.saldo = (this.total + this.paymentPosnetForm.value.Surcharges) - (this.paymentForm.value.Amount + this.paymentPosnetForm.value.Surcharges);
      if (this.saldo === 0) {
        this.disabled = false;
        this.isCheck = true; // if necesary change dinamyc, comment this line,because this method called every change
      } else if (this.saldo <= 0) {
        this.disabled = false;
        this.isCheck = true; // if necesary change dinamyc, comment this line,because this method called every change
      } else {
        this.disabled = true;
        this.isCheck = false;
      }
    } else {
      this.saldo = this.total - (this.paymentForm.value.Amount + this.paymentPosnetForm.value.Surcharges);
      if (this.saldo === 0) {
        this.disabled = false;
        this.isCheck = true; // if necesary change dinamyc, comment this line,because this method called every change
      } else if (this.saldo <= 0) {
        this.disabled = false;
        this.isCheck = true; // if necesary change dinamyc, comment this line,because this method called every change
      } else {
        this.disabled = true;
        this.isCheck = false;
      }
    }
    return this.saldo;
  }

  savePaymentDetails(event) {
    if (event === 5 || event === 3) {
      if (this.paymentForm.valid && this.paymentPosnetForm.valid && this.paymentForm.value.Amount <= this.total) {
        this.isLoad = true;
        const creditTicket = new CreditcardTicket();
        this.paymentDetail.PaymentDetailDate = this.date;
        this.paymentDetail.Amount = this.paymentForm.value.Amount;
        this.paymentDetail.IdPaymentMethod = this.paymentForm.value.IdPaymentMethod;
        this.paymentDetail.IdUser = this.user.idUser;
        this.paymentDetail.IdPaymentHeader = this.idpayment;
        this.paymentDetail.Surcharges = this.paymentPosnetForm.value.Surcharges;
        this.paymentDetail.Observations = this.paymentPosnetForm.value.Observations;
        this.paymentDetail.postPaymentRemarks = this.paymentForm.value.PostPaymentRemarks;
        creditTicket.lot = this.paymentPosnetForm.value.lot;
        creditTicket.coupon = this.paymentPosnetForm.value.coupon;
        creditTicket.TypeCard = this.paymentPosnetForm.value.typeCard;
        creditTicket.CardIssuer = this.paymentPosnetForm.value.card;
        creditTicket.CreditcardTicketDate = this.date;
        creditTicket.NoDues = this.paymentPosnetForm.value.NoDues;
        creditTicket.NumberOperation = this.paymentPosnetForm.value.NumberOperation;
        this.paymentDetail.CreditcardTicket.push(creditTicket);

        this.bookingService.postPaymentDetails(this.paymentDetail).subscribe(() => {
          if (!this.disabled && this.isCheck) {
            this.bookingService.getPaymentVoucher(this.idB).subscribe(data => {
              this.notificationService.success('Éxito', 'El pago se registró correctamente');
              this.providerDialog.close(true);
              this.isLoad = false;
            });
          } else {
            this.notificationService.success('Éxito', 'El pago se registró correctamente');
            this.providerDialog.close(true);
            this.isLoad = false;
          }
        });
      } else {
        this.notificationService.warn('Error', 'Debe completar todos los campos');
      }
    } else {
      if (event === 1 || event === 4) {
        if (this.paymentForm.valid && this.paymentForm.value.Amount <= this.total) {
          this.isLoad = true;
          this.paymentDetail.PaymentDetailDate = this.date;
          this.paymentDetail.Amount = this.paymentForm.value.Amount;
          this.paymentDetail.IdPaymentMethod = this.paymentForm.value.IdPaymentMethod;
          this.paymentDetail.IdUser = this.user.idUser;
          this.paymentDetail.IdPaymentHeader = this.idpayment;
          this.paymentDetail.Vps = this.paymentForm.value.Vps;
          this.paymentDetail.postPaymentRemarks = this.paymentForm.value.PostPaymentRemarks;

          this.bookingService.postPaymentDetails(this.paymentDetail).subscribe(() => {
            if (!this.disabled && this.isCheck) {
              this.bookingService.getPaymentVoucher(this.idB).subscribe(data => {
                this.notificationService.success('Éxito', 'El pago se registró correctamente');
                this.providerDialog.close(true);
                this.isLoad = false;
              });
            } else {
              this.notificationService.success('Éxito', 'El pago se registró correctamente');
              this.providerDialog.close(true);
              this.isLoad = false;
            }
          });
        } else {
          this.notificationService.warn('Error', 'Debe completar todos los campos');
          this.isLoad = false;
        }
      } else {
        if (event === 2) {
          if (this.paymentForm.valid && this.bankTransferForm.valid && this.paymentForm.value.Amount <= this.total) {
            this.isLoad = true;
            const bankTranfer = new BankTransfers();
            this.paymentDetail.PaymentDetailDate = this.date;
            this.paymentDetail.Amount = this.paymentForm.value.Amount;
            this.paymentDetail.IdPaymentMethod = this.paymentForm.value.IdPaymentMethod;
            this.paymentDetail.IdUser = this.user.idUser;
            this.paymentDetail.IdPaymentHeader = this.idpayment;
            this.paymentDetail.Vps = this.paymentForm.value.Vps;
            this.paymentDetail.postPaymentRemarks = this.paymentForm.value.PostPaymentRemarks;
            bankTranfer.Bank = this.bankTransferForm.value.Bank;
            bankTranfer.BankTransferDate = this.bankTransferForm.value.BankTransferDate;
            bankTranfer.ResponsiblePayment = this.bankTransferForm.value.ResponsiblePayment;
            bankTranfer.Cbu = this.bankTransferForm.value.Cbu;
            this.paymentDetail.BankTransfers.push(bankTranfer);

            this.bookingService.postPaymentDetails(this.paymentDetail).subscribe(() => {
              if (!this.disabled && this.isCheck) {
                this.bookingService.getPaymentVoucher(this.idB).subscribe(data => {
                  this.notificationService.success('Éxito', 'El pago se registró correctamente');
                  this.providerDialog.close(true);
                  this.isLoad = false;
                });
              } else {
                this.notificationService.success('Éxito', 'El pago se registró correctamente');
                this.providerDialog.close(true);
                this.isLoad = false;
              }
            });
          } else {
            this.notificationService.warn('Error', 'Debe completar todos los campos');
            this.isLoad = false;
          }
        } else {
          this.notificationService.warn('Error', 'Debe completar todos los campos');
          this.isLoad = false;
        }
      }
    }
  }

  getErrorMessage() {
    return 'Este campo es requerido';
  }

  paymentConfirmation(event) {
    if (event === 3 || event === 5) {
      if (this.paymentForm.valid && this.paymentPosnetForm.valid) {
        let amountSurcharges = this.paymentForm.value.Amount + this.paymentPosnetForm.value.Surcharges;
        const paymentDialogConfig = new MatDialogConfig();
        paymentDialogConfig.data = {
          title: 'Confirmación de pago',
          question: 'El monto a pagar es de: $' + amountSurcharges + ' .¿Desea continuar?'
        };
        const paymentDialogRef = this.dialog.open(
          ConfirmationComponent,
          paymentDialogConfig
        );
        paymentDialogRef.afterClosed().subscribe((resp: any) => {
          if (resp) {
            this.savePaymentDetails(event);
          }
        });
      } else {
        this.notificationService.warn('Error', 'Debe completar todos los campos');
        this.isLoad = false;
      }
    } else {
      if (event === 4) {
        if (this.paymentForm.valid) {
          const paymentDialogConfig = new MatDialogConfig();
          paymentDialogConfig.data = {
            title: 'Confirmación de pago',
            question: 'El monto a pagar es de: $' + this.paymentForm.value.Amount + ' .¿Desea continuar?'
          };
          const paymentDialogRef = this.dialog.open(
            ConfirmationComponent,
            paymentDialogConfig
          );
          paymentDialogRef.afterClosed().subscribe((resp: any) => {
            if (resp) {
              this.savePaymentDetails(event);
            }
          });
        } else {
          this.notificationService.warn('Error', 'Debe completar todos los campos');
          this.isLoad = false;
        }
      } else {
        if (this.paymentForm.valid && this.bankTransferForm.valid) {
          const paymentDialogConfig = new MatDialogConfig();
          paymentDialogConfig.data = {
            title: 'Confirmación de pago',
            question: 'El monto a pagar es de: $' + this.paymentForm.value.Amount + ' .¿Desea continuar?'
          };
          const paymentDialogRef = this.dialog.open(
            ConfirmationComponent,
            paymentDialogConfig
          );
          paymentDialogRef.afterClosed().subscribe((resp: any) => {
            if (resp) {
              this.savePaymentDetails(event);
            }
          });
        } else {
          this.notificationService.warn('Error', 'Debe completar todos los campos');
          this.isLoad = false;
        }
      }
    }
  }

  saveDiscount() {
    if (this.paymentDiscountForm.valid) {
      this.isLoadD = true;
      this.paymentDetail.PaymentDetailDate = this.date;
      this.paymentDetail.Discount = this.paymentDiscountForm.value.Discount;
      this.paymentDetail.DiscountReason = this.paymentDiscountForm.value.DiscountReason;
      this.paymentDetail.Observations = this.paymentDiscountForm.value.Observations;
      this.paymentDetail.IdUser = this.user.idUser;
      this.paymentDetail.IdPaymentHeader = this.idpayment;

      this.bookingService.postPaymentDiscount(this.paymentDetail).subscribe((resp: any) => {
        this.notificationService.success('Éxito', 'El descuento se registró correctamente');
        this.total = resp.data.balance;
        this.allowDiscount = resp.data.allowDiscount;
        this.isLoadD = false;
        this.paymentDiscountForm.patchValue({ Discount: '', DiscountReason: '', Observations: '' });
        this.clear();
        this.isCheckDisc = false;
      }, (error) => {
        console.error(this.message);
        this.notificationService.warn(`No se pueden realizar más descuentos`);
        this.isLoadD = false;
        this.isDiscount = false;
      });
    } else {
      this.notificationService.warn('Error, debe completar todos los campos');
    }
  }

  changeCheck(e) {
    this.isCheckDisc = e.checked;
  }

  clear() {
    this.checkControl.reset();
  }

  sendEmail() {
    const mail = {
      Url: this.mp,
      IdBooking: this.idB,
    };
    this.bookingService.postMailMP(mail).subscribe(resp => {
      this.providerDialog.close();
    });
  }
}
