<app-container>

    <!--HEADER-->
    <app-header header></app-header>
    <!--CONTENIDO-->
    <app-container content></app-container>
    <!--FOOT-->
    <app-foot foot></app-foot>

</app-container>
