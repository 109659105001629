import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public jwtHelper: JwtHelperService
  ) { }

  /**
   * Check whether the token is expired and return.
   */
  isAuthenticated(): boolean {
    const token = sessionStorage.getItem('token');

    return !this.jwtHelper.isTokenExpired(token);
  }

  getAuth() {
    return sessionStorage.getItem('user');
  }

  setAuth(user) {
    sessionStorage.setItem('idUser', user.idUser);
    sessionStorage.setItem('rol', user.rol);
    sessionStorage.setItem('userAccount', user.userAccount);
  }

  getRole() {
    return sessionStorage.getItem('rol');
  }
}
