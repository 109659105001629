import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { BookingService } from 'src/app/shared/service/booking.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { LoginAuthService } from 'src/app/auth/providers/login-auth.service';
import { PaymentHistoryComponent } from '../payment-history/payment-history.component';
import { DialogDiscountComponent } from '../dialog-discount/dialog-discount.component';


@Component({
  selector: 'app-booking-user',
  templateUrl: './booking-user.component.html',
  styleUrls: ['./booking-user.component.scss']
})
export class BookingUserComponent implements OnInit {
  public detail: any;
  public details = [];
  public id: number;
  public rowsDetails: UntypedFormArray = this.formBuilder.array([]);
  public pageActual = 1;
  user: any;
  now = new Date();
  public randomBKU: string;
  isPaxs = true;
  public allowDiscount: boolean;
  public isDiscount = true;
  public time: any;
  public startDate: any;
  public active: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private bookingService: BookingService,
    private router: Router,
    private dialog: MatDialog,
    private loginAuthService: LoginAuthService
  ) { }

  ngOnInit() {
    this.randomBKU = 'id' + this.getRandom();
    this.user = this.loginAuthService.getUser();
    this.activatedRoute.queryParams.subscribe(params => {
      this.id = params.id;
      this.active = params.active;
      this.bookingService.getBookingReport(this.id, this.user.idUser).subscribe(resp => {
        if (resp.data) {
          this.detail = resp.data;
          this.isPaxs = this.detail && this.detail.bookings.length !== 0 ? false : true;
          this.startDate = this.detail.startDate;
        }
      });
    });
  }

  print() {
    this.bookingService.getBookingResumePrintReport(this.id, this.user.idUser).subscribe(resp => {
      var newBlob = new Blob([resp], { type: 'application/pdf' });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      const nav = (window.navigator as any);
      if (nav && nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data;
      link.download = this.detail.startDate + '-' + this.detail.title + '.pdf';
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    },
      error => {
        console.log('response error ', error);
      });
  }

  /** directs to main screen */
  returnExcursion() {
    this.router.navigate(['/recep-page/excursions-user']);
  }

  getRandom() {
    return Math.random();
  }

  paymentHistory(index: number) {
    const paymentDialogConfig = new MatDialogConfig();
    paymentDialogConfig.disableClose = true;
    paymentDialogConfig.autoFocus = true;
    paymentDialogConfig.data = {
      idBooking: this.detail.bookings[index].idBooking,
      detail: this.detail,
    };
    const paymentDialogRef = this.dialog.open(
      PaymentHistoryComponent,
      paymentDialogConfig
    );
    paymentDialogRef.afterClosed().subscribe((resp: any) => {
    });
  }

  openDiscountDialog(event) {
    const paymentDialogConfig = new MatDialogConfig();
    paymentDialogConfig.disableClose = true;
    paymentDialogConfig.autoFocus = true;
    paymentDialogConfig.data = {
      booking: this.detail,
      id: event,
    };
    const paymentDialogRef = this.dialog.open(
      DialogDiscountComponent,
      paymentDialogConfig
    );
    paymentDialogRef.afterClosed().subscribe((resp: any) => {
      this.bookingService.getBookingReport(this.id, this.user.idUser).subscribe(resp => {
        if (resp.data) {
          this.detail = resp.data;
          this.isPaxs = this.detail && this.detail.bookings.length !== 0 ? false : true;
          this.startDate = this.detail.startDate;
        }
      });
    });
  }

  openBookingNUser() {
    this.router.navigate(['/recep-page/forms-recep'], { queryParams: { id: this.id, number: 0 } });
  }

  disableBooking(){
    if(this.active == "true"){
      return true;
    }else{
      return false;
    }
  }
}
