import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-email-admin',
  templateUrl: './email-admin.component.html',
  styleUrls: ['./email-admin.component.scss']
})
export class EmailAdminComponent implements OnInit {

  public emailForm: UntypedFormGroup;
  UserEmail: string;

  constructor(
    private providerDialog: MatDialogRef<EmailAdminComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) data
  ) { }

  ngOnInit() {
    this.emailForm = this.fb.group({
      UserEmail: [this.UserEmail, []],
    });
  }

  send() {
    this.providerDialog.close(this.emailForm.value);
  }

  cancel() {
    this.providerDialog.close(false);
  }

}
