import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Code } from 'src/app/shared/class/code';
import { NotificationsService } from 'angular2-notifications';
import { LoginAuthService } from '../../providers/login-auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  public code: Code;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationsService,
    private loginAuthService: LoginAuthService
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      code1: ['', [Validators.required]],
    });
  }

  openAdmin() {
    this.router.navigate(['/vendedores']);
  }

  validateCode() {
    if (this.loginForm.valid) {
      if (this.loginForm.value) {
        this.loginAuthService.authenticateGuest(this.loginForm.value).then((resp: boolean) => {
          if (resp) {
            this.notificationService.success('Éxito', 'Ingresó correctamente');
            this.router.navigate(['/guest-page/excursions-guest']);
          } else {
            this.notificationService.warn('Error', 'Código ingresado inválido');
          }
        }).catch(error => console.error(error));
      }
    }
  }

}
