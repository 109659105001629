<!--<app-navbar *ngIf="userType === 2"></app-navbar>-->
<div class="container-fluid container-lg bg-azul-light p-3">
  <div class="row">
    <div class="col-5">
      <span (click)="redirect()" style="cursor:pointer"><i class="fa-solid fa-chevron-left text-rojo" style="font-size: 10px"></i> Volver</span>
    </div>
    <div class="col-7 text-right">
      <span class=""><strong>RESERVAS</strong></span>
    </div>
  </div>
</div>


<div *ngFor="let d of detail" class="container-fluid container-lg p-0">

  <div class="row title-result-destino m-0 align-items-center pl-2 pr-3">
    <div class="col-auto p-2 text-center">
      <i class="fa-solid fa-location-dot"></i>
    </div>
    <div class="col p-2 text-left">
      <h5 class="card-title m-0"><small>Destino:</small><br>{{d.endingPlace}}</h5>
    </div>
  </div>
  <div class="row title-result-desde m-0 align-items-center pl-2 pr-3" style="background-color: #e3f1f8">
    <div class="col-auto p-2 text-center">
      <i class="fa-sharp fa-solid fa-location-crosshairs"></i>
    </div>
    <div class="col p-2 text-left">
      <h5 class="card-title m-0"><small class="text-dark">Salida desde:</small><br>{{d.startingPlace}}</h5>
    </div>
  </div>
</div>


        <mat-horizontal-stepper [linear]="true" #stepper class="container-fluid container-lg p-0 bg-azul-light">
          <!-- STEP ONE: REGISTRO DE RESERVAS -->
          <mat-step [stepControl]="firstFormToControl" [editable]="isEditableStepOne">
            <ng-template matStepLabel>{{"RESERVA" | translate}}</ng-template>
            <div class="row">
              <div class="col-sm-5">
                <form [formGroup]="bookingForm">
                  <div class="card" *ngFor="let det of detail">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <div class="row justify-content-between">


                            <div class="col-6 mt-2" *ngIf="!idBooking">
                              <p><span class="text-azul">Salida: </span> <b>{{det.startDate | date:'dd/MM/yyyy'}}</b></p>
                            </div>

                            <mat-form-field class="col-6" *ngIf="idBooking">
                              <mat-select placeholder="Fecha" formControlName="startDate"
                                (selectionChange)="selectionDate($event)">
                                <mat-option *ngFor="let op of dateStart" [value]="op.idLayout">
                                  {{op.startDate | date:'short'}} - Precio: $ {{op.price}}
                                </mat-option>
                              </mat-select>
                              <mat-error
                                *ngIf="bookingForm.controls['startDate'].invalid && bookingForm.controls['startDate'].touched">
                                <span *ngIf="bookingForm.controls['startDate'].errors['required']">Por favor
                                  rellene el campo</span>
                              </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col-6" floatLabel="always">
                              <mat-label>
                                <i class="fas fa-suitcase-rolling text-azul mr-1" aria-hidden="true"></i> Cantidad de pasajeros:</mat-label>
                                <input matInput placeholder="Cantidad" type="number" formControlName="quantity" max="100"
                                min="1"
                                onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))">
                              <mat-error
                                *ngIf="bookingForm.controls['quantity'].invalid && bookingForm.controls['quantity'].touched">
                                <span *ngIf="bookingForm.controls['quantity'].errors['required']">Por favor
                                  rellene el campo.</span>
                                <span *ngIf="this.bookingForm.value.quantity > this.free || this.bookingForm.value.quantity > this.lockSeat ||
                                  this.bookingForm.value.quantity < 1 && type===1">Cant.inválida.</span>
                                <span *ngIf="
                                  this.bookingForm.value.quantity < 2 && type === 2">Cant.min.: 2</span>
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>

                        <mat-form-field class="col-sm-12" *ngIf="this.userType !== 2">
                          <mat-label>¿Dónde lo buscamos?:</mat-label>
                          <input matInput placeholder="¿Dónde lo buscamos?" formControlName="pickUp"
                            [readonly]="!disablePax()">
                          <mat-error
                            *ngIf="bookingForm.controls['pickUp'].invalid && bookingForm.controls['pickUp'].touched">
                            <span *ngIf="bookingForm.controls['pickUp'].errors['required']">Por favor
                              rellene el campo</span>
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-sm-12" *ngIf="this.userType !== 2">
                          <mat-label>¿Dónde lo dejamos?:</mat-label>
                          <input matInput placeholder="¿Dónde lo dejamos?" formControlName="dropOff"
                            [readonly]="!disablePax()">
                          <mat-error
                            *ngIf="bookingForm.controls['dropOff'].invalid && bookingForm.controls['dropOff'].touched">
                            <span *ngIf="bookingForm.controls['dropOff'].errors['required']">Por favor
                              rellene el campo</span>
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-sm-12">
                          <mat-label>Lugar de alojamiento:</mat-label>
                          <input matInput placeholder="Ej. Hotel Cregorio I, Jujuy" formControlName="hotel"
                            [readonly]="!disablePax()">
                          <mat-error
                            *ngIf="bookingForm.controls['hotel'].invalid && bookingForm.controls['hotel'].touched">
                            <span *ngIf="bookingForm.controls['hotel'].errors['required']">Por favor
                              rellene el campo</span>
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-sm-12">
                          <mat-label>Observaciones:</mat-label>
                          <textarea matInput placeholder="¿Ustedes tiene alguna dificultad física?" formControlName="observations"
                            [readonly]="!disablePax()"></textarea>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </form>

                <div class="card mt-2 mb-2" *ngFor="let det of detail">
                  <div class="card-body">
                    <div class="row" style="font-size: 16px;">
                      <div class="col-12 text-center" style="font-size: 16px;">

                          {{"PRECIO UNITARIO" | translate }}:
                          <b>${{ getPrice(det.price) }}</b>

                        <strong style="margin-left: 30px;font-size: 17px;"><i class="fa-solid fa-circle-info text-azul mr-1"></i> Total:
                          ${{getTotal(det.price)}}
                        </strong>
                      </div>
                    </div>
                    <div class="row mt-3" [formGroup]="bookingForm"
                      *ngIf="userType === 0">
                      <div class="col-12 text-center" style="font-size: 16px;">
                      <mat-form-field class="col-sm-12" style="margin-top: -15px;"
                        *ngIf=" this.startDate > this.time && status !== 'disable'">
                        <mat-label>Vendedor:</mat-label>
                        <mat-select formControlName="idSeller">
                          <mat-option>Ninguno</mat-option>
                          <mat-option *ngFor="let set of seller" [value]="set.idUser">
                            {{set.lastName}}
                            {{set.firstName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="col-sm-6" style="margin-top: -15px;"
                        *ngIf="(idBooking && this.startDate < this.time) || status === 'disable'">
                        <input matInput formControlName="idSeller" [readonly]="!disablePax()">
                      </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-sm-7">
                <mat-accordion *ngIf="this.bookingForm.valid">
                  <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Contacto de reserva
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <form (submit)="savePax()" [formGroup]="paxForm">
                      <div class="row">

                        <mat-form-field class="col-sm-6">
                          <mat-label>Tipo de Documento</mat-label>
                          <mat-select formControlName="idType" [disabled]="!disablePax()">
                            <mat-option *ngFor="let type of types" [value]="type.value">{{ type.show }}</mat-option>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field class="col-sm-6" *ngIf="viewAccordingSelectedIdType(paxForm.value.idType)">
                          <mat-label>Número:</mat-label>
                          <input matInput placeholder="Número" [formControl]="dniControl" mask="00000000"
                            [readonly]="!disablePax()">
                          <mat-error *ngIf="dniControl.invalid && dniControl.touched">
                            <span *ngIf="dniControl.errors['required']">Por favor
                              rellene el campo</span>
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-sm-6" *ngIf="!viewAccordingSelectedIdType(paxForm.value.idType)">
                          <mat-label>Número:</mat-label>
                          <input matInput placeholder="Número" [formControl]="passportControl" [readonly]="!disablePax()">
                          <mat-error *ngIf="passportControl.invalid && passportControl.touched">
                            <span *ngIf="passportControl.errors['required']">Por favor
                              rellene el campo</span>
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-sm-6">
                          <mat-label>Nombre:</mat-label>
                          <input matInput placeholder="Nombre" formControlName="firstName" [readonly]="!disablePax()">
                          <mat-error
                            *ngIf="paxForm.controls['firstName'].invalid && paxForm.controls['firstName'].touched">
                            <span *ngIf="paxForm.controls['firstName'].errors['required']">Por favor
                              rellene el
                              campo</span>
                            <span *ngIf="paxForm.controls['firstName'].errors['pattern']">Formato
                              incorrecto</span>
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-sm-6">
                          <mat-label>Apellido:</mat-label>
                          <input matInput placeholder="Apellido" formControlName="lastName" [readonly]="!disablePax()">
                          <mat-error
                            *ngIf="paxForm.controls['lastName'].invalid && paxForm.controls['lastName'].touched">
                            <span *ngIf="paxForm.controls['lastName'].errors['required']">Por favor
                              rellene el
                              campo</span>
                            <span *ngIf="paxForm.controls['lastName'].errors['pattern']">Formato
                              incorrecto</span>
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-sm-6">
                          <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker2"
                            placeholder="Fecha de nacimiento: (Ej: dd/mm/yyyy)" formControlName="birthDate"
                            [readonly]="!disablePax()"
                            onkeydown="javascript: return (event.keyCode === 111 || (event.shiftKey && event.keyCode === 55) || event.keyCode === 46 || event.keyCode === 8) ? true : !isNaN(Number(event.key))">
                          <mat-datepicker-toggle matSuffix [for]="picker2" [disabled]="!disablePax()">
                          </mat-datepicker-toggle>
                          <mat-datepicker #picker2 [disabled]="!disablePax()"></mat-datepicker>
                          <mat-error
                            *ngIf="paxForm.controls['birthDate'].invalid && paxForm.controls['birthDate'].touched">
                            <span *ngIf="paxForm.controls['birthDate'].errors['required']">Por favor
                              rellene el campo</span>
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-sm-6">
                          <mat-select placeholder="Nacionalidad" formControlName="nationality"
                            [disabled]="!disablePax()">
                            <mat-option *ngFor="let op of nationality" [value]="op.description">{{op.description}}
                            </mat-option>
                          </mat-select>
                          <mat-error
                            *ngIf="paxForm.controls['nationality'].invalid && paxForm.controls['nationality'].touched">
                            <span *ngIf="paxForm.controls['nationality'].errors['required']">Por favor
                              rellene el campo</span>
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-sm-6">
                          <mat-label>Celular: (Ej: cod.País+cod.Área+Número)</mat-label>
                          <input matInput placeholder="Celular" formControlName="mobile" [readonly]="!disablePax()"
                            onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 || event.keyCode === 86 ? true : !isNaN(Number(event.key))"
                            maxlength="20">
                          <mat-error *ngIf="paxForm.controls['mobile'].invalid && paxForm.controls['mobile'].touched">
                            <span *ngIf="paxForm.controls['mobile'].errors['required']">Por favor
                              rellene el
                              campo</span>
                            <span *ngIf="paxForm.controls['mobile'].errors['pattern']">Formato
                              incorrecto</span>
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-sm-6">
                          <mat-label>E-mail: (ejemplo@gmail.com)</mat-label>
                          <input matInput placeholder="Email" formControlName="email" [readonly]="!disablePax()">
                          <mat-error *ngIf="paxForm.controls['email'].invalid && paxForm.controls['email'].touched">
                            <span *ngIf="paxForm.controls['email'].errors['required']">Por favor
                              rellene el campo</span>
                            <span *ngIf="paxForm.controls['email'].errors['email']">Formato
                              incorrecto</span>
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-sm-12">
                          <mat-label>Observaciones:</mat-label>
                          <textarea matInput placeholder="Observaciones" formControlName="observations"
                            [readonly]="!disablePax()"></textarea>
                        </mat-form-field>

                        <div class="col-sm-12" style="text-align: right;" *ngIf="userType !== 2">
                          <mat-checkbox formControlName="reservationContact" color="primary">
                            ¿Es pasajero?
                          </mat-checkbox>
                        </div>
                      </div>
                    </form>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>

            <div style="margin-top: 20px;">
              <button *ngIf= "appearButton()" mat-raised-button class="btn btn-gris" (click)="redirectUser()"><i class="fa-solid fa-chevron-left mr-2"></i> {{"ATRAS" | translate }}</button>
              <button *ngIf= "!appearButton()" mat-raised-button class="btn btn-gris" (click)="redirect()"><i class="fa-solid fa-chevron-left mr-2"></i> {{"ATRAS" | translate }}</button>
              <button mat-raised-button class="btn btn-rojo" (click)="goNextStepTwo()"
                *ngIf="bookingForm.valid && paxForm.valid && this.bookingForm.value.quantity > 0 && !idBooking && type === 1" style="float: right;">
                {{"SIGUIENTE" | translate }} <i class="fa-solid fa-chevron-right ml-2"></i></button>
              <button mat-raised-button class="btn btn-rojo" (click)="goNextStepTwo()"
                *ngIf="bookingForm.valid && paxForm.valid && this.bookingForm.value.quantity > 1 && !idBooking && type === 2" style="float: right;">
                {{"SIGUIENTE" | translate }} <i class="fa-solid fa-chevron-right ml-2"></i></button>
              <button mat-raised-button class="btn btn-rojo" (click)="goNextStepTwo()"
                *ngIf="bookingForm.valid && paxForm.valid && idBooking"
                style="float: right;">{{"SIGUIENTE" | translate }} <i class="fa-solid fa-chevron-right ml-2"></i></button>
            </div>
          </mat-step>
          <!-- STEP TWO: REGISTRO DE PASAJEROS -->
          <mat-step [stepControl]="secondFormToControl" [editable]="isEditableStepTwo">
            <ng-template matStepLabel class="text-uppercase">{{"PASAJEROS RESERVA" | translate }}</ng-template>

            <mat-accordion>
              <div class="row">
                <div class="col-sm-6" *ngFor="let pax of paxes.controls; let i = index">
                  <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <span style="font-size: 17px; font-weight: bold;">
                          Pasajero - {{ pax.value.firstName }} {{ pax.value.lastName }}
                        </span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <form [formGroup]="pax" autocomplete="off">
                      <div class="row">
                        <mat-form-field class="col-sm-6">
                          <mat-label>Tipo de Documento</mat-label>
                          <mat-select formControlName="idType" (selectionChange)="setType($event.value, i)"
                            [disabled]="!disablePax()">
                            <mat-option *ngFor="let type of types" [value]="type.value">{{ type.show }}</mat-option>
                          </mat-select>
                          <mat-error *ngIf="pax.controls['idType'].invalid && pax.controls['idType'].touched">
                            <span *ngIf="pax.controls['idType'].errors['required']">Por favor
                              rellene el campo</span>
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-sm-6" *ngIf="viewAccordingSelectedIdType(pax.value.idType)">
                          <mat-label>Número:</mat-label>
                          <input matInput placeholder="DNI" formControlName="dni" mask="00000000"
                            [readonly]="!disablePax()" (keyup)="keyUp($event, false, i);"
                            (change)="validateFirst(pax,i)">
                          <mat-error *ngIf="pax.controls['dni'].invalid && pax.controls['dni'].touched">
                            <span *ngIf="pax.controls['dni'].errors['required']">Por favor
                              rellene el campo</span>
                            <span *ngIf="pax.controls['dni'].errors['pattern']">Formato
                              incorrecto</span>
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-sm-6" *ngIf="!viewAccordingSelectedIdType(pax.value.idType)">
                          <mat-label>Número:</mat-label>
                          <input matInput placeholder="Pasaporte" formControlName="passport" (change)="validateFirst(pax,i)"
                            [readonly]="!disablePax()">
                          <mat-error *ngIf="pax.controls['passport'].invalid && pax.controls['passport'].touched">
                            <span *ngIf="pax.controls['passport'].errors['required']">Por favor
                              rellene el campo</span>
                            <span *ngIf="pax.controls['passport'].errors['pattern']">Formato
                              incorrecto</span>
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-sm-6">
                          <mat-label>Nombre:</mat-label>
                          <input matInput placeholder="Nombre" formControlName="firstName" [readonly]="!disablePax()"
                            (keydown)="validateFirst(pax,i)" (keypress)="validateFirst(pax,i)"
                            (keyup)="validateFirst(pax,i)">
                          <mat-error *ngIf="pax.controls['firstName'].invalid && pax.controls['firstName'].touched">
                            <span *ngIf="pax.controls['firstName'].errors['required']">Por favor
                              rellene el
                              campo</span>
                            <span *ngIf="pax.controls['firstName'].errors['pattern']">Formato
                              incorrecto</span>
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-sm-6">
                          <mat-label>Apellido:</mat-label>
                          <input matInput placeholder="Apellido" formControlName="lastName" [readonly]="!disablePax()"
                            (keydown)="validateFirst(pax,i)" (keypress)="validateFirst(pax,i)"
                            (keyup)="validateFirst(pax,i)">
                          <mat-error *ngIf="pax.controls['lastName'].invalid && pax.controls['lastName'].touched">
                            <span *ngIf="pax.controls['lastName'].errors['required']">Por favor
                              rellene el
                              campo</span>
                            <span *ngIf="pax.controls['lastName'].errors['pattern']">Formato
                              incorrecto</span>
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-sm-6">
                          <input matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate"
                            [readonly]="!disablePax()" placeholder="Fecha de nacimiento: (Ej: dd/mm/yyyy)"
                            formControlName="birthDate" (keydown)="validateFirst(pax,i)"
                            (keypress)="validateFirst(pax,i)" (keyup)="validateFirst(pax,i)"
                            onkeydown="javascript: return (event.keyCode === 111 || (event.shiftKey && event.keyCode === 55) || event.keyCode === 46 || event.keyCode === 8) ? true : !isNaN(Number(event.key))">
                          <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="!disablePax()">
                          </mat-datepicker-toggle>
                          <mat-datepicker #picker [disabled]="!disablePax()"></mat-datepicker>
                          <mat-error *ngIf="pax.controls['birthDate'].invalid && pax.controls['birthDate'].touched">
                            <span *ngIf="pax.controls['birthDate'].errors['required']">Por favor
                              rellene el campo</span>
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-sm-6">
                          <mat-select placeholder="Nacionalidad" formControlName="nationality"
                            [disabled]="!disablePax()" (keydown)="validateFirst(pax,i)"
                            (keypress)="validateFirst(pax,i)" (keyup)="validateFirst(pax,i)">
                            <mat-option *ngFor="let op of nationality" [value]="op.description">
                              {{op.description}}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="pax.controls['nationality'].invalid && pax.controls['nationality'].touched">
                            <span *ngIf="pax.controls['nationality'].errors['required']">Por favor
                              rellene el campo</span>
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-sm-6">
                          <mat-label>Celular: (Ej: cod.País+cod.Área+Número)</mat-label>
                          <input matInput placeholder="Celular" formControlName="mobile" [readonly]="!disablePax()"
                            onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))"
                            maxlength="20">
                        </mat-form-field>

                        <mat-form-field class="col-sm-6">
                          <mat-label>E-mail: (ejemplo@gmail.com)</mat-label>
                          <input matInput placeholder="Email" formControlName="email" (keydown)="validateFirst(pax,i)"
                            [readonly]="!disablePax()" (keypress)="validateFirst(pax,i)" (keyup)="validateFirst(pax,i)">

                          <mat-error *ngIf="pax.controls['email'].invalid && pax.controls['email'].touched">
                            <span *ngIf="pax.controls['email'].errors['required']">Por favor
                              rellene el campo</span>
                            <span *ngIf="pax.controls['email'].errors['email']">Formato
                              incorrecto</span>
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-sm-12">
                          <mat-label>Observaciones:</mat-label>
                          <textarea matInput placeholder="Observaciones" formControlName="observations"
                            [readonly]="!disablePax()"></textarea>
                        </mat-form-field>
                      </div>
                    </form>
                  </mat-expansion-panel>
                </div>
              </div>
            </mat-accordion>

            <div class="row justify-content-between" style="margin-top: 20px">
              <div class="col-sm-12" style="text-align: left;">
                <mat-checkbox [checked]="isCheckSendEmail" color="primary"  *ngIf="idBooking" (change)="checkBoxValueSendEmail($event)">
                  ¿Enviar email?
                </mat-checkbox>
              </div>
              <div class="col-4 col-sm-6">
                <div class="row">
                  <div class="col-12">
                    <button mat-raised-button class="btn btn-gris"
                      (click)="goBackStepOne()">{{"ANTERIOR" | translate }}</button>
                  </div>
                  <div class="col-12" *ngIf="this.startDate < this.time || status === 'disable'">
                    <button class="btn  btn-gris" (click)="redirectUser()"> Volver</button>
                  </div>
                </div>
              </div>

              <div class="col-8 col-sm-6">
                <div class="row" style="float: right;">
                  <!--Boton ADN-->
                  <div class="col-12" *ngIf="paxes.valid && !idBooking && !isLoad && user.rol != 'Guest'" style="text-align: end;">
                    <button class="btn btn-rojo"
                      (click)="onConfirmReservation()">{{"GENERAR RESERVA" | translate }}  <i class="fa-solid fa-chevron-right ml-2"></i></button>
                  </div>

                  <!--Boton Invitado-->
                  <div class="col-12" *ngIf="paxes.valid && !idBooking && !isLoad && user.rol == 'Guest' " style="text-align: end;">
                    <button class="btn btn-rojo"
                      (click)="onConfirmReservationCliente()">{{"GENERAR RESERVA" | translate }} <i class="fa-solid fa-chevron-right ml-2"></i></button>
                  </div>

                  <div class="col-12" *ngIf="paxes.valid && idBooking && !isLoad  && disablePax()"
                    style="text-align: end;">
                    <button class="btn btn-rojo"
                      (click)="onConfirmReservation()"> MODIFICAR RESERVA</button>
                  </div>
                  <div class="col-12" style="text-align: end;">
                    <button class="btn btn-rojo"
                      style="background-color: #1cac67; color: white;border: #1cac67;" *ngIf="isLoad"
                      [disabled]="!isLoad">
                      <span class="spinner-border" style="width: 1rem; height: 1rem; border-width: 0.15em;"
                        role="status" aria-hidden="true"></span> Cargando...
                    </button>
                  </div>

                </div>
              </div>

            </div>
          </mat-step>
          <!-- STEP THREE: PAGO -->
          <mat-step [completed]="false" [editable]="false" *ngIf="!idBooking || pagePax === 'pax'">
            <ng-template matStepLabel class="text-uppercase">{{"PAGO" | translate }}</ng-template>

            <div class="row" style="padding-bottom: 30px;">
              <div class="col-sm-6">
                <div class="card mt-3 mb-2" *ngFor="let det of detail" style="border-radius: 13px">

                  <div class="row m-0 align-items-center  border-bottom pl-2 pr-3">
                    <div class="col-auto p-2 ">
                      Salida:
                    </div>
                    <div class="col p-2 text-right">
                      <b>{{det.startDate | date:'dd/MM/yyyy'}}</b>

                    </div>
                  </div>
                  <div class="row m-0 align-items-center  border-bottom pl-2 pr-3">
                    <div class="col-auto p-2 ">
                      {{"CANTIDAD DE PASAJEROS" | translate }}:
                    </div>
                      <div class="col p-2 text-right">
                      <strong>{{bookingForm.get('quantity').value || '0'}}</strong> <i class="fas fa-suitcase-rolling text-azul ml-1"></i>
                    </div>
                  </div>
                  <div class="row m-0 align-items-center  border-bottom pl-2 pr-3">
                    <div class="col-auto p-2 ">
                      {{"PRECIO" | translate }}
                    </div>
                    <div class="col p-2 text-right">
                      <small>$</small><strong> {{getPrice(det.price)}}</strong>
                    </div>
                  </div>

                  <div class="row m-0 align-items-center  border-bottom pl-2 pr-3">
                    <div class="col-auto p-2 ">
                      <strong> {{"TOTAL" | translate }}</strong>
                    </div>
                    <div class="col p-2 text-right">
                      <small>$</small><strong> {{getTotal(det.price)}}</strong> <i class="fa-solid fa-circle-info text-azul ml-1"></i>
                    </div>
                  </div>
                </div>

                <div style="text-align: right; padding: 10px 0px 2px;" *ngIf="userType !== 2 && allowDiscount === true">
                  <mat-checkbox color="primary" (change)="changeCheck($event)"> Descuentos
                  </mat-checkbox>
                </div>

                <form [formGroup]="paymentForm" *ngIf="this.isCheck === true && allowDiscount === true">
                  <div class="card p-2" style="border-radius: 13px">
                    <div class="row">
                      <p style="font-size: 14px;margin-left: 25px;margin-top: 20px; margin-bottom: 0">Monto: $</p>
                      <mat-form-field class="col-sm-2" style="margin-left: 10px; margin-right: 10px;">
                        <input matInput type="number" formControlName="Discount" min="0" maxlength="this.new.length"
                          onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 || event.keyCode === 188 || event.keyCode === 190 || event.keyCode === 110 ? true : !isNaN(Number(event.key));">
                        <mat-error
                          *ngIf="paymentForm.controls['Discount'].invalid && paymentForm.controls['Discount'].touched">
                          <span *ngIf="paymentForm.controls['Discount'].errors['required']">Por favor
                            rellene el
                            campo</span>
                          <span
                            *ngIf="(this.paymentForm.value.Discount > this.total && userType !== 2 && userType !== 1) || (this.paymentForm.value.Discount > 0.1 * this.totalPrice && userType !== 2 && userType !== 0)">Monto
                            ingresado
                            inválido</span>
                        </mat-error>
                      </mat-form-field>

                      <!-- <div class="row"> -->
                      <p style="font-size: 14px;margin-left: 25px;margin-top: 20px;">Motivo: </p>
                      <mat-form-field style="margin-left: 10px; margin-right: 10px;">
                        <mat-select formControlName="DiscountReason">
                          <mat-option *ngFor="let type of reasons" [value]="type.show">
                            {{type.show}}</mat-option>
                        </mat-select>
                        <mat-error
                          *ngIf="paymentForm.controls['DiscountReason'].invalid && paymentForm.controls['DiscountReason'].touched">
                          <span *ngIf="paymentForm.controls['DiscountReason'].errors['required']">Por favor
                            rellene el
                            campo</span>
                        </mat-error>
                      </mat-form-field>
                      <!-- </div> -->
                    </div>

                    <div class="row">
                      <p style="font-size: 14px;margin-left: 20px;margin-top: 20px;">
                        Observaciones:
                      </p>
                      <mat-form-field class="col-sm-4" *ngIf="this.paymentForm.value.DiscountReason !== 'Otros'" style="margin-left: 10px; margin-right: 10px;">
                        <textarea matInput formControlName="Observations" ></textarea>
                        <mat-error
                          *ngIf="paymentForm.controls['Observations'].invalid && paymentForm.controls['Observations'].touched">
                          <span *ngIf="paymentForm.controls['Observations'].errors['required']">Por favor
                            rellene el
                            campo</span>
                        </mat-error>
                      </mat-form-field>
                      <mat-form-field class="col-sm-4" *ngIf="this.paymentForm.value.DiscountReason === 'Otros'" style="margin-left: 10px; margin-right: 10px;">
                        <textarea matInput formControlName="Observations" ></textarea>
                        <mat-error
                          *ngIf="paymentForm.controls['Observations'].invalid && paymentForm.controls['Observations'].touched">
                          <span *ngIf="paymentForm.controls['Observations'].errors['required']">Por favor
                            rellene el
                            campo</span>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <button class="btn btn-rojo btn-block mb-2"
                      (click)="saveDiscount()" *ngIf="!isLoad" [disabled]="!this.paymentForm.valid">Aplicar</button>
                    <button class="btn btn-rojo bg-success col-sm-2"
                      style="" *ngIf="isLoad"
                      [disabled]="isLoad">
                      <span class="spinner-border" style="width: 1rem; height: 1rem; border-width: 0.15em;"
                        role="status" aria-hidden="true"></span> Cargando...
                    </button>
                  </div>
                </form>
              </div>

              <div class="col-sm-6">
                <div class="card mt-3" style="border-radius: 13px">
                  <h5 class="title-boxes ml-3 mt-3 mb-4"><i class="fa-sharp fa-solid fa-circle fa-2xs"></i> {{"ABONAR" | translate }}</h5>

                  <div class="radio" style="margin-left: 20px; margin-bottom: 20px;" *ngIf="this.user.rol === 'Guest'">
                    <label><input id="mp" type="radio" name="optradio" [value]="'mp'"
                        (change)="payment($event)">&nbsp;<img src="assets/img/pago-mp.svg" class="iconos-pago"></label>
                    <p *ngIf="this.user.rol === 'Guest' && disableButton"><b>Aviso: tu reserva estará disponible por 30
                        minutos, por favor realizá el pago para poder asegurar tu lugar</b>
                    </p>
                  </div>
                  <div class="radio" style="margin-left: 20px; margin-bottom: 20px;" *ngIf="this.user.rol !== 'Guest'">
                    <label><input id="mp" type="radio" name="optradio" [value]="'mp'"
                        (change)="payment($event)">&nbsp;<img src="assets/img/pago-mp.svg" class="iconos-pago"> </label>
                  </div>
                  <div class="radio" style="margin-left: 20px; margin-bottom: 20px;" *ngIf="!disableButton">
                    <label><input id="bank" type="radio" name="optradio" [value]="'bank'"
                        (change)="payment($event)">&nbsp;<img src="assets/img/pago-trasferencia.svg" class="iconos-pago"></label>
                  </div>
                  <div *ngIf="userType === 0" class="radio" style="margin-left: 20px; margin-bottom: 20px;">
                    <label><input id="postnet" type="radio" name="optradio" [value]="'postnet'"
                        (change)="payment($event)">&nbsp;<img src="assets/img/pago-postnet.svg" class="iconos-pago"></label>
                  </div>
                  <div *ngIf="userType === 0" class="radio" style="margin-left: 20px; margin-bottom: 20px;">
                    <label><input id="postnet-mp" type="radio" name="optradio" [value]="'postnet-mp'"
                        (change)="payment($event)">&nbsp;<img src="assets/img/pago-postnet_mp.svg" class="iconos-pago"></label>
                  </div>
                  <div *ngIf="userType === 0" class="radio" style="margin-left: 20px; margin-bottom: 20px;">
                    <label><input id="cash" type="radio" name="optradio" [value]="'cash'"
                        (change)="payment($event)">&nbsp;<img src="assets/img/pago-agencia.svg" class="iconos-pago"></label>
                  </div>
                </div>
                <a mat-button class="btn btn-block btn-gris mb-2 mt-2" (click)="paymentHistory(this.idBooking)" target="_blank" *ngIf="userType !== 2">Ver
                  historial de pagos</a>
              </div>
            </div>

            <div class="row pb-5">
              <div class="col-12 col-sm-6">
                  <button class="btn btn-block btn-gris mb-2"

                          (click)="onCancelTheReservation('cancel')">CANCELAR</button>
              </div>
              <div class="col-12 col-sm-6">
                <button class="btn btn-block btn-rojo mb-2" *ngIf="!disableButton"
                        (click)="onCancelOrPayLaterTheReservation('pay-after')">{{"PAGAR LUEGO" | translate }}</button>

              </div>
              <div class="col-12 col-sm-6">
                <button class="btn btn-block btn-gris mb-2" *ngIf="userType === 0"

                        (click)="editBooking()">EDITAR
                  RESERVA</button>
              </div>
            </div>

            <div class="row pb-5">
              <div class="col-12 ">
                <button class="btn btn-block btn-rojo bg-secondary mb-2" (click)="openExcursionAdmin()"><i class="fa-solid fa-magnifying-glass"></i>  Buscar más excursiones...</button>
              </div>
            </div>

          </mat-step>
        </mat-horizontal-stepper>
