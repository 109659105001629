<div class="table-responsive">
  <table class="table table-striped">
    <thead>
      <tr>
        <th class="focus-text">Fecha</th>
        <th class="focus-text">Origen</th>
        <th class="focus-text">Servicio</th>
        <th class="focus-text">Tipo</th>
        <th class="focus-text">Lugares disponibles</th>
        <th class="focus-text">Lugares bloqueados</th>
        <th class="focus-text">Precio</th>
        <th class="focus-text">Estado</th>
        <th class="focus-text">Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let t of tours | paginate: {itemsPerPage: 10, currentPage: pageActual, id: random}">
        <th class="focus-text">{{ t.startDate | date:'EE dd/MM HH:mm a' }}</th>
        <td class="focus-text">{{ t.startingPlace }}</td>
        <td class="focus-text">{{ t.title }}</td>
        <td class="focus-text">{{ t.typeTourDescription }}</td>
        <td class="focus-text">{{t.freeSeats}}</td>
        <td class="focus-text">
          {{t.totalLockSeat}} <button mat-button matSuffix mat-icon-button aria-label="Clear" [disabled]="currentUser?.rol === 'Consultor'"
            matTooltip="Bloquear lugares" (click)="lockSeatsDialog(t.idLayout, t.freeSeats)"
            [disabled]=" t.startDate < this.time">
            <mat-icon>event_seat</mat-icon>
          </button></td>
        <td class="focus-text">{{ t.price }}</td>
        <td class="focus-text">{{ t.statusLayout }}</td>
        <td class="focus-text" style="width: 17%;">

          <div *ngIf="isUser;else templateName">
            <button mat-icon-button (click)="openBookingNUser(t.idLayout)" style="margin-left: 10px;"
              *ngIf="disableBooking(t.freeSeats, t.statusLayout, t.startDate, t.typeTourDescription, t.reservedSeats)"
              mat-raised-button matTooltip="Cargar una reserva" class="example-button">
              <mat-icon>add_circle_outline</mat-icon>
            </button>&nbsp;
            <button mat-icon-button (click)="openDetailUser(t.idLayout)" mat-raised-button matTooltip="Ver detalles"
              class="example-button">
              <mat-icon>search</mat-icon>
            </button>
            <button mat-icon-button (click)="openBookingUser(t.idLayout, t.freeSeats, t.statusLayout, t.startDate, t.typeTourDescription, t.reservedSeats)" mat-raised-button matTooltip="Ver reservas"
              class="example-button" style="margin-left: 10px;">
              <mat-icon>list</mat-icon>
            </button>
          </div>

          <ng-template #templateName>
            <button mat-icon-button (click)="openBookingN(t.idLayout)"
              *ngIf="disableBooking(t.freeSeats, t.statusLayout, t.startDate, t.typeTourDescription, t.reservedSeats)"
              mat-raised-button matTooltip="Cargar una reserva" class="example-button" [disabled]="currentUser?.rol === 'Consultor'">
              <mat-icon>add_circle_outline</mat-icon>
            </button>&nbsp;
            <button mat-icon-button (click)="openDetail(t.idLayout)" mat-raised-button matTooltip="Ver detalles"
              class="example-button">
              <mat-icon>search</mat-icon>
            </button>&nbsp;
            <button mat-icon-button [matMenuTriggerFor]="menu" mat-raised-button matTooltip="Ver listas" [disabled]="currentUser?.rol === 'Consultor'"
              class="example-button">
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="openBookingByTour(t.idLayout, t.freeSeats, t.statusLayout, t.startDate, t.typeTourDescription, t.reservedSeats)">
                <mat-icon>assignment</mat-icon>
                <span>Lista de reserva</span>
              </button>
              <button mat-menu-item (click)="openBookingByTourPax(t.idLayout)">
                <mat-icon>list</mat-icon>
                <span>Reporte de CNRT</span>
              </button>
            </mat-menu>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<pagination-controls (pageChange)="pageActual = $event" id="{{random}}" style="text-align: right;"
  previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
