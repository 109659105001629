import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BookingService } from 'src/app/shared/service/booking.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginAuthService } from 'src/app/auth/providers/login-auth.service';

@Component({
  selector: 'app-list-person-admin',
  templateUrl: './list-person-admin.component.html',
  styleUrls: ['./list-person-admin.component.scss']
})
export class ListPersonAdminComponent implements OnInit {

  public id: number;
  public detail: any;
  public details: any;
  public rowsDetails: UntypedFormArray = this.formBuilder.array([]);
  public user: any;
  public idU: number;
  public pageActual = 1;
  public rol: string;

  public randomLPA: string;

  constructor(
    private bookingService: BookingService,
    private activatedRoute: ActivatedRoute,
    public formBuilder: UntypedFormBuilder,
    public route: Router,
    private loginAuthService: LoginAuthService
  ) { }

  ngOnInit() {
    this.user = this.loginAuthService.getUser();
    this.randomLPA = 'id' + this.getRandom();
    this.activatedRoute.queryParams.subscribe(params => {
      this.id = params.id;
    });

    this.bookingService.getPaxesByLayout(this.id).subscribe(resp => {
      this.detail = resp;
    });
    this.bookingService.getBookingByTourPax(this.id).subscribe(resp => {
      this.details = resp.data;
    });
  }

  getFormGroupDetail(): UntypedFormGroup {
    return this.formBuilder.group({
      id: ['', [Validators.required]],
      birthDate: ['', [Validators.required]],
      dni: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      nationality: ['', [Validators.required]],
      passport: ['', [Validators.required]],
      language: ['', [Validators.required]],
    });
  }

  addRowDetailToday(event) {
    for (let detail of event) {
      let rowDetail: UntypedFormGroup = this.getFormGroupDetail();
      rowDetail.patchValue({
        id: detail.id,
        firstName: detail.firstName,
        lastName: detail.lastName,
        dni: detail.dni,
        passport: detail.passport,
        nationality: detail.nationality,
        birthDate: detail.birthDate,
        language: detail.language,
      });
      this.rowsDetails.push(rowDetail);
    }
  }
  openExcursion() {
    if (this.rol === 'Admin') {
      this.route.navigate(['/excursions-admin']);
    } else {
      this.route.navigate(['/excursions-ofic']);
    }
  }

  print() {
    this.bookingService.getBookingByPaxListPrint(this.id).subscribe(resp => {
      var newBlob = new Blob([resp], { type: "application/pdf" });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      const nav = (window.navigator as any);
      if (nav && nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data;
      link.download = this.details.tourismAgencyNumber + "-" + this.details.title + ".pdf";
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    },
      error => {
        console.log('response error ', error);
      });
  }

  getRandom() {
    return Math.random();
  }
}

