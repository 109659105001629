import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  public configuration: any;
  public cancelForm: UntypedFormGroup;
  public type: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogReference: MatDialogRef<ConfirmationComponent>) {
    this.type = this.data.type;
    this.configuration = {
      title: data.title,
      question: data.question
    };
  }

  ngOnInit() {
    this.cancelForm = this.formBuilder.group({
      Observations: ['', [Validators.required]],
    });
  }

  onButtonAction(action: any) {
    this.dialogReference.close(action);
  }

}

