import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Filter } from 'src/app/shared/class/filter';
import { Excursions } from 'src/app/shared/class/excursions.model';
import { ToursService } from 'src/app/shared/service/tours.service';
import { LoginAuthService } from 'src/app/auth/providers/login-auth.service';
import { FilterDataService } from 'src/app/service/filter-data.service';
import { Subscription } from 'rxjs';
import { BookingService } from 'src/app/shared/service/booking.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss']
})
export class BodyComponent implements OnInit, OnDestroy {

  constructor(
    private toursService: ToursService,
    private router: Router,
    private loginAuthService: LoginAuthService,
    private filterDataService: FilterDataService,
    private bookingService: BookingService
  ) { }

  @Output() toursFilter = new EventEmitter<any>();
  public excursions: Excursions[];
  public arrayOfArrays: any[] = [];
  public ending: any;
  public starting: any;
  public locations: any[];
  public originControl = new UntypedFormControl('');
  public destinationControl = new UntypedFormControl('');
  public dateControl = new UntypedFormControl('');
  public user: any;
  public current: any;
  public innerWidth;
  public isFilter = false;
  public cargando = true;
  private onFiltersCleaned: Subscription;
  public onSelectedActivity: Subscription;
  public subcriptions: Subscription;
  public isFilter$: Subscription;
  public activitiesLoaded$: Subscription;
  public isSelectedActivity;
  public currentActivity;

  Students = {
    dob: ''
  };
  fechaPlaceholderText = 'dd/mm/aaaaa';

//////
  ngOnDestroy(): void {
    this.onFiltersCleaned.unsubscribe();
    this.onSelectedActivity.unsubscribe();
    this.subcriptions.unsubscribe();
    this.isFilter$.unsubscribe();
    // this.isFilter = false;
    // this.filterDataService.resetFilter();
  }

  ngOnInit() {
    this.cargando = true;
    // this.isSelectedActivity = false;
    // this.isFilter = false;
    this.user = this.loginAuthService.getUser();
    this.isFilter$ = this.toursService.isFilter.subscribe((isFilter: any) => {
      this.isFilter = isFilter;

      const dataFilter = this.filterDataService.getFilter();
      if (dataFilter && !isFilter) {
        this.originControl.reset('');
        this.destinationControl.reset('');
        this.dateControl.reset('');
      }

      if (!dataFilter && !isFilter) {
        this.originControl.reset('');
        this.destinationControl.reset('');
        this.dateControl.reset('');
        this.getExcursion();
        this.getEndingPlace();
        this.getStartingPlace();
      } else if (dataFilter && dataFilter.IdTypeActivity) {
        this.getEndingPlaceByActivity(dataFilter.IdTypeActivity);
        this.getStartingPlaceByActivity(dataFilter.IdTypeActivity);
      } else if (dataFilter && !dataFilter.IdTypeActivity) {
        this.getEndingPlace();
        this.getStartingPlace();
      }
    });
    // usar promesa para quitar timeout



    // Se limpian todos los filtros
    this.onFiltersCleaned = this.toursService.filtersCleaned.subscribe(img => {
      const dataFilter = this.filterDataService.getFilter();

      if (dataFilter && dataFilter.IdTypeActivity) {
        this.getExcursionsByActivityLocalStorage(dataFilter.IdTypeActivity);
      } else {
        this.getExcursion();
      }
    });

    this.onSelectedActivity = this.toursService.selectActivity.subscribe(resp => {
      // Si no hay filtros de búsqueda voy por los enpoints de actividades individuales.
      if (!this.isFilter) {
        if (resp !== '') {
          this.currentActivity = resp;
          this.isSelectedActivity = true;
          this.getExcursionsByActivity();
          this.getEndingPlaceByActivity(this.currentActivity.idTypeActivity);
          this.getStartingPlaceByActivity(this.currentActivity.idTypeActivity);
        } else {
          this.currentActivity = '';
          this.isSelectedActivity = false;
          this.getExcursion();
          this.getEndingPlace();
          this.getStartingPlace();
        }
      } else {

        if (resp !== '') {
          this.currentActivity = resp;
          this.isSelectedActivity = true;
          this.getEndingPlaceByActivity(this.currentActivity.idTypeActivity);
          this.getStartingPlaceByActivity(this.currentActivity.idTypeActivity);
        } else {
          this.currentActivity = '';
          this.isSelectedActivity = false;
          this.getEndingPlace();
          this.getStartingPlace();
        }
        this.currentActivity = resp;
        // this.isSelectedActivity = false;
        this.searchTour();
      }


    });

    this.current = new Date();

    // Cargar nombre de actividad
    const dataFilter = this.filterDataService.getFilter();
    this.activitiesLoaded$ = this.bookingService.activitiesLoaded.subscribe((resp:any) => {
      if (dataFilter && dataFilter.IdTypeActivity) {
        this.isSelectedActivity = true;
        this.currentActivity = resp.data.find(act => act.idTypeActivity === dataFilter.IdTypeActivity);
      }
    });


    // Se comenta carga de filtros al inicar la aplicación

    setTimeout(() => {
      const dataFilter = this.filterDataService.getFilter();

      if (dataFilter) {
        // se comenta esto para que al buscar con todos los filtros vacios, entrar a una excursion y volver, vuelva a mostrar
        // la busqueda previa (la que se hizo sin filtros!)
        /*if (dataFilter.dateTime !== '' || dataFilter.endingPlace !== '' || dataFilter.startingPlace !== '') {*/
          /// this.isFilter = true; //no va!
          this.originControl.setValue(dataFilter.startingPlace);
          this.destinationControl.setValue(dataFilter.endingPlace);
          this.dateControl.setValue(dataFilter.dateTime);
          this.getTourData(dataFilter);
        /*} else*/ if (dataFilter.IdTypeActivity !== '' && (dataFilter.dateTime == '' && dataFilter.endingPlace == '' && dataFilter.startingPlace == '')) {

          this.isSelectedActivity = true;
          // this.currentActivity = {
          // idTypeActivity: dataFilter.IdTypeActivity
          // }

          this.getExcursionsByActivity();
          this.getEndingPlaceByActivity(dataFilter.IdTypeActivity);
          this.getStartingPlaceByActivity(dataFilter.IdTypeActivity);
        }

      } else {
        this.currentActivity = '';
        this.isFilter = false;
        this.getExcursion();
        this.getEndingPlace();
        this.getStartingPlace();
      }
    }, 1000); // si falla al cargar mas de 3 elementos, darle mas tiempo
  }

  getEndingPlace() {
    this.subcriptions = this.toursService.getEndingPlace().subscribe(resp => {
      this.ending = resp.data;
    });
  }

  getStartingPlace() {
    this.subcriptions = this.toursService.getStartingPlace().subscribe(resp => {
      this.starting = resp.data;
    });

  }

  getEndingPlaceByActivity(idTypeActivity) {
    this.subcriptions = this.toursService.getEndingPlaceByActivity(idTypeActivity).subscribe(resp => {
      this.ending = resp.data;
    });
  }

  getStartingPlaceByActivity(idTypeActivity) {
    this.subcriptions = this.toursService.getStartingPlaceByActivity(idTypeActivity).subscribe(resp => {
      this.starting = resp.data;
    });
  }



  getExcursion() {
    this.cargando = true;
    this.subcriptions = this.toursService.getExcursions().subscribe(resp => {
      this.excursions = resp.data;
      this.arrayOfArrays = [];
      this.repartirToday();
      this.isFilter = false;
      this.cargando = false;
    });
  }

  getExcursionsByActivity() {
    this.cargando = true;
    this.subcriptions = this.toursService.getExcursionsByActivity(this.currentActivity.idTypeActivity).subscribe(resp => {
      this.excursions = resp.data;
      this.arrayOfArrays = [];
      this.repartirToday();
      this.isFilter = false;
      this.cargando = false;
    });
  }

  getExcursionsByActivityLocalStorage(idTypeActivity) {
    this.cargando = true;
    this.subcriptions = this.toursService.getExcursionsByActivity(idTypeActivity).subscribe(resp => {
      this.excursions = resp.data;
      this.arrayOfArrays = [];
      this.repartirToday();
      this.isFilter = false;
      this.cargando = false;
    });
  }

  /** directs to excursion detail screen */
  openDetail(rowIndex: number) {
    this.router.navigate(['/guest-page/excursions-detail-pax'], { queryParams: { id: rowIndex } });
  }

  searchTour() {

    this.toursService.isLoading.emit(true);

    let activity = '';
    if (this.currentActivity == undefined || this.currentActivity == null || this.currentActivity == '') {
      activity = '';
    } else {
      activity = this.currentActivity.idTypeActivity;
    }
    // this.currentActivity !== '' ? this.currentActivity.idTypeActivity : ''
    const tour = {
      dateTime: this.dateControl.value,
      endingPlace: this.destinationControl.value,
      startingPlace: this.originControl.value,
      IdTypeActivity: activity
    };
    this.filterDataService.setFilter(tour);
    this.getTourData(tour);
    this.isFilter = true;
  }

  borrarBusqueda() {
    this.filterDataService.resetFilter();
    this.originControl.reset('');
    this.destinationControl.reset('');
    this.dateControl.reset('');
  }

  resetFilter() {
    sessionStorage.removeItem('busquedaPortal');
  }

  getTourData(tour: any) {
    this.cargando = true;
    this.subcriptions = this.toursService.getFilterTour(tour).subscribe(resp => {
      this.toursFilter.emit(resp.data);
      this.toursService.isLoading.emit(false);
      this.cargando = false;
    });
  }

  onClear() {
    /*this.originControl.reset('');
    this.destinationControl.reset('');
    this.dateControl.reset('');*/

    this.isFilter = false;
    this.toursService.isFilter.emit(false);

    // this.toursService.filtersCleaned.emit('');
    this.toursService.selectActivity.emit('');


    this.filterDataService.resetFilter();
    // this.getExcursionsTomorrow();
    // this.getExcursionAfterT();
  }

  /** show the images of today in a carrousel */
  repartirToday() {
    const length: number = this.excursions.length;
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 575) {

      const pages: number = this.excursions.length;
      this.arrayOfArrays = [];
      for (let i = 0; i < pages; i++) {
        this.arrayOfArrays.push(this.excursions.slice(i, (i + 1)));
      }
    } else if (this.innerWidth > 575 && this.innerWidth <= 767) {
      const pages: number = Math.trunc(length / 2) + ((length % 2 > 0) ? 1 : 0);
      this.arrayOfArrays = [];
      for (let i = 0; i < pages; i++) {
        this.arrayOfArrays.push(this.excursions.slice(2 * i, 2 * (i + 1)));
      }
    } else {
      const pages: number = Math.trunc(length / 3) + ((length % 3 > 0) ? 1 : 0);
      this.arrayOfArrays = [];
      for (let i = 0; i < pages; i++) {
        this.arrayOfArrays.push(this.excursions.slice(3 * i, 3 * (i + 1)));
      }
    }
  }

  getArray() {
    return this.arrayOfArrays;
  }

  onResize() {
    this.innerWidth = window.innerWidth;
    this.repartirToday();
  }

  checkDate() {
    const dateSendingToServer = new DatePipe('en-US').transform(this.Students.dob, 'dd/MM/yyyy');
    //console.log(dateSendingToServer);
  }

}
