import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-detail-bank-transfer',
  templateUrl: './dialog-detail-bank-transfer.component.html',
  styleUrls: ['./dialog-detail-bank-transfer.component.scss']
})
export class DialogDetailBankTransferComponent implements OnInit {
  public detail: any;
  public payment: string;

  constructor(
    private providerDialog: MatDialogRef<DialogDetailBankTransferComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.detail = this.data.detail;
    this.payment = this.data.payment;
  }

  ngOnInit() {
  }

  cancel() {
    this.providerDialog.close();
  }
}
