<div class="focused-content">
  <div class="container-fluid">
    <div class="card">
      <h5 class="card-header">Reservas</h5>
      <div class="card-body">
        <div class="row" [formGroup]="previousBooking">
          <mat-form-field class="col-sm-2">
            <input matInput [matDatepicker]="picker1" placeholder="Fecha reserva desde:"
              formControlName="StartDateBooking">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="col-sm-2">
            <input matInput [matDatepicker]="picker2" placeholder="Fecha reserva hasta:"
              formControlName="EndDateBooking">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="col-sm-2">
            <input matInput [matDatepicker]="picker3" placeholder="Fecha servicio desde:"
              formControlName="StartDateLayout">
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="col-sm-2">
            <input matInput [matDatepicker]="picker4" placeholder="Fecha servicio hasta:"
              formControlName="EndDateLayout">
            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-datepicker #picker4></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="col-sm-2">
            <mat-label>Servicio: </mat-label>
            <input (keyup)="getDestino($event)" matInput placeholder="Ingrese servicio" [matAutocomplete]="autoTour"
              formControlName="IdTour">
            <mat-autocomplete autoActiveFirstOption #autoTour="matAutocomplete" [displayWith]="displayFnTitle">
              <mat-option *ngFor="let t of tours" [value]="t">
                {{t.title}}</mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field class="col-sm-2">
            <mat-label>Actividad: </mat-label>
            <mat-select placeholder="Tipo de actividad" formControlName="IdTypeActivity">
              <mat-option>Ninguno</mat-option>
              <mat-option *ngFor="let op of activity" [value]="op.idTypeActivity">
                {{ op.typeActivityDescription }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-sm-2">
            <mat-label>Origen: </mat-label>
            <mat-select placeholder="Origen" formControlName="startingPlace">
              <mat-option>Ninguno</mat-option>
              <mat-option *ngFor="let op of origin" [value]="op.description">
                {{ op.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-sm-2">
            <mat-label>Destino: </mat-label>
            <mat-select placeholder="" formControlName="endingPlace">
              <mat-option>Ninguno</mat-option>
              <mat-option *ngFor="let end of ending" [value]="end.description">
                {{end.description}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-sm-2">
            <mat-label>Estado de reserva: </mat-label>
            <mat-select placeholder="Estado de reserva" formControlName="StatusBooking">
              <mat-option>Ninguno</mat-option>
              <mat-option *ngFor="let status of statusB" [value]="status.valor">
                {{status.muestraValor}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-sm-2">
            <mat-label>Vendedor: </mat-label>
            <mat-select placeholder="Vendedor" formControlName="IdSeller">
              <mat-option>Ninguno</mat-option>
              <mat-option *ngFor="let set of seller" [value]="set.idPerson">{{set.lastName}}
                {{set.firstName}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-sm-2">
            <mat-label>Forma de pago: </mat-label>
            <mat-select placeholder="Forma de pago" formControlName="IdPaymentMethod">
              <mat-option>Ninguno</mat-option>
              <mat-option *ngFor="let payment of payMethod" [value]="payment.id">
                {{payment.muestraValor}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-sm-2">
            <mat-label>Movimiento: </mat-label>
            <mat-select placeholder="Movimiento" formControlName="IdTransactionType">
              <mat-option>Ninguno</mat-option>
              <mat-option *ngFor="let moviment of moviment" [value]="moviment.id">
                {{moviment.muestraValor}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <!-- BOTONES -->
        <div class="row" style="text-align: left; padding: 10px 0px 10px 0px">
          <div class="col-8 col-sm-2">
            <button mat-raised-button class="btn btn-primary"
              style="background-color: #1cac67;color: white;border: #1cac67; width: 105%;" (click)="filterBookings()">
              <mat-icon>search</mat-icon> Buscar
            </button>
          </div>
          <div class="col-4 col-sm-1">
            <button mat-raised-button class="button" (click)="clear()" style="width: 100%;"> Limpiar
            </button>
          </div>
        </div>

        <div class="row" style="padding: 15px 0 15px 0;">
          <div class="col-sm-12">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th></th>
                    <th style="font-weight: bold; text-align: center;">Código de reserva</th>
                    <th style="font-weight: bold; text-align: center;">Fecha reserva</th>
                    <th style="font-weight: bold; text-align: center;">Fecha servicio</th>
                    <th style="font-weight: bold; text-align: center;">Servicio</th>
                    <!-- <th style="font-weight: bold; text-align: center;">Origen</th>
                    <th style="font-weight: bold; text-align: center;">Destino</th> -->
                    <th style="font-weight: bold; text-align: center;">Tipo</th>
                    <th style="font-weight: bold; text-align: center;">Actividad</th>
                    <th style="font-weight: bold; text-align: center;">Vendedor</th>
                    <th style="font-weight: bold; text-align: center;">Reserva</th>
                    <th style="font-weight: bold; text-align: center;">Estado</th>
                    <th style="font-weight: bold; text-align: center;">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let def of default | paginate: {itemsPerPage: 5, currentPage: pageActual, id: randomBLB};let z = index">
                    <td>
                      <mat-icon style="color: #f78123;" *ngIf="def.alertToPending === true"
                        matTooltip="Reservas en estado P. Pago en donde haya pasado 48hs desde la fecha de reserva">
                        warning
                      </mat-icon>
                      <mat-icon style="color: red;" *ngIf="def.alertToPartial === true"
                        matTooltip=" Reservas en estado P. Parcial y que están a 24hs de la fecha de excursión">
                        warning
                      </mat-icon>
                    </td>
                    <td class="align" style="text-align: center;">
                      {{ pad(def.idBooking, 5)  }} </td>
                    <td class="align" style="text-align: center;">
                      {{ def.bookingDate | date:'EE dd/MM h:mm a' }} hs</td>
                    <td class="align" style="text-align: center;">
                      {{ def.layout.startDate | date:'EE dd/MM h:mm a' }} hs</td>
                    <td class="align" style="text-align: center;"> {{def.destinationTitle}} </td>
                    <!-- <td class="align" style="text-align: center;"> {{def.startingPlace}} </td>
                    <td class="align" style="text-align: center;">{{def.endingPlace}}</td> -->
                    <td class="align" style="text-align: center;">{{def.typeTour}}</td>
                    <td class="align" style="text-align: center;">{{def.typeActivityDescription}}</td>
                    <td class="align" style="text-align: center;">{{def.nameSeller}}</td>
                    <td class="align" style="text-align: center;">{{ def.contactFirstName }} {{ def.contactLastName }}
                      <span style="font-weight: bold;">x{{ def?.listPaxs?.length }}</span>
                    </td>
                    <td class="align" style="text-align: center;">{{def.statusBooking }}</td>

                    <td class="align w-20">

                      <button mat-icon-button [matMenuTriggerFor]="menuDet" mat-raised-button matTooltip="Detalles"
                        class="example-button" style="margin-right: 7px;">
                        <mat-icon>search</mat-icon>
                      </button>

                      <button mat-icon-button mat-raised-button [matTooltip]="def.cancellationRemark"
                        *ngIf="def.statusBooking === 'CANCELADO'" matTooltipClass="example-tooltip-red"
                        class="example-button" style="margin-right: 7px;">
                        <mat-icon>info</mat-icon>
                      </button>

                      <button mat-icon-button mat-raised-button matTooltip="Modificar reserva" class="example-button" [disabled]="user?.rol === 'Consultor'"
                        (click)="openBookingByTour(def.idBooking,def.layout.idLayout, 'change', def.pickUp, def.dropOff)"
                        style="margin-right: 7px;"
                        *ngIf="def.layout.startDate > time && def.statusBooking !== 'CANCELADO'">
                        <mat-icon>edit</mat-icon>
                      </button>

                      <button mat-icon-button mat-raised-button matTooltip="Cancelar reserva" class="example-button" [disabled]="user?.rol === 'Consultor'"
                        (click)="onCancelOrPayLaterTheReservation('cancel',def.idBooking)" style="margin-right: 7px;"
                        *ngIf="def.layout.startDate > time && def.statusBooking !== 'CANCELADO'">
                        <mat-icon>clear</mat-icon>
                      </button>

                      <mat-menu #menuDet="matMenu">
                        <button mat-menu-item (click)="openBookingByTour(def.idBooking,def.layout.idLayout,'disable')" [disabled]="user?.rol === 'Consultor'">
                          <span>Detalle de reserva</span>
                        </button>
                        <button mat-menu-item (click)="openDetail(def.idLayout)">
                          <span>Detalle del servicio</span>
                        </button>
                      </mat-menu>

                      <button mat-icon-button [matMenuTriggerFor]="menu" mat-raised-button matTooltip="Pagos" [disabled]="user?.rol === 'Consultor'"
                        class="example-button">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item
                          (click)="openPaymentDialog(def.idBooking, def.idPaymentHeader, def.totalPrice, def.balance, def.allowDiscount, def.layout.startDate, def.contactEmail)"
                          *ngIf="def.statusBooking !== 'PAGADO' && def.statusBooking !== 'CANCELADO'">
                          <mat-icon>attach_money</mat-icon>
                          <span>Ingresar pago</span>
                        </button>
                        <button mat-menu-item (click)="paymentHistory(def.idBooking, z)">
                          <mat-icon>request_quote</mat-icon>
                          <span>Historial de pagos</span>
                        </button>
                        <button mat-menu-item (click)="downloadVoucher(def.idBooking, def.destinationTitle)"
                          *ngIf="def.statusBooking === 'PAGADO'">
                          <mat-icon>cloud_download</mat-icon>
                          <span>Descargar voucher</span>
                        </button>
                        <button mat-menu-item
                          (click)="openReturnsDialog(def.idPaymentHeader,def.totalPrice, def.maxRefund)"
                          *ngIf="def.allowRefund === true">
                          <mat-icon>attach_money</mat-icon>
                          <span>Devoluciones</span>
                        </button>
                      </mat-menu>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination-controls (pageChange)="pageActual = $event" id="{{randomBLB}}" style="text-align: right;"
              previousLabel="Anterior" nextLabel="Siguiente">
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>