<div class="dialog-body">

    <p>¿Desea continuar para confirmar la pre-reserva?</p>
    <hr>

    <button type="button" class="btn"
        style="background-color: #1cac67; margin-left: 250px; color: white;border: #1cac67;width: 90px;">Si
    </button>

    <button type="button" class="btn"
        style="background-color: #1cac67; margin-left: -200px; color: white;border: #1cac67;width: 90px; "
        (click)="cancel()">No
    </button>
</div>
