import { Component, OnInit } from '@angular/core';
import { Tours } from 'src/app/shared/class/tours';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { CodeAdminComponent } from '../code-admin/code-admin.component';
import { LayoutService } from 'src/app/shared/service/layout.service';
import { forkJoin } from 'rxjs';
import { LoginAuthService } from 'src/app/auth/providers/login-auth.service';
import { LockSeatsComponent } from '../lock-seats/lock-seats.component';

@Component({
  selector: 'app-excursions-admin',
  templateUrl: './excursions-admin.component.html',
  styleUrls: ['./excursions-admin.component.scss']
})
export class ExcursionsAdminComponent implements OnInit {
  today: number = Date.now();
  public tours1: Tours[];
  public tours2: Tours[];
  public formTableToday: UntypedFormGroup;
  public formTableTomorrow: UntypedFormGroup;
  public formTableAfterTomorrow: UntypedFormGroup;
  public monthYearForm: UntypedFormGroup;
  public rowsDetails: UntypedFormArray = this.formBuilder.array([]);
  public user: any;
  public code: any;
  public users: string;
  public pageActual = 1;
  // public pageActual1 = 1;
  // public pageActual2 = 1;
  isReady = true;
  tours = [
    { type: 'HOY', tours: [] },
    { type: 'MAÑANA', tours: [] },
    { type: 'PRÓXIMAS', tours: [] }
  ];
  date = new Date();

  months: any = [
    { name: 'Enero', value: 1 },
    { name: 'Febrero', value: 2 },
    { name: 'Marzo', value: 3 },
    { name: 'Abril', value: 4 },
    { name: 'Mayo', value: 5 },
    { name: 'Junio', value: 6 },
    { name: 'Julio', value: 7 },
    { name: 'Agosto', value: 8 },
    { name: 'Septiembre', value: 9 },
    { name: 'Octubre', value: 10 },
    { name: 'Noviembre', value: 11 },
    { name: 'Diciembre', value: 12 }
  ];
  years: any = [
    { name: '2020', value: 2020 },
    { name: '2021', value: 2021 },
    { name: '2022', value: 2022 },
    { name: '2023', value: 2023 },
    { name: '2024', value: 2024 },
  ];
  actualYear: number;
  actualMonth: number;
  public rol: string;
  public dateStart: any;

  constructor(
    private route: Router,
    public dialog: MatDialog,
    public formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private layoutService: LayoutService,
    private loginAuthService: LoginAuthService

  ) { }

  ngOnInit() {
    this.user = this.loginAuthService.getUser();
    // obtiene la fecha de pasado mañana
    this.date.setDate(this.date.getDate() + 2);

    const actualMonth = this.date.getMonth() + 1;
    const actualYear = this.date.getFullYear();

    this.monthYearForm = this.formBuilder.group({
      month: [actualMonth],
      year: [actualYear]
    });
    this.tour();
  }

  tour() {
    forkJoin(
      this.layoutService.getToursToday(this.user.idUser),
      this.layoutService.getToursTomorrow(this.user.idUser),
      this.layoutService.getToursAfterTomorrow(this.monthYearForm.value.month, this.monthYearForm.value.year, this.user.idUser)
    ).subscribe(
      ([toursToday, toursTomorrow, toursAfterTomorrow]) => {
        this.tours[0].tours = toursToday.data;
        this.tours[1].tours = toursTomorrow.data;
        this.tours[2].tours = toursAfterTomorrow.data;
        this.isReady = true;
      });
  }

  /** open pop-up code */
  openCodeDialog() {
    const customerDialogConfig = new MatDialogConfig();
    customerDialogConfig.disableClose = true;
    customerDialogConfig.autoFocus = true;
    customerDialogConfig.width = '500px';
    customerDialogConfig.data = { code: this.code, rol: this.rol };

    const customerDialogRef = this.dialog.open(CodeAdminComponent, customerDialogConfig);
    customerDialogRef.afterClosed().subscribe(() => console.log(this.user));
    customerDialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.user = data.user;
        }
      });
  }

  selectMonth(month) {
    this.actualMonth = month;
    this.loadAfterTomorrowTable();
  }

  selectYear(year) {
    this.actualYear = year;
    this.loadAfterTomorrowTable();
  }

  loadAfterTomorrowTable() {
    if (this.actualMonth === undefined) {
      this.actualMonth = this.date.getMonth() + 1;
    }
    if (this.actualYear === undefined) {
      this.actualYear = this.date.getFullYear();
    }
    this.layoutService.getToursAfterTomorrow(this.actualMonth, this.actualYear, this.user.idUser)
      .subscribe(
        toursAfterTomorrow => {
          this.tours[2].tours = toursAfterTomorrow.data;
          this.pageActual = 1;
        }
      );
  }
}
