import { PreviousBookingsComponent } from './previous-bookings/previous-bookings.component';
import { ExcursionsAdminContainerComponent } from './excursions-admin-container/excursions-admin-container.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExcursionsAdminComponent } from './excursions-admin/excursions-admin.component';

const routes: Routes = [{
  path: '', component: ExcursionsAdminContainerComponent,
  children: [
    { path: 'excursions', component: ExcursionsAdminComponent },
    { path: 'bookings', component: PreviousBookingsComponent },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExcursionsAdminRoutingModule { }
