import { PreviousBookingsComponent } from './excursions-admin/previous-bookings/previous-bookings.component';
import { ExcursionsComponent } from './excursions/excursions/excursions.component';
import { ExcursionsDetailPaxComponent } from './excursions-detail-pax/excursions-detail-pax/excursions-detail-pax.component';
import { ExcursionsDetailComponent } from './excursions-detail/excursions-detail/excursions-detail.component';
import { ExcursionDetailAdminComponent } from './excursions-detail-admin/excursion-detail-admin/excursion-detail-admin.component';
import { ExcursionsAdminComponent } from './excursions-admin/excursions-admin/excursions-admin.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './auth/home/home/home.component';
import { PaxFormsComponent } from './pax-forms/pax-forms/pax-forms.component';
import { AdminComponent } from './auth/admin/admin/admin.component';
import { PaxFormExitComponent } from './pax-forms/pax-form-exit/pax-form-exit.component';
import { CodeAdminComponent } from './excursions-admin/code-admin/code-admin.component';
import { BookingAdminComponent } from './excursions-admin/booking-admin/booking-admin.component';
import { PaxFormsAdminComponent } from './pax-forms/pax-forms-admin/pax-forms-admin.component';
import { ListPersonAdminComponent } from './excursions-admin/list-person-admin/list-person-admin.component';
import { ReportCnrtComponent } from './excursions-admin/report-cnrt/report-cnrt.component';
import { PaxFormsHomeComponent } from './pax-forms/pax-forms-home/pax-forms-home.component';
import { BookingUserComponent } from './excursions-admin/booking-user/booking-user.component';
import { RoleGuardService as RoleGuard } from './auth/providers/role-guard.service';
import { ExcursionsUserComponent } from './excursions-admin/excursions-user/excursions-user.component';
import { ExcursionsAdminContainerComponent } from './excursions-admin/excursions-admin-container/excursions-admin-container.component';
import { ExcursionsDetailAdminModule } from './excursions-detail-admin/excursions-detail-admin.module';
import { ExcursionsDetailModule } from './excursions-detail/excursions-detail.module';
import { ExcursionsModule } from './excursions/excursions.module';
import { ExcursionsDetailPaxModule } from './excursions-detail-pax/excursions-detail-pax.module';
import { LinkComponent } from './auth/link/link.component';
import { LogoutGuestComponent } from './auth/logout-guest/logout-guest.component';
import { SessionGuard } from './auth/providers/session.guard';

const routes: Routes = [

  ////////{ path: '', pathMatch: 'full', redirectTo: 'admin' },
  { path: '', pathMatch: 'full', redirectTo: 'guest-page/excursions-guest' },
  //{ path: '', pathMatch: 'full', redirectTo: 'guest-page/excursions-guest' },
  {
    path: 'home', component: HomeComponent
  },

  {
    path: 'vendedores', component: AdminComponent
  },

  //accesslogin
  {
    path: 'accessLogin', component: LinkComponent
  },

  {
    path: 'logout-guest', component: LogoutGuestComponent
  },

  {
    path: 'admin-page', component: ExcursionsAdminContainerComponent,
    children: [
      { path: 'excursions', component: ExcursionsAdminComponent },
      { path: 'bookings', component: PreviousBookingsComponent },
      { path: 'list-pax', component: ListPersonAdminComponent },
      { path: 'forms-admin', component: PaxFormsAdminComponent },
      { path: 'report-cnrt', component: ReportCnrtComponent },
      { path: 'code-admin', component: CodeAdminComponent },
      { path: 'booking-admin', component: BookingAdminComponent },
      { path: 'pay-exit', component: PaxFormExitComponent },
      { path: 'excursions-detail-admin', component: ExcursionDetailAdminComponent },
    ],
    canActivate: [SessionGuard]
  },
  {
    path: 'ofic-page', component: ExcursionsAdminContainerComponent,
    children: [
      { path: 'excursions', component: ExcursionsAdminComponent },
      { path: 'bookings', component: PreviousBookingsComponent },
      { path: 'list-pax-ofic', component: ListPersonAdminComponent },
      { path: 'forms-ofic', component: PaxFormsAdminComponent },
      { path: 'report-cnrt-ofic', component: ReportCnrtComponent },
      { path: 'code-ofic', component: CodeAdminComponent },
      { path: 'booking-ofic', component: BookingAdminComponent },
      { path: 'pay-exit-ofic', component: PaxFormExitComponent },
      { path: 'excursions-detail-ofic', component: ExcursionDetailAdminComponent },
    ],
    canActivate: [SessionGuard]
  },
  {
    path: 'recep-page', component: ExcursionsAdminContainerComponent,
    children: [
      { path: 'excursions-user', component: ExcursionsUserComponent },
      { path: 'forms-recep', component: PaxFormsComponent },
      { path: 'booking-user', component: BookingUserComponent },
      { path: 'excursions-detail', component: ExcursionsDetailComponent },
    ],
    canActivate: [SessionGuard]
  },
  {
    path: 'guest-page', component: ExcursionsAdminContainerComponent,
    children: [
      { path: 'excursions-guest', component: ExcursionsComponent },
      { path: 'forms-home', component: PaxFormsHomeComponent },
      { path: 'excursions-detail-pax', component: ExcursionsDetailPaxComponent },
    ],
    //canActivate: [SessionGuard] //se comenta esto para evitar la validacion de jwt y que nos lleve al admin
  },
  {
    path: 'consult-page', component: ExcursionsAdminContainerComponent,
    children: [
      { path: 'excursions-consult', component: ExcursionsAdminComponent },
      { path: 'bookings-consult', component: PreviousBookingsComponent },
      { path: 'excursions-detail-consult', component: ExcursionDetailAdminComponent },
    ],
    canActivate: [SessionGuard]
  },
  {
    path: '**', redirectTo: 'guest-page/excursions-guest'
  },

  // {
  //   path: 'accessLogin', component: ExcursionsAdminContainerComponent,
  //   children: [
  //     { path: 'excursions-guest', component: ExcursionsComponent },
  //     { path: 'forms-home', component: PaxFormsHomeComponent },
  //     { path: 'excursions-detail-pax', component: ExcursionsDetailPaxComponent },
  //   ]
  // },

  // {
  //   path: 'list-pax',
  //   component: ListPersonAdminComponent,
  //   canActivate: [RoleGuard],
  //   data: {
  //     expectedRole: 'Admin'
  //   }
  // },
  // {
  //   path: 'forms-admin',
  //   component: PaxFormsAdminComponent,
  //   canActivate: [RoleGuard],
  //   data: {
  //     expectedRole: 'Admin'
  //   }
  // },
  // {
  //   path: 'report-cnrt',
  //   component: ReportCnrtComponent,
  //   canActivate: [RoleGuard],
  //   data: {
  //     expectedRole: 'Admin'
  //   }
  // },
  // {
  //   path: 'code-admin',
  //   component: CodeAdminComponent,
  //   canActivate: [RoleGuard],
  //   data: {
  //     expectedRole: 'Admin'
  //   }
  // },
  // {
  //   path: 'booking-admin',
  //   component: BookingAdminComponent,
  //   canActivate: [RoleGuard],
  //   data: {
  //     expectedRole: 'Admin'
  //   }
  // },
  // {
  //   path: 'pay-exit',
  //   component: PaxFormExitComponent,
  //   canActivate: [RoleGuard],
  //   data: {
  //     expectedRole: 'Admin'
  //   }
  // },
  // {
  //   path: 'excursions-detail-admin', loadChildren: './excursions-detail-admin/excursions-detail-admin.module#ExcursionsDetailAdminModule',
  //   canActivate: [RoleGuard],
  //   data: {
  //     title: 'Detalles',
  //     status: true,
  //     expectedRole: 'Admin'
  //   }
  // },
  // {
  //   path: 'previous-bookings', loadChildren: './previous-bookings/previous-bookings.module#PreviousBookingsModule',
  //   canActivate: [RoleGuard],
  //   data: {
  //     title: 'Detalles',
  //     status: true,
  //     expectedRole: 'Admin'
  //   }
  // },

  /** USER: OFIC */
  // {
  //   path: 'excursions-ofic',
  //   component: ExcursionsAdminComponent,
  //   canActivate: [RoleGuard],
  //   data: {
  //     expectedRole: 'Ofic'
  //   }
  // },
  // {
  //   path: 'list-pax-ofic',
  //   component: ListPersonAdminComponent,
  //   canActivate: [RoleGuard],
  //   data: {
  //     expectedRole: 'Ofic'
  //   }
  // },
  // {
  //   path: 'forms-ofic',
  //   component: PaxFormsAdminComponent,
  //   canActivate: [RoleGuard],
  //   data: {
  //     expectedRole: 'Ofic'
  //   }
  // },
  // {
  //   path: 'report-cnrt-ofic',
  //   component: ReportCnrtComponent,
  //   canActivate: [RoleGuard],
  //   data: {
  //     expectedRole: 'Ofic'
  //   }
  // },
  // {
  //   path: 'code-ofic',
  //   component: CodeAdminComponent,
  //   canActivate: [RoleGuard],
  //   data: {
  //     expectedRole: 'Ofic'
  //   }
  // },
  // {
  //   path: 'booking-ofic',
  //   component: BookingAdminComponent,
  //   canActivate: [RoleGuard],
  //   data: {
  //     expectedRole: 'Ofic'
  //   }
  // },
  // {
  //   path: 'pay-exit-ofic',
  //   component: PaxFormExitComponent,
  //   canActivate: [RoleGuard],
  //   data: {
  //     expectedRole: 'Ofic'
  //   }
  // },
  // {
  //   path: 'excursions-detail-ofic', loadChildren: './excursions-detail-admin/excursions-detail-admin.module#ExcursionsDetailAdminModule',
  //   canActivate: [RoleGuard],
  //   data: {
  //     title: 'Detalles',
  //     status: true,
  //     expectedRole: 'Ofic'
  //   }
  // },

  /** USER: RECEP */
  // {
  //   path: 'excursions-user',
  //   component: ExcursionsUserComponent,
  //   canActivate: [RoleGuard],
  //   data: {
  //     expectedRole: 'Recep'
  //   }
  // },

  // {
  //   path: 'forms-recep',
  //   component: PaxFormsComponent,
  //   canActivate: [RoleGuard],
  //   data: {
  //     expectedRole: 'Recep'
  //   }
  // },
  // {
  //   path: 'booking-user',
  //   component: BookingUserComponent,
  //   canActivate: [RoleGuard],
  //   data: {
  //     expectedRole: 'Recep'
  //   }
  // },
  // {
  //   path: 'excursions-detail', loadChildren: './excursions-detail/excursions-detail.module#ExcursionsDetailModule',
  //   canActivate: [RoleGuard],
  //   data: {
  //     title: 'Detalles',
  //     status: true,
  //     expectedRole: 'Recep'
  //   }
  // },


  /** USER: GUEST */
  // {
  //   path: 'excursions', loadChildren: './excursions/excursions.module#ExcursionsModule',
  //   canActivate: [RoleGuard],
  //   data: {
  //     title: 'Excursiones',
  //     status: true,
  //     expectedRole: 'Guest'
  //   }
  // },
  // {
  //   path: 'excursions-detail-pax', loadChildren: './excursions-detail-pax/excursions-detail-pax.module#ExcursionsDetailPaxModule',
  //   canActivate: [RoleGuard],
  //   data: {
  //     title: 'Detalles',
  //     status: true,
  //     expectedRole: 'Guest'
  //   }
  // }, {
  //   path: 'forms-home',
  //   component: PaxFormsHomeComponent,
  //   canActivate: [RoleGuard],
  //   data: {
  //     expectedRole: 'Guest'
  //   }
  // },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
