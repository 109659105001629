<h5 class="container-lg text-uppercase title-actividad">{{detail[0]?.typeActivityDescription}}</h5>
<div class="container-fluid container-lg bg-azul-light p-3">
  <div class="row">
    <div class="col-5">
      <span (click)="openExcursionAdmin()" style="cursor: pointer;"><i class="fa-solid fa-chevron-left text-rojo" style="font-size: 10px "></i> Ir a resultados</span>
    </div>
    <div class="col-7 text-right">
      <span class="text-azul">Salida:</span> <strong> {{detail[0]?.startDate | date:'EE dd/MM HH:mm'}}hs</strong>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-md-none d-block" *ngFor="let det of detail">
      <button type="button" id="btn-search" class="btn btn-block btn-rojo mt-2"  style="margin: 0; width: 100%;" *ngIf="detail[0].freeSeats != 0"
              (click)="openBooking(det.idLayout)" >CONTINUAR Y RESERVAR <i class="fa-solid fa-chevron-right"></i></button>
    </div>
  </div>
</div>


<div class="container-fluid container-lg p-0 mt-md-3">
  <div class="card border-0 mb-4">
    <div class="card-body p-0">
      <div class="row">
        <div class="col-12 col-md-5">
          <div id="carouseltourFotos" class="carousel slide carousel-indicadores pointer-event" data-ride="carousel">

            <ol class="carousel-indicators">
              <li data-target="#carouseltourFotos" *ngFor="let url of detail[0]?.url | slice: 1; let i = index" [ngClass]="i == 0 ? 'active' : ''"></li>
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item" *ngFor="let url of detail[0]?.url | slice: 0; let i = index" [ngClass]="i == 0 ? 'active' : ''">
                <div class="row m-0">

                  <div class="col-12  pl-0 pr-0">
                    <div class="w-100 card-img-adn-detail" [ngStyle]="{'background-image': 'url(\''+ url + '\')'}" (click)="openImageDialog(url)">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flechas-img">
              <a class="carousel-control-prev" href="#carouseltourFotos" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"
                                      style=""></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carouseltourFotos" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"
                                      style=""></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-7 pl-md-0">
          <div *ngFor="let det of detail" style="text-align: center;">
            <div *ngFor="let d of detail">

              <div class="row title-result-destino m-0 align-items-center pl-2 pr-3">
                <div class="col-auto p-2 text-center">
                  <i class="fa-solid fa-location-dot"></i>
                </div>
                <div class="col p-2 text-left">
                  <h5 class="card-title m-0"><small>Destino:</small><br>{{d.endingPlace}}</h5>
                </div>
              </div>
              <div class="row title-result-desde m-0 align-items-center pl-2 pr-3">
                <div class="col-auto p-2 text-center">
                  <i class="fa-sharp fa-solid fa-location-crosshairs"></i>
                </div>
                <div class="col p-2 text-left">
                  <h5 class="card-title m-0"><small class="text-dark">Salida desde:</small><br>{{d.startingPlace}}</h5>
                </div>
              </div>

              <div class="row title-result-detalles m-0 align-items-center  border-bottom pl-3 pr-3">
                <div class="col-auto p-2 ">
                  Tipo de excursión:
                </div>
                <div class="col p-2 text-right">
                 <strong>{{d.typeTourName}}</strong>
                </div>
              </div>

              <div class="row title-result-detalles m-0 align-items-center  border-bottom pl-3 pr-3">
                <div class="col-auto p-2 ">
                  Precio final por persona:
                </div>
                <div class="col p-2 text-right">
                  <small>$ </small><strong>{{d.price}}</strong> <i class="fa-solid fa-circle-info text-azul ml-1"></i>
                </div>
              </div>
              <div class="row title-result-detalles m-0 align-items-center border-bottom pl-3 pr-3">
                <div class="col-auto p-2">
                  Cantidad disponible:
                </div>
                <div class="col p-2 text-right">
                  <strong>{{d.freeSeats}}</strong> <i class="fas fa-suitcase-rolling text-azul ml-1"></i>
                </div>
              </div>
              <div class="row title-result-detalles m-0 align-items-center  border-bottom pl-3 pr-3">
                <div class="col-auto p-2">
                  Tipo de Actividad:
                </div>
                <div class="col p-2 text-right pt-0 pb-0">
                  <small></small><strong> {{d.typeActivityDescription}}</strong> <img src="assets/img/{{d.idTypeActivity}}.svg" style="width: 23px;" class="ml-2">
                </div>
              </div>

              <div class="col-12 d-none d-md-block" *ngFor="let det of detail">
                <div class="row">
                  <div class="col-6">
                    <button type="button" class="btn btn-rojo bg-secondary mt-4"  style="margin: 0; width: 100%;" *ngIf="detail[0].freeSeats != 0"
                                             (click)="openExcursionAdmin()" ><i class="fa-solid fa-chevron-left"></i> VOLVER </button>
                  </div>
                  <div class="col-6"><button type="button" class="btn btn-rojo mt-4"  style="margin: 0; width: 100%;" *ngIf="detail[0].freeSeats != 0"
                                             (click)="openBooking(det.idLayout)" >CONTINUAR Y RESERVAR <i class="fa-solid fa-chevron-right"></i></button>
                  </div>
                </div>






              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="container-fluid container-lg p-0">
  <div class="card border-0 mb-4">


    <div class="container">
      <div class="" *ngFor="let de of detail">
        <div class="col-12 p-2">
          <h5 class="title-boxes mb-1 mt-3"><i class="fa-sharp fa-solid fa-circle fa-2xs"></i>Descripción</h5>

          <p class="text-detalle">
            {{de.itinerary}}
          </p>
        </div>
      </div>
    </div>



    <h5 class="title-boxes mb-1 mt-3 pl-3"><i class="fa-sharp fa-solid fa-circle fa-2xs"></i>Servicios incluidos</h5>

    <div class="container-fluid container-lg bg-azul-light mb-2">
      <div class="row">
        <div class="col-12 p-3">
          <li style="font-size: 15px;" *ngFor="let de of detail[0]?.services">{{de}}
          </li>
        </div>
      </div>
    </div>

    <div class="col-12 d-md-none d-block" *ngFor="let det of detail">
      <button type="button" id="btn-search" class="btn btn-block btn-rojo mt-3 mb-3"  style="margin: 0; width: 100%;" *ngIf="detail[0].freeSeats != 0"
              (click)="openBooking(det.idLayout)" >CONTINUAR Y RESERVAR <i class="fa-solid fa-chevron-right"></i></button>
    </div>



  </div>
</div>


<!--

<div class="focused-content">
  <div class="container-fluid">
    <div class="card">
      <h5 class="card-header">{{ "GALERIA" | translate }}</h5>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <div class="row">
              <p class="card-text"><strong style="font-size: 20px;margin-left: 15px;">
                  Fecha del servicio: {{detail[0].startDate | date:'EE dd/MM h:mm a'}}</strong> </p>
              <div class="col-sm-12 big-image">
                <img alt="picture" src="{{detail[0]?.url[0]}}" (click)="openImageDialog(detail[0]?.url[0])"
                  style="cursor: pointer;">
              </div>

              <div class="col-sm-4 small-image" *ngFor="let url of detail[0]?.url | slice: 1; let i = index"
                style="margin-top: 20px;">
                <img alt="picture" src="{{url}}" (click)="openImageDialog(url)" style="cursor: pointer;">
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
  </div>
</div>
-->
