import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BookingService } from 'src/app/shared/service/booking.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginAuthService } from 'src/app/auth/providers/login-auth.service';


@Component({
  selector: 'app-report-cnrt',
  templateUrl: './report-cnrt.component.html',
  styleUrls: ['./report-cnrt.component.scss']
})
export class ReportCnrtComponent implements OnInit {
  public users: any;
  public id: number;
  public details: any;
  public rowsDetails: UntypedFormArray = this.formBuilder.array([]);
  // public idU: number;
  public pageActual = 1;
  public date = new Date();
  // public user: any;
  isPaxs = true;
  public younger: string;

  constructor(
    private bookingService: BookingService,
    private activatedRoute: ActivatedRoute,
    public formBuilder: UntypedFormBuilder,
    public route: Router,
    private loginAuthService: LoginAuthService
  ) { }

  ngOnInit() {
    this.users = this.loginAuthService.getUser();
    this.activatedRoute.queryParams.subscribe(params => {
      this.id = params.id;
    });
    this.bookingService.getBookingByTourPax(this.id).subscribe(resp => {
      this.details = resp.data;
      this.isPaxs = this.details && this.details.detailBookings.length !== 0 ? false : true;
    });
  }

  getFormGroupDetail(): UntypedFormGroup {
    return this.formBuilder.group({
      id: ['', [Validators.required]],
      birthDate: ['', [Validators.required]],
      dni: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      nationality: ['', [Validators.required]],
      passport: ['', [Validators.required]],
      language: ['', [Validators.required]],
    });
  }

  addRowDetailToday(event) {
    for (let detail of event) {
      let rowDetail: UntypedFormGroup = this.getFormGroupDetail();
      rowDetail.patchValue({
        id: detail.id,
        firstName: detail.firstName,
        lastName: detail.lastName,
        dni: detail.dni,
        passport: detail.passport,
        nationality: detail.nationality,
        birthDate: detail.birthDate,
        language: detail.language,
      });
      this.rowsDetails.push(rowDetail);
    }
  }

  openExcursion() {
    if (this.users.rol === 'Admin') {
      this.route.navigate(['/admin-page/excursions']);
    } else {
      this.route.navigate(['/ofic-page/excursions']);
    }
  }

  print() {
    this.bookingService.getBookingByTourPaxPrint(this.id).subscribe(resp => {

      var newBlob = new Blob([resp], { type: 'application/pdf' });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      const nav = (window.navigator as any);
      if (nav && nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data;
      link.download = this.details.tourismAgencyNumber + '-' + this.details.title + '.pdf';
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    },
      error => {
        console.log('response error ', error);
      });
  }

  onPrintInFormatExcel() {
    XlsxPopulate.fromBlankAsync()
      .then(workbook => {

        // ANCHO PARA CADA COLUMNA
        workbook.sheet(0).column('C').width(25);
        workbook.sheet(0).column('D').width(35);
        workbook.sheet(0).column('E').width(35);
        workbook.sheet(0).column('F').width(25);
        workbook.sheet(0).column('G').width(25);
        workbook.sheet(0).column('H').width(25);

        const cellStyle = {
          leftBorder: true,
          rightBorder: true,
          topBorder: true,
          bottomBorder: true
        };

        const cellStyleWithBackground = {
          ...cellStyle,
          // fill: 'EFB810'
        };

        const cellStyleWithAlignRight = {
          ...cellStyle,
          horizontalAlignment: 'right'
        };

        const cellStyleWithAlignRightAndBold = {
          ...cellStyle,
          horizontalAlignment: 'right',
          bold: true,
        };

        const cellStyleWithAlignLeftAndBackgroundAndBold = {
          ...cellStyle,
          horizontalAlignment: 'left',
          // fill: 'EFB810',
          bold: true,
        };

        const cellStyleWithAlignLeft = {
          ...cellStyle,
          horizontalAlignment: 'left',
        };

        const title = {
          horizontalAlignment: 'center',
          //fill: 'EFB810',
          bold: true,
          topBorder: true,
          bottomBorder: true,
          fontSize: 14
        };

        const secondTitle = {
          horizontalAlignment: 'left',
          fontSize: 11
        };

        // TITULO DATOS DE VIAJE
        workbook.sheet(0).cell('E2').value('DATOS DEL VIAJE').style(title);
        workbook.sheet(0).cell('B2').style({ leftBorder: true, topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('C2').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('D2').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('F2').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('G2').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('H2').style({ rightBorder: true, topBorder: true, bottomBorder: true });

        // DESTINO
        workbook.sheet(0).cell('E4').value(this.details.title).style(title);
        workbook.sheet(0).cell('B4').style({ leftBorder: true, topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('C4').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('D4').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('F4').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('G4').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('H4').style({ rightBorder: true, topBorder: true, bottomBorder: true });


        // CONTRATANTE
        workbook.sheet(0).cell('C5').value('CONTRATANTE').style(secondTitle);

        // DATOS CONTRATANTE NOMBRE
        // NOMBRE
        workbook.sheet(0).cell('B7').value('NOMBRE    ').style(cellStyleWithAlignLeftAndBackgroundAndBold);
        workbook.sheet(0).cell('C7').style(cellStyleWithBackground);
        workbook.sheet(0).cell('D7').value(this.details.businessName).style(cellStyleWithAlignRight);

        // CUIT
        workbook.sheet(0).cell('B8').value('CUIT               ').style(cellStyleWithAlignLeftAndBackgroundAndBold);
        workbook.sheet(0).cell('C8').style(cellStyleWithBackground);
        workbook.sheet(0).cell('D8').value(this.details.cuit).style(cellStyleWithAlignRight);


        // Nº LEGAJO
        workbook.sheet(0).cell('B9').value('Nº LEGAJO').style(cellStyleWithAlignLeftAndBackgroundAndBold);
        workbook.sheet(0).cell('D9').value(this.details.tourismAgencyNumber).style(cellStyleWithAlignRight);
        workbook.sheet(0).cell('C9').style(cellStyleWithBackground);

        // TEL
        workbook.sheet(0).cell('B10').value('TELÉFONO').style(cellStyleWithAlignLeftAndBackgroundAndBold);
        workbook.sheet(0).cell('D10').value(this.details.mobile).style(cellStyleWithAlignRight);
        workbook.sheet(0).cell('C10').style(cellStyleWithBackground);

        // E. TRANSPORTE
        workbook.sheet(0).cell('G5').value('E. TRANSPORTE').style(secondTitle);

        // DATO E, TRANSPORTE
        // NOMBRE
        workbook.sheet(0).cell('G7').value('NOMBRE').style(cellStyleWithAlignLeftAndBackgroundAndBold);
        workbook.sheet(0).cell('H7').value(this.details.transport.businessName).style(cellStyleWithAlignRight);

        // CUIT
        workbook.sheet(0).cell('G8').value('CUIT').style(cellStyleWithAlignLeftAndBackgroundAndBold);
        workbook.sheet(0).cell('H8').value(this.details.transport.cuit).style(cellStyleWithAlignRight);

        // HABILITACION
        workbook.sheet(0).cell('G9').value('HABILITACIÓN').style(cellStyleWithAlignLeftAndBackgroundAndBold);
        workbook.sheet(0).cell('H9').value(this.details.transport.enablementNumber).style(cellStyleWithAlignRight);

        // TELEFONO
        workbook.sheet(0).cell('G10').value('TELÉFONO').style(cellStyleWithAlignLeftAndBackgroundAndBold);
        workbook.sheet(0).cell('H10').value(this.details.transport.mobile).style(cellStyleWithAlignRight);


        // SERVICIO
        workbook.sheet(0).cell('E12').value('SERVICIO').style(title);
        workbook.sheet(0).cell('B12').style({ leftBorder: true, topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('C12').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('D12').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('F12').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('G12').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('H12').style({ rightBorder: true, topBorder: true, bottomBorder: true });

        // ENCABEZADO TABLA SERVICIO
        workbook.sheet(0).cell('B14').value([
          ['TIPO', 'ORIGEN', 'DESTINO', 'F.INICIO', 'H.INICIO', 'F.FIN', 'H.FIN']
        ]).style(cellStyleWithAlignLeftAndBackgroundAndBold);

        const dataForReport = [];

        dataForReport.push(
          [
            this.details.typeTourDescription,
            this.details.startingPlace,
            this.details.title,
            new Date(this.details.startDate).toLocaleDateString(),
            new Date(this.details.startDate).toLocaleTimeString(),
            new Date(this.details.startDate).toLocaleDateString(),
            new Date(this.details.endingDate).toLocaleTimeString()
          ]);

        // DATOS DE TABLA SERVICIO
        workbook.sheet(0).cell('B15').value([...dataForReport]).style(cellStyleWithAlignLeft);

        // VEHICULO
        workbook.sheet(0).cell('E17').value('VEHICULO').style(title);
        workbook.sheet(0).cell('B17').style({ leftBorder: true, topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('C17').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('D17').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('F17').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('G17').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('H17').style({ rightBorder: true, topBorder: true, bottomBorder: true });

        // ENCABEZADO TABLA VEHICULO
        workbook.sheet(0).cell('D19').value([
          ['TIPO', 'PATENTE', 'CAPACIDAD', 'SEGURO']
        ]).style(cellStyleWithAlignLeftAndBackgroundAndBold);

        workbook.sheet(0).cell('C20').value('Unidad').style(secondTitle);

        const dataForReportVehiculo = [];

        dataForReportVehiculo.push(
          [
            this.details.transport.transportType,
            this.details.transport.patent,
            this.details.transport.capacity,
            this.details.transport.insurance,
          ]);

        // DATOS DE TABLA VEHICULO
        workbook.sheet(0).cell('D20').value([...dataForReportVehiculo]).style(cellStyleWithAlignLeft);


        // TRIPULACION
        workbook.sheet(0).cell('E22').value('TRIPULACIÓN').style(title);
        workbook.sheet(0).cell('B22').style({ leftBorder: true, topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('C22').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('D22').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('F22').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('G22').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('H22').style({ rightBorder: true, topBorder: true, bottomBorder: true });

        // ENCABEZADO TABLA TRIPULACION
        workbook.sheet(0).cell('D24').value([
          ['APELLIDO', 'NOMBRE', 'CUIL', 'NACIONALIDAD']
        ]).style(cellStyleWithAlignLeftAndBackgroundAndBold);

        workbook.sheet(0).cell('C25').value('Guía').style(secondTitle);
        workbook.sheet(0).cell('C26').value('Chofer').style(secondTitle);

        const dataForReportTripulacion = [];

        dataForReportTripulacion.push(
          [
            this.details.guide.lastName,
            this.details.guide.firstName,
            this.details.guide.cuil,
            this.details.guide.nationality,
          ]);
        const dataForReportTripulacionNew = [];

        dataForReportTripulacionNew.push(
          [
            this.details.driver.lastName,
            this.details.driver.firstName,
            this.details.driver.cuil,
            this.details.driver.nationality,
          ]);

        // DATOS DE TABLA TRIPULACION
        workbook.sheet(0).cell('D25').value([...dataForReportTripulacion]).style(cellStyleWithAlignLeft);
        workbook.sheet(0).cell('D26').value([...dataForReportTripulacionNew]).style(cellStyleWithAlignLeft);

        // PASAJEROS
        workbook.sheet(0).cell('E28').value('PASAJEROS').style(title);
        workbook.sheet(0).cell('B28').style({ leftBorder: true, topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('C28').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('D28').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('F28').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('G28').style({ topBorder: true, bottomBorder: true });
        workbook.sheet(0).cell('H28').style({ rightBorder: true, topBorder: true, bottomBorder: true });

        // ENCABEZADO DE TABLA PASAJEROS
        workbook.sheet(0).cell('C30').value([
          ['#', 'APELLIDO', 'NOMBRE', 'DNI/PASAPORTE', 'NACIONALIDAD', 'MENOR']
        ]).style(cellStyleWithAlignLeftAndBackgroundAndBold);

        const dataForReportPax = [];
        for (let index = 0; index < this.details.detailBookings.length; index++) {
          const dniOrPasaport = this.details.detailBookings[index].passport !== ' ' &&
            this.details.detailBookings[index].passport ? this.details.detailBookings[index].passport :
            this.details.detailBookings[index].dni;
          if (this.details.detailBookings[index].younger === false) {
            this.younger = 'No';
          } else {
            this.younger = 'Si';
          }
          dataForReportPax.push(
            [
              index + 1,
              this.details.detailBookings[index].lastName,
              this.details.detailBookings[index].firstName,
              dniOrPasaport,
              this.details.detailBookings[index].nationality,
              this.younger
            ]);
        }

        // DATOS DE TABLA
        workbook.sheet(0).cell('C31').value([...dataForReportPax]).style(cellStyleWithAlignLeft);

        const columnTotal = this.details.detailBookings.length + 30 + 3;

        if (this.details.guide.lastName === '-' && this.details.guide.firstName === '-' &&
          this.details.driver.lastName === '-' && this.details.driver.firstName === '-' ||
          this.details.guide.lastName === null && this.details.guide.firstName === null &&
          this.details.driver.lastName === null && this.details.driver.firstName === null ||
          this.details.guide.lastName === 'undefined' && this.details.guide.firstName === 'undefined' &&
          this.details.driver.lastName === 'undefined' && this.details.driver.firstName === 'undefined') {
          // TOTAL
          workbook.sheet(0).cell(`G${columnTotal}`).value('TOTAL').style(cellStyleWithAlignLeftAndBackgroundAndBold);
          // tslint:disable-next-line: max-line-length
          workbook.sheet(0).cell(`H${columnTotal}`).value(this.details.detailBookings.length).style(cellStyleWithAlignRightAndBold);
        } else {
          if (this.details.guide.lastName === '-' && this.details.guide.firstName === '-' ||
            this.details.driver.lastName === '-' && this.details.driver.firstName === '-' ||
            this.details.guide.lastName === null && this.details.guide.firstName === null ||
            this.details.driver.lastName === null && this.details.driver.firstName === null ||
            this.details.guide.lastName === 'undefined' && this.details.guide.firstName === 'undefined' ||
            this.details.driver.lastName === 'undefined' && this.details.driver.firstName === 'undefined') {
            // TOTAL
            workbook.sheet(0).cell(`G${columnTotal}`).value('TOTAL').style(cellStyleWithAlignLeftAndBackgroundAndBold);
            // tslint:disable-next-line: max-line-length
            workbook.sheet(0).cell(`H${columnTotal}`).value(this.details.detailBookings.length + 1).style(cellStyleWithAlignRightAndBold);
          } else {
            // TOTAL
            workbook.sheet(0).cell(`G${columnTotal}`).value('TOTAL').style(cellStyleWithAlignLeftAndBackgroundAndBold);
            // tslint:disable-next-line: max-line-length
            workbook.sheet(0).cell(`H${columnTotal}`).value(this.details.detailBookings.length + 2).style(cellStyleWithAlignRightAndBold);
          }
        }

        // FECHA DE IMPRESIÓN
        workbook.sheet(0).cell(`G${columnTotal + 2}`).value('FECHA DE IMPRESIÓN').style(cellStyleWithAlignLeftAndBackgroundAndBold);
        workbook.sheet(0).cell(`H${columnTotal + 2}`).value(new Date().toLocaleString()).style(cellStyleWithAlignRightAndBold);

        workbook.outputAsync()
          .then((blob) => {
            const nav = (window.navigator as any);
            if (nav && nav.msSaveOrOpenBlob) {
              // If IE, you must uses a different method.
              nav.msSaveOrOpenBlob(blob, 'REPORTE_CNRT.xlsx');
            } else {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              document.body.appendChild(a);
              a.href = url;
              a.download = 'REPORTE_CNRT.xlsx';
              a.click();
              window.URL.revokeObjectURL(url);
              document.body.removeChild(a);
            }
          });
      });
  }
}
