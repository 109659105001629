<simple-notifications></simple-notifications>
<div class="dialog-body">
  <div class="row justify-content-between">
    <div class="col">
      <h2 mat-dialog-title>Descuentos </h2>
    </div>

    <div class="col">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

  </div>

  <hr />
  <div class="mat-typography" style="padding-top: 1px;">
    <form [formGroup]="paymentForm">
      <p style="font-size: 14px;margin-left: 10px;margin-top: 20px;">Fecha: <strong
          style="font-size: 14px;margin-left: 10px;margin-top: 20px;">{{date | date:'dd/MM/yyyy'}}</strong></p>
      <p style="font-size: 14px;margin-left: 10px;margin-top: 20px;"> Total: <strong
          style="font-size: 14px;margin-left: 10px;margin-top: 20px;">$
          {{this.totalPrice}}</strong></p>

      <p style="font-size: 14px;margin-left: 10px;margin-top: 20px;"> Saldo: <strong
          style="font-size: 14px;margin-left: 10px;margin-top: 20px;">$
          {{this.saldo}}</strong></p>
      <div class="row">
        <p style="font-size: 14px;margin-left: 25px;margin-top: 20px;">Monto: $</p>
        <mat-form-field class="col-sm-3">
          <input matInput type="number" formControlName="Discount" min="0" maxlength="this.new.length"
            onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 || event.keyCode === 188 || event.keyCode === 190 || event.keyCode === 110 ? true : !isNaN(Number(event.key));">
          <mat-error *ngIf="paymentForm.controls['Discount'].invalid && paymentForm.controls['Discount'].touched">
            <span *ngIf="paymentForm.controls['Discount'].errors['required']">Por favor
              rellene el
              campo</span>
            <span *ngIf="this.paymentForm.value.Discount > getPorcetaje()">Monto inválido</span>
          </mat-error>
        </mat-form-field>

        <div class="row">
          <p style="font-size: 14px;margin-left: 25px;margin-top: 20px;">Motivo: </p>
          <mat-form-field style="margin-left: 5px;">
            <mat-select formControlName="DiscountReason">
              <mat-option *ngFor="let type of reasons" [value]="type.show">
                {{type.show}}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="paymentForm.controls['DiscountReason'].invalid && paymentForm.controls['DiscountReason'].touched">
              <span *ngIf="paymentForm.controls['DiscountReason'].errors['required']">Por favor
                rellene el
                campo</span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <p style="font-size: 14px;margin-left: 20px;margin-top: 20px;"
          *ngIf="this.paymentForm.value.DiscountReason === 'Otros'">
          Observaciones:
        </p>
        <mat-form-field class="col-sm-6" *ngIf="this.paymentForm.value.DiscountReason === 'Otros'">
          <textarea matInput formControlName="Observations"></textarea>
          <mat-error
            *ngIf="paymentForm.controls['Observations'].invalid && paymentForm.controls['Observations'].touched">
            <span *ngIf="paymentForm.controls['Observations'].errors['required']">Por favor
              rellene el
              campo</span>
          </mat-error>
        </mat-form-field>
      </div>
    </form>

    <hr />
    <div style="text-align: right;">
      <button class="btn btn-primary col-sm-4"
        style="background-color: #1cac67;color: white;border: #1cac67;margin-top: 15px;font-size: 14px;"
        (click)="saveDiscount()" *ngIf="!isLoad"
        [disabled]="this.paymentForm.value.Discount > getPorcetaje() || !this.paymentForm.valid">Aplicar</button>

      <button class="btn btn-primary" style="background-color: #1cac67;color: white;" cdkFocusInitial *ngIf="isLoad"
        [disabled]="isLoad">
        <span class="spinner-border" style="width: 1rem; height: 1rem; border-width: 0.15em;" role="status"
          aria-hidden="true"></span> Cargando...
      </button>
    </div>
  </div>