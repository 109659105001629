import { Component, OnInit } from "@angular/core";
import { ExcursionsDetails } from "src/app/shared/class/excursions-details.model";
import { ActivatedRoute, Router } from "@angular/router";
import { ToursService } from "src/app/shared/service/tours.service";
import { MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { DialogImagesComponent } from "src/app/excursions-detail-admin/dialog-images/dialog-images.component";
import { DialogImagePaxComponent } from "../dialog-image-pax/dialog-image-pax.component";
import { LoginAuthService } from "src/app/auth/providers/login-auth.service";

import { Location, PopStateEvent } from "@angular/common";
import { NavigationStart, NavigationEnd } from "@angular/router";

declare var $: any;

@Component({
  selector: "app-excursions-detail-pax",
  templateUrl: "./excursions-detail-pax.component.html",
  styleUrls: ["./excursions-detail-pax.component.scss"],
})


export class ExcursionsDetailPaxComponent implements OnInit {
  public detail: ExcursionsDetails[] = [];
  public id: number;
  public service: ExcursionsDetails[] = [];
  public user: string;

  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];

  

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private tourService: ToursService,
    private loginAuthService: LoginAuthService,
    private location: Location
  ) {}

  ngOnInit() {
    this.user = this.loginAuthService.getUser();
    this.activatedRoute.queryParams.subscribe((params) => {
      this.id = params.id;
    });
    this.tourService.getExcursionDetail(this.id).subscribe((resp) => {
      this.detail = resp.data;
    });

    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationStart) {
        if (ev.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (ev instanceof NavigationEnd) {
        if (ev.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else window.scrollTo(0, 0);
      }
    });

    $(document).ready(function() {
      $('.carousel').carousel();
    })
    /*setTimeout(() => {
      let content2: HTMLElement = document.getElementById(
        "w-100 card-img-adn-detail"
      ) as HTMLElement;
      content2.click();
      console.log("500");
    }, 500);*/
    /*setTimeout(() => {
      let content3: HTMLElement = document.getElementById(
        "carousel-control-prev"
      ) as HTMLElement;
      content3.click();
      console.log("550");
    }, 5500);*/
    /*let content2:HTMLElement= document.getElementById('carousel-control-next') as HTMLElement;
    content2.click();
    let content3:HTMLElement= document.getElementById('carousel-control-prev') as HTMLElement;
    content3.click();*/
  }

  /*ngAfterViewInit() {
    setTimeout(() => {
      let content2: HTMLElement = document.getElementById(
        "w-100 card-img-adn-detail"
      ) as HTMLElement;
      content2.click();
      console.log("500");
    }, 500);
    setTimeout(() => {
      let content3: HTMLElement = document.getElementById(
        "carousel-control-prev"
      ) as HTMLElement;
      content3.click();
      console.log("550");
    }, 5500);
 }*/

  openBooking(rowIndex: number) {
    this.router.navigate(["/guest-page/forms-home"], {
      queryParams: { id: rowIndex, number: 0 },
    });
  }

  openExcursionAdmin() {
    this.router.navigate(["/guest-page/excursions-guest"]);
  }

  openImageDialog(event) {
    const payDialogConfig = new MatDialogConfig();
    payDialogConfig.disableClose = true;
    payDialogConfig.autoFocus = true;
    payDialogConfig.width = "800px";
    payDialogConfig.data = event;

    const payDialogRef = this.dialog.open(
      DialogImagePaxComponent,
      payDialogConfig
    );
    payDialogRef.afterClosed().subscribe(() => {});
  }
}
