<div class="dialog-body">
  <div class="row justify-content-between">

    <div class="col-10">
      <h2 mat-dialog-title *ngIf="this.status !== 'CANCELADO'" class="mb-0 text-azul"> Historial de Pagos </h2>
      <h2 mat-dialog-title *ngIf="this.status === 'CANCELADO'"  class="mb-0 text-azul"> Historial de Pagos - Reserva cancelada </h2>
    </div>

    <div class="col">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>

  <hr />
  <div class="mat-typography" style="padding-top: 1px;">
    <div class="row">
      <div class="col-sm-6">
        <p class="text-header" style="font-size: 16px">Total: $
          <b class="text-rojo">{{ totalPriceBooking }}</b></p>
        <p class="text-header" style="font-size: 16px">Saldo: $
          <b class="text-rojo"> {{ balance }}</b><b *ngIf="balance < 0"> pendiente a devolver ${{balance*-1}}</b></p>
        <p class="text-header" style="font-size: 16px">Devolución: $
          <b class="text-rojo"> {{ refund }}</b></p>
      </div>

      <div class="col-sm-12" style="height: 365px;">
        <i class="fa-solid fa-arrow-right-arrow-left float-right text-azul mb-1 d-md-none"></i>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="focus-text">Fecha</th>
                <th class="focus-text">Estado</th>
                <th class="focus-text">Monto</th>
                <th class="focus-text">F.Pago</th>
                <th class="focus-text">Cuotas</th>
                <th class="focus-text">Tipo de transacción</th>
                <th class="focus-text" *ngIf="startDate < time">Motivo</th>
                <th class="focus-text">Observaciones</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let deta of paymentHistory?.detailPayment | paginate: {itemsPerPage: 5, currentPage: pageActual, id: randomBKA}; let i=index">
                <th class="focus-text">{{deta.datePayment | date:'EE dd/MM h:mm a' }}</th>
                <td class="focus-text"> {{deta.statusPayment}}</td>
                <td class="focus-text"> $ {{deta.amount}}</td>
                <td class="focus-text"
                  *ngIf="(deta.idPaymentMethod === 4 || deta.idPaymentMethod === 1) && deta.transactionType === 'Pago'">
                  {{deta.paymentMethod }}</td>
                <td class="focus-text"
                  *ngIf=" deta.transactionType === 'Descuento' ||  deta.transactionType === 'Recargo'">
                  {{deta.paymentMethod }}</td>
                <td class="focus-text"
                  *ngIf="(deta.idPaymentMethod === 4 || deta.idPaymentMethod === 1) &&  deta.transactionType === 'Devolución'">
                  {{deta.paymentMethod }}</td>
                <td class="focus-text" *ngIf="deta.idPaymentMethod === 2">
                  {{deta.paymentMethod || '-'}} <button mat-icon-button mat-raised-button [matTooltip]=""
                    style="margin: 4px;" matTooltipClass="example-tooltip-red" class="example-button"
                    (click)="detailTransfer(deta.bankTransferRequest,deta.idPaymentDetail,'transfer')">
                    <mat-icon>info</mat-icon>
                  </button></td>
                <td class="focus-text"
                  *ngIf="(deta.idPaymentMethod === 3 || deta.idPaymentMethod === 5) && (deta.transactionType === 'Pago' || deta.transactionType === 'Devolución')">
                  {{deta.paymentMethod }} <button mat-icon-button mat-raised-button [matTooltip]="" style="margin: 4px;"
                    matTooltipClass="example-tooltip-red" class="example-button"
                    (click)="detailTransfer(deta.creditCardTicketRequest,deta.idPaymentDetail,'posnet')">
                    <mat-icon>info</mat-icon>
                  </button></td>
                <td class="focus-text">{{ getQuotes(deta.creditCardTicketRequest) || '-'}}</td>
                <td class="focus-text">{{ deta.transactionType }}</td>
                <td class="focus-text" *ngIf="startDate < time">{{ deta.postPaymentRemarks }}</td>
                <td class="focus-text">{{deta.observation}}</td>
              </tr>
            </tbody>
          </table>
          <!--      <pagination-controls (pageChange)="pageActual = $event" id="{{randomBKA}}" previousLabel="Anterior" style="float: right;"
            nextLabel="Siguiente">
          </pagination-controls> -->
        </div>
      </div>

      <!-- <div class="col-12" style="text-align: right;">

      </div> -->

    </div>
  </div>
</div>
<pagination-controls (pageChange)="pageActual = $event" id="{{randomBKA}}" previousLabel="Anterior"
  style="float: right;" nextLabel="Siguiente">
</pagination-controls>
