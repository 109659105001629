import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { LoginAuthService } from './login-auth.service';

@Injectable()
export class RoleGuardService  {

  constructor(
    private auth: AuthService,
    private loginAuthService: LoginAuthService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {

    const expectedRole = route.data.expectedRole;

    const user: any = this.loginAuthService.getUser();

    if (!this.auth.isAuthenticated() || user.rol !== expectedRole) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }

}
