<div class="modal-header" style="padding: 0; padding-bottom: 10px;">
  <h5 class="modal-title">{{ configuration.title }}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onButtonAction(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{ configuration.question }}</p>
</div>
<div class="modal-footer" style="padding: 0; padding-top: 10px;">
  <!--  <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="onButtonAction(false)">Cancelar</button> -->
  <button mat-raised-button class="btn btn-rojo" (click)="onButtonAction(true)"
    cdkFocusInitial>Aceptar</button>
</div>
