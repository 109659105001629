<!-- <app-navbar></app-navbar> -->
<div class="focused-content">
  <div class="container-fluid">
    <div class="card">
      <h5 class="card-header">Reporte CNRT</h5>
      <div class="card-body">
        <h5 style="padding-bottom: 10px;">{{ details?.title }}</h5>
        <div class="row">
          <div class="col-sm-6">
            <p class="strong"><b>Fecha de impresión: </b>{{date | date:'dd/MM/yyyy hh:mm a'}}</p>
            <p class="strong"><b>Fecha del servicio:
              </b>{{details?.startDate | date:'dd/MM/yyyy hh:mm a'}}</p>
          </div>
          <div class="col-sm-6">
            <p class="strong"><b>Operado por: </b>{{details?.businessName}}</p>
            <p class="strong"><b>N° de Legajo: </b>{{details?.tourismAgencyNumber}}</p>
          </div>

          <div class="col-sm-12">
            <div class="table-responsive custom-table-insside">
              <table class="table table-striped" *ngIf="details?.length!==0">
                <thead>
                  <tr>
                    <th class="focus-text">Nombre</th>
                    <th class="focus-text">Apellido</th>
                    <th class="focus-text">DNI</th>
                    <th class="focus-text">Pasaporte</th>
                    <th class="focus-text">Nacionalidad</th>
                    <th class="focus-text">Fecha de nacimiento</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let d of details?.detailBookings | paginate: {itemsPerPage: 5, currentPage: pageActual}">
                    <td class="focus-text">{{d.firstName}}</td>
                    <td class="focus-text"> {{d.lastName}}</td>
                    <td class="focus-text">{{d.dni}}</td>
                    <td class="focus-text">{{d.passport}}</td>
                    <td class="focus-text">{{d.nationality}}</td>
                    <td class="focus-text">{{d.birthDate | date:'dd/MM/yyyy'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <pagination-controls (pageChange)="pageActual = $event" style="text-align: right;" previousLabel="Anterior"
          nextLabel="Siguiente">
        </pagination-controls>
      </div>
      <div style="text-align: right; padding: 0 18px 18px;">
        <button type="button" mat-raised-button class="button" (click)="openExcursion()" style="width: 5%;">Salir
        </button>&nbsp;

        <button mat-raised-button class="btn btn-primary" style="background-color: #1cac67;color: white;" (click)="print()" [disabled]="isPaxs">PDF</button>&nbsp;

        <button mat-raised-button class="btn btn-primary" style="background-color: #1cac67;color: white;" (click)="onPrintInFormatExcel()"
          [disabled]="isPaxs">EXCEL</button>

      </div>
    </div>
  </div>

</div>
