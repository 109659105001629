import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { LoginAuthService } from '../providers/login-auth.service';
import { UntypedFormGroup, Validators } from '@angular/forms';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit {
  public code: string;
  public codeEncrypt: string;
  public loginForm: UntypedFormGroup;
  conversionDecryptOutput: string;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationsService,
    private loginAuthService: LoginAuthService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.codeEncrypt = params['code'];
      //console.log(this.codeEncrypt); // Print the parameter to the console. //el problema es el "+" es en codigo, lo toma como espacio vacio
      //this.codeEncrypt = this.codeEncrypt.replace(" ", "+");
      this.codeEncrypt = this.codeEncrypt.split(" ").join("+");
      console.log(this.codeEncrypt.trim());
      // alert(this.code);
    });
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      code1: ['', [Validators.required]],
    });
    // this.loginForm.value.code1 = this.code;
    try {
      var iv = CryptoJS.enc.Hex.parse('e84ad660c4721ae0e84ad660c4721ae0');
      // Encoding the Password in from UTF8 to byte array
      // var Pass = CryptoJS.enc.Utf8.parse('insightresult');
      var Pass = CryptoJS.enc.Utf8.parse('ADN TRAVEL');
      // Encoding the Salt in from UTF8 to byte array
      var Salt = CryptoJS.enc.Utf8.parse("insight123resultxyz");
      // Creating the key in PBKDF2 format to be used during the decryption
      var key128Bits1000Iterations = CryptoJS.PBKDF2(Pass.toString(CryptoJS.enc.Utf8), Salt, { keySize: 128 / 32, iterations: 1000 });
      // Enclosing the test to be decrypted in a CipherParams object as supported by the CryptoJS libarary
      var cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(this.codeEncrypt.trim())
      });

      // Decrypting the string contained in cipherParams using the PBKDF2 key
      var decrypted = CryptoJS.AES.decrypt(cipherParams, key128Bits1000Iterations, { mode: CryptoJS.mode.CBC, iv: iv, padding: CryptoJS.pad.Pkcs7 });
      // console.log(decrypted);
      // alert(JSON.stringify(decrypted));
      this.conversionDecryptOutput = decrypted.toString(CryptoJS.enc.Utf8);

      // console.log(this.conversionDecryptOutput);
      // // alert(this.conversionDecryptOutput);
      this.code = this.conversionDecryptOutput;
      // console.log(this.code);
      this.loginForm.value.code1 = this.code;

      this.validateCode();
    } catch (e) {
      alert(JSON.stringify(e));
      console.log(e);
    }
    
  }

  validateCode() {
    this.loginAuthService.authenticateGuest(this.loginForm.value).then((resp: boolean) => {
      if (resp) {
        this.notificationService.success('Éxito', 'Ingresó correctamente');
        this.router.navigate(['/guest-page/excursions-guest']);
      } else {
        this.notificationService.warn('Error', 'Código ingresado inválido');
        this.router.navigate(['/home']);
      }
    }).catch(error => console.error(error));
  }

}
