import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/shared/service/login.service';
import { Code } from 'src/app/shared/class/code';
import { EmailAdminComponent } from '../email-admin/email-admin.component';
import { formatDate } from '@angular/common';
import { LoginAuthService } from 'src/app/auth/providers/login-auth.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-code-admin',
  templateUrl: './code-admin.component.html',
  styleUrls: ['./code-admin.component.scss']
})
export class CodeAdminComponent implements OnInit {
  public id: number;
  public code: Code;
  user: any;
  currentUser: any;
  public currentDateTime: any;
  emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  emailControl = new UntypedFormControl(null, [Validators.required, Validators.pattern(this.emailRegEx)]);

  constructor(
    private providerDialog: MatDialogRef<CodeAdminComponent>,
    private loginService: LoginService,
    public dialog: MatDialog,
    private loginAuthService: LoginAuthService,
    private notificationService: NotificationsService,
  ) { }

  ngOnInit() {
    this.currentUser = this.loginAuthService.getUser();
    if (this.currentUser) {
      this.loginService.postCode(this.currentUser.idUser).subscribe(resp => {
        this.code = resp;
        this.currentDateTime = formatDate(this.code.expirationDate, 'dd-MM-yyyyTHH:mm:ss', 'en-US');
      });
    }
  }

  /** open pop-up to send email with code */
  openEmailDialog(code: number) {
    const customerDialogConfig = new MatDialogConfig();
    customerDialogConfig.disableClose = true;
    customerDialogConfig.autoFocus = true;
    customerDialogConfig.width = '500px';
    customerDialogConfig.data = this.code;

    const customerDialogRef = this.dialog.open(EmailAdminComponent, customerDialogConfig);
    // Obtiene los datos una vez cerrado el dialogo
    customerDialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.loginService.getCodeMail(data.UserEmail, this.code.code1, this.currentDateTime).subscribe(resp => {

          });
          if (data) {
            this.user = data.user;
          }
        }
      });
  }

  sendForEmail() {
    this.loginService.getCodeMail(this.emailControl.value, this.code.code1, this.currentDateTime).subscribe(resp => {
      this.notificationService.success(
        'Éxito',
        'Se envió el código correctamente'
      );
      this.providerDialog.close();
    }, error => console.error(error));
  }

  /** close pop-up */
  cancel() {
    this.providerDialog.close();
  }
}
