import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExcursionsAdminComponent } from './excursions-admin/excursions-admin.component';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BodyModule } from '../body/body.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ExcursionsAdminContainerComponent } from './excursions-admin-container/excursions-admin-container.component';
import { CodeAdminComponent } from './code-admin/code-admin.component';
import { MaterialModule } from '../shared/material.module';
import { BookingAdminComponent } from './booking-admin/booking-admin.component';
import { EmailAdminComponent } from './email-admin/email-admin.component';
import { ExcursionsUserComponent } from './excursions-user/excursions-user.component';
import { ListPersonAdminComponent } from './list-person-admin/list-person-admin.component';
import { ReportCnrtComponent } from './report-cnrt/report-cnrt.component';
import { DialogPaymentComponent } from './dialog-payment/dialog-payment.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { DataTableComponent } from './excursions-admin/data-table/data-table.component';
import { BookingUserComponent } from './booking-user/booking-user.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { DialogDiscountComponent } from './dialog-discount/dialog-discount.component';
import { DialogReturnsComponent } from './dialog-returns/dialog-returns.component';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { ExcursionsAdminRoutingModule } from './excursions-admin-routing.module';
import { PreviousBookingsComponent } from './previous-bookings/previous-bookings.component';
import { CancelConfirmationsComponent } from './cancel-confirmations/cancel-confirmations.component';
import { LockSeatsComponent } from './lock-seats/lock-seats.component';
import { DialogDetailBankTransferComponent } from './dialog-detail-bank-transfer/dialog-detail-bank-transfer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    ExcursionsAdminComponent,
    ExcursionsAdminContainerComponent,
    CodeAdminComponent,
    BookingAdminComponent,
    EmailAdminComponent,
    ExcursionsUserComponent,
    ListPersonAdminComponent,
    ReportCnrtComponent,
    DialogPaymentComponent,
    DataTableComponent,
    BookingUserComponent,
    PaymentHistoryComponent,
    DialogDiscountComponent,
    DialogReturnsComponent,
    PreviousBookingsComponent,
    CancelConfirmationsComponent,
    LockSeatsComponent,
    DialogDetailBankTransferComponent
  ],
  imports: [
    ExcursionsAdminRoutingModule,
    CommonModule,
    FontAwesomeModule,
    SharedModule,
    ReactiveFormsModule,
    BodyModule,
    NgxDatatableModule,
    MaterialModule,
    NgxPaginationModule,
    NgxMaskDirective,
    NgxMaskPipe,
    SimpleNotificationsModule.forRoot(
      // position: ['top', 'center'],
      // timeOut: 3000,
      // showProgressBar: true,
      // pauseOnHover: true,
      // clickToClose: true,
      // clickIconToClose: true
    ),
  ],
  providers: [
    provideNgxMask()
  ],
  exports: [
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class ExcursionsAdminModule { }
