



<actividades-navbar></actividades-navbar>

<app-body [isFilter]="isFilter" (toursFilter)="toursFilter($event)"></app-body>



<div class="container-fluid container-lg mb-5" (window:resize)="onResize()">

  <!--   <app-header></app-header> -->


  <h5 class="title-boxes mt-3 mb-1 mt-5" *ngIf="isFilter" style="position: relative"><i class="fa-sharp fa-solid fa-circle fa-2xs"></i>Resultado de búsqueda <!--<span class="btn btn-rojo btn-sm" style="cursor: pointer; position: absolute; right: 0; bottom: 0;" (click)="onClearSearches()"><i class="fa-solid fa-refresh text-white" style="font-size: 15px; color: #ffffff !important;" ></i> Limpiar Búsqueda</span>--></h5>
  <h5 class="title-boxes mt-3 mb-1 mt-5" *ngIf="!isFilter"><i class="fa-sharp fa-solid fa-circle fa-2xs"></i> {{ "EXC" | translate }} <span class="text-rojo">{{ "MAÑANA" | translate }}</span> <span *ngIf="isSelectedActivity">: </span> <span *ngIf="isSelectedActivity" class="text-azul text-uppercase">  {{currentActivity?.typeActivityDescription}}</span></h5>


  <div class="card border-0" *ngIf="!isFilter">

    <h6 *ngIf="arrayOfArrays.length == 0 && !cargando" class="ml-3 mt-3 mb-3 text-azul sinresultados"><i class="fa-solid fa-triangle-exclamation text-rojo"></i> No se encontraron actividades para <span class="text-rojo text-uppercase">Mañana</span></h6>

    <div class="container-fluid container-lg" *ngIf="cargando" style="margin-top: 1rem;">
      <div class="col-12">
          <div class="alert alert-info text-center" style="background-color: #e3f1f8;">
            <i class="fa fa-spin fa-solid fa-circle-notch fa-2x"></i>
              <p class="mb-0">Cargando... Por favor espere</p>
          </div>
      </div>
    </div>

    <div class="card-body p-0" *ngIf="!cargando">

        <div class="mb-3" *ngFor="let excs of getArray(); let k = index" [ngClass]="k == 0 ? 'active' : ''">
          <div class="row m-0">
            <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 pl-1 pr-1" *ngFor="let exc of excs" (click)="openDetail(exc.idLayout)">
              <div class="w-100 card-img-adn" [ngStyle]="{'background-image': 'url(\''+ exc.url + '\')'}" (click)="openDetail(exc.idLayout)">
                <div class="overlay-card-adn-x2">
                  <h5 class="card-title">{{exc.title}} desde {{exc.startingPlace}}</h5>
                  <h5 class="card-title activity"><img _ngcontent-wex-c7="" class="ng-star-inserted" style="margin-right: 7px;" src="assets/img/{{exc.idTypeActivity}}.svg" alt="Travesía 4x4">{{exc.typeActivity}}</h5>

                </div>
              </div>
            </div>
          </div>



      </div>
    </div>
  </div>


  <div class="container-fluid container-lg" *ngIf="isFilter && cargando" style="margin-top: 1rem;">
    <div class="col-12">
        <div class="alert alert-info text-center" style="background-color: #e3f1f8;">
          <i class="fa fa-spin fa-solid fa-circle-notch fa-2x"></i>
            <p class="mb-0">Cargando... Por favor espere</p>
        </div>
    </div>
  </div>

  <div class="card border-0" *ngIf="isFilter && !cargando">
    <div class="card-body p-0">
      <h6 *ngIf="arrayOfArrays.length == 0 && !cargando" class="ml-3 mt-3 mb-3 text-azul sinresultados"><i class="fa-solid fa-triangle-exclamation text-rojo"></i> No se encontraron actividades </h6>
      <div class="row m-0 mb-2" *ngFor="let excs of getArray(); let k = index" [ngClass]="k == 0 ? 'active' : ''">
            <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 pl-1 pr-1 mb-3" *ngFor="let exc of excs" (click)="openDetail(exc.idLayout)">
              <div class="w-100 card-img-adn-result border-left border-top border-right" [ngStyle]="{'background-image': 'url(\''+ exc.url + '\')'}" (click)="openDetail(exc.idLayout)">

              </div>
              <div class="row title-result-destino m-0 align-items-center border-left border-right">
                <div class="col-auto p-2 text-center">
                  <i class="fa-solid fa-location-dot"></i>
                </div>
                <div class="col p-2">
                  <h5 class="card-title m-0"><small>Destino:</small><br>{{exc.title}}</h5>
                </div>
              </div>
              <div class="row title-result-desde m-0 align-items-center border-left border-right">
                <div class="col-auto p-2 text-center">
                  <i class="fa-sharp fa-solid fa-location-crosshairs"></i>
                </div>
                <div class="col p-2">
                  <h5 class="card-title m-0"><small class="text-dark">Salida desde:</small><br>{{exc.startingPlace}}</h5>
                </div>
              </div>
              <div class="row title-result-detalles m-0 align-items-center border-left border-right border-bottom border-top">
                <div class="col-auto p-2">
                  Modalidad:
                </div>
                <div class="col p-2 text-right">
                  <strong>{{ exc.typeTour }}</strong>
                </div>
              </div>
              <div class="row title-result-detalles m-0 align-items-center border-left border-right border-bottom border-top">
                <div class="col-auto p-2">
                    Fecha de salida:
                </div>
                <div class="col p-2 text-right">
                  <strong>{{ exc.dateLayout | date:'MMMM d, y HH:mm' }}hs</strong>
                </div>
              </div>
              <div class="row title-result-detalles m-0 align-items-center border-left border-right border-bottom border-top">
                <div class="col-auto p-2">
                    Precio final por persona:
                </div>
                <div class="col p-2 text-right">
                  <small>$ </small><strong>{{exc.price}}</strong> <i class="fa-solid fa-circle-info text-azul ml-1"></i>
                </div>
              </div>
              <div class="row title-result-detalles m-0 align-items-center border-left border-right border-bottom">
                <div class="col-auto p-2">
                  Cantidad disponible:
                </div>
                <div class="col p-2 text-right">
                  <strong>{{exc.freeSeats}}</strong> <i class="fas fa-suitcase-rolling text-azul ml-1"></i>
                </div>
              </div>
              <div class="row title-result-detalles m-0 align-items-center border-left border-right border-bottom bottom-radius">
                <div class="col-auto p-2">
                  Tipo de Actividad:
                </div>
                <div class="col p-2 text-right pt-0 pb-0">
                  <small></small><strong> {{exc.typeActivity}}</strong> <img src="assets/img/{{exc.idTypeActivity}}.svg" style="width: 23px;" class="ml-2">
                </div>
              </div>
            </div>
        </div>
    </div>
  </div>





  <h5 class="title-boxes mt-3 mb-2 mt-5"><i class="fa-sharp fa-solid fa-circle fa-2xs"></i> {{ "Próximas Actividades" | translate }} <span class="text-rojo">PROGRAMADAS</span> <span *ngIf="isSelectedActivity">: </span> <span *ngIf="isSelectedActivity" class="text-azul text-uppercase"> {{currentActivity?.typeActivityDescription}}</span></h5>


  <div class="row m-0">
    <div class="col-6">
      <mat-form-field class="w-100">
        <mat-label class="text-rojo">Mes</mat-label>
        <mat-select placeholder="Mes" [(ngModel)]="seleccionadaM" (selectionChange)="selectMonth($event.value)">
          <mat-option *ngFor="let m of months" [value]="m.value">
            {{m.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field class="w-100">
        <mat-label class="text-rojo">Año</mat-label>
        <mat-select placeholder="Año" [(ngModel)]="seleccionadaY" (selectionChange)="selectYear($event.value)">
          <mat-option *ngFor="let y of years" [value]="y.value">
            {{y.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="card border-0">
    <div class="card-body p-0">
      <div id="proximasProgramadas" class="carousel slide carousel-indicadores" data-ride="carousel">

        <ol class="carousel-indicators">
          <li data-target="#proximasProgramadas" *ngFor="let excs of getArrayAfter(); let k = index" [ngClass]="k == 0 ? 'active' : ''"></li>
        </ol>

        <h6 *ngIf="arrayOfArraysAfter.length == 0" class="ml-3 mt-3 mb-3 text-azul sinresultados"><i class="fa-solid fa-triangle-exclamation text-rojo"></i> No se encontraron actividades <span class="text-rojo text-uppercase">PROGRAMADAS </span></h6>

        <div class="carousel-inner">
          <div class="carousel-item" *ngFor="let excs of getArrayAfter(); let k = index" [ngClass]="k == 0 ? 'active' : ''">
            <div class="row m-0">
              <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 pl-1 pr-1" *ngFor="let af of excs" (click)="openDetail(af.idLayout)">
                <div class="w-100 card-img-adn" [ngStyle]="{'background-image': 'url(\''+ af.url + '\')'}" (click)="openDetail(af.idLayout)">
                  <div class="overlay-card-adn-x2">
                    <h5 class="card-title" style="font-size: 12px; top: 10px; text-shadow: 0px 1px 17px rgba(0,0,0,1);;
">{{ af.dateLayout | date:'MMMM d, y HH:mm' }}hs  </h5>
                    <h5 class="card-title">{{af.title}} desde {{af.startingPlace}}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>
        <div *ngIf="this.after?.length !== 0">
          <a class="carousel-control-prev" href="#proximasProgramadas" role="button" data-slide="prev"
             style="">
                          <span class="carousel-control-prev-icon" aria-hidden="true"
                                style=""></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#proximasProgramadas" role="button" data-slide="next"
             style="">
                          <span class="carousel-control-next-icon" aria-hidden="true"
                                style=""></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>

    </div>
  </div>

