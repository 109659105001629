import { ExcursionsDetailPaxModule } from './excursions-detail-pax/excursions-detail-pax.module';
import { ExcursionsDetailAdminModule } from './excursions-detail-admin/excursions-detail-admin.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { HeaderModule } from './header/header.module';
import { BodyModule } from './body/body.module';
// import { FootModule } from './foot/foot.module';
import { ExcursionsModule } from './excursions/excursions.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ExcursionsDetailModule } from './excursions-detail/excursions-detail.module';
import { PaxFormsModule } from './pax-forms/pax-forms.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ExcursionsAdminModule } from './excursions-admin/excursions-admin.module';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { SharedModule } from './shared/shared.module';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { HomeModule } from './auth/home/home.module';
import { AdminModule } from './auth/admin/admin.module';
import { LoginAuthService } from './auth/providers/login-auth.service';
import { AdnInterceptor } from './@core/adn-interceptor';
import { RoleGuardService } from './auth/providers/role-guard.service';
import { AuthService } from './auth/providers/auth.service';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { CustomDateAdapter } from './pax-forms/pax/custom.date.adapter';
import { LinkComponent } from './auth/link/link.component';
import { LogoutGuestComponent } from './auth/logout-guest/logout-guest.component';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent,
    LinkComponent,
    LogoutGuestComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SimpleNotificationsModule.forRoot({
      position: ['top', 'center'],
      timeOut: 3000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true,
      clickIconToClose: true
    }),
    HttpClientModule,
    BodyModule,
    ExcursionsModule,
    CommonModule,
    MatDialogModule,
    ExcursionsDetailModule,
    HomeModule,
    AdminModule,
    PaxFormsModule,
    ExcursionsDetailAdminModule,
    ExcursionsAdminModule,
    BrowserAnimationsModule,
    SharedModule,
    ExcursionsDetailPaxModule,
    FontAwesomeModule
  ],
  exports: [

  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AdnInterceptor,
      multi: true
    },
    LoginAuthService,
    RoleGuardService,
    AuthService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService
  ],
  bootstrap: [AppComponent],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }
