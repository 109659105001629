import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/providers/auth.service';

@Component({
  selector: 'app-excursions-admin-container',
  templateUrl: './excursions-admin-container.component.html',
  styleUrls: ['./excursions-admin-container.component.scss']
})
export class ExcursionsAdminContainerComponent implements OnInit {
  private menuOptions = {
    Admin: [
      {
        path: ['/admin-page/excursions'], id: 'excursions', title: 'Servicios', icon: '', children: null
      },
      {
        path: ['/admin-page/bookings'], id: 'bookings', title: 'Reservas', icon: '', children: null
      },
    ],

    Ofic: [
      {
        path: ['/ofic-page/excursions'], id: 'excursions-admin', title: 'Servicios', icon: '', children: null
      },
      {
        path: ['/ofic-page/bookings'], id: 'previous-bookings', title: 'Reservas', icon: '', children: null
      },
    ],
    Recep: [
      {
        path: ['/recep-page/excursions-user'], id: 'excursions-user', title: 'Servicios', icon: '', children: null
      },
    ],
    Guest: [
      {
        path: ['/guest-page/excursions-guest'], id: 'excursions-guest', title: 'Servicios', icon: '', children: null
      },
    ],
    Consultor: [
      {
        path: ['/consult-page/excursions-consult'], id: 'excursions-consult', title: 'Servicios', icon: '', children: null
      },
      {
        path: ['/consult-page/bookings-consult'], id: 'previous-bookings', title: 'Reservas', icon: '', children: null
      },
    ]
  };
  public menuItems = [];

  constructor(private authService: AuthService) {
    this.menuItems = this.menuOptions[this.authService.getRole()];
  }

  ngOnInit() {
  }

  //eliminar session de busqueda al recargar, cerrar tab o cerrar nvegador
  @HostListener('window:unload', ['$event'])
    unloadHandler(event) {
      window.sessionStorage.removeItem('busquedaPortal');
    }

}
