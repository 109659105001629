import { Person } from './person';

export class RolPerson {
  public idRolPerson: number;
  public idPax: number;
  public rolPersonDate: Date;
  public idPersonNavigation?: Person;
  public idPerson?: number;
  public observations?: string;
  public language?: string;
  public idRolPersonNavigation?: any;

  constructor() { }
}

