import { Injectable } from '@angular/core';
import { LoginService } from 'src/app/shared/service/login.service';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class LoginAuthService {
  page: any;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.page = params.page;
      //this.init();
    });
  }

  authenticate(data: any) {
    return new Promise((resolve, reject) => {
      this.loginService.getUser(data).subscribe(resp => {

        if (resp.data) {
          this.setUser(resp.data);
          this.setToken(resp.data.jwt);
          resolve({ idUser: resp.data.idUser, userAccount: resp.data.userAccount, rol: resp.data.rol });
        } else {
          resolve(null);
        }
      }, error => reject(error));
    });
  }

  authenticateGuest(data: any) {
    return new Promise((resolve, reject) => {
      this.loginService.postCodeValidate(data).subscribe(resp => {

        if (resp.data) {
          this.setUser(resp.data);
          this.setToken(resp.data.jwt);
          resolve(true);
        } else {
          resolve(false);
        }
      }, error => reject(error));
    });
  }

  getUser() {
    return JSON.parse(sessionStorage.getItem('user'));
  }

  getRol() {
    return String(sessionStorage.getItem('rol'));
  }

  setRol(rol: any) {
    sessionStorage.setItem('rol', String(rol));
  }

  setUser(user: any) {
    sessionStorage.setItem('user', JSON.stringify(user));
  }

  clearUser() {
    sessionStorage.removeItem('user');
  }

  setToken(token: string) {
    sessionStorage.setItem('token', token);
  }

  getToken() {
    return sessionStorage.getItem('token');
  }

  clearToken() {
    sessionStorage.removeItem('token');
  }

  logout(type: any) {
    //console.log(type);
    this.clearUser();
    this.clearToken();
    if (type == 1) {
      //this.router.navigate(['/home']); //original
      this.router.navigate(['/vendedores']);
      // var popup = window.open(window.location.pathname, '_blank', '');
      // popup.close();
    } else {
      this.router.navigate(['/vendedores']);
    }

  }

}
