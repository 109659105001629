<!-- <app-navbar></app-navbar> -->
<div class="focused-content">
  <div class="container-fluid">

    <div class="card">
      <h5 class="card-header">Lista de pasajeros</h5>

      <div class="card-body">
        <h5 style="padding-bottom: 10px;">{{ details?.title }}</h5>

        <div class="row">
          <div class="col-sm-6">
            <p class="text-header"><b>Fecha: </b>{{details?.now | date:'dd/MM/yyyy hh:mm a'}}</p>
            <p class="text-header"><b>Fecha de excursión:
              </b>{{details?.startDate | date:'dd/MM/yyyy hh:mm a'}}</p>
          </div>
          <div class="col-sm-12">
            <div class="table-responsive custom-table-insside">
              <table class="table table-striped" *ngIf="detail?.length!==0">
                <thead>
                  <tr>
                    <th class="focus-text">Nombre</th>
                    <th class="focus-text">Apellido</th>
                    <th class="focus-text">DNI</th>
                    <th class="focus-text">Pasaporte</th>
                    <th class="focus-text">Nacionalidad</th>
                    <th class="focus-text">Fecha de nacimiento</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let deta of detail | paginate: {itemsPerPage: 5, currentPage: pageActual, id: randomLPA}">
                    <td class="focus-text">{{deta.firstName}}</td>
                    <td class="focus-text"> {{deta.lastName}}</td>
                    <td class="focus-text">{{deta.dni}}</td>
                    <td class="focus-text">{{deta.passport}}</td>
                    <td class="focus-text">{{deta.nationality}}</td>
                    <td class="focus-text">{{deta.birthDate | date:'dd/MM/yyyy'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <pagination-controls (pageChange)="pageActual = $event" id="{{randomLPA}}" style="text-align: right;"
              previousLabel="Anterior" nextLabel="Siguiente">
            </pagination-controls>

          </div>
        </div>
      </div>
      <div style="text-align: right; padding: 0 18px 18px;">
        <button type="button" class="btn" (click)="openExcursion()">Salir
        </button>&nbsp;
        <button type="button" class="btn btn-primary" (click)="print()">Imprimir</button>
      </div>

    </div>

  </div>
</div>
