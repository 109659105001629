export class CreditcardTicket {

  public lot: string;
  public coupon: string;
  public CreditcardTicketDate: Date;
  public TypeCard?: string;
  public CardIssuer?: string;
  public NoDues: any;
  public RefundNumber: string;
  public NumberOperation: string;

  constructor() { }
}
