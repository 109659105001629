<simple-notifications></simple-notifications>
<div class="dialog-body">
  <div class="row justify-content-between">

    <div class="col">
      <h2 mat-dialog-title><img src="assets/img/pago-agencia.svg" class="iconos-pago"></h2>
    </div>

    <div class="col-1">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closed()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

  </div>

  <hr />
  <div class="mat-typography" style="padding-top: 1px;">
    <form [formGroup]="paymentForm">
      <p style="font-size: 18px;margin-left: 10px;margin-top: 20px;">Fecha: <strong
          style="font-size: 18px;margin-left: 10px;margin-top: 20px;">{{date | date:'dd/MM/yyyy'}}</strong></p>
      <p style="font-size: 18px;margin-left: 10px;margin-top: 20px;"> Total: <strong
          style="font-size: 18px;margin-left: 10px;margin-top: 20px;">$
          {{this.totalPrice}}</strong></p>
      <p style="font-size: 18px;margin-left: 10px;margin-top: 20px;"> Saldo: <strong
          style="font-size: 18px;margin-left: 10px;margin-top: 20px;">$
          {{this.saldoT()}}</strong></p>
<hr>
      <div class="row">
        <p style="font-size: 18px;margin-left: 25px;margin-top: 20px;" >Monto recibido: $</p>
        <mat-form-field class="col-sm-6" style="margin-left: 10px;">
          <input matInput type="number" formControlName="Amount" min="0" maxlength="this.new.length"
            onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 || event.keyCode === 188 || event.keyCode === 190 || event.keyCode === 110 ? true : !isNaN(Number(event.key));">
          <mat-error *ngIf="paymentForm.controls['Amount'].invalid && paymentForm.controls['Amount'].touched">
            <span *ngIf="paymentForm.controls['Amount'].errors['required']">Por favor
              rellene el
              campo</span>
            <span *ngIf="this.paymentForm.value.Amount > this.total">Monto ingresado
              inválido</span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row">
        <p style="font-size: 18px;margin-left: 25px;margin-top: 20px;">VPS: </p>
        <mat-form-field class="col-sm-4" style="margin-left: 10px;">
          <input matInput mask="000-00000" formControlName="Vps"
            onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key));">
        </mat-form-field>
      </div>
    </form>
  </div>
  <br>
  <div *ngIf="getSaldo() === 0">
    <p><strong>*Se enviara el Voucher por E-mail</strong></p>
  </div>

  <hr />
  <div style="text-align: center;">
    <button class="btn btn-rojo" (click)="paymentConfirmation()"
      cdkFocusInitial [disabled]="this.paymentForm.valid && (this.saldo < 0 || this.paymentForm.value.Amount <= 0)"
      *ngIf="!isLoad"> Aceptar
    </button>

    <button class="btn btn-primary" style="background-color: #1cac67;color: white;"
      cdkFocusInitial *ngIf="isLoad" [disabled]="isLoad">
      <span class="spinner-border" style="width: 1rem; height: 1rem; border-width: 0.15em;" role="status"
        aria-hidden="true"></span> Cargando...
    </button>
  </div>
</div>
