import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { BookingService } from 'src/app/shared/service/booking.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { PaymentDetails } from 'src/app/shared/class/paymentDetails';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { AdnValidators } from 'src/app/shared/validators/adn-validators';
import { ConfirmationComponent } from 'src/app/shared/confirmation/confirmation.component';
import { LoginAuthService } from 'src/app/auth/providers/login-auth.service';

@Component({
  selector: 'app-pax-form-pay-cash',
  templateUrl: './pax-form-pay-cash.component.html',
  styleUrls: ['./pax-form-pay-cash.component.scss']
})
export class PaxFormPayCashComponent implements OnInit {
  public paymentForm: UntypedFormGroup;
  public date = new Date();
  public paymentDetail: PaymentDetails = new PaymentDetails();
  public IdPaymentMethod: number;
  public idpayment: number;
  public saldo: number;
  public disabled = true;
  public total: number;
  public user: any;
  public isCheck = false;
  public idBook: number;
  public balance: number;
  public isLoad: boolean;
  public totalPrice: number;
  public page: string;
  public countPage = 0;
  public showPage: boolean;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private bookingService: BookingService,
    private providerDialog: MatDialogRef<PaxFormPayCashComponent>,
    private notificationService: NotificationsService,
    public dialog: MatDialog,
    private loginAuthService: LoginAuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data.payment === 'cash') {
      this.IdPaymentMethod = 4;
    }
    this.idpayment = this.data.booking.idPaymentHeader; // idPaymentHeader
    // this.balance = !this.data.paymentDiscount ? this.data.total : this.data.total - this.data.paymentDiscount.paymentDetail[0].amount;
    this.total = this.data.totalBooking;
    this.idBook = this.data.booking.idBooking || this.data.booking.booking[0].idBooking; // idBooking
    this.totalPrice = this.data.booking.totalPrice || this.data.booking.booking[0].totalPrice;
    this.page = this.data.page;
  }

  ngOnInit() {
    this.user = this.loginAuthService.getUser();
    this.paymentForm = this.formBuilder.group({
      Amount: ['', [Validators.required, AdnValidators.validateAmount(this.total)]],
      Vps: [''],
    });
  }

  closed() {
    this.providerDialog.close();
  }

  saldoT() {
    if (this.page === 'pax' && this.data.balance === this.data.total) {
      if (!this.data.paymentDiscount) {
        this.balance = this.data.balance;
      } else {
        this.balance = this.data.paymentDiscount.balance;
      }

    } else {
      if (this.page !== 'pax' || this.data.balance !== this.data.total) {
        if (!this.data.paymentDiscount) {
          this.balance = this.data.total;
        } else {
          this.balance = this.data.paymentDiscount.balance;
        }
      }
    }
    return this.balance;
  }

  getSaldo(): number {
    this.saldo = this.balance - this.paymentForm.value.Amount;
    if (this.saldo === 0) {
      this.disabled = false;
      this.isCheck = true; // if necesary change dinamyc, comment this line,because this method called every change
    } else if (this.saldo <= 0) {
      this.disabled = false;
      this.isCheck = true; // if necesary change dinamyc, comment this line,because this method called every change
    } else {
      this.disabled = true;
      this.isCheck = false;
    }
    return this.saldo;
  }

  savePaymentDetails() {
    this.isLoad = true;
    if (this.paymentForm.valid && this.paymentForm.value.Amount <= this.total) {
      this.paymentDetail.PaymentDetailDate = this.date;
      this.paymentDetail.Amount = this.paymentForm.value.Amount;
      this.paymentDetail.IdPaymentMethod = this.IdPaymentMethod;
      this.paymentDetail.IdUser = this.user.idUser;
      this.paymentDetail.IdPaymentHeader = this.idpayment;
      this.paymentDetail.Vps = this.paymentForm.value.Vps;

      this.bookingService.postPaymentDetails(this.paymentDetail).subscribe((resp: any) => {

        if (!this.disabled && this.isCheck) {
          this.bookingService.getPaymentVoucher(this.data.booking.idBooking).subscribe(data => {
            this.notificationService.success('Éxito', 'El pago se registró correctamente');
            this.downloadVoucher();
            this.providerDialog.close({ ...resp.data });
            this.isLoad = false;
          });
        } else {
          this.notificationService.success('Éxito', 'El pago se registró correctamente');
          this.providerDialog.close(resp.data);
          this.isLoad = false;
        }
      });
    } else {
      this.notificationService.warn('El pago no se puede realizar');
      this.isLoad = false;
    }
  }

  downloadVoucher() {

    this.bookingService.printVoucher(this.idBook).subscribe(resp => {

      var newBlob = new Blob([resp], { type: 'application/pdf' });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      const nav = (window.navigator as any);
      if (nav && nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data;
      link.download = this.idBook + '-' + this.data.booking.idLayoutNavigation.idTourNavigation.idDestinationNavigation.title + '-' + 'VOUCHER' + '.pdf';
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    },
      error => {
        console.log('response error ', error);
      });
  }

  paymentConfirmation() {
    if (this.paymentForm.valid) {
      const paymentDialogConfig = new MatDialogConfig();
      paymentDialogConfig.data = {
        title: 'Confirmación de pago',
        question: 'El monto a pagar es de: $' + this.paymentForm.value.Amount + ' .¿Desea continuar?'
      };
      const paymentDialogRef = this.dialog.open(
        ConfirmationComponent,
        paymentDialogConfig
      );
      paymentDialogRef.afterClosed().subscribe((resp: any) => {
        if (resp) {
          this.savePaymentDetails();
        }
      });
    } else {
      this.notificationService.warn('Error', 'Debe completar todos los campos');
      this.isLoad = false;
    }

  }
}
