import { BookingDetail } from './../../shared/class/booking-detail';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { BookingService } from 'src/app/shared/service/booking.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { PaymentDetails } from 'src/app/shared/class/paymentDetails';
import { CreditcardTicket } from 'src/app/shared/class/creditcard-ticket';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { AdnValidators } from 'src/app/shared/validators/adn-validators';
import { ConfirmationComponent } from 'src/app/shared/confirmation/confirmation.component';
import { LoginAuthService } from 'src/app/auth/providers/login-auth.service';

@Component({
  selector: 'app-pax-form-pay-postnet',
  templateUrl: './pax-form-pay-postnet.component.html',
  styleUrls: ['./pax-form-pay-postnet.component.scss']
})
export class PaxFormPayPostnetComponent implements OnInit {
  public paymentForm: UntypedFormGroup;
  public date = new Date();
  public paymentDetail: PaymentDetails = new PaymentDetails();
  public IdPaymentMethod: number;
  public idpayment: number;
  public ticket: CreditcardTicket = new CreditcardTicket();
  public saldo: number;
  public disabled = true;
  public total: number;
  public idU: number;
  public user: any;
  cardTypes = ['Crédito', 'Débito'];
  cards = ['Visa', 'Master', 'Naranja'];
  postnetType = 3;
  public isCheck = false;
  public id: number;
  new: string;
  public balance: number;
  public isLoad: boolean;
  quotes = [{ show: '2', value: '2' }, { show: '3', value: '3' }, { show: '6', value: '6' },
  { show: '12', value: '12' }, { show: 'Otros', value: '0' }];
  public totalPrice: number;
  public idUser: number;
  public page: string;
  public countPage = 0;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private bookingService: BookingService,
    private providerDialog: MatDialogRef<PaxFormPayPostnetComponent>,
    private router: Router,
    private notificationService: NotificationsService,
    public dialog: MatDialog,
    private loginAuthService: LoginAuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.idpayment = this.data.booking.idPaymentHeader;
    this.balance = !this.data.paymentDiscount ? this.data.total : this.data.total - this.data.paymentDiscount.paymentDetail[0].amount;
    this.total = this.data.totalBooking;
    this.id = this.data.booking.idBooking || this.data.booking.booking[0].idBooking; // idBooking
    this.IdPaymentMethod = this.data.payment === 'postnet' ? 3 : 5;
    this.totalPrice = this.data.booking.totalPrice || this.data.booking.booking[0].totalPrice;
    this.page = this.data.page;
  }

  ngOnInit() {
    this.paymentForm = this.formBuilder.group({
      Amount: ['', [Validators.required, AdnValidators.validateAmount(this.total), Validators.maxLength(this.total)]],
      lot: ['', [Validators.minLength(3), Validators.maxLength(3)]],
      coupon: ['', [Validators.minLength(4), Validators.maxLength(4)]],
      typeCard: ['', Validators.required],
      card: ['', Validators.required],
      NoDues: [''],
      Surcharges: [''],
      Observations: [''],
      NumberOperation: [''],
    });
    this.user = this.loginAuthService.getUser();
  }

  closed() {
    this.providerDialog.close();
  }

  getSaldo(): number {
    this.saldo = this.total - this.paymentForm.value.Amount;
    if (this.saldo === 0) {
      this.disabled = false;
      this.isCheck = true; // if necesary change dinamyc, comment this line,because this method called every change
    }
    else if (this.saldo <= 0) {
      this.disabled = false;
      this.isCheck = true; // if necesary change dinamyc, comment this line,because this method called every change
    }
    else {
      this.disabled = true;
      this.isCheck = false;
    }

    return this.saldo;
  }

  getCreditTicket(paymentForm: UntypedFormGroup) {
    this.ticket = new CreditcardTicket();
    this.ticket.coupon = paymentForm.value.coupon;
    this.ticket.lot = paymentForm.value.lot;
    this.ticket.CreditcardTicketDate = this.date;
    this.ticket.NoDues = paymentForm.value.NoDues;

    return this.ticket;
  }

  savePaymentDetails() {
    this.isLoad = true;
    if (this.paymentForm.valid && this.paymentForm.value.Amount <= this.total) {
      const creditTicket = new CreditcardTicket();
      creditTicket.CreditcardTicketDate = this.date;
      creditTicket.lot = this.paymentForm.value.lot;
      creditTicket.coupon = this.paymentForm.value.coupon;
      creditTicket.TypeCard = this.paymentForm.value.typeCard;
      creditTicket.CardIssuer = this.paymentForm.value.card;
      creditTicket.NoDues = this.paymentForm.value.NoDues;
      creditTicket.NumberOperation = this.paymentForm.value.NumberOperation;

      this.paymentDetail.PaymentDetailDate = this.date;
      this.paymentDetail.Amount = this.paymentForm.value.Amount;
      this.paymentDetail.IdPaymentMethod = this.IdPaymentMethod;
      this.paymentDetail.IdUser = this.user.idUser;
      this.paymentDetail.IdPaymentHeader = this.idpayment;
      this.paymentDetail.Surcharges = this.paymentForm.value.Surcharges;
      this.paymentDetail.Observations = this.paymentForm.value.Observations;
      this.paymentDetail.CreditcardTicket.push(creditTicket);
      this.bookingService.postPaymentDetails(this.paymentDetail).subscribe((resp: any) => {
        if (!this.disabled && this.isCheck) {
          this.bookingService.getPaymentVoucher(this.data.booking.idBooking).subscribe(data => {
            this.notificationService.success('Éxito', 'El pago se registró correctamente');
            this.downloadVoucher();
            this.providerDialog.close(...resp.data);
            this.isLoad = false;
          });
        } else {
          this.notificationService.success('Éxito', 'El pago se registró correctamente');
          this.providerDialog.close(resp.data);
          this.isLoad = false;
        }
      });
    } else {
      this.notificationService.warn('El pago no se puede realizar');
      this.isLoad = false;
    }
  }

  changeCheck(e) {
    this.isCheck = undefined;
    this.isCheck = e.checked;
  }

  checkSaldo() {
    if (this.saldo === 0) {
      this.isCheck = true;
    } else {
      this.isCheck = false;
    }
  }

  maxNumber(e) {
    if (e.keyCode === 46 || e.keyCode === 8) {
      return true;
    } else {
      if (this.saldo <= 0) {
        this.paymentForm.value.Amount = this.balance;
        return false;
      }
    }
    return true;
  }

  downloadVoucher() {

    this.bookingService.printVoucher(this.id).subscribe(resp => {

      var newBlob = new Blob([resp], { type: 'application/pdf' });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      const nav = (window.navigator as any);
      if (nav && nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data;
      link.download = this.id + '-' + ' ' + 'VOUCHER' + '.pdf';
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    },
      error => {
        console.log('response error ', error);
      });
  }

  paymentConfirmation() {
    if (this.paymentForm.valid) {
      let amountSurcharges = this.paymentForm.value.Amount + this.paymentForm.value.Surcharges;
      const paymentDialogConfig = new MatDialogConfig();
      // paymentDialogConfig.disableClose = true;
      // paymentDialogConfig.autoFocus = true;
      paymentDialogConfig.data = {
        title: 'Confirmación de pago',
        question: 'El monto a pagar es de: $' + amountSurcharges + ' .¿Desea continuar?'
      };
      const paymentDialogRef = this.dialog.open(
        ConfirmationComponent,
        paymentDialogConfig
      );
      paymentDialogRef.afterClosed().subscribe((resp: any) => {
        if (resp) {
          this.savePaymentDetails();
        }
      });
    } else {
      this.notificationService.warn('Error', 'Debe completar todos los campos requeridos');
    }
  }

  saldoT() {
    if (this.page === 'pax' && this.data.balance === this.data.total) {
      if (!this.data.paymentDiscount) {
        this.balance = this.data.balance;
      } else {
        this.balance = this.data.paymentDiscount.balance;
      }

    } else {
      if (this.page !== 'pax' || this.data.balance !== this.data.total) {
        if (!this.data.paymentDiscount) {
          this.balance = this.data.total;
        } else {
          this.balance = this.data.paymentDiscount.balance;
        }
      }
    }
    return this.balance;
  }
}
