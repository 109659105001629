import { Component, OnInit, Inject } from '@angular/core';
import { BookingService } from 'src/app/shared/service/booking.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentDetails } from 'src/app/shared/class/paymentDetails';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AdnValidators } from 'src/app/shared/validators/adn-validators';
import { NotificationsService } from 'angular2-notifications';
import { CreditcardTicket } from 'src/app/shared/class/creditcard-ticket';
import { LoginAuthService } from 'src/app/auth/providers/login-auth.service';
import { BankTransfers } from 'src/app/shared/class/bank-transfers';

@Component({
  selector: 'app-dialog-returns',
  templateUrl: './dialog-returns.component.html',
  styleUrls: ['./dialog-returns.component.scss']
})
export class DialogReturnsComponent implements OnInit {
  public date = new Date();
  public paymentDetail: PaymentDetails = new PaymentDetails();
  public paymentForm: UntypedFormGroup;
  public paymentPosnetForm: UntypedFormGroup;
  public isLoad: boolean;
  public idPayment: number;
  public user: any;
  public totalPrice: number;
  public maxRefund: number;
  public bankTransferForm: UntypedFormGroup;

  public payMethod = [
    { valor: 'Mercado Pago', muestraValor: 'Mercado Pago', id: 1 },
    { valor: 'Transferencia Bancaria', muestraValor: 'Transferencia Bancaria', id: 2 },
    { valor: 'Posnet', muestraValor: 'Posnet', id: 3 },
    { valor: 'Posnet - Mercado Pago', muestraValor: 'Posnet - Mercado Pago', id: 5 },
    { valor: 'Efectivo', muestraValor: 'Efectivo', id: 4 },
  ];

  constructor(
    private bookingService: BookingService,
    private providerDialog: MatDialogRef<DialogReturnsComponent>,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationsService,
    private loginAuthService: LoginAuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.idPayment = this.data.idPaymentHeader;
    this.totalPrice = this.data.totalPrice;
    this.maxRefund = this.data.maxRefund;
  }

  ngOnInit() {
    this.user = this.loginAuthService.getUser();
    this.paymentForm = this.formBuilder.group({
      Refund: ['', [Validators.required, AdnValidators.validateRefund(this.maxRefund)]],
      IdPaymentMethod: ['', [Validators.required]],
      Observations: ['']
    });
    this.paymentPosnetForm = this.formBuilder.group({
      lot: ['', [Validators.required]],
      coupon: ['', [Validators.required]],
      RefundNumber: ['', [Validators.required]],
      Observations: ['']
    });
    this.bankTransferForm = this.formBuilder.group({
      Bank: ['', [Validators.required]],
      ResponsiblePayment: ['', [Validators.required]],
      BankTransferDate: ['', [Validators.required]],
      Cbu: ['', [Validators.required]],
    });
  }

  cancel() {
    this.providerDialog.close();
  }

  getErrorMessage() {
    return 'Este campo es requerido';
  }

  saveReasons(event) {
    if (event === 5 || event === 3) {
      if (this.paymentForm.valid && this.paymentPosnetForm.valid) {
        this.isLoad = true;
        const creditTicket = new CreditcardTicket();
        this.paymentDetail.PaymentDetailDate = this.date;
        this.paymentDetail.Refund = this.paymentForm.value.Refund;
        this.paymentDetail.IdPaymentMethod = this.paymentForm.value.IdPaymentMethod;
        this.paymentDetail.IdUser = this.user.idUser;
        this.paymentDetail.IdPaymentHeader = this.idPayment;
        this.paymentDetail.Observations = this.paymentForm.value.Observations;
        creditTicket.lot = this.paymentPosnetForm.value.lot;
        creditTicket.coupon = this.paymentPosnetForm.value.coupon;
        creditTicket.RefundNumber = this.paymentPosnetForm.value.RefundNumber;
        creditTicket.CreditcardTicketDate = this.date;
        this.paymentDetail.CreditcardTicket.push(creditTicket);
        this.bookingService.postPaymentRefund(this.paymentDetail).subscribe((resp) => {
          this.notificationService.success('Éxito', 'El pago se registró correctamente');
          this.providerDialog.close(true);
          this.isLoad = false;
        });
      } else {
        this.notificationService.warn('Error', 'Debe completar todos los campos');
        this.isLoad = false;
      }
    } else {
      if (event === 1 || event === 4) {
        if (this.paymentForm.valid) {
          this.isLoad = true;
          const creditTicket = new CreditcardTicket();
          this.paymentDetail.PaymentDetailDate = this.date;
          this.paymentDetail.Refund = this.paymentForm.value.Refund;
          this.paymentDetail.IdPaymentMethod = this.paymentForm.value.IdPaymentMethod;
          this.paymentDetail.IdUser = this.user.idUser;
          this.paymentDetail.IdPaymentHeader = this.idPayment;
          this.paymentDetail.Observations = this.paymentForm.value.Observations;
          this.bookingService.postPaymentRefund(this.paymentDetail).subscribe((resp) => {
            this.notificationService.success('Éxito', 'El pago se registró correctamente');
            this.providerDialog.close(true);
            this.isLoad = false;
          });
        } else {
          this.notificationService.warn('Error', 'Debe completar todos los campos');
          this.isLoad = false;
        }
      } else {
        if (event === 2) {
          if (this.paymentForm.valid && this.bankTransferForm.valid) {
            this.isLoad = true;
            const bankTranfer = new BankTransfers();
            this.paymentDetail.PaymentDetailDate = this.date;
            this.paymentDetail.Refund = this.paymentForm.value.Refund;
            this.paymentDetail.IdPaymentMethod = this.paymentForm.value.IdPaymentMethod;
            this.paymentDetail.IdUser = this.user.idUser;
            this.paymentDetail.IdPaymentHeader = this.idPayment;
            this.paymentDetail.Observations = this.paymentForm.value.Observations;
            bankTranfer.Bank = this.bankTransferForm.value.Bank;
            bankTranfer.BankTransferDate = this.bankTransferForm.value.BankTransferDate;
            bankTranfer.ResponsiblePayment = this.bankTransferForm.value.ResponsiblePayment;
            bankTranfer.Cbu = this.bankTransferForm.value.Cbu;
            this.paymentDetail.BankTransfers.push(bankTranfer);
            this.bookingService.postPaymentRefund(this.paymentDetail).subscribe((resp) => {
              this.notificationService.success('Éxito', 'El pago se registró correctamente');
              this.providerDialog.close(true);
              this.isLoad = false;
            });
          } else {
            this.notificationService.warn('Error', 'Debe completar todos los campos');
            this.isLoad = false;
          }
        } else {
          this.notificationService.warn('Error', 'Debe completar todos los campos');
          this.isLoad = false;
        }
      }
    }
  }
}
