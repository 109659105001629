import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { ExcursionsDetails } from 'src/app/shared/class/excursions-details.model';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-pax-forms',
  templateUrl: './pax-forms.component.html',
  styleUrls: ['./pax-forms.component.scss']
})
export class PaxFormsComponent implements OnInit {
  //userType = 1;
  idLayout: number;
  typeSite = 2;

  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.idLayout = params.id;
    });
  }
}
