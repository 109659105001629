import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment2 } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  constructor(private http: HttpClient) { }

  getToursToday(idUser: number): Observable<any> {
    return this.http.get<any>(`/services/layout/api/Layouts/Today/${idUser}`);
  }

  getToursTomorrow(idUser: number): Observable<any> {
    return this.http.get<any>(`/services/layout/api/Layouts/Tomorrow/${idUser}`);
  }

  getToursAfterTomorrow(month: number, year: number, idUser: number): Observable<any> {
    return this.http.get<any>(`/services/layout/api/Layouts/AfterTomorrow/${month}/${year}/${idUser}`);
  }

  getTime(): Observable<any> {
    return this.http.get<any>(`/services/layout/api/Layouts/datetime`);
  }

  getOpenExcursionsTotal() {
    return parseInt(sessionStorage.getItem('total'), 10);
  }
  getLayout(idBooking: number): Observable<any> {
    return this.http.get<any>(`/services/layout/api/Layouts/timeLayout/${idBooking}`);
  }
}
