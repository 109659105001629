import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { Tours } from 'src/app/shared/class/tours';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LayoutService } from 'src/app/shared/service/layout.service';
import { LockSeatsComponent } from '../../lock-seats/lock-seats.component';
import { LoginAuthService } from 'src/app/auth/providers/login-auth.service';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit, OnChanges {

  @Input() tours: Tours[];
  @Input() users: any;
  @Input() id: any;
  @Input() isUser: boolean;
  @Input() type: any;
  @Output() tourEvent = new EventEmitter<any>();

  public rowsDetails: UntypedFormArray = this.formBuilder.array([]);
  public user: any;
  public pageActual = 1;
  public random: string;
  public date = new Date().toLocaleDateString() + new Date().toLocaleTimeString();
  public time: any;
  currentUser: any;
  public active: boolean;

  x: number[] = [];

  constructor(
    private route: Router,
    public dialog: MatDialog,
    public formBuilder: UntypedFormBuilder,
    public layoutService: LayoutService,
    private loginAuthService: LoginAuthService
  ) {
    this.currentUser = this.loginAuthService.getUser();
  }

  ngOnInit() {
    this.random = 'id' + this.getRandom();
    this.layoutService.getTime().subscribe(resp => {
      this.time = resp.data;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  /** directs to excursion detail screen */
  openDetail(rowIndex: number) {
    if (this.users.rol === 'Admin') {
      this.route.navigate(['/admin-page/excursions-detail-admin'], { queryParams: { id: rowIndex } });
    } else {
      if (this.users.rol === 'Ofic') {
        this.route.navigate(['/ofic-page/excursions-detail-ofic'],
          { queryParams: { id: rowIndex } });
      } else {
        this.route.navigate(['/consult-page/excursions-detail-consult'],
          { queryParams: { id: rowIndex } });
      }
    }
  }

  /** directs to form booking */
  openBookingN(rowIndex: number) {
    if (this.users.rol === 'Admin') {
      this.route.navigate(['/admin-page/forms-admin'], { queryParams: { id: rowIndex, number: 0 , sheet: 0} });
    } else if (this.users.rol === 'Ofic') {
      this.route.navigate(['/ofic-page/forms-ofic'], { queryParams: { id: rowIndex, number: 0 , sheet: 0} });
    } else {
      this.route.navigate(['/recep-page/forms-recep'], { queryParams: { id: rowIndex, number: 0 , sheet: 0 } });
    }
  }

  /** directs to booking screen */
  openBookingByTour(rowIndex: number, freeSeats: number, statusLayout: string, startDate: any, typeTour: string, reservedSeat: number) {
    if (this.users.rol === 'Admin') {
      this.disableBooking(freeSeats, statusLayout, startDate, typeTour, reservedSeat);
      this.route.navigate(['/admin-page/booking-admin'], { queryParams: { id: rowIndex , active: this.active} });
    } else {
      this.disableBooking(freeSeats, statusLayout, startDate, typeTour, reservedSeat);
      this.route.navigate(['/ofic-page/booking-ofic'], { queryParams: { id: rowIndex , active: this.active} });
    }
  }

  /** directs to report cnrt screen */
  openBookingByTourPax(rowIndex: number) {
    if (this.users.rol === 'Admin') {
      this.route.navigate(['/admin-page/report-cnrt'], { queryParams: { id: rowIndex } });
    } else {
      this.route.navigate(['/ofic-page/report-cnrt-ofic'],
        { queryParams: { id: rowIndex } });
    }

  }
  /** directs to list pax screen */
  openBookingByTourPerson(rowIndex: number) {
    if (this.users.rol === 'Admin') {
      this.route.navigate(['/admin-page/list-pax'], { queryParams: { id: rowIndex } });
    } else {
      this.route.navigate(['/ofic-page/list-pax-ofic'], { queryParams: { id: rowIndex } });
    }
  }

  openDetailUser(rowIndex: number) {
    this.route.navigate(['/recep-page/excursions-detail'],
      { queryParams: { id: rowIndex } });
  }

  openBookingNUser(rowIndex: number) {
    this.route.navigate(['/recep-page/forms-recep'], { queryParams: { id: rowIndex, number: 0 , sheet: 0} });
  }

  openBookingUser(rowIndex: number, freeSeats: number, statusLayout: string, startDate: any, typeTour: string, reservedSeat: number) {
    this.disableBooking(freeSeats, statusLayout, startDate, typeTour, reservedSeat);
    this.route.navigate(['/recep-page/booking-user'], { queryParams: { id: rowIndex , active: this.active} });
  }

  getRandom() {
    return Math.random();
  }

  disableBooking(freeSeats: number, statusLayout: string, startDate: any, typeTour: string, reservedSeat: number) {
    if (typeTour === 'Privado' && reservedSeat !== 0) {
      this.active = false;
      return false;
    } else {
      if (freeSeats !== 0 && statusLayout === 'ABIERTO') {
        if (this.type === 'HOY' && this.time > startDate) {
          this.active = false;
          return false;
        } else {
          this.active = true;
          return true;
        }
      } else {
        this.active = false;
        return false;
      }
    }
  }

  lockSeatsDialog(event: number, freeSeats: number) {
    const lockSeatsDialogConfig = new MatDialogConfig();
    lockSeatsDialogConfig.disableClose = true;
    lockSeatsDialogConfig.autoFocus = true;
    lockSeatsDialogConfig.maxHeight = '80vh';
    lockSeatsDialogConfig.data = {
      idLayout: event,
      freeSeats: freeSeats,
    };
    const lockSeatDialogRef = this.dialog.open(LockSeatsComponent, lockSeatsDialogConfig);
    lockSeatDialogRef.afterClosed().subscribe((resp) => {
      this.tourEvent.emit();
    });
  }
}
