import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ToursService {

  constructor(private http: HttpClient) { }

  isFilter = new EventEmitter<boolean>();

  public filtersCleaned: EventEmitter<string> = new EventEmitter<string>();
  public selectActivity: EventEmitter<string> = new EventEmitter<(string)>();
  public isLoading: EventEmitter<boolean> = new EventEmitter<(boolean)>();

  

  getStartingPlace(): Observable<any> {
    return this.http.get<any>(`/services/tour/api/tour/StartingPlace/`);
  }

  getEndingPlace(): Observable<any> {
    return this.http.get<any>(`/services/tour/api/tour/EndingPlace/`);
  }

  getStartingPlaceByActivity(idTypeActivity): Observable<any> {
    return this.http.get<any>(`/services/tour/api/tour/StartingPlace/${idTypeActivity}`);
  }

  getEndingPlaceByActivity(idTypeActivity): Observable<any> {
    return this.http.get<any>(`/services/tour/api/tour/EndingPlace/${idTypeActivity}`);
  }

  endingPlace(ending): Observable<any> {
    return this.http.get<any>(`/services/tourapi/tour/ToursByEndingPlace/ `, ending);
  }

  // EXCURSIONES DE HOY
  getExcursions(): Observable<any> {
    let header = new HttpHeaders({
      'Accept': 'application/json'
    });
    return this.http.get<any>(`/services/tour/api/tour/today`, { headers: header });
  }

  getExcursionsByActivity(idTypeActivity): Observable<any> {
    let header = new HttpHeaders({
      'Accept': 'application/json'
    });
    return this.http.get<any>(`/services/tour/api/tour/Today/${idTypeActivity}`, { headers: header });
  }

  // EXCURSIONES DE MAÑANA
  getExcursionsTomorrow(): Observable<any> {
    return this.http.get<any>(`/services/tour/api/tour/Tomorrow/`);
  }

  // EXCURSIONES DE MAÑANA
  getExcursionsTomorrowByActivity(idTypeActivity: number): Observable<any> {
    return this.http.get<any>(`/services/tour/api/tour/Tomorrow/${idTypeActivity}`);
  }

  // EXCURSIONES PROXIMAS
  getExcursionsAfter(month: number, year: number): Observable<any> {
    return this.http.get<any>(`/services/tour/api/tour/AfterTomorrow/${month}/${year}`);
  }

  // EXCURSIONES PROXIMAS POR ACTIVIDAD
  getExcursionsAfterByActivity(month: number, year: number, idTypeActivity: number): Observable<any> {
    return this.http.get<any>(`/services/tour/api/tour/AfterTomorrow/${month}/${year}/${idTypeActivity}`);
  }

  // DETALLE DE EXCURSIONES
  getExcursionDetail(id: number, idUser?: number): Observable<any> {
    return this.http.get<any>(`/services/tour/api/tour/${id}/${idUser}`);
  }

  getFilterTour(tour: any): Observable<any> {
    return this.http.post<any>(`/services/tour/api/tour/ToursByFilters`, tour);
  }

  getStoppingPlaces(idStartingLocation: number): Observable<any> {
    return this.http.get<any>(`/services/tour/api/tour/StoppingPlace/${idStartingLocation}`);
  }

  /**get locations*/
  getLocations(): Observable<any> {
    return this.http.get<any>(`/services/tour/api/tour/Locations`);
  }
  getTourByTitle(title: string): Observable<any> {
    return this.http.get<any>(`/services/tour/api/tour/ToursByTitle/${title}`);
  }
  getTypeActivity(): Observable<any> {
    return this.http.get<any>(`/services/tour/api/tour/TypeActivity`);
  }
}
