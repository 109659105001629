import { Component, OnInit } from '@angular/core';
import { CodeAdminComponent } from '../code-admin/code-admin.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, Validators, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LayoutService } from 'src/app/shared/service/layout.service';
import { forkJoin } from 'rxjs';
import { LoginAuthService } from 'src/app/auth/providers/login-auth.service';
@Component({
  selector: 'app-excursions-user',
  templateUrl: './excursions-user.component.html',
  styleUrls: ['./excursions-user.component.scss']
})
export class ExcursionsUserComponent implements OnInit {

  public formTableToday: UntypedFormGroup;
  public formTableTomorrow: UntypedFormGroup;
  public formTableAfterTomorrow: UntypedFormGroup;
  public monthYearForm: UntypedFormGroup;
  public rowsDetails: UntypedFormArray = this.formBuilder.array([]);
  public user: any;
  public code: any;
  isReady = true;
  tours = [
    { type: 'HOY', tours: [] },
    { type: 'MAÑANA', tours: [] },
    { type: 'PRÓXIMAS', tours: [] }
  ];
  date = new Date();

  months: any = [
    { name: 'Enero', value: 1 },
    { name: 'Febrero', value: 2 },
    { name: 'Marzo', value: 3 },
    { name: 'Abril', value: 4 },
    { name: 'Mayo', value: 5 },
    { name: 'Junio', value: 6 },
    { name: 'Julio', value: 7 },
    { name: 'Agosto', value: 8 },
    { name: 'Septiembre', value: 9 },
    { name: 'Octubre', value: 10 },
    { name: 'Noviembre', value: 11 },
    { name: 'Diciembre', value: 12 }
  ];
  actualMonth: number;

  years: any = [
    { name: '2020', value: 2020 },
    { name: '2021', value: 2021 }
  ];
  actualYear: number;

  constructor(
    private layoutService: LayoutService,
    private route: Router,
    public dialog: MatDialog,
    public formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private loginAuthService: LoginAuthService
  ) {

  }

  ngOnInit() {
    this.user = this.loginAuthService.getUser();
    // obtiene la fecha de pasado mañana
    this.date.setDate(this.date.getDate() + 2);

    const actualMonth = this.date.getMonth() + 1;
    const actualYear = this.date.getFullYear();

    this.monthYearForm = this.formBuilder.group({
      month: [actualMonth],
      year: [actualYear]
    });
    this.tour();
  }

  tour() {
    forkJoin(
      this.layoutService.getToursToday(this.user.idUser),
      this.layoutService.getToursTomorrow(this.user.idUser),
      this.layoutService.getToursAfterTomorrow(this.monthYearForm.value.month, this.monthYearForm.value.year, this.user.idUser)
    ).subscribe(
      ([toursToday, toursTomorrow, toursAfterTomorrow]) => {

        this.tours[0].tours = toursToday.data;
        this.tours[1].tours = toursTomorrow.data;
        this.tours[2].tours = toursAfterTomorrow.data;

        this.isReady = true;
      });
  }
  openDetail(rowIndex: number) {
    this.route.navigate(['/recep-page/excursions-detail'],
      { queryParams: { id: rowIndex } });
  }

  openBookingN(rowIndex: number) {
    this.route.navigate(['/recep-page/forms-recep'], { queryParams: { id: rowIndex, number: 0 } });
  }

  getFormGroupDetail(): UntypedFormGroup {
    return this.formBuilder.group({
      id: ['', [Validators.required]],
      startDate: [null, [Validators.required]],
      title: ['', [Validators.required]],
      typeTourDescription: ['', [Validators.required]],
      price: ['', [Validators.required]],
      availableSeats: ['', [Validators.required]],
    });
  }

  addRowDetail(event) {
    for (let detail of event) {
      let rowDetail: UntypedFormGroup = this.getFormGroupDetail();
      rowDetail.patchValue({
        id: detail.id,
        startDate: detail.startDate,
        title: detail.title,
        typeTourDescription: detail.typeTourDescription,
        price: detail.price,
        availableSeats: detail.availableSeats,
      });
      this.rowsDetails.push(rowDetail);
    }
  }

  openBookingByTour(rowIndex: number) {
    this.route.navigate(['/booking-admin'], { queryParams: { id: rowIndex } });
  }

  openCodeDialog() {
    const customerDialogConfig = new MatDialogConfig();
    customerDialogConfig.disableClose = true;
    customerDialogConfig.autoFocus = true;
    customerDialogConfig.width = '500px';
    customerDialogConfig.data = this.code;

    const customerDialogRef = this.dialog.open(CodeAdminComponent, customerDialogConfig);
    customerDialogRef.afterClosed().subscribe(() => console.log(this.user));
    customerDialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.user = data.user;
        }
      });
  }

  selectMonth(month) {
    this.actualMonth = month;
    this.loadAfterTomorrowTable();
  }

  selectYear(year) {
    this.actualYear = year;
    this.loadAfterTomorrowTable();
  }

  loadAfterTomorrowTable() {
    if (this.actualMonth === undefined) {
      this.actualMonth = this.date.getMonth() + 1;
    }
    if (this.actualYear === undefined) {
      this.actualYear = this.date.getFullYear();
    }
    this.layoutService.getToursAfterTomorrow(this.actualMonth, this.actualYear, this.user.idUser)
      .subscribe(
        toursAfterTomorrow => {
          this.tours[2].tours = toursAfterTomorrow.data;
        }
      );
  }

}



