import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExcursionDetailAdminComponent } from './excursion-detail-admin/excursion-detail-admin.component';
import { ExcursionDetailAdminContainerComponent } from './excursion-detail-admin-container/excursion-detail-admin-container.component';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ExcursionsDetailAdminRoutingModule } from './excursions-detail-admin-routing.module';
import { MaterialModule } from '../shared/material.module';
import { DialogImagesComponent } from './dialog-images/dialog-images.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



@NgModule({
  declarations: [ExcursionDetailAdminComponent,
    ExcursionDetailAdminContainerComponent,
    DialogImagesComponent
  ],

  imports: [
    CommonModule,
    FontAwesomeModule,
    SharedModule,
    ReactiveFormsModule,
    RouterModule,
    ExcursionsDetailAdminRoutingModule,
    MaterialModule
  ],
})
export class ExcursionsDetailAdminModule { }
