
export class Person {

  public idPerson: number;
  public firstName: string;
  public lastName: string;
  public dni: string;
  public cuil: string;
  public passport: string;
  public address: string;
  public email: string;
  public mobile: string;
  public birthDate: Date;
  public nationality: string;
  public rolPerson?: any[] = [];

  constructor() { }
}
