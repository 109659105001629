export class Filter {

  public startingPlace: string;
  public endingPlace: string;
  public dateTime: Date;
  public dni: any;
  public passport: any;

  constructor() { }
}
