import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/shared/confirmation/confirmation.component';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-cancel-confirmations',
  templateUrl: './cancel-confirmations.component.html',
  styleUrls: ['./cancel-confirmations.component.scss']
})
export class CancelConfirmationsComponent implements OnInit {

  public configuration: any;
  public cancelForm: UntypedFormGroup;
  public type: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogReference: MatDialogRef<CancelConfirmationsComponent>) {
    this.type = this.data.type;
    this.configuration = {
      title: data.title,
      question: data.question
    };
  }

  ngOnInit() {
    this.cancelForm = this.formBuilder.group({
      Observations: ['', [Validators.required]],
    });
  }

  onButtonAction(action: any) {
    if (this.cancelForm.valid) {
      this.dialogReference.close(this.cancelForm.value.Observations);
    } else {
      this.notificationService.warn('Error', 'Debe completar un motivo');
    }

  }

  closeButton(action: any) {
    this.dialogReference.close(action);
  }
}
