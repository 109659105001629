<div class="wrapper">
    <div class="sidebar" data-color="danger" data-background-color="white" data-image="./assets/img/sidebar-1.jpg">
    </div>
    <div class="main-panel">
        <!-- HEADER -->
        <ng-content select="[header]"> </ng-content>
        <!-- CONTENIDO -->
        <ng-content select="[content]"> </ng-content>
        <div *ngIf="isMaps('maps')">
            <!-- FOOTER -->
            <ng-content select="[footer]"> </ng-content>
        </div>
    </div>
</div>
