import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-foot',
  templateUrl: './foot.component.html',
  styleUrls: ['./foot.component.scss']
})
export class FootComponent implements OnInit {
  constructor(
    public router: Router,
  ) { }
  ngOnInit() {
    console.log(this.router.url);
  }
}
