<div class="foot" role="banner" style="width: 100%;" *ngIf="router.url != '/vendedores'">
  <div class="container">
    <div class="row">
      <div class="col-6 text-white pt-3">
        <small>©ADN Travel </small>
      </div>
      <div class="col-6 text-right  pt-3">
        <a href="https://api.whatsapp.com/send?phone=5493884047879" target="_blank"><i class="fa-brands fa-whatsapp fa-2x text-white"></i></a>

        <a href="https://www.facebook.com/adntravel" class="mx-3" target="_blank">
          <i class="fa-brands fa-facebook fa-2x text-white"></i>
        </a>
        <a href="https://www.instagram.com/jujuyadntravel/" target="_blank">
          <i class="fa-brands fa-instagram fa-2x text-white"></i>
        </a>
      </div>
    </div>
</div>
</div>
