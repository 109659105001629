
  <div class="card card-search">

    <div class="card-body container-md">
        <!-- <form> -->
      <div class="row align-items-center pr-2 pl-2 pt-md-3">
        <div class="col-12 col-md-12 col-xl-3 col-lg-3">
          <div class="row align-items-center pr-2 pl-2">
            <div class="col-6 col-xl-12 col-md-4 col-lg-12 pt-md-3 pt-lg-0">
              <span for="fecha" class="text-azul">Fecha de salida</span>
            </div>
            <div class="col-6 col-md-8 col-lg-12 col-xl-12 p-0 ">
              <i class="fa-regular fa-calendar ico-calendar-home d-lg-none"></i>
              <input type="date" [placeholder]="fechaPlaceholderText" [(ngModel)]="Students.dob" (ngModelChange)="checkDate()" name="Students.dob" class="form-control box-select" [formControl]="dateControl" min='{{current | date:"yyyy-MM-dd"}}'>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-7 col-xl-7 p-0 p-lg-2 p-md-2">
          <div class="box-search">
            <div class="row align-items-center icon-search" style="background-image: url('assets/img/iconos-search.png')">
              <div class="text-right p-0 " style="width: 30px" >
              </div>
              <div class="col">
                <div class="col-12">
                  <select class="form-control box-select mt-2 mb-2" [formControl]="originControl" placeholder="¿Desde donde quieres salir?" onchange="this.blur()" onblur="this.size=0;">
                    <option value="" disabled selected hidden>
                      ¿Desde dónde quieres salir?
                    </option>
                    <option *ngFor="let start of starting" [ngValue]="start">
                      {{start}}
                    </option>
                  </select>
                </div>

                <div class="col-12">
                  <select class="form-control box-select mb-2" [formControl]="destinationControl" placeholder="Destino" onchange="this.blur()" onblur="this.size=0;">
                    <option value="" disabled selected hidden>
                      ¿Hacia dónde quieres ir?
                    </option>
                    <option *ngFor="let destino of ending" [ngValue]="destino">
                      {{destino}}</option>
                  </select>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-2 col-xl-2 custom_style mt-3 p-0 p-lg-2 p-md-2 pr-md-3">
          <button type="button" id="btn-search" class="btn btn-block btn-rojo" (click)="searchTour()" style="margin: 0; width: 100%;"><i class="fa-solid fa-magnifying-glass"></i> BUSCAR</button>
          <button id="btn-clear" class="btn btn-block btn-rojo bg-secondary" style="cursor: pointer;" (click)="onClear()"><i class="fa-solid fa-refresh text-white" style="font-size: 15px; color: #ffffff !important;" ></i> Limpiar</button>
          <!--<button *ngIf="isFilter" id="btn-clear" class="btn btn-block btn-rojo bg-secondary" style="cursor: pointer;" (click)="onClear()"><i class="fa-solid fa-refresh text-white" style="font-size: 15px; color: #ffffff !important;" ></i> Limpiar</button> -->
        </div>
      </div>




      </div>


  </div>



<div class="container-fluid container-lg" (window:resize)="onResize()" >

  <h5 class="title-boxes mt-5" *ngIf="!isFilter"><i class="fa-sharp fa-solid fa-circle fa-2xs"></i> {{ "EXC" | translate }} <span class="text-rojo">{{ "HOY" | translate }}</span> <span *ngIf="isSelectedActivity">: </span> <span *ngIf="isSelectedActivity" class="text-azul text-uppercase"> {{currentActivity.typeActivityDescription}}</span></h5>
  <div class="card border-0" *ngIf="!isFilter">

    <h6 *ngIf="arrayOfArrays.length == 0 && !cargando" class="ml-3 mt-3 mb-3 text-azul sinresultados"><i class="fa-solid fa-triangle-exclamation text-rojo"></i> No se encontraron actividades para <span class="text-rojo text-uppercase">HOY </span></h6>

    <div class="container-fluid container-lg" *ngIf="cargando" style="margin-top: 1rem;">
      <div class="col-12">
          <div class="alert alert-info text-center" style="background-color: #e3f1f8;">
            <i class="fa fa-spin fa-solid fa-circle-notch fa-2x"></i>
              <p class="mb-0">Cargando... Por favor espere</p>
          </div>
      </div>
    </div>

    <div class="card-body p-0" *ngIf="!cargando">

          <div class="mb-3" *ngFor="let excs of getArray(); let k = index" [ngClass]="k == 0 ? 'active' : ''">
            <div class="row m-0">
              <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 pl-1 pr-1" *ngFor="let exc of excs" (click)="openDetail(exc.idLayout)">
                <div class="w-100 card-img-adn" [ngStyle]="{'background-image': 'url(\''+ exc.url + '\')'}" (click)="openDetail(exc.idLayout)">
                  <div class="overlay-card-adn-x2">
                    <h5 class="card-title">{{exc.title}} desde {{exc.startingPlace}}</h5>
                    <h5 class="card-title activity"><img _ngcontent-wex-c7="" class="ng-star-inserted" style="margin-right: 7px;" src="assets/img/{{exc.idTypeActivity}}.svg" alt="Travesía 4x4">{{exc.typeActivity}}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>


    </div>

  </div>


</div>
