import { BookingDetail } from './booking-detail';

export class Booking {

  public idLayout: number;
  public totalPax: number;
  public unitPrice: number;
  public totalPrice: number;
  public statusBooking: string;
  public pickUp: string;
  public dropOff: string;
  public bookingDate: Date;
  public idUser: number;
  public idCode: number;
  public bookingDetail: BookingDetail[] = [];
  public hotel: string;
  public observations: string;
  public IdResponsable?: number;
  public IdSite?: number;

  constructor() { }
}
