<div class="dialog-body">
  <div class="row justify-content-between">
    <div class="col">
      <h2 mat-dialog-title> Bloqueo de lugares </h2>
    </div>

    <div class="col-1">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel(true)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>

  <hr />
  <div class="mat-typography" style="padding-top: 1px;">
    <form [formGroup]="lockSeatForm" *ngIf="this.user.rol ==='Admin' || this.user.rol === 'Ofic'">

      <div class="form-row">

        <div class="form-group col-12 col-sm-6">
          <div class="form-row">
            <div class="col-4">
              <p style="font-size: 15px; padding-top: calc(.375rem + 5px);"><strong>Cant. lugares:</strong></p>
            </div>
            <mat-form-field class="col-4">
              <input matInput type="number" formControlName="quantity" min="0"
                onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46  ? true : !isNaN(Number(event.key));">
              <mat-error *ngIf="lockSeatForm.controls['quantity'].invalid && lockSeatForm.controls['quantity'].touched">
                <span *ngIf="this.lockSeatForm.value.quantity > this.free">Cant.inválida.</span>
              </mat-error>
            </mat-form-field>
            <div class="col-4">
              <button mat-raised-button class="btn btn-primary"
                style="background-color: #1cac67;color: white;height: max-content;" cdkFocusInitial
                (click)="saveLockSeats()" [disabled]="!this.lockSeatForm.valid">Aceptar
              </button>
            </div>

          </div>
        </div>

        <div class="form-group col-12 col-sm-6">
          <div class="form-row">
            <div class="col-8">
              <p style="font-size: 15px; padding-top: calc(.375rem + 5px);">
                <strong>Total de lugares bloqueados:
                  {{totalLock || 0}}</strong>
              </p>
            </div>
            <div class="col-4">
              <button mat-raised-button class="btn btn-primary"
                style="background-color: #62ad89;color: white;height: max-content;" cdkFocusInitial
                (click)="openBookingN(idLayout)">Reservar
              </button>
            </div>

          </div>
        </div>

      </div>

    </form>

    <div class="col-sm-12">
      <div class="table-responsive custom-table-insside">
        <table class="table table-striped">
          <thead>
            <tr>
              <th class="focus-text">Fecha inicio</th>
              <th class="focus-text">Fecha fin</th>
              <th class="focus-text">Vendedor</th>
              <th class="focus-text">Cant. lugares</th>
              <th class="focus-text" *ngIf="this.user.rol ==='Admin' || this.user.rol === 'Ofic'">Desbloquear lugares
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let lock of formRow.controls | paginate: {itemsPerPage: 3, currentPage: pageActual, id: randomBKA}; let i=index"
              [formGroup]="lock">
              <th class="align">{{lock.value.data.startDate | date:'EE dd/MM h:mm a' }}</th>
              <th class="align">{{lock.value.data.endingDate | date:'EE dd/MM h:mm a' }}</th>
              <td class="align"> {{lock.value.data.nameUser}}</td>
              <td class="align"> {{lock.value.data.quantity}}</td>
              <td class="align" *ngIf="this.user.rol !=='Recep'">
                <mat-form-field style="width: 75px; margin: 0 25px 0 25px;">
                  <input matInput formControlName="quantity1" type="number" min="0"
                    [readonly]="!disableUnlock(lock.value.data.idUser)"
                    onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))">
                  <mat-error *ngIf="lock.controls['quantity1'].invalid && lock.controls['quantity1'].touched">
                    <span *ngIf="lock.value.data.quantity > lock.value.quantity1">Cant.inválida.</span>
                  </mat-error>
                  <button mat-button matSuffix mat-icon-button matTooltip="Guardar Cantidad"
                    (click)="saveUnlockSeats(lock.value.quantity1, lock.value.data.idLockSeat)"
                    [disabled]="!disableUnlock(lock.value.data.idUser) || !this.lock.valid">
                    <mat-icon style="font-size: 20px;">save</mat-icon>
                  </button>
                </mat-form-field>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<pagination-controls (pageChange)="pageActual = $event" id="{{randomBKA}}" style="float: right;"
  previousLabel="Anterior" nextLabel="Siguiente">
</pagination-controls>