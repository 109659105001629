import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Booking } from '../class/booking';
import { Pax } from '../class/pax';
import { PaymentDetails } from '../class/paymentDetails';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(private http: HttpClient) { }

  public activitiesLoaded: EventEmitter<string> = new EventEmitter<string>();


  getBookingByTourPax(id: number): Observable<any> {
    return this.http.post<any>(`/services/booking/api/Bookings/CNRTReport/${id}`, undefined);
  }
  postBooking(booking: Booking, number: string): Observable<Booking> {
    return this.http.post<Booking>(`/services/booking/api/Bookings/${number}`, booking);
  }
  //solo para portal cliente sin login
  postBookingCliente(booking: Booking, number: string, portal: string): Observable<Booking> {
    return this.http.post<Booking>(`/services/booking/api/Bookings/${number}/${portal}`, booking);
  }

  savePerson(pax: Pax, idBooking: number, idLayout: number): Observable<any> {
    return this.http.post<any>(`/services/booking/api/Paxes/${idLayout}/${idBooking}`, pax);
  }
  getSearchDniPassport(dni: string, tipo: boolean): Observable<any> {
    return this.http.get<any>(`/services/booking/api/Paxes/${dni}/${tipo}`);
  }
  getPaxesByLayout(id: number): Observable<any> {
    return this.http.get<any>(`/services/booking/api/Paxes/PaxesByLayout/${id}`);
  }
  getCbu(idBooking: number): Observable<any> {
    return this.http.get<any>(`/services/booking/api/Cbus/${idBooking}`);
  }
  getMercado(id: number): Observable<any> {
    return this.http.post<any>(`/services/booking/api/PaymentHeaders/CreateUpdateLinkMPago/${id}`, id);
  }
  getBookingReport(id: number, idUser: number): Observable<any> {
    return this.http.post<any>(`/services/booking/api/Bookings/ResumeReport/${id}/${idUser}`, undefined);
  }
  postPaymentDetails(paymentDetail: PaymentDetails): Observable<PaymentDetails> {
    return this.http.post<PaymentDetails>(`/services/booking/api/PaymentDetails`, paymentDetail);
  }
  getCbuEmail(id: number): Observable<any> {
    return this.http.get<any>(`/services/booking/api/Cbus/Mail/${id}`);
  }
  getPaymentVoucher(id: number): Observable<any> {
    return this.http.get<any>(`/services/booking/api/PaymentDetails/MailVoucher/${id}`);
  }
  getMailPax(id: number): Observable<any> {
    return this.http.get<any>(`/services/booking/api/Paxes/MailByPax/${id}`);
  }
  getBookingByTourPaxPrint(id: number): Observable<Blob> {
    return this.http.get<any>(`/services/booking/api/Bookings/CNRTReportPrint/${id}`, {
      responseType: 'blob' as 'json'
    });
  }
  getBookingByPaxListPrint(id: number): Observable<Blob> {
    return this.http.get<any>(`/services/booking/api/Bookings/PaxListPrint/${id}`, {
      responseType: 'blob' as 'json'
    });
  }
  getBookingResumePrintReport(id: number, idUser: number): Observable<Blob> {
    return this.http.get<any>(`/services/booking/api/Bookings/GetResumePrintReportUser/${id}/${idUser}`, {
      responseType: 'blob' as 'json'
    });
  }
  getNationalities(): Observable<any> {
    return this.http.get<any>(`/services/booking/api/Paxes/Nationalities`);
  }
  postMailMP(mail: any): Observable<any> {
    return this.http.post<any>(`/services/booking/api/PaymentHeaders/MailMPago`, mail);
  }
  bookingCancel(booking: any): Observable<any> {
    return this.http.post<any>(`/services/booking/api/Bookings/Cancel`, booking);
  }
  printVoucher(idBooking: number): Observable<any> {
    return this.http.get<any>(`/services/booking/api/PaymentDetails/PrintVoucher/${idBooking}`, {
      responseType: 'blob' as 'json'
    });
  }
  getBookingData(idBooking: number): Observable<any> {
    return this.http.get<any>(`/services/booking/api/Bookings/GetBookingData/${idBooking}`);
  }
  updateBooking(booking: Booking, confirmSendEmail: boolean): Observable<any> {
    return this.http.put<any>(`/services/booking/api/Bookings/Update/${confirmSendEmail}`, booking);
  }
  getlayoutAvailable(idBooking: number): Observable<any> {
    return this.http.get<any>(`/services/booking/api/Bookings/GetLayoutsAvailable/${idBooking}`);
  }
  getHistoryPayment(idBooking: number): Observable<any> {
    return this.http.get<any>(`/services/booking/api/PaymentDetails/${idBooking}`);
  }
  postPaymentRefund(paymentDetail: any): Observable<any> {
    return this.http.post<any>(`/services/booking/api/PaymentDetails/Refund`, paymentDetail);
  }
  postPaymentDiscount(paymentDetail: any): Observable<any> {
    return this.http.post<any>(`/services/booking/api/PaymentDetails/Discount`, paymentDetail);
  }
  getDefaultBooking(idUser: number): Observable<any> {
    return this.http.post<any>(`/services/booking/api/Bookings/GetDefaultBooking/${idUser}`, undefined);
  }
  getSellers(): Observable<any> {
    return this.http.get<any>(`/services/booking/api/Bookings/getSellers`);
  }
  postFilterBookings(filter: any): Observable<any> {
    return this.http.post<any>(`/services/booking/api/Bookings/FilterBookings`, filter);
  }
  lockSeats(lock: any): Observable<any> {
    return this.http.post<any>(`/services/booking/api/Bookings/LockSeat`, lock);
  }
  unLockSeats(unlock: any): Observable<any> {
    return this.http.post<any>(`/services/booking/api/Bookings/UnlockSeat`, unlock);
  }
  getLock(idLayout: number): Observable<any> {
    return this.http.get<any>(`/services/booking/api/Bookings/GetLockSeat/${idLayout}`);
  }
  getActivities(): Observable<any> {
    return this.http.get<any>(`/services/booking/api/Bookings/GetActivities`);
  }
}
