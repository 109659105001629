import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExcursionsDetails } from 'src/app/shared/class/excursions-details.model';
import { ToursService } from 'src/app/shared/service/tours.service';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { DialogImageUserComponent } from '../dialog-image-user/dialog-image-user.component';
import { LoginAuthService } from 'src/app/auth/providers/login-auth.service';

@Component({
  selector: 'app-excursions-detail',
  templateUrl: './excursions-detail.component.html',
  styleUrls: ['./excursions-detail.component.scss']
})
export class ExcursionsDetailComponent implements OnInit {
  public detail: ExcursionsDetails[] = [];
  public id: number;
  public service: ExcursionsDetails[] = [];
  public user: any;
  isReady = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private tourService: ToursService,
    private dialog: MatDialog,
    private loginAuthService: LoginAuthService
  ) { }

  ngOnInit() {
    this.user = this.loginAuthService.getUser();
    this.activatedRoute.queryParams.subscribe(params => {
      this.id = params.id;
    });
    this.tourService.getExcursionDetail(this.id).subscribe(resp => {
      this.detail = resp.data;
      this.isReady = true;
    });
  }

  openBooking(rowIndex: number) {
    this.router.navigate(['/recep-page/forms'], { queryParams: { id: rowIndex, number: 0 } });
  }
  openExcursionAdmin() {
    this.router.navigate(['/recep-page/excursions-user']);
  }

  openImageDialog(event) {
    const payDialogConfig = new MatDialogConfig();
    payDialogConfig.disableClose = true;
    payDialogConfig.autoFocus = true;
    payDialogConfig.width = '750px';
    payDialogConfig.data = event;

    const payDialogRef = this.dialog.open(
      DialogImageUserComponent,
      payDialogConfig
    );
    payDialogRef.afterClosed().subscribe(() => {
    });
  }
}
