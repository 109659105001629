<div class="main-content toolbar" role="banner" style="width: 1200px; margin-left: -50px;">
  <div class=" col-md-3">
    <a class="logo" href="/excursions">
      <h4 style="margin-bottom: 40px; margin-left: 40px;">Central de Reservas</h4>
    </a>
  </div>

  <div class="col-md-3" style="margin-left: 52%; margin-top: 40px;">
    <fa name="phone" class="phone"></fa>
    <strong style="margin: 3%;">{{ "PHONE" | translate }}: 0388- 4242294</strong>
    <div class="contenedor-botones" style="margin-bottom: 20px;">
      <button type="button" class="btn btn-link" [ngClass]="{'active': activeLang == 'es'}"
        (click)="cambiarLenguaje('es')">{{ "ESPAÑOL" | translate }}</button>
      <button type="button" class="btn btn-link" [ngClass]="{'active': activeLang == 'en'}"
        (click)="cambiarLenguaje('en')">{{ "INGLES" | translate }}</button>
    </div>
  </div>
</div>
