<div class="dialog-body">
  <div class="row justify-content-between">
    <div class="col">
      <h2 mat-dialog-title><img src="assets/img/pago-trasferencia.svg" class="iconos-pago"></h2>
    </div>
    <div class="col-1">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closed(false)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <hr>

  <div class="row justify-content-between">
    <div class="col-12">
      <p class="text-azul mb-3"><strong> ¡Felicidades! Tenemos tu reserva.</strong></p>

      <p *ngIf="cbu">
        Esperamos el comprobante de pago.<br>
        Datos para Transferencia Bancaria<br>
        <br><br>
        <strong>Cuenta corriente en pesos(AR$)</strong><br><br>
        Banco: <strong>{{cbu[0]?.bank}}</strong><br>
        Titular: <strong>{{cbu[0]?.holder}}</strong><br>
        Tipo de cuenta: <strong>{{cbu[0]?.accountType}}</strong><br>
        CUIT: <strong>{{cbu[0]?.cuit}}</strong><br>
        N° de cuenta: <strong>{{cbu[0]?.currentAccountNumber}}</strong><br>
        CBU: <strong>{{cbu[0]?.cbu1}}</strong><br>
        <br>
        Los datos serán enviados al siguiente correo: <strong>{{email}}</strong><br>
        <br>
        ¡Gracias por visitarnos!
      </p>
<hr>
      <p class="text-center mt-3">
        <button mat-raised-button class="btn btn-rojo" (click)="sendEmail()" cdkFocusInitial
                style="margin-top: -7px;">Finalizar <i class="fa-solid fa-chevron-right ml-2"></i>
        </button>
      </p>
    </div>

  </div>

</div>
