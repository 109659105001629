import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExcursionDetailAdminComponent } from './excursion-detail-admin/excursion-detail-admin.component';

const routes: Routes = [{
  path: '', component: ExcursionDetailAdminComponent,
  children: [
    { path: '', component: ExcursionDetailAdminComponent, data: { title: 'Detalles', status: true }, },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExcursionsDetailAdminRoutingModule { }
