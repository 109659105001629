import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogImagesComponent } from 'src/app/excursions-detail-admin/dialog-images/dialog-images.component';

@Component({
  selector: 'app-dialog-image-pax',
  templateUrl: './dialog-image-pax.component.html',
  styleUrls: ['./dialog-image-pax.component.scss']
})
export class DialogImagePaxComponent implements OnInit {

  constructor(private providerDialog: MatDialogRef<DialogImagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  cancel() {
    this.providerDialog.close();
  }
}
