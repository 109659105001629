import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, UntypedFormControl, AbstractControl, EmailValidator } from '@angular/forms';
import { ExcursionsDetails } from 'src/app/shared/class/excursions-details.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Person } from 'src/app/shared/class/person';
import { MatCardActions } from '@angular/material/card';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { PaxFormPayBankComponent } from '../pax-form-pay-bank/pax-form-pay-bank.component';
import { NotificationsService } from 'angular2-notifications';
import { Pax } from 'src/app/shared/class/pax';
import { PaxFormPayMpComponent } from '../pax-form-pay-mp/pax-form-pay-mp.component';
import { RolPerson } from 'src/app/shared/class/rol-person';
import { Filter } from 'src/app/shared/class/filter';
import { Booking } from 'src/app/shared/class/booking';
import { BookingService } from 'src/app/shared/service/booking.service';
import { ToursService } from 'src/app/shared/service/tours.service';
import { PaxFormConfirmationComponent } from '../pax-form-confirmation/pax-form-confirmation.component';
import { ConfirmationComponent } from 'src/app/shared/confirmation/confirmation.component';
import { PaxFormPayPostnetComponent } from '../pax-form-pay-postnet/pax-form-pay-postnet.component';
import { PaxFormPayCashComponent } from '../pax-form-pay-cash/pax-form-pay-cash.component';
import { AdnValidators } from 'src/app/shared/validators/adn-validators';
import { BookingDetail } from 'src/app/shared/class/booking-detail';
//import { asLiteral } from '@angular/compiler/src/render3/view/util';
import { PaymentHistoryComponent } from 'src/app/excursions-admin/payment-history/payment-history.component';
import { PaymentDetails } from 'src/app/shared/class/paymentDetails';
import { LayoutService } from 'src/app/shared/service/layout.service';
import { CancelConfirmationsComponent } from 'src/app/excursions-admin/cancel-confirmations/cancel-confirmations.component';
import { LoginAuthService } from 'src/app/auth/providers/login-auth.service';

@Component({
  selector: 'app-pax',
  templateUrl: './pax.component.html',
  styleUrls: ['./pax.component.scss']
})
export class PaxComponent implements OnInit {


  /**
   * UsetType === 0 => 'ADMIN'
   * UsetType === 1 => 'USER'
   * UsetType === 2 => 'CLIENTE'
   */

  //@Input() userType: number;
  @Input() idLayout: number;
  @Input() typeSite: number;

  isLinear = false;
  firstFormGroup: UntypedFormGroup;
  public bookingForm: UntypedFormGroup;
  public paxForm: UntypedFormGroup;
  public paxes: UntypedFormArray = this.formBuilder.array([]);
  public dniControl: UntypedFormControl = new UntypedFormControl();
  public passportControl: UntypedFormControl = new UntypedFormControl();
  public detail: ExcursionsDetails[] = [];
  public id: number;
  public person: Person = new Person();
  public pax: Pax = new Pax();
  public rolPerson: RolPerson = new RolPerson();
  public filter: Filter = new Filter();
  public booking: Booking = new Booking();
  public user: any;
  public paymentMethod: string;
  public paymentHeader: any;
  public savedPaxes = [];
  minDate = new Date(1910, 1, 1);
  maxDate = new Date(2019, 1, 1);
  public pickup: string;
  public dropoff: string;
  public idStartingLocation: number;
  public nationality: any[];
  public cuitPatterns = { 0: { pattern: new RegExp('[0-9]') } };
  @ViewChild('stepper') stepper;
  isEditableStepOne = true;
  isEditableStepTwo = true;
  firstFormToControl = new UntypedFormControl('', Validators.required);
  secondFormToControl = new UntypedFormControl('', Validators.required);
  public type: number;
  public free: number;
  public idBooking: number;
  public bookingUpdate: any = {};
  public respUpdate: any;
  public dateStart: any[] = [];
  private totalUpdate: number;
  private totalBooking: number;
  public idLa: number;
  private reservationC: boolean;
  private updateFirst: boolean;
  private updated: boolean;
  private auxBookingDetails = [];
  public statusBooking: boolean;
  public showedMessage: boolean;
  public balance: number;
  public message: string;
  public isLoad: boolean;
  public paymentDetail: PaymentDetails = new PaymentDetails();
  public paymentForm: UntypedFormGroup;
  public date = new Date();
  public isCheck: any;
  public total: number;
  public isDiscount = true;
  public paymentDiscount: any;
  public totalPrice: number;
  public time: Date;
  public startDate: Date;
  public paxDisable = true;
  public page: string;
  public number: string;
  public lockSeat: number;
  public seller: any;
  public idResponsable: number;
  public idUserBooking: number;
  public sellerDisable: boolean;
  public sellerBooking: any;
  public sellerBookingT: any;
  public sellerBookingTL: any;
  public disableButton: boolean;
  public status: string;
  public pagePax: string;
  public mp: any;
  view = false;
  public types = [{ show: 'DNI', value: 'dni' }, { show: 'PASAPORTE', value: 'passport' }];
  public pago: number;
  public allowDiscount = true;
  public priceBooking: number;
  public totalPriceBooking: number;
  public currentIdLayout: number;
  public layoutNotChanged: boolean = true;
  public isCheckSendEmail: boolean = true;
  public sheet: number;

  public rolUser: any;
  public userType: any;

  public portalCliente: number;

  public reasons = [{ show: 'Convenios', value: 'convenios' }, { show: 'Devolución', value: 'devolución' }, { show: 'Discapacidad', value: 'discapacidad' },
  { show: 'Gremios', value: 'gremios' }, { show: 'Grupo familiar', value: 'grupo familiar' }, { show: 'Jubilados', value: 'jubilados' }, { show: 'Menores', value: 'menores' },
  { show: 'Otros', value: 'otros' }, { show: 'Pago en efectivo', value: 'pago en efectivo' }];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private notificationService: NotificationsService,
    private bookingService: BookingService,
    private tourService: ToursService,
    private layoutService: LayoutService,
    private router: Router,
    private loginAuthService: LoginAuthService
  ) {

    this.activatedRoute.queryParams.subscribe(params => {
      //console.log(params);
      this.id = params.id || params.idL || params.idLayout; // id
      this.idBooking = params.idB;
      this.pagePax = params.pagePax;
      this.page = params.page;
      this.number = params.number;
      this.status = params.status;
      this.dropoff = params.dropOff;
      this.pickup = params.pickUp;
      this.sheet = params.sheet;
      this.init();
    });
  }

  ngOnInit() {
  }

  init() {
    this.user = this.loginAuthService.getUser();

    this.rolUser = this.loginAuthService.getRol();

    switch (this.rolUser) {
      case 'Admin':
          this.userType = 0;
          break;
      case 'Ofic':
          this.userType = 0;
          break;
      case 'Recep':
          this.userType = 1;
          break;
      case 'Reseller':
          this.userType = 1;
          break;
      default:
        this.userType = 2;
    }

    //si se quiere cambiar el usuario que se usa cuando es cliente hacerlo por el id de usuario que figura en BD
    if(this.user === null){
      this.user = {
        idUser: 63,
        rol: "Guest"
      }
      this.userType = 2;
    }
    //por defecto lo considerara logueado
    this.portalCliente = 0;

    this.layoutService.getTime().subscribe(resp => {
      this.time = resp.data;
    });

    this.tourService.getExcursionDetail(this.id, this.user.idUser).subscribe(resp => {
      this.detail = resp.data;
      this.startDate = this.detail[0].startDate;
      this.type = resp.data[0].typeTour;
      this.free = resp.data[0].freeSeats;
      this.lockSeat = resp.data[0].quantityLockSeat;

      this.bookingForm.get('quantity').setValidators([Validators.required, AdnValidators.validateSeats(this.free, this.number, this.lockSeat), AdnValidators.validateSeatsValid(this.type)]);
      this.bookingForm.get('quantity').updateValueAndValidity();
      this.tourService.getStoppingPlaces(resp.data[0].idStartingLocation).subscribe(respSP => {
        this.pickup = respSP.data;
        this.dropoff = respSP.data;
      });
      if (this.number === '1') {
        this.bookingForm.get('quantity').setValue(this.lockSeat);
      }
      if (this.user.rol === 'Recep' || this.user.rol === 'Guest') {
        const today: Date = new Date();
        today.setHours(today.getHours() + +12);
        this.disableButton = today.getTime() > new Date(this.startDate).getTime();
      }
    });

    if (this.user.rol === 'Guest') {
      this.createFormGuest();
    } else {
      if (this.idBooking && (this.dropoff === '' || this.dropoff === undefined) && (this.pickup === '' || this.pickup === undefined)) {
        this.createFormGuest();
      } else {
        this.createForm();
      }
    }
    this.bookingService.getNationalities().subscribe(respSP => {
      this.nationality = respSP.data;
    });
    this.paxForm = this.getPaxForm([Validators.required]);
    this.setIdTypeValidators();
    this.paxForm.patchValue({ rContact: true }, { emitEvent: false });

    this.bookingForm.get('quantity').valueChanges.subscribe((value: number) => {
      if (this.bookingForm.get('quantity').valid && !this.idBooking) {
        this.addToFormArray(value || 0);
      }
    });
    this.bookingForm.get('hotel').valueChanges.subscribe((value: string) =>
      this.paxes.controls.forEach(form => form.get('hotel').setValue(value))
    );
    if (this.userType === 2 || this.user.rol === 'Guest') {
      this.paxForm.patchValue({ reservationContact: true }, { emitEvent: false });
    }
    /**
     * Validación para un pasajero existente por DNI y Passport
     */
    this.dniControl.valueChanges.subscribe(dni => this.keyUp(dni, true, 0));
    this.passportControl.valueChanges.subscribe(passport => this.keyUp(passport, true, 0));
    this.paxForm.valueChanges.subscribe(contract => {
      if (contract.reservationContact === this.reservationC) {
        if (this.paxForm.get('idType').value === 'dni') {
          this.updateFirstForm(contract, this.paxes.controls[0] as UntypedFormGroup, this.dniControl.value);
        } else {
          this.updateFirstForm(contract, this.paxes.controls[0] as UntypedFormGroup, this.passportControl.value);
        }
        this.updateFirstFormObservation2(contract, this.paxes.controls[0] as UntypedFormGroup);
      }
      this.reservationC = contract.reservationContact;
    });
    this.paxForm.get('reservationContact').valueChanges.subscribe(resp => {
      if (!this.updated && this.updateFirst) {
        this.addContact();
      }
      if (!this.idBooking || this.idBooking && resp || this.updateFirst) {
        const form: UntypedFormGroup = this.getPaxForm();
        form.get('rContact').setValue(resp);
        this.paxes.removeAt(0);
        this.paxes.insert(0, form);
        if (!this.reservationC && resp) {
          this.updateFirstForm(this.paxForm.value, form, this.dniControl.value);
        }
        this.updateFirst = true;
      }
    });
    if (this.idBooking) {
      this.bookingService.getBookingData(this.idBooking).subscribe(resp => {
        this.respUpdate = resp.data;
        this.bookingUpdate = resp.data;
        this.idResponsable = resp.data.idResponsable;
        this.idUserBooking = resp.data.idUser;
        this.priceBooking = resp.data.unitPrice;
        this.totalPriceBooking = resp.data.idPaymentHeaderNavigation.totalBooking;
        this.isUpdate();
        this.bookingService.getSellers().subscribe(resp => {
          this.seller = resp.data;
        });
        if (this.idResponsable !== this.idUserBooking || this.user.idUser !== this.idUserBooking) {
          this.bookingService.getSellers().subscribe(resp => {
            this.seller = resp.data;
            if (this.startDate < this.time) {
              this.sellerBookingT = resp.data.find((resp: any) => resp.idUser === this.idUserBooking).firstName;
              this.sellerBookingTL = resp.data.find((resp: any) => resp.idUser === this.idUserBooking).lastName;
              this.bookingForm.get('idSeller').setValue(this.sellerBookingTL + '' + this.sellerBookingT);
            } else {
              if (this.status === 'disable') {
                this.sellerBookingT = resp.data.find((resp: any) => resp.idUser === this.idUserBooking).firstName;
                this.sellerBookingTL = resp.data.find((resp: any) => resp.idUser === this.idUserBooking).lastName;
                this.bookingForm.get('idSeller').setValue(this.sellerBookingTL + '' + this.sellerBookingT);
              } else {
                this.sellerBooking = resp.data.find((resp: any) => resp.idUser === this.idUserBooking).idUser;
                this.bookingForm.get('idSeller').setValue(this.sellerBooking);
              }
            }
          });
        } else {
          if (this.idUserBooking >= 6 && this.idUserBooking <= 45 && this.idResponsable >= 6 && this.idResponsable <= 45) {
            this.bookingService.getSellers().subscribe(resp => {
              this.seller = resp.data;
              if (this.startDate < this.time) {
                this.sellerBookingT = resp.data.find((resp: any) => resp.idUser === this.idUserBooking).firstName;
                this.sellerBookingTL = resp.data.find((resp: any) => resp.idUser === this.idUserBooking).lastName;
                this.bookingForm.get('idSeller').setValue(this.sellerBookingTL + '' + this.sellerBookingT);
              } else {
                if (this.status === 'disable') {
                  this.sellerBookingT = resp.data.find((resp: any) => resp.idUser === this.idUserBooking).firstName;
                  this.sellerBookingTL = resp.data.find((resp: any) => resp.idUser === this.idUserBooking).lastName;
                  this.bookingForm.get('idSeller').setValue(this.sellerBookingTL + '' + this.sellerBookingT);
                } else {
                  this.sellerBooking = resp.data.find((resp: any) => resp.idUser === this.idUserBooking).idUser;
                  this.bookingForm.get('idSeller').setValue(this.sellerBooking);
                }
              }
            });
          }
        }
      });
      this.bookingService.getlayoutAvailable(this.idBooking).subscribe(resp => {
        this.dateStart = resp.data;
        this.currentIdLayout = this.dateStart[0].idLayout;
        this.bookingForm.get('startDate').patchValue(this.currentIdLayout);
      });

      this.createFormDiscount(this.totalPriceBooking, this.userType);
    }
    this.disablePax();
    this.bookingService.getSellers().subscribe(resp => {
      this.seller = resp.data;
    });

    setTimeout(() => {
      this.isEditableStepOne = true;
      this.isEditableStepTwo = false;
      this.stepper.selectedIndex = 0;

      this.stepper.steps._results[0].editable = true;
      this.stepper.steps._results[0].completed = false;
      this.stepper.steps._results[1].editable = false;
      this.stepper.steps._results[1].completed = false;
      // this.stepper.steps._results[2].editable = false;
      // this.stepper.steps._results[2].completed = false;
      this.isEditableStepOne = true;
      this.isEditableStepTwo = false;
      this.stepper.selectedIndex = 0;

      this.stepper.steps._results[0].editable = true;
      this.stepper.steps._results[0].completed = false;
      this.stepper.steps._results[1].editable = false;
      this.stepper.steps._results[1].completed = false;

      this.isEditableStepOne = true;
      this.isEditableStepTwo = false;
      this.stepper.selectedIndex = 0;

      this.stepper.steps._results[0].editable = true;
      this.stepper.steps._results[0].completed = false;
      this.stepper.steps._results[1].editable = false;
      this.stepper.steps._results[1].completed = false;

    }, 1000);
  }

  selectionDate(event) {
    this.layoutNotChanged = event.value === this.currentIdLayout;
    this.bookingService.getlayoutAvailable(this.idBooking).subscribe(resp => {
      this.pago = resp.data.find((resp: any) => resp.idLayout === this.bookingForm.value.startDate).price;
    });
  }

  isUpdate() {
    this.bookingForm.patchValue({
      pickUp: this.bookingUpdate.pickUp,
      dropOff: this.bookingUpdate.dropOff,
      hotel: this.bookingUpdate.hotel,
      observations: this.bookingUpdate.observations,
      quantity: this.bookingUpdate.totalPax,
    },
      { emitEvent: false }
    );
    this.bookingForm.get('quantity').disable({ emitEvent: false });
    this.bookingForm.get('quantity').setValidators([]);

    this.bookingUpdate.bookingDetail.forEach((bookingDetail: BookingDetail) => {
      const dni: string = bookingDetail.idPaxNavigation.idRolPersonNavigation.idPersonNavigation.dni;
      const passport: string = bookingDetail.idPaxNavigation.idRolPersonNavigation.idPersonNavigation.passport;
      if (bookingDetail.reservationContact) {
        const isPax: boolean = this.bookingUpdate.bookingDetail.length === this.bookingUpdate.totalPax;
        this.reservationC = this.bookingUpdate.bookingDetail.length === this.bookingUpdate.totalPax;
        if (dni !== null) {
          this.updateFirstForm(bookingDetail.idPaxNavigation.idRolPersonNavigation.idPersonNavigation, this.paxForm, dni);
          this.updateFirstFormObservation(bookingDetail.idPaxNavigation, this.paxForm);
          this.dniControl.setValue(dni, { emitEvent: false });
          this.paxForm.get('reservationContact').setValue(isPax);
          this.paxes.at(0).get('dni').setValidators([Validators.required]);
        } else {
          this.updateFirstForm(bookingDetail.idPaxNavigation.idRolPersonNavigation.idPersonNavigation, this.paxForm, passport);
          this.updateFirstFormObservation(bookingDetail.idPaxNavigation, this.paxForm);
          this.passportControl.setValue(passport, { emitEvent: false });
          this.paxForm.get('reservationContact').setValue(isPax);
          this.paxes.at(0).get('passport').setValidators([Validators.required]);
        }
      } else {
        this.auxBookingDetails.push(bookingDetail);
      }
    });
  }

  createFormGuest() {
    this.bookingForm = this.formBuilder.group({
      totalPax: [''],
      unitPrice: [''],
      totalPrice: [''],
      statusBooking: [''],
      bookingDate: [''],
      pickUp: [''],
      dropOff: [''],
      hotel: ['', [Validators.required]],
      observations: [''],
      language: null,
      startDate: [''],
      quantity: ['', [Validators.required, AdnValidators.validateSeats(this.free, this.number, this.lockSeat), AdnValidators.validateSeatsValid(this.type)]],
      idSeller: [''],
      IdSite: ['']
    });
  }

  createForm() {
    this.bookingForm = this.formBuilder.group({
      totalPax: [''],
      unitPrice: [''],
      totalPrice: [''],
      statusBooking: [''],
      bookingDate: [''],
      pickUp: ['', [Validators.required]],
      dropOff: ['', [Validators.required]],
      hotel: ['', [Validators.required]],
      observations: [''],
      language: null,
      startDate: [''],
      quantity: ['', [Validators.required, AdnValidators.validateSeats(this.free, this.number, this.lockSeat), AdnValidators.validateSeatsValid(this.type)]],
      idSeller: [''],
      IdSite: ['']
    });
  }

  createFormDiscount(total: number, user: number) {
    this.paymentForm = this.formBuilder.group({
      Discount: ['', [Validators.required, AdnValidators.validateDiscount(this.total, user)]],
      DiscountReason: ['', [Validators.required]],
      Observations: [''],
    });
    this.paymentForm.get('DiscountReason').valueChanges.subscribe(res => {
      this.paymentForm.get('Observations').setValidators(res === 'Otros' ? [Validators.required] : []);
      this.paymentForm.get('Observations').updateValueAndValidity();
      this.paymentForm.get('Observations').setValue('');
    });
  }

  updateFirstForm(contract, formToUpdate: UntypedFormGroup, dniPassValue: string) {
    if (contract.dni === null) {
      formToUpdate.get('idType').setValue('passport', { emitEvent: false });
    }
    if (this.paxForm.get('idType').value === 'dni') {
      formToUpdate.get('dni').setValue(dniPassValue, { emitEvent: false });
      formToUpdate.get('passport').setValue(contract.passport, { emitEvent: false });
    } else {
      formToUpdate.get('dni').setValue(contract.dni, { emitEvent: false });
      formToUpdate.get('passport').setValue(dniPassValue, { emitEvent: false });
    }

    formToUpdate.get('birthDate').setValue(new Date(contract.birthDate), { emitEvent: false });
    formToUpdate.get('id').setValue(contract.id, { emitEvent: false });
    formToUpdate.get('idPerson').setValue(contract.idPerson, { emitEvent: false });
    formToUpdate.get('firstName').setValue(contract.firstName, { emitEvent: false });
    formToUpdate.get('lastName').setValue(contract.lastName, { emitEvent: false });
    formToUpdate.get('nationality').setValue(contract.nationality, { emitEvent: false });
    formToUpdate.get('email').setValue(contract.email, { emitEvent: false });
    formToUpdate.get('mobile').setValue(contract.mobile, { emitEvent: false });
    formToUpdate.get('language').setValue(contract.language, { emitEvent: false });
  }
  updateFirstFormObservation(contract, formToUpdate: UntypedFormGroup) {
    formToUpdate.get('observations').setValue(contract.observations, { emitEvent: false });
  }

  updateFirstFormObservation2(contract, formToUpdate: UntypedFormGroup) {
    formToUpdate.get('observations').setValue(contract.observations, { emitEvent: false });
  }

  updateee() {
    this.isLoad = true;
    const size = this.paxForm.value.reservationContact ? this.paxes.length : this.paxes.length + 1;
    if (this.savedPaxes.length === size) {
      const bookingAux: any = { ...this.bookingUpdate };
      bookingAux['IdBooking'] = bookingAux.idBooking;
      delete bookingAux.idBooking;
      delete bookingAux.idLayoutNavigation;
      delete bookingAux.idPaymentHeaderNavigation;
      delete bookingAux.idUserNavigation;
      delete bookingAux.idCode;
      delete bookingAux.idCurrency;
      delete bookingAux.idCurrencyNavigation;
      delete bookingAux.idPaymentHeader;
      delete bookingAux.room;
      delete bookingAux.observations;

      bookingAux.startDate = this.bookingForm.value.startDate;
      bookingAux.dropOff = this.bookingForm.value.dropOff;
      bookingAux.pickUp = this.bookingForm.value.pickUp;
      bookingAux.hotel = this.bookingForm.value.hotel;
      bookingAux.idLayout = this.bookingForm.value.startDate;
      bookingAux.observations = this.bookingForm.value.observations;
      bookingAux.idUser = this.bookingForm.value.idSeller || this.user.idUser;
      bookingAux.bookingDetail = this.savedPaxes;
      bookingAux.IdSite = this.typeSite;

      this.bookingService.updateBooking(bookingAux, this.isCheckSendEmail).subscribe(resp => {

        this.paymentHeader = this.bookingUpdate;
        this.totalUpdate = this.bookingUpdate.idPaymentHeaderNavigation.balance;
        this.isLoad = false;
        this.notificationService.success('Éxito', 'Se modificó la reserva correctamente');
        if (this.pagePax === 'pax') {
          this.goNextStepThree();
        } else {
          this.redirectUser();
        }
        this.createFormDiscount(this.totalUpdate, this.userType);
      });
    }
  }

  checkBoxValueSendEmail(event) {
    this.isCheckSendEmail = event.checked;
  }

  updateSave() {
    if (this.paxForm.valid) {
      this.savePaxes();
    } else {
      this.notificationService.warn('Error', 'Debe completar todos los campos');
    }
  }

  // TODO revisar
  addToFormArray(to: number) {
    while (this.paxes.length) {
      this.paxes.removeAt(0);
    }
    const auxPaxesArray: UntypedFormArray = this.formBuilder.array([]);
    for (let index = 0; index < to; index++) {
      const newform: UntypedFormGroup = this.getPaxForm();
      this.paxes.push(newform);
    }
  }

  getTotal(price): number {
    return this.bookingForm.get('quantity').value * this.getPrice(price);
  }

  getPrice(price): number {
    return this.idBooking ? this.layoutNotChanged ? this.priceBooking : this.pago : price;
  }

  setType(event, i?: number) {
    !i ? this.paxForm.get('idType').setValue(event) :
      this.paxes.at(i).get('idType').setValue(event);
  }

  getPaxForm(validators: Validators[] = []): UntypedFormGroup {
    return this.formBuilder.group({
      id: null,
      idPerson: null,
      firstName: ['', [Validators.required, Validators.pattern('^[^0-9]+$')]],
      lastName: ['', [Validators.required, Validators.pattern('^[^0-9]+$')]],
      dni: [''],
      passport: [''],
      nationality: ['', [Validators.required]],
      birthDate: ['', [Validators.required]],
      email: ['', [...validators, Validators.email]],
      mobile: ['', [...validators]],
      hotel: [this.bookingForm.get('hotel').value],
      observations: '',
      language: null,
      reservationContact: false,
      rContact: false,
      idType: ['dni', [Validators.required]]
    });
  }

  openPayMpDialog(event) {
    if (this.user.rol !== 'Guest') {
      const payDialogConfig = new MatDialogConfig();
      payDialogConfig.disableClose = true;
      payDialogConfig.autoFocus = true;
      payDialogConfig.data = {
        total: this.getTotal(this.detail[0].price),
        booking: this.paymentHeader,
        event,
        userType: this.userType,
        totalBooking: this.totalBooking || this.paymentHeader.idPaymentHeaderNavigation.totalBooking
      };
      const payDialogRef = this.dialog.open(
        PaxFormPayMpComponent,
        payDialogConfig
      );
      payDialogRef.afterClosed().subscribe((resp: boolean) => {
        if (resp) {
          if (this.userType !== 0) {
            this.notificationService.success(
              'Éxito',
              'Se ha enviado el mail correctamente.'
            );
            this.redirectUser();
          } else {
            this.notificationService.success(
              'Éxito',
              'Se ha enviado el mail correctamente.'
            );
            this.paymentMethodSetting();
          }
        } else {
          this.paymentMethodSetting();
        }
      });
    }

  }

  openPayBankDialog() {
    const payDialogConfig = new MatDialogConfig();
    payDialogConfig.disableClose = true;
    payDialogConfig.autoFocus = true;
    //  payDialogConfig.height = '600px';
    payDialogConfig.data = {
      total: this.getTotal(this.detail[0].price),
      payment: this.paymentMethod,
      booking: this.paymentHeader,
      userType: this.userType,
      totalBooking: this.totalBooking || this.paymentHeader.idPaymentHeaderNavigation.totalBooking
    };
    const payDialogRef = this.dialog.open(
      PaxFormPayBankComponent,
      payDialogConfig
    );
    payDialogRef.afterClosed().subscribe((resp: boolean) => {
      if (resp) {
        if (this.userType === 2) {
          this.notificationService.success(
            'Éxito',
            'Se ha enviado el mail correctamente.'
          );
          this.redirectUser();
        } else {
          this.notificationService.success(
            'Éxito',
            'Se ha enviado el mail correctamente.'
          );
          this.paymentMethodSetting();
        }
      } else {
        this.paymentMethodSetting();
      }
    });
  }

  openPayPostnetDialog() {
    const payDialogConfig = new MatDialogConfig();
    payDialogConfig.disableClose = true;
    payDialogConfig.autoFocus = true;
    payDialogConfig.height = '600px';
    payDialogConfig.data = {
      total: this.idBooking ? this.totalUpdate : this.balance || this.getTotal(this.detail[0].price),
      payment: this.paymentMethod,
      booking: this.paymentHeader,
      page: this.pagePax,
      pageClose: 'close',
      totalBooking: this.totalBooking || this.paymentHeader.idPaymentHeaderNavigation.totalBooking,
      paymentDiscount: this.paymentDiscount
    };
    const payDialogRef = this.dialog.open(
      PaxFormPayPostnetComponent,
      payDialogConfig
    );
    payDialogRef.afterClosed().subscribe((resp: any) => {
      if (resp) {
        this.paymentDiscount = undefined;
        if (resp.balance === 0) {
          this.redirectUser();
        } else {
          this.paymentMethodSetting();
          this.totalBooking = resp.totalBooking;
          this.idBooking ? this.totalUpdate = resp.balance : this.balance = resp.balance;
        }
      } else {
        this.paymentMethodSetting();
      }
    });
  }

  openPayCashDialog() {
    const payDialogConfig = new MatDialogConfig();
    payDialogConfig.disableClose = true;
    payDialogConfig.autoFocus = true;
    payDialogConfig.data = {
      total: this.idBooking ? this.totalUpdate : this.balance || this.getTotal(this.detail[0].price),
      payment: this.paymentMethod,
      booking: this.paymentHeader,
      page: this.pagePax,
      balance: this.paymentHeader.idPaymentHeaderNavigation.balance,
      totalBooking: this.totalBooking || this.paymentHeader.idPaymentHeaderNavigation.totalBooking,
      paymentDiscount: this.paymentDiscount
    };
    const payDialogRef = this.dialog.open(
      PaxFormPayCashComponent,
      payDialogConfig
    );
    payDialogRef.afterClosed().subscribe((resp: any) => {
      if (resp) {
        this.paymentDiscount = undefined;
        if (resp.balance === 0) {
          this.redirectUser();
        } else {
          this.paymentMethodSetting();
          this.totalBooking = resp.totalBooking;
          this.idBooking ? this.totalUpdate = resp.balance : this.balance = resp.balance;
        }
      } else {
        this.paymentMethodSetting();
      }
    });
  }

  getPaxObject(form: UntypedFormGroup) {
    this.pax = new Pax();
    this.pax.language = form.value.language;
    this.pax.observations = form.value.observations;
    this.person.firstName = form.value.firstName;
    this.person.lastName = form.value.lastName;
    //form.get('idType').value === 'dni' ? this.person.dni = form.value.dni : this.person.passport = form.value.dni;
    if (form.get('idType').value === 'dni') {
      this.person.dni = form.value.dni;
      this.person.passport = null;
    } else {
      this.person.dni = null;
      this.person.passport = form.value.passport;
    }
    this.person.birthDate = form.value.birthDate;
    this.person.mobile = form.value.mobile;
    this.person.email = form.value.email;
    this.person.nationality = form.value.nationality;
    this.person.idPerson = form.value.idPerson == null ? undefined : form.value.idPerson;
    this.rolPerson.rolPersonDate = new Date();
    this.rolPerson.idPersonNavigation = this.person;
    this.pax.idRolPersonNavigation = this.rolPerson;
    return this.pax;
  }

  savePax() {
    this.isLoad = true;
    const size = this.paxForm.value.reservationContact ? this.paxes.length : this.paxes.length + 1;
    if (size === this.savedPaxes.length) {
      this.booking.bookingDate = new Date();
      this.booking.dropOff = this.bookingForm.value.dropOff;
      this.booking.pickUp = this.bookingForm.value.pickUp;
      this.booking.statusBooking = 'P.PAGO';
      this.booking.hotel = this.bookingForm.value.hotel;
      this.booking.totalPrice = this.getTotal(this.detail[0].price);
      this.booking.unitPrice = this.detail[0].price;
      this.booking.totalPax = this.bookingForm.get('quantity').value;
      this.booking.idUser = this.bookingForm.value.idSeller || this.user.idUser;
      this.booking.IdResponsable = this.user.idUser;
      this.booking.idCode = this.user.idCode;
      this.booking.idLayout = this.id;
      this.booking.observations = this.bookingForm.value.observations;
      this.booking.IdSite = this.typeSite;
      this.booking.bookingDetail = this.savedPaxes;

      if(this.portalCliente == 1){
        console.log("portalCliente");
        
        this.bookingService.postBookingCliente(this.booking, this.number,"1").subscribe((resp: any) => {
          this.isLoad = false;
          this.paymentHeader = resp.data;
          this.total = this.paymentHeader.idPaymentHeaderNavigation.totalBooking;
          this.totalPrice = this.paymentHeader.totalPrice;
          this.notificationService.success(
            'Éxito',
            'Pasajero registrado correctamente'
          );
          this.notificationService.success('Éxito', 'Se registró la pre reserva correctamente. Te enviamos un email con los datos.');
          this.goNextStepThree();
          this.bookingService.getMercado(this.paymentHeader.idBooking).subscribe(resp => {
            this.view = true;
            this.mp = resp.data;
          });
          this.createFormDiscount(this.totalPrice, this.userType);
        });
        this.portalCliente = 0;
      }else{
        console.log("logeado");
        
        this.bookingService.postBooking(this.booking, this.number).subscribe((resp: any) => {
          this.isLoad = false;
          this.paymentHeader = resp.data;
          this.total = this.paymentHeader.idPaymentHeaderNavigation.totalBooking;
          this.totalPrice = this.paymentHeader.totalPrice;
          this.notificationService.success(
            'Éxito',
            'Pasajero registrado correctamente'
          );
          this.notificationService.success('Éxito', 'Se registró la pre reserva correctamente. Te enviamos un email con los datos.');
          this.goNextStepThree();
          this.bookingService.getMercado(this.paymentHeader.idBooking).subscribe(resp => {
            this.view = true;
            this.mp = resp.data;
          });
          this.createFormDiscount(this.totalPrice, this.userType);
        });
      }
    }
  }

  payment(event) {
    // ['mp', 'bank', 'postnet', 'cash']
    this.paymentMethod = event.currentTarget.value;
    let paymentMethodCapitalized =
      this.paymentMethod.charAt(0).toUpperCase() + this.paymentMethod.slice(1);
    if (this.paymentMethod === 'postnet-mp') { paymentMethodCapitalized = 'Postnet'; }
    const fn = `openPay${paymentMethodCapitalized}Dialog`;
    this[fn]();

    if (this.paymentMethod === 'mp' && this.user.rol === 'Guest') {
      this.onNavigate();
      this.sendEmail();
      if (this.disableButton) {
        this.redirectUser();
      } else {
        this.paymentMethodSetting();
      }
    }
  }

  keyUp(event: any, isForm: boolean, index: number) {
    const searchBy: string = isForm ? this.paxForm.get('idType').value : this.paxes.at(index).get('idType').value;
    this.filter.dni = event.target ? event.target.value : event;
    this.bookingService.getSearchDniPassport(this.filter.dni, searchBy === 'dni').subscribe(resp => {
      this.loadPaxForm(resp.data ? resp.data.person : new Person(), isForm, index);
    });
  }

  loadPaxForm(data, isForm: boolean, index: number) {
    const form: UntypedFormGroup = this.paxes.controls[index] as UntypedFormGroup;
    if (this.paxForm.get('idType').value === 'dni') {
      this.updateFirstForm(data, form, form.get('dni').value);
      if (!isForm) {
        if (index === 0 && this.paxForm.value.reservationContact) {
          this.updateFirstForm(form.value, this.paxForm, form.get('dni').value);
          this.dniControl.setValue(form.get('dni').value, { emitEvent: false });
        }
      } else {
        this.updateFirstForm(data, this.paxForm, this.dniControl.value);
        if (this.paxForm.value.reservationContact) {
          this.updateFirstForm(data, form, this.dniControl.value);
        }
      }
    } else {
      this.updateFirstForm(data, form, form.get('passport').value);
      if (!isForm) {
        if (index === 0 && this.paxForm.value.reservationContact) {
          this.updateFirstForm(form.value, this.paxForm, form.get('passport').value);
          this.passportControl.setValue(form.get('passport').value, { emitEvent: false });
        }
      } else {
        this.updateFirstForm(data, this.paxForm, this.passportControl.value);
        if (this.paxForm.value.reservationContact) {
          this.updateFirstForm(data, form, this.passportControl.value);
        }
      }
    }
  }

  savePaxes() {
    this.isLoad = true;
    if (this.paxForm.valid) {
      this.savedPaxes = [];
      if (!this.paxForm.get('reservationContact').value) {
        this.isLoad = false;
        this.savePaxItem(this.paxForm);
      }
      this.paxes.controls.forEach((form) => {
        this.isLoad = false;
        this.savePaxItem(form as UntypedFormGroup);
      });
    } else {
      this.notificationService.warn('Error', 'Debe completar todos los campos');
    }
  }

  savePaxItem(form: UntypedFormGroup) {
    this.bookingService
      .savePerson(this.getPaxObject(form), this.id, this.idBooking)
      .subscribe(resp => {
        this.savedPaxes.push({
          ...resp.data,
          observation: form.get('observations').value,
          reservationContact: form.get('rContact').value ? 1 : 0
        });
        this.idBooking ? this.updateee() : this.savePax();
      }, (error) => {
        if (!this.showedMessage) {
          this.showedMessage = true;
          this.notificationService.warn(error.error.message);
        }
        this.redirectUser();
      });
  }

  /**
   * Fuction to redirect user.
   */
  redirectUser() {
    if (this.userType === 0) {
      if (this.user.rol === 'Admin' && (this.page === 'booking-forms-admin' || this.page === undefined)) {
        this.router.navigate(['/admin-page/excursions']);
      } else {
        if (this.page === 'booking-forms-ofic' || this.page === undefined) {
          this.router.navigate(['/ofic-page/excursions']);
        } else {
          if (this.user.rol === 'Admin' && (this.page === 'previous-forms' || this.page === undefined)) {
            this.router.navigate(['/admin-page/bookings']);
          } else {
            this.router.navigate(['/ofic-page/bookings']);
          }
        }
      }
    } else if (this.userType === 1) {
      this.router.navigate(['/recep-page/excursions-user']);
    } else {
      this.router.navigate(['/guest-page/excursions-guest']);
    }
  }

  openSaveConfirmationDialog() {
    const payDialogConfig = new MatDialogConfig();
    payDialogConfig.disableClose = true;
    payDialogConfig.autoFocus = true;
    payDialogConfig.width = '800px';
    const payDialogRef = this.dialog.open(
      PaxFormConfirmationComponent,
      payDialogConfig
    );
    payDialogRef.afterClosed().subscribe(() => console.log());
    // Obtiene los datos una vez cerrado el dialogo
    payDialogRef.afterClosed().subscribe(data => {
    });
  }

  /**
   * Fuction for to 'Registro de Reserva' step change.
   */
  goBackStepOne() {
    this.stepper.steps._results[0].editable = true;
    this.stepper.steps._results[0].completed = false;
    // move to previus step
    this.stepper.previous();
  }

  /**
   * Fuction for to 'Registro de Pasajeros' step change.
   */
  goNextStepTwo() {
    // complete the current step
    this.stepper.selected.editable = false;
    this.stepper.selected.completed = true;
    // move to next step
    if (!this.idBooking) {
      if (this.bookingForm.get('quantity').value <= this.free) {
        // Actualizacion del primer form array si selecciono la primer opcion "¿Es pasajero?"
        const paxFormValue = this.paxForm.value;
        if (paxFormValue.reservationContact) {
          this.paxes.at(0).get('idType').setValue(paxFormValue.idType);
          this.paxes.at(0).patchValue({
            id: paxFormValue.id,
            firstName: paxFormValue.firstName,
            lastName: paxFormValue.lastName,
            dni: this.dniControl.value,
            passport: this.passportControl.value,
            nationality: paxFormValue.nationality,
            birthDate: new Date(paxFormValue.birthDate),
            email: paxFormValue.email,
            mobile: paxFormValue.mobile,
            observations: paxFormValue.observations,
            language: paxFormValue.language,
            reservationContact: true,
            rContact: true
          }, { emitEvent: false });
          this.paxes.at(0).get('email').setValidators([Validators.required, Validators.email]);
          this.paxes.at(0).get('email').updateValueAndValidity();
          this.paxes.updateValueAndValidity();
        } else {
          this.paxes.at(0).reset();
          this.paxes.at(0).get('idType').setValue('dni');
        }
        if (this.paxForm.get('idType').value === 'dni') {
          this.paxes.at(0).get('dni').setValidators([Validators.required]);
        } else {
          this.paxes.at(0).get('passport').setValidators([Validators.required]);
        }
        this.stepper.next();
        this.isEditableStepOne = false;
      } else {
        this.notificationService.warn('Error, la cantidad ingresada no es válida');
      }
    } else {
      if (!this.updated) {
        this.addContact();
      }
      this.stepper.next();
      this.isEditableStepOne = false;
    }
    //si se desea revertir el comportamiento nuevo de volver al paso 1 que queda con el lapiz
    //(no se puede quitar el lapiz porque sino no deja volver) comentar las lineas hasta antes
    //del ultimo "}"
    this.isEditableStepOne = true;

    this.stepper.steps._results[0].editable = true;
    //this.stepper.steps._results[0].completed = true;
  }

  addContact() {
    this.auxBookingDetails.forEach(bookingDetail => {
      const form: UntypedFormGroup = this.getPaxForm();
      this.updateFirstForm(bookingDetail.idPaxNavigation.idRolPersonNavigation.idPersonNavigation, form, bookingDetail.idPaxNavigation.idRolPersonNavigation.idPersonNavigation.dni);
      this.updateFirstFormObservation(bookingDetail.idPaxNavigation, form);
      this.paxes.push(form);
    });
    this.updated = true;
  }

  /**
   * Fuction for to 'Pago' step change.
   */
  goNextStepThree() {
    // complete the current step
    this.stepper.selected.completed = true;
    // move to next step
    this.stepper.next();
    this.isEditableStepOne = false;
    this.isEditableStepTwo = false;
  }


  /**
   * Function to confirm portal cliente no login
   */
  onConfirmReservationCliente(){
    this.portalCliente = 1;
    this.onConfirmReservation();
  }
  /**
   * Fuction to confirm reservation.
   */
  onConfirmReservation(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Confirmación',
      question: !this.idBooking ? 'Está a punto de hacer una pre-reserva. ¿Desea continuar?' : 'Se va a modificar la pre-reserva. ¿Desea continuar?'
    };
    this.dialog.open(ConfirmationComponent, dialogConfig).afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.idBooking ? this.updateSave() : this.savePaxes();
      }
    });
  }

  /**
   * Open cancel pay Modal.
   */
  onCancelOrPayLaterTheReservation(type: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    const cancel = 'Está a punto de cancelar la pre-reserva. ¿Desea continuar?';
    if (this.userType === 2) {
      this.message = 'Su reserva no se ha pagado aún, por lo que deberá comunicarse con la oficina para concretar el pago.¿Desea continuar?';
    } else {
      this.message = 'Su reserva no se ha pagado aún o queda un saldo pendiente.¿Desea continuar?';
    }
    const payLater = this.message;
    dialogConfig.data = {
      title: 'Atención',
      question: type === 'cancel' ? cancel : payLater
    };
    this.dialog.open(ConfirmationComponent, dialogConfig).afterClosed().subscribe((result: boolean) => {
      if (result) {
        if (type === 'cancel') {
          this.bookingService.bookingCancel(this.paymentHeader.idBooking).subscribe(() => {
            this.notificationService.alert('Atención', 'Su reserva fue cancelada.');
            this.redirectUser();
          }, error => console.error(error));
        } else {
          this.redirectUser();
        }
      }
    });
  }

  /**CANCELAR RESERVA */
  onCancelTheReservation(type: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    const cancel = 'Está a punto de cancelar la pre reserva. ¿Desea continuar?';
    const payLater = 'Su reserva no se ha pagado aún, por lo que deberá comunicarse con la oficina para concretar el pago.¿Desea continuar?';
    dialogConfig.data = {
      title: 'Atención',
      type: type,
      question: type === 'cancel' ? cancel : payLater
    };
    this.dialog.open(CancelConfirmationsComponent, dialogConfig).afterClosed().subscribe((result: any) => {
      if (result) {
        if (type === 'cancel') {
          const booking = {
            IdBooking: this.paymentHeader.idBooking,
            Observations: result,
          };
          this.bookingService.bookingCancel(booking).subscribe(() => {
            this.notificationService.alert('Atención', 'La reserva fue cancelada.');
            this.redirectUser();
          }, error => console.error(error));
        }
      }
    });
  }

  viewAccordingSelectedIdType(valueIdType: string) {
    return valueIdType === 'dni';
  }

  setIdTypeValidators() {
    const dniControl = this.paxForm.get('dni');
    const passportControl = this.paxForm.get('passport');
    this.paxForm.get('idType').valueChanges.subscribe(value => {
      if (value === 'dni') {
        dniControl.setValidators([Validators.required]);
        passportControl.setValidators(null);
      }
      if (value === 'passport') {
        passportControl.setValidators([Validators.required]);
        dniControl.setValidators(null);
      }
      dniControl.updateValueAndValidity();
      passportControl.updateValueAndValidity();
    });
  }

  paymentMethodSetting() {
    (<HTMLInputElement>document.getElementById('mp')).checked = null;
    (<HTMLInputElement>document.getElementById('bank')).checked = null;
    (<HTMLInputElement>document.getElementById('cash')).checked = null;
    (<HTMLInputElement>document.getElementById('postnet')).checked = null;
    (<HTMLInputElement>document.getElementById('postnet-mp')).checked = null;
  }

  validateFirst(paxOne: UntypedFormGroup, i?: number) {
    var states = true;
    if (this.paxForm.value.rContact === true) {
      if (i === 0 || i === 0) {
        this.paxes.at(i).get('email').setValidators([Validators.required, Validators.email]);
        if (this.paxes.at(i).get('dni').valid && this.paxes.at(i).get('firstName').valid && this.paxes.at(i).get('lastName').valid && this.paxes.at(i).get('birthDate').valid
          && this.paxes.at(i).get('nationality').valid && this.paxes.at(i).get('email').valid) {
          states = true;
        } else {
          states = false;
        }
      } else {
        if (this.paxes.at(i).get('dni').valid && this.paxes.at(i).get('firstName').valid && this.paxes.at(i).get('lastName').valid && this.paxes.at(i).get('birthDate').valid
          && this.paxes.at(i).get('nationality').valid) {
          states = true;
        } else {
          states = false;
        }
      }
    } else {
      if (this.paxes.at(i).get('dni').valid && this.paxes.at(i).get('firstName').valid && this.paxes.at(i).get('lastName').valid && this.paxes.at(i).get('birthDate').valid
        && this.paxes.at(i).get('nationality').valid) {
        states = true;
      } else {
        states = false;
      }
    }

    if (states) {
      this.paxes.setErrors(null);
    } else {
      this.paxes.setErrors({ 'invalid': true });
    }
  }

  paymentHistory(index: number) {
    const paymentDialogConfig = new MatDialogConfig();
    paymentDialogConfig.disableClose = true;
    paymentDialogConfig.autoFocus = true;
    paymentDialogConfig.data = {
      idBooking: this.paymentHeader.idBooking,
      detail: this.paymentHeader.idLayoutNavigation,
    };
    const paymentDialogRef = this.dialog.open(
      PaymentHistoryComponent,
      paymentDialogConfig
    );
    paymentDialogRef.afterClosed().subscribe((resp: any) => {
    });
  }

  changeCheck(e) {
    this.isCheck = e.checked;
  }

  saveDiscount() {
    if (this.paymentForm.valid) {
      this.isLoad = true;
      this.paymentDetail.PaymentDetailDate = this.date;
      this.paymentDetail.Discount = this.paymentForm.value.Discount;
      this.paymentDetail.DiscountReason = this.paymentForm.value.DiscountReason;
      this.paymentDetail.Observations = this.paymentForm.value.Observations;
      this.paymentDetail.IdUser = this.user.idUser;
      this.paymentDetail.IdPaymentHeader = this.paymentHeader.idPaymentHeaderNavigation.idPaymentHeader;

      this.bookingService.postPaymentDiscount(this.paymentDetail).subscribe((resp: any) => {
        this.paymentDiscount = resp.data;
        this.allowDiscount = resp.data.allowDiscount;
        this.notificationService.success('Éxito', 'El descuento se registró correctamente');
        this.isLoad = false;
      }, (error) => {
        console.error(this.message);
        this.notificationService.warn(`No se pueden realizar más descuentos`);
        this.isLoad = false;
        this.isDiscount = false;
      });
    } else {
      this.notificationService.warn('Error, debe completar todos los campos');
      this.isLoad = false;
    }
  }

  disablePax() {
    if (this.startDate < this.time || this.status === 'disable') {
      this.paxDisable = false;
      this.bookingForm.get('startDate').disable({ emitEvent: false });
      //  this.bookingForm.get('idSeller').disable({ emitEvent: false });
    } else {
      this.paxDisable = true;
    }
    return this.paxDisable;
  }

  disableSeller() {
    if (this.user.rol === 'Ofic' && this.idBooking) {
      if (this.user.idUser === this.idResponsable) {
        this.sellerDisable = true;
      } else {
        this.sellerDisable = false;
      }
    } else {
      this.sellerDisable = true;
    }
    return this.sellerDisable;
  }

  editBooking() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.router.onSameUrlNavigation = 'reload';

    if (this.user.rol === 'Admin') {
      this.router.navigate(['/admin-page/forms-admin'], {
        queryParams: {
          idB: this.paymentHeader.idBooking,
          id: this.paymentHeader.idLayout,
          pagePax: 'pax'
        }
      });
    } else {
      this.router.navigate(['/ofic-page/forms-ofic'], {
        queryParams: {
          idB: this.paymentHeader.idBooking,
          id: this.paymentHeader.idLayout,
          pagePax: 'pax'
        }
      });
    }
  }

  onNavigate() {
    window.open(this.mp);
  }

  sendEmail() {
    const mail = {
      Url: this.mp,
      IdBooking: this.paymentHeader.idBooking,
    };
    this.bookingService.postMailMP(mail).subscribe(resp => {
      //this.closed(true);
    });
  }

  appearButton() {
    if (this.sheet == 0) {
      return true;
    } else {
      return false;
    }
  }

  redirect() {
    window.history.back();
  }
  openExcursionAdmin() {
    this.router.navigate(["/guest-page/excursions-guest"]);
  }

}
