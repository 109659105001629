<simple-notifications></simple-notifications>
<div class="dialog-body">
  <div class="row justify-content-between">
    <div class="col">
      <h2 mat-dialog-title *ngIf="IdPaymentMethod === 3"><img src="assets/img/pago-postnet.svg" class="iconos-pago"></h2>
      <h2 mat-dialog-title *ngIf="IdPaymentMethod != 3"><img src="assets/img/pago-postnet_mp.svg" class="iconos-pago"></h2>

    </div>

    <div class="col-1">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closed()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</div>
<hr />
<div class="mat-typography" style="padding-top: 1px;">
  <p class="text-azul mb-3"><strong> Pago a traves de {{ IdPaymentMethod === 3 ? 'Postnet' : 'Postnet - Mercado Pago'  }}</strong></p>
<hr>
  <form [formGroup]="paymentForm">
    <p style="font-size: 18px;margin-left: 10px;margin-top: 20px;">Fecha: <strong
        style="font-size: 18px;margin-left: 10px;margin-top: 20px;">{{date | date:'dd/MM/yyyy'}}</strong></p>
    <p style="font-size: 18px;margin-left: 10px;margin-top: 20px;"> Total: <strong
        style="font-size: 18px;margin-left: 10px;margin-top: 20px;">$
        {{this.totalPrice}}</strong></p>

    <p style="font-size: 18px;margin-left: 10px;margin-top: 20px;"> Saldo: <strong
        style="font-size: 18px;margin-left: 10px;margin-top: 20px;">$
        {{this.saldoT()}}</strong></p>
<hr>
    <div class="row">
      <p style="font-size: 18px;margin-left: 25px;margin-top: 20px;">Monto recibido $</p>
      <mat-form-field class="col-sm-3" style="margin-left: 10px;">
        <input matInput formControlName="Amount" min="0" maxlength="this.new.length" type="number"
          onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 || event.keyCode === 188 || event.keyCode === 190 || event.keyCode === 110 ? true : !isNaN(Number(event.key));">
        <mat-error *ngIf="paymentForm.controls['Amount'].invalid && paymentForm.controls['Amount'].touched">
          <span *ngIf="paymentForm.controls['Amount'].errors['required']">Por favor
            rellene el
            campo</span>
          <span *ngIf="this.paymentForm.value.Amount > this.total">Monto ingresado
            inválido</span>
        </mat-error>
      </mat-form-field>

      <p style="font-size: 18px;margin-left: 25px;margin-top: 20px;">Recargo: $</p>
      <mat-form-field class="col-sm-3" style="margin-left: 10px;">
        <input matInput type="number" formControlName="Surcharges" min="0"
          onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 || event.keyCode === 188 || event.keyCode === 190 || event.keyCode === 110 ? true : !isNaN(Number(event.key));">
      </mat-form-field>
    </div>

    <div class="row">
      <label for="card-type" style="font-size: 18px;margin-left: 25px;margin-top: 20px;">Cuotas: </label>
      <mat-form-field class="col-sm-3">
        <mat-select formControlName="NoDues">
          <mat-option *ngFor="let op of quotes" [value]="op.value">
            {{op.show}}</mat-option>
        </mat-select>
      </mat-form-field>

      <p style="font-size: 18px;margin-left: 25px;margin-top: 20px;" *ngIf="this.paymentForm.value.NoDues === '0'">
        Observaciones:
      </p>
      <mat-form-field class="col-sm-4" *ngIf="this.paymentForm.value.NoDues === '0'">
        <textarea matInput formControlName="Observations"></textarea>
      </mat-form-field>
    </div>

    <!-- <div class="row">
      <p style="font-size: 18px;margin-left: 25px;margin-top: 20px;">N° de lote: </p>
      <mat-form-field class="col-sm-2" style="margin-left: 10px;">
        <input matInput formControlName="lot" maxlength="3" min="0" mask="000"
          onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))">
        <mat-error *ngIf="paymentForm.controls['lot'].invalid && paymentForm.controls['lot'].touched">
          <span *ngIf="paymentForm.controls['lot'].errors['required']">Por favor
            rellene el
            campo</span>
        </mat-error>
      </mat-form-field>

      <p style="font-size: 18px;margin-left: 25px;margin-top: 20px;">N° de cupón: </p>
      <mat-form-field class="col-sm-2" style="margin-left: 10px;">
        <input matInput formControlName="coupon" min="0" mask="0000"
          onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))"
          maxlength="4">
        <mat-error *ngIf="paymentForm.controls['coupon'].invalid && paymentForm.controls['coupon'].touched">
          <span *ngIf="paymentForm.controls['coupon'].errors['required']">Por favor
            rellene el
            campo</span>
        </mat-error>
      </mat-form-field>
    </div> -->

    <div class="row">
      <p style="font-size: 18px;margin-left: 25px;margin-top: 20px;">N° de operación: </p>
      <mat-form-field class="col-sm-4" style="margin-left: 10px;">
        <input matInput formControlName="NumberOperation" min="0" maxlength="25">
      </mat-form-field>
    </div>

    <div class="row">
      <label for="card-type" style="font-size: 18px;margin-left: 25px;margin-top: 20px;">Tipo de Tarjeta: </label>
      <mat-form-field class="col-sm-3">
        <mat-select formControlName="typeCard">
          <mat-option *ngFor="let op of cardTypes" [value]="op">
            {{op}}</mat-option>
        </mat-select>
        <mat-error *ngIf="paymentForm.controls['typeCard'].invalid && paymentForm.controls['typeCard'].touched">
          <span *ngIf="paymentForm.controls['typeCard'].errors['required']">Por favor
            rellene el
            campo</span>
        </mat-error>
      </mat-form-field>

      <label for="card" style="font-size: 18px;margin-left: 25px;margin-top: 20px;">Tarjeta: </label>
      <mat-form-field class="col-sm-3">
        <mat-select formControlName="card">
          <mat-option *ngFor="let op of cards" [value]="op">
            {{op}}</mat-option>
        </mat-select>
        <mat-error *ngIf="paymentForm.controls['card'].invalid && paymentForm.controls['card'].touched">
          <span *ngIf="paymentForm.controls['card'].errors['required']">Por favor
            rellene el
            campo</span>
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
<br>
<div *ngIf="getSaldo() === 0">
  <p><strong>*Se enviara el Voucher por E-mail</strong></p>
</div>
<hr />
<p class="text-center">
  <button class="btn btn-rojo" (click)="paymentConfirmation()"
    cdkFocusInitial [disabled]="this.saldo < 0 || this.paymentForm.value.Amount <= 0" *ngIf="!isLoad">Finalizar
  </button>

  <button class="btn btn-primary" style="background-color: #1cac67;color: white;" cdkFocusInitial *ngIf="isLoad"
    [disabled]="isLoad">
    <span class="spinner-border" style="width: 1rem; height: 1rem; border-width: 0.15em;" role="status"
      aria-hidden="true"></span> Cargando...
  </button>
</p>
