<div class="focused-content">
  <div class="container-fluid">
    <div class="card">
      <h5 class="card-header">{{ "GALERIA" | translate }}</h5>
      <div class="card-body">
        <div class="row" *ngIf="isReady">
          <div class="col-md-6">
            <div class="row">
              <p class="card-text"><strong style="font-size: 20px;margin-left: 15px;">
                  Fecha del servicio: {{detail[0].startDate | date:'EE dd/MM h:mm a'}}</strong> </p>
              <div class="col-md-12 big-image">
                <img alt="picture" src="{{detail[0]?.url[0]}}" (click)="openImageDialog(detail[0].url[0])"
                  style="cursor: pointer;">
              </div>
              <div class="col-md-3 small-image" *ngFor="let url of detail[0]?.url | slice: 1; let i = index"
                style="margin-top: 20px;">
                <img alt="picture" src="{{ url }}" (click)=" openImageDialog(url)" style="cursor: pointer;">
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">

              <div class="col-md-12 mb-3">
                <div *ngFor="let det of detail" style="text-align: center;">
                  <div class="card">
                    <h6 class="card-header">{{det.title}}</h6>
                    <div class="card-body" *ngFor="let d of detail">
                      <p class="card-text">ORIGEN: {{d.startingPlace}}</p>
                      <p class="card-text">DESTINO: {{d.endingPlace}}</p>
                      <p class="card-text">AGENCIA ASOCIADA: {{d.companyName}}</p>
                      <p class="card-text">TIPO DE ACTIVIDAD: {{d.typeActivityDescription}}</p>
                      <p class="card-text">{{"PRECIO POR PERSONA" | translate}}: <strong style="font-size: 25px;"> $
                          {{d.price}}</strong> </p>
                      <p class="card-text" style="font-size: 15px;">
                        {{"CANTIDAD DISPONIBLE" | translate}}:
                        <strong style="font-size: 18px;">{{d.freeSeats}}</strong></p>
                    </div>
                    <button type="button" class="btn"
                      style="background-color: #1cac67; float: right; color: white; border: #1cac67;"
                      (click)="openExcursionAdmin()">Volver
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mb-3">
                <div class="card">
                  <h6 class="card-header">Descripción</h6>
                  <div class="card-body">
                    <span style="font-size: 14px;">
                      {{detail[0]?.itinerary}}
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mb-3">
                <div class="card">
                  <h6 class="card-header">Servicios incluidos</h6>
                  <div class="card-body">
                    <li style="font-size: 15px;" *ngFor="let de of detail[0]?.services">{{de}}</li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
