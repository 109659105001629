import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ContainerComponent } from './container/container.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { FootComponent } from './foot/foot.component';
import { HeaderComponent } from './header/header.component';
import { CardComponent } from './card/card.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ActividadesComponent } from './actividades/actividades.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask, IConfig, provideEnvironmentNgxMask } from 'ngx-mask';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    FootComponent,
    HeaderComponent,
    ContainerComponent,
    ConfirmationComponent,
    CardComponent,
    NavbarComponent,
    ActividadesComponent
  ],

  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [
    provideNgxMask(),
    provideEnvironmentNgxMask(maskConfig),
  ],
  exports: [
    TranslateModule,
    HeaderComponent,
    FootComponent,
    ContainerComponent,
    CardComponent,
    NavbarComponent,
    ActividadesComponent,
  ],

  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class SharedModule { }
