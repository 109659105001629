<div class="focused-content">
  <div class="container-fluid">

    <div class="card">
      <h5 class="card-header">Lista de reservas</h5>
      <div class="card-body">

        <h5 style="padding-bottom: 10px;">{{ detail?.title }}</h5>

        <div class="row">
          <div class="col-sm-6">
            <p class="text-header"><b>Fecha de impresión: </b>{{ now | date:'dd/MM/yyyy hh:mm a' }}</p>
            <p class="text-header"><b>Fecha del servicio:
              </b>{{ detail?.startDate | date:'dd/MM/yyyy hh:mm a' }}</p>
          </div>

          <div class="col-sm-6" style="text-align: right;">
            <button mat-raised-button class="new-buttom" (click)="openBookingN()"
              *ngIf="disableBooking()"
              style="background-color: #1cac67; color: white;">
              <mat-icon>add_circle_outline</mat-icon> Cargar Reserva
            </button>
          </div>
          
          <div class="col-sm-12">
            <div class="table-responsive custom-table-insside">

              <table class="table table-striped">
                <thead>
                  <tr>
                    <th></th>
                    <th class="focus-text">Fecha Reserva</th>
                    <th class="focus-text">PickUp</th>
                    <th class="focus-text">DropOff</th>
                    <th class="focus-text">Vendedor</th>
                    <th class="focus-text">Reserva</th>
                    <th class="focus-text">Teléfono de contacto</th>
                    <th class="align" style="font-weight: bold; width: 23%;">Pasajeros</th>
                    <th class="focus-text">Estado</th>
                    <th class="focus-text">Forma de pago</th>
                    <th class="focus-text">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let deta of detail?.bookings | paginate: {itemsPerPage: 5, currentPage: pageActual, id: randomBKA}; let i=index">
                    <td>
                      <mat-icon style="color: #f78123;" *ngIf="deta.alertToPending === true"
                        matTooltip="Reservas en estado P. Pago en donde haya pasado 48hs desde la fecha de reserva">
                        warning
                      </mat-icon>
                      <mat-icon style="color: red;" *ngIf="deta.alertToPartial === true"
                        matTooltip=" Reservas en estado P. Parcial y que están a 24hs de la fecha de excursión">
                        warning
                      </mat-icon>
                    </td>
                    <th class="focus-text">{{deta.bookingDate | date:'EE dd/MM h:mm a' }}</th>
                    <td class="focus-text"> {{deta.pickUp}}</td>
                    <td class="focus-text">{{deta.dropOff}}</td>
                    <td class="focus-text">{{deta.firstName}} {{deta.lastName}}</td>
                    <td class="align">{{ deta.contactFirstName }} {{ deta.contactLastName }} <span
                        style="font-weight: bold;">x{{ deta?.listPaxs?.length }}</span>
                    </td>
                    <td class="focus-text">{{deta.mobile}}</td>
                    <td class="align">
                      <div *ngFor="let paxs of deta?.listPaxs; let i = index" style="text-align: left;">
                        <span style="font-weight: bold;">{{i + 1 }}.</span> {{ paxs.lastName }},
                        {{ paxs.firstName }} | {{ paxs.observations }}
                      </div>
                    </td>
                    <td class="focus-text">{{deta.statusBooking}}</td>
                    <td class="focus-text">
                      <button type="button" class="btn btn-primary" mat-icon-button rowindex="rowIndex"
                        (click)="openPaymentDialog(deta.idBooking, deta.idPaymentHeader, deta.totalPrice, deta.balance, deta.allowDiscount, deta.contactEmail)"
                        style="border-radius: 40px; width: 25px;"
                        [disabled]="deta.statusBooking === 'PAGADO' || deta.statusBooking === 'CANCELADO'">
                        <mat-icon>attach_money</mat-icon>
                      </button>
                    </td>
                    <td class="focus-text" style="width: 17%;">
                      <button mat-icon-button mat-raised-button matTooltip="Cancelar reserva" class="example-button"
                        (click)="onCancelOrPayLaterTheReservation('cancel', deta.idBooking)"
                        *ngIf="detail.startDate > time && deta.statusBooking !== 'CANCELADO'">
                        <mat-icon>clear</mat-icon>
                      </button>
                      <button mat-icon-button mat-raised-button [matTooltip]="deta.cancellationRemark"
                        style="margin: 4px;" *ngIf="deta.statusBooking === 'CANCELADO'"
                        matTooltipClass="example-tooltip-red" class="example-button">
                        <mat-icon>info</mat-icon>
                      </button>

                      <button mat-icon-button mat-raised-button matTooltip="Modificar reserva" class="example-button"
                        (click)="editbooking(i, deta.idBooking, deta.pickUp, deta.dropOff)"
                        *ngIf="!(detail?.statusLayout === 'CERRADO') && detail.startDate > time && deta.statusBooking !== 'CANCELADO'">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button mat-icon-button [matMenuTriggerFor]="menu" mat-raised-button matTooltip=""
                        class="example-button">
                        <mat-icon>more_vert</mat-icon>
                      </button>

                      <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="downloadVoucher(deta.idBooking)"
                          *ngIf="deta.statusBooking === 'PAGADO'">
                          <mat-icon>cloud_download</mat-icon>
                          <span>Descargar voucher</span>
                        </button>
                        <button mat-menu-item (click)="paymentHistory(deta.idBooking)">
                          <mat-icon>request_quote</mat-icon>
                          <span>Historial de Pagos</span>
                        </button>

                        <button mat-menu-item
                          (click)="openReturnsDialog(deta.idPaymentHeader,deta.totalPrice, deta.maxRefund)"
                          *ngIf="deta.allowRefund === true">
                          <mat-icon>attach_money</mat-icon>
                          <span>Devoluciones</span>
                        </button>
                      </mat-menu>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <pagination-controls (pageChange)="pageActual = $event" id="{{randomBKA}}" style="text-align: right;"
          previousLabel="Anterior" nextLabel="Siguiente">
        </pagination-controls>
      </div>
      <div class="row">
        <div class="col">
          <div style="text-align: right; padding: 0 18px 18px;">
            <button type="button" (click)="openExcusrion()" mat-raised-button class="button">Salir
            </button>&nbsp;
            <button mat-raised-button class="btn btn-primary" style="background-color: #1cac67;color: white;" (click)="print()" [disabled]="isPaxs">Imprimir</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
