<div class="main-content focused-login">
  <div class="container-fluid">

    <div class="card" style="width: 350px;">
      <div class="card-body">

        <div style="text-align: center;">

          <div style="padding-top: 10px;">
            <fa name="camera-retro" size="3x"></fa>
          </div>
          <div>
            <div style="padding-top: 10px; font-size: 20px;"><b><u>Acceso Clientes</u></b><br>
              <small>Ingresando el código proporcionado por el vendedor
                podrá gestionar su reserva.</small>
            </div>
          </div>

        </div>

        <form [formGroup]="loginForm" (ngSubmit)="validateCode()" style="margin: 10px 10px 10px;">
          <div class="form-group">
            <label>{{ "CODIGO" | translate }}</label>
            <input type="number" class="form-control" placeholder="Ej: 123456" formControlName="code1">
          </div>
          <div class="btn-actions">
            <div class="row justify-content-between">
              <div class="col-6">
                <a style="cursor: pointer; font-size: 17px;" (click)="openAdmin()">
                  <u>¿Eres un vendedor?</u>
                </a>
              </div>

              <div class="col-4">
                <button type="submit" class="btn btn-light btn-block btn-custom"
                  style="width: auto;float: right;">Aceptar</button>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>

  </div>
</div>
