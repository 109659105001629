<!-- <app-navbar></app-navbar> -->
<div class="focused-content">
  <div class="container-fluid">

    <div class="card">

      <h5 class="card-header">Lista de Excursiones</h5>

      <div class="card-body">

        <div class="row" *ngFor="let t of tours; let i = index">

          <div class="col-sm-12">
            <form [formGroup]="monthYearForm" *ngIf="t.type == 'PRÓXIMAS'">

              <div class="row">
                <div class="col" style="margin-top: 22px;">
                  <h6 class="card-title">{{ t.type }}</h6>
                </div>
                <mat-form-field class="col-sm-2">
                  <mat-select placeholder="Mes" formControlName="month" (selectionChange)="selectMonth($event.value)">
                    <mat-option *ngFor="let m of months" [value]="m.value">
                      {{m.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="col-sm-1">
                  <mat-select placeholder="Año" formControlName="year" (selectionChange)="selectYear($event.value)">
                    <mat-option *ngFor="let y of years" [value]="y.value">
                      {{y.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

            </form>
          </div>

          <div class="col">
            <h6 class="card-title" *ngIf="t.type !== 'PRÓXIMAS'">{{ t.type }}</h6>
          </div>

          <div class="col-sm-12">
            <app-data-table (tourEvent)="this.tour()" [tours]="t.tours" [users]="user" [isUser]="true" [type]="t.type"
              *ngIf="isReady">
            </app-data-table>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
