import { Component, OnInit } from '@angular/core';
import { ExcursionsDetails } from 'src/app/shared/class/excursions-details.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToursService } from 'src/app/shared/service/tours.service';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { DialogImagesComponent } from '../dialog-images/dialog-images.component';
import { LoginAuthService } from 'src/app/auth/providers/login-auth.service';


@Component({
  selector: 'app-excursion-detail-admin',
  templateUrl: './excursion-detail-admin.component.html',
  styleUrls: ['./excursion-detail-admin.component.scss']
})
export class ExcursionDetailAdminComponent implements OnInit {
  public detail: ExcursionsDetails[] = [];
  public id: number;
  public service: ExcursionsDetails[] = [];
  public user: any;
  isReady = false;
  public page: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private tourService: ToursService,
    private dialog: MatDialog,
    private loginAuthService: LoginAuthService
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.id = params.id;
      this.page = params.page;
    });
    this.user = this.loginAuthService.getUser();
    this.tourService.getExcursionDetail(this.id).subscribe(resp => {
      this.detail = resp.data;
      this.isReady = true;
    });
  }

  openBooking(rowIndex: number) {
    this.router.navigate(['/forms'], { queryParams: { id: rowIndex } });
  }

  openExcursionAdmin() {
    if (this.user.rol === 'Admin' && this.page === undefined) {
      this.router.navigate(['/admin-page/excursions']);
    } else {
      if (this.user.rol === 'Ofic' && this.page === undefined) {
        this.router.navigate(['/ofic-page/excursions']);
      } else {
        if (this.user.rol === 'Consultor' && this.page === undefined) {
          this.router.navigate(['/consult-page/excursions-consult']);
        } else {
          if (this.user.rol === 'Admin' && this.page === 'previousBooking') {
            this.router.navigate(['/admin-page/bookings']);
          } else {
            this.router.navigate(['/admin-page/bookings']);
          }
        }
      }
    }
  }

  openImageDialog(event) {
    const payDialogConfig = new MatDialogConfig();
    payDialogConfig.disableClose = true;
    payDialogConfig.autoFocus = true;
    payDialogConfig.width = '750px';
    payDialogConfig.data = event;

    const payDialogRef = this.dialog.open(
      DialogImagesComponent,
      payDialogConfig
    );
    payDialogRef.afterClosed().subscribe(() => {
    });
  }
}

