<div class="dialog-body">
    <h2 mat-dialog-title> Enviar email a: </h2>

    <form [formGroup]="emailForm">
        <div class="form-group" style="margin: 20px 30px 10px 30px;">
            <input type="text" class="form-control" placeholder="Email" formControlName="UserEmail">
        </div>
    </form>

    <hr>
    <button mat-raised-button class="btn btn-primary" style="background-color: #1cac67;color: white;border: #1cac67;" (click)="send()">Enviar
    </button>
    <button mat-raised-button class="button"
        style="width: 80px; margin-top: -65px;"
        (click)="cancel()">Salir
    </button>
</div>