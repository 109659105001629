import { element } from 'protractor';
import { LoginAuthService } from './../../auth/providers/login-auth.service';
import { Component, OnInit, Input, ElementRef, OnDestroy } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { CodeAdminComponent } from 'src/app/excursions-admin/code-admin/code-admin.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../service/login.service';
import { LayoutService } from '../service/layout.service';
import { Location } from '@angular/common';
import { BookingService } from '../service/booking.service';
import { ToursService } from '../service/tours.service';
import { FilterDataService } from 'src/app/service/filter-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'actividades-navbar',
  templateUrl: './actividades.component.html',
  styleUrls: ['./actividades.component.scss'],
})
export class ActividadesComponent implements OnInit, OnDestroy {

  private listTitle: any[];
  public location: Location;
  public mobile_menu_visible: any = 0;
  private toggleButton: any;
  private sidebarVisible: boolean;
  public tempState = [];
  public breadcrumbs: Array<any>;
  @Input() menuItems = [];
  totalOpenExcursion = 0;
  currentUser: any;
  code: any;
  user: any;
  ruta: any;
  ruteSepared: any;
  public activities: any[] = [];

  public activities2: any[] = []; //activities para segunda lista

  currentActivity: string;
  public subscriptions: Subscription;
  dataFilter: any;
  public currentA: Subscription;

  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    private loginService: LoginService,
    private layoutService: LayoutService,
    public dialog: MatDialog,
    private loginAuthService: LoginAuthService,
    private bookingService: BookingService,
    private toursService: ToursService,
    private filterDataService: FilterDataService
  ) {
    this.currentUser = this.loginAuthService.getUser();
    //  console.log(this.currentUser);
    this.currentActivity = "";
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    //console.log(this.router.url);
    this.currentActivity = '';
    this.ruta = this.router.url;
    this.dataFilter = this.filterDataService.getFilter();
    this.getActivity();
    //agregar el emiter para que deje current vacio si se limpia
    this.currentA = this.toursService.selectActivity.subscribe((currentAct: any) => {
      if(currentAct != null && currentAct != undefined){
        this.currentActivity = currentAct.typeActivityDescription;
      }
    });
  }

  logout() {
    var separedRoute = this.ruta.split("/");
    this.ruteSepared = separedRoute[1];
    if (this.ruteSepared == "guest-page") {
      this.loginAuthService.logout(1); //usuario portal cliente
    } else {
      this.loginAuthService.logout(0);
    }

  }

  /** open pop-up code */
  openCodeDialog() {
    const customerDialogConfig = new MatDialogConfig();
    customerDialogConfig.disableClose = true;
    customerDialogConfig.autoFocus = true;
    //customerDialogConfig.width = '400px';
    customerDialogConfig.data = this.code;

    const customerDialogRef = this.dialog.open(CodeAdminComponent, customerDialogConfig);
    customerDialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.user = data.user;
        }
      });
  }

  getActivity() {
    this.subscriptions = this.bookingService.getActivities().subscribe(resp => {
      this.activities = resp.data;
      //console.log("dsadasdasddsa");
      //se le agregan los primeros elementos al final del array para tener 12 y que se muestre el efecto de carrousel (con los primeros 3 al final)
      let j = 0;
      for (let i = resp.data.length; i < 12; i++) {
        //console.log(resp.data[j]);
        this.activities.push(resp.data[j]);
        j++;
      }
      //console.log("sdfsdfdsfsdfsdff");
      //console.log(this.activities);

      this.bookingService.activitiesLoaded.emit(resp);

      if (this.dataFilter !== null && this.dataFilter.IdTypeActivity) { 
        this.currentActivity = this.activities.find(act => act.idTypeActivity === this.dataFilter.IdTypeActivity).typeActivityDescription;
      } else {
        this.currentActivity = '';
      }
      //this.arrayOfArrays = [];
      //this.repartir();
    });
  }


  onSelectActivity(act) {
    this.toursService.isLoading.emit(true);
    const dataFilter = this.filterDataService.getFilter();
    if (dataFilter) {
      const tour = {
        dateTime: dataFilter.dateTime,
        endingPlace: dataFilter.endingPlace,
        startingPlace: dataFilter.startingPlace,
        IdTypeActivity: act.idTypeActivity
      };
      this.filterDataService.setFilter(tour);

    } else {
      const tour = {
        dateTime: '',
        endingPlace: '',
        startingPlace: '',
        IdTypeActivity: act.idTypeActivity
      };
      this.filterDataService.setFilter(tour);
    }

    this.currentActivity = act.typeActivityDescription;
    this.toursService.selectActivity.emit(act);
  }

  isCurrentActivity(act) {
    return this.currentActivity === act.typeActivityDescription ? true : false;
  }

  cleanAllFilters() {
    const dataFilter = this.filterDataService.getFilter();
    if(dataFilter != null && dataFilter != undefined){
      if (dataFilter.dateTime === '' && dataFilter.endingPlace === '' && dataFilter.startingPlace === ''){
        this.filterDataService.resetFilter();
      } else{
        const tour = {
          dateTime: dataFilter.dateTime,
          endingPlace: dataFilter.endingPlace,
          startingPlace: dataFilter.startingPlace,
          IdTypeActivity: ''
        };
        this.filterDataService.setFilter(tour);
      }
    }

    this.currentActivity = "";
    this.toursService.selectActivity.emit('');
  }
  // code: any;
  // user: any;
  // currentUser: any;

  // constructor(
  //   public dialog: MatDialog,
  //   private loginAuthService: LoginAuthService
  // ) {
  //   this.currentUser = this.loginAuthService.getUser();
  // }

  // ngOnInit() {
  // }

  // /** open pop-up code */
  // openCodeDialog() {
  //   const customerDialogConfig = new MatDialogConfig();
  //   customerDialogConfig.disableClose = true;
  //   customerDialogConfig.autoFocus = true;
  //   //customerDialogConfig.width = '400px';
  //   customerDialogConfig.data = this.code;

  //   const customerDialogRef = this.dialog.open(CodeAdminComponent, customerDialogConfig);
  //   customerDialogRef.afterClosed().subscribe(
  //     data => {
  //       if (data) {
  //         this.user = data.user;
  //       }
  //     });
  // }

  // logout() {
  //   this.loginAuthService.logout();
  // }
}
