import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExcursionsDetailComponent } from './excursions-detail/excursions-detail.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { ExcursionsDetailRoutingModule } from './excursions-detail-routing.module';
import { ExcursionsDetailContainerComponent } from './excursions-detail-container/excursions-detail-container.component';
import { MaterialModule } from '../shared/material.module';
import { DialogImageUserComponent } from './dialog-image-user/dialog-image-user.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



@NgModule({
  declarations: [
    ExcursionsDetailComponent,
    ExcursionsDetailContainerComponent,
    DialogImageUserComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    SharedModule,
    ReactiveFormsModule,
    RouterModule,
    ExcursionsDetailRoutingModule,
    MaterialModule
  ],
  exports: [
    ExcursionsDetailComponent
  ],
})
export class ExcursionsDetailModule { }
