



<div class="dialog-body">
  <div class="row justify-content-between">
    <div class="col">
      <h2 mat-dialog-title><img src="assets/img/pago-mp.svg" class="iconos-pago"></h2>
    </div>
    <div class="col-1">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closed(false)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <hr>

  <div class="row justify-content-between">
    <div class="col-12">
      <p class="text-azul mb-3"><strong> Pago a través de Mercado Pago</strong></p>

      <div *ngIf="userType === 2;else templateName" style="text-align: center;">
        <a class="link-mp" (click)="onNavigate()" *ngIf="view">Pagar por Mercado Pago</a>
        <hr>
        <p>Se enviará el link al siguiente correo: <strong style="margin-left: 2px;">{{email}}</strong></p>
      </div>
      <ng-template #templateName>
        <p>Se enviará el link al siguiente correo: <strong style="margin-left: 2px;">{{email}}</strong></p>
      </ng-template>
      <hr>
      <p class="text-center mt-3">





        <button mat-raised-button class="btn btn-rojo"  role="button" aria-pressed="true" (click)="sendEmail()" [disabled]="isLoad"
                cdkFocusInitial>Finalizar</button>
      </p>
    </div>

  </div>

</div>
