import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, FormsModule, FormControl } from '@angular/forms';
import { Excursions } from 'src/app/shared/class/excursions.model';
import { Router } from '@angular/router';
import { ToursService } from 'src/app/shared/service/tours.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { LoginAuthService } from 'src/app/auth/providers/login-auth.service';
import { FilterDataService } from 'src/app/service/filter-data.service';
import { Subscription } from 'rxjs';
import { BookingService } from 'src/app/shared/service/booking.service';

declare var $: any;

@Component({
  selector: 'app-excursions',
  templateUrl: './excursions.component.html',
  styleUrls: ['./excursions.component.scss']
})
export class ExcursionsComponent implements OnInit, OnDestroy {
  public rowsDetails: UntypedFormArray = this.frmBuilder.array([]);
  public formExcursionsDetails: UntypedFormGroup;
  public excursions: Excursions[] = [];
  public after: Excursions[];
  public arrayOfArrays: any[] = [];
  public arrayOfArraysAfter: any[] = [];
  date = new Date();
  isFilter = false;
  public innerWidth;
  public monthYearForm: UntypedFormGroup;
  actualYear: number;
  actualMonth: number;
  public seleccionadaM: string;
  public seleccionadaY: any;
  public user: any;
  public cargando = true;
  public isSelectedActivity;
  public onSelectedActivity: Subscription;
  public currentActivity: any;
  public isFilter$: Subscription;
  public getExcursionsTomorrowByActivity$: Subscription;
  public excursionsAfter$: Subscription;
  public excursionsAfterByActivity$: Subscription;
  public excursionsTomorrow$: Subscription;
  public activitiesLoaded$: Subscription;
  public isLoading$: Subscription;

  public excursionsToday$: Subscription;
  public arrayOfArraysToday: any[] = [];
  public excursionsT: Excursions[] = [];

  months: any = [
    { name: 'Enero', value: 1 },
    { name: 'Febrero', value: 2 },
    { name: 'Marzo', value: 3 },
    { name: 'Abril', value: 4 },
    { name: 'Mayo', value: 5 },
    { name: 'Junio', value: 6 },
    { name: 'Julio', value: 7 },
    { name: 'Agosto', value: 8 },
    { name: 'Septiembre', value: 9 },
    { name: 'Octubre', value: 10 },
    { name: 'Noviembre', value: 11 },
    { name: 'Diciembre', value: 12 }
  ];
  years: any = [
    { name: '2023', value: 2023 },
    { name: '2024', value: 2024 },
  ];
  formBuilder: any;

  constructor(
    private frmBuilder: UntypedFormBuilder,
    private router: Router,
    private tourService: ToursService,
    private loginAuthService: LoginAuthService,
    private filterDataServices: FilterDataService,
    private bookingService: BookingService,

  ) { }

  ngOnDestroy(): void {
    this.onSelectedActivity.unsubscribe();
    this.isFilter$.unsubscribe();

    if (this.getExcursionsTomorrowByActivity$ !== undefined) {
      this.getExcursionsTomorrowByActivity$.unsubscribe();
    }

    if(this.excursionsAfter$ !== undefined){
      this.excursionsAfter$.unsubscribe();
    }

    if (this.excursionsAfterByActivity$ !== undefined) {
      this.excursionsAfterByActivity$.unsubscribe();
    }
    if (this.excursionsTomorrow$ !== undefined) {
      this.excursionsTomorrow$.unsubscribe();
    }

    this.activitiesLoaded$.unsubscribe();
    this.isLoading$.unsubscribe();
    //console.log("reseteo los filtros");
    //this.filterDataServices.resetFilter();
  }

  ngOnInit() {
    this.cargando = true;

    this.isLoading$ = this.tourService.isLoading.subscribe(resp => {
      this.cargando = resp;
    });

    //this.isFilter = false;
    this.user = this.loginAuthService.getUser();
    // obtiene la fecha de pasado mañana
    this.date.setDate(this.date.getDate() + 2);
    const actualMonth = this.date.getMonth() + 1;
    const actualYear = this.date.getFullYear();
    const actualMonthN = this.date.getMonth();
    this.seleccionadaM = this.months[actualMonthN].value;
    this.seleccionadaY = actualYear;

    this.isFilter$ = this.tourService.isFilter.subscribe((isFilter: any) => {
      this.isFilter = isFilter;
      const dataFilter = this.filterDataServices.getFilter()
      if (!dataFilter && !isFilter) {
        this.getExcursionsTomorrow();
      } else {
        this.getExcursionAfterT();
      }

    });

    this.activitiesLoaded$ = this.bookingService.activitiesLoaded.subscribe((resp:any) => {
      if (dataFilter && dataFilter.IdTypeActivity) {
        this.isSelectedActivity = true;
        this.currentActivity = resp.data.find(act => act.idTypeActivity === dataFilter.IdTypeActivity);
      }
    });

    // Funcionalidad para mantener los filtros en recargas 
    const dataFilter = this.filterDataServices.getFilter();
    
    if (dataFilter) {

      if (dataFilter.IdTypeActivity) {
        this.isSelectedActivity = true;
      }

      if (dataFilter.dateTime !== '' || dataFilter.endingPlace !== '' || dataFilter.startingPlace !== '') {
        this.isFilter = true;
      }else{
        this.isFilter = true;
        this.getExcursionAfterT();
      }

      if (dataFilter.IdTypeActivity && !this.isFilter) {
        this.cargando = true;
        this.getExcursionsTomorrowByActivity$ = this.tourService.getExcursionsTomorrowByActivity(dataFilter.IdTypeActivity).subscribe(resp => {
          this.excursions = resp.data;
          this.arrayOfArrays = [];
          this.repartir();
          this.cargando = false;
        });

        this.getExcursionAfterByActivityByFilter(dataFilter.IdTypeActivity);
      }
    } else {
      this.isSelectedActivity = false;
      this.getExcursionsTomorrow();

      this.excursionsAfter$ = this.tourService.getExcursionsAfter(actualMonth, actualYear).subscribe(resp => {
        this.after = resp.data;
        this.repartirAfter();
      });
    }
    // Fin Funcionalidad para mantener los filtros en recargas 

   

    this.formExcursionsDetails = this.frmBuilder.group({
      details: this.rowsDetails
    });

    // this.isSelectedActivity = false;
    this.onSelectedActivity = this.tourService.selectActivity.subscribe(resp => {
      // Actividad seleccionada y filtros vacios
      if (resp !== '' && !this.isFilter) {
        this.isSelectedActivity = true;
        this.currentActivity = resp;
        this.getExcursionAfterByActivity();
        this.getExcursionsTomorrowByActivity();
      } else if (resp !== '' && this.isFilter) { // actividad seleccionada y filtros activos
        this.isSelectedActivity = true;
        this.currentActivity = resp;

        // Recibo los datos por output del componente hijo  

      } else if (resp === '' && !this.isFilter) { // actividad no seleccionada y filtros vacios
        this.isSelectedActivity = false;
        this.currentActivity = '';
        this.getExcursionsTomorrow();
        this.getExcursionAfterT();
        //
        this.getExcursionsToday();
      } else {
        // actividad no seleccionada y filtros activos 
        this.isSelectedActivity = false;
        this.currentActivity = '';
        this.getExcursionAfterT();
      }
    });

    setTimeout(() => {
      $(document).ready(function() {
        $('#carouselmaniana').carousel();
        $('#proximasProgramadas').carousel();
      })
    }, 1500);
    

  }

  selectMonth(month) {
    this.actualMonth = month;
    this.getExcursionAfterT();
  }

  selectYear(year) {
    this.actualYear = year;
    this.getExcursionAfterT();
  }

  getExcursionAfterT() {
    if (this.actualMonth === undefined) {
      this.actualMonth = this.date.getMonth() + 1;
    }
    if (this.actualYear === undefined) {
      this.actualYear = this.date.getFullYear();
    }

    this.excursionsAfter$ = this.tourService.getExcursionsAfter(this.actualMonth, this.actualYear).subscribe(resp => {
      this.after = resp.data;
      this.repartirAfter();
    });
  }

  getExcursionAfterByActivity() {
    if (this.actualMonth === undefined) {
      this.actualMonth = this.date.getMonth() + 1;
    }
    if (this.actualYear === undefined) {
      this.actualYear = this.date.getFullYear();
    }
    this.excursionsAfterByActivity$ = this.tourService.getExcursionsAfterByActivity(this.actualMonth, this.actualYear, this.currentActivity.idTypeActivity).subscribe(resp => {
      this.after = resp.data;
      this.repartirAfter();
    });
  }

  getExcursionAfterByActivityByFilter(idTypeActivity) {
    if (this.actualMonth === undefined) {
      this.actualMonth = this.date.getMonth() + 1;
    }
    if (this.actualYear === undefined) {
      this.actualYear = this.date.getFullYear();
    }
    this.excursionsAfterByActivity$ = this.tourService.getExcursionsAfterByActivity(this.actualMonth, this.actualYear, idTypeActivity).subscribe(resp => {
      this.after = resp.data;
      this.repartirAfter();
    });
  }

  getExcursionsTomorrow() {
    this.cargando = true;
    this.excursionsTomorrow$ = this.tourService.getExcursionsTomorrow().subscribe(resp => {
      this.excursions = resp.data;
      this.arrayOfArrays = [];
      this.repartir();
      this.cargando = false;
    });
  }

  getExcursionsToday() {
    this.cargando = true;
    this.excursionsToday$ = this.tourService.getExcursions().subscribe(resp => {
      this.excursionsT = resp.data;
      this.arrayOfArraysToday = [];
      this.repartirToday();
      this.cargando = false;
    });
  }

  getExcursionsTomorrowByActivity() {
    this.cargando = true;
    this.getExcursionsTomorrowByActivity$ = this.tourService.getExcursionsTomorrowByActivity(this.currentActivity.idTypeActivity).subscribe(resp => {
      this.excursions = resp.data;
      this.arrayOfArrays = [];
      this.repartir();
      this.cargando = false;
    });
  }

  getExcursionsTomorrowByActivityFilter(idTypeActivity) {
    this.cargando = true;
    this.getExcursionsTomorrowByActivity$ = this.tourService.getExcursionsTomorrowByActivity(idTypeActivity).subscribe(resp => {
      this.excursions = resp.data;
      this.arrayOfArrays = [];
      this.repartir();
      this.cargando = false;
    });
  }

  /** directs to excursion detail screen */
  openDetail(rowIndex: number) {
    this.router.navigate(['/guest-page/excursions-detail-pax'], { queryParams: { id: rowIndex } });/*.then(() => {
      window.location.reload();
    });*/
  }

  /** show the images of after tomorrow in a carrousel */
  repartirAfter() {
    const length: number = this.after.length;
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 575) {
      const pages: number = this.after.length;
      this.arrayOfArraysAfter = [];
      for (let i = 0; i < pages; i++) {
        this.arrayOfArraysAfter.push(this.after.slice(i, (i + 2)));
      }
    } else if (this.innerWidth > 575 && this.innerWidth <= 767) {
      const pages: number = Math.trunc(length / 2) + ((length % 2 > 0) ? 1 : 0);

      this.arrayOfArraysAfter = [];
      for (let i = 0; i < pages; i++) {
        this.arrayOfArraysAfter.push(this.after.slice(2 * i, 2 * (i + 1)));
      }
    } else {
      const pages: number = Math.trunc(length / 4) + ((length % 4 > 0) ? 1 : 0);
      this.arrayOfArraysAfter = [];
      for (let i = 0; i < pages; i++) {
        this.arrayOfArraysAfter.push(this.after.slice(4 * i, 4 * (i + 1)));
      }
    }
  }

  /** show the images of tomorrow in a carrousel */
  repartir() {
    const length: number = this.excursions.length;
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 575) {

      const pages: number = this.excursions.length;
      this.arrayOfArrays = [];
      for (let i = 0; i < pages; i++) {
        this.arrayOfArrays.push(this.excursions.slice(i, (i + 1)));
      }
    } else if (this.innerWidth > 575 && this.innerWidth <= 767) {
      const pages: number = Math.trunc(length / 2) + ((length % 2 > 0) ? 1 : 0);
      this.arrayOfArrays = [];
      for (let i = 0; i < pages; i++) {
        this.arrayOfArrays.push(this.excursions.slice(2 * i, 2 * (i + 1)));
      }
    } else {
      const pages: number = Math.trunc(length / 3) + ((length % 3 > 0) ? 1 : 0);
      this.arrayOfArrays = [];
      for (let i = 0; i < pages; i++) {
        this.arrayOfArrays.push(this.excursions.slice(3 * i, 3 * (i + 1)));
      }
    }
  }

  repartirToday() {
    const length: number = this.excursionsT.length;
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 575) {

      const pages: number = this.excursionsT.length;
      this.arrayOfArraysToday = [];
      for (let i = 0; i < pages; i++) {
        this.arrayOfArraysToday.push(this.excursionsT.slice(i, (i + 1)));
      }
    } else if (this.innerWidth > 575 && this.innerWidth <= 767) {
      const pages: number = Math.trunc(length / 2) + ((length % 2 > 0) ? 1 : 0);
      this.arrayOfArraysToday = [];
      for (let i = 0; i < pages; i++) {
        this.arrayOfArraysToday.push(this.excursionsT.slice(2 * i, 2 * (i + 1)));
      }
    } else {
      const pages: number = Math.trunc(length / 3) + ((length % 3 > 0) ? 1 : 0);
      this.arrayOfArraysToday = [];
      for (let i = 0; i < pages; i++) {
        this.arrayOfArraysToday.push(this.excursionsT.slice(3 * i, 3 * (i + 1)));
      }
    }
  }

  /**
   * Method to set new filtered tours.
   * @param tours filtered.
   */
  toursFilter(tours: any[]) {
    this.excursions = [];
    this.arrayOfArrays = [];
    this.excursions = tours || [];
    this.isFilter = true;
    this.tourService.isFilter.emit(true);
    this.repartir();
  }

  /**
   * Method to clear searches to get tours for tomorrow.
   */
  onClearSearches() {
 
    const dataFilter = this.filterDataServices.getFilter();

    /*if (dataFilter.IdTypeActivity !== '') {
      const tour = {
        dateTime: '',
        endingPlace: '',
        startingPlace: '',
        IdTypeActivity: dataFilter.IdTypeActivity
      };
      this.filterDataServices.setFilter(tour);
      this.getExcursionsTomorrowByActivityFilter(dataFilter.IdTypeActivity);
      //no hay afterTomorrow con filtros de actividad??
      
    } else {*/
      //nuevo
      /*this.tourService.selectActivity.emit('');

      this.filterDataServices.resetFilter();
      this.getExcursionsTomorrow();
      this.getExcursionAfterT();*/
      
    //}
    this.isFilter = false;
    this.tourService.isFilter.emit(false);

    this.tourService.filtersCleaned.emit('');
    this.tourService.selectActivity.emit('');


    this.filterDataServices.resetFilter();
      this.getExcursionsTomorrow();
      this.getExcursionAfterT();
      //this.getExcursionsToday();
  }

  onResize() {
    this.innerWidth = window.innerWidth;
    this.repartir();
    this.repartirAfter();
    //
    this.repartirToday();
  }

  getArray() {
    return this.arrayOfArrays;
  }

  getArrayAfter() {
    return this.arrayOfArraysAfter;
  }

  getArrayToday() {
    return this.arrayOfArraysToday;
  }

}
