import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-pax-forms-home',
  templateUrl: './pax-forms-home.component.html',
  styleUrls: ['./pax-forms-home.component.scss']
})
export class PaxFormsHomeComponent implements OnInit {
  //userType = 2;
  idLayout: number;
  typeSite = 3;

  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.idLayout = params.id;
    });
  }
}
