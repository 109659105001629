import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { LoginAuthService } from '../../providers/login-auth.service';
import { AuthService } from '../../providers/auth.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  hide = true;
  public isLoad: boolean;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationsService,
    private loginAuthService: LoginAuthService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      userAccount: ['', [Validators.required]],
      userPassword: ['', [Validators.required]],
    });
  }

  /** validate user */
  login() {
    if (this.loginForm.valid) {
      this.isLoad = true;
      this.loginAuthService.authenticate(this.loginForm.value).then((resp: any) => {
        this.isLoad = false;
        if (resp) {
          this.authService.setAuth(resp);
          if (resp.rol === 'Admin') {
            this.router.navigate(['/admin-page/excursions']);
            this.isLoad = false;
          } else {
            if (resp.rol === 'Ofic') {
              this.router.navigate(['/ofic-page/excursions']);
              this.isLoad = false;
            } else {
              if (resp.rol === 'Recep') {
                this.router.navigate(['/recep-page/excursions-user']);
                this.isLoad = false;
              } else {
                this.router.navigate(['/consult-page/excursions-consult']);
                this.isLoad = false;
              }
            }
          }
          this.notificationService.success('Éxito', 'Ingresó correctamente');
        } else {
          this.notificationService.warn('Error', 'Usuario o contraseña incorrecto');
          this.isLoad = false;
        }
      }).catch(() => {
        this.notificationService.warn('Error', 'Usuario o contraseña incorrecto');
        this.isLoad = false;
      });
    } else {
      this.notificationService.warn('Error', 'Debe completar Usuario y contraseña');
    }
  }

  openClient() {
    this.router.navigate(['/home']);
  }

}
