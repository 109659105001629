import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-image-user',
  templateUrl: './dialog-image-user.component.html',
  styleUrls: ['./dialog-image-user.component.scss']
})
export class DialogImageUserComponent implements OnInit {

  constructor(
    private providerDialog: MatDialogRef<DialogImageUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  cancel() {
    this.providerDialog.close();
  }
}