import { AbstractControl } from '@angular/forms';
import { isNullOrUndefined } from 'util';

export class AdnValidators {

  static validateAmount(total: number) {
    return (control: AbstractControl) => {
      return control.value && +control.value > total ? { maxTotal: true } : null;
    };
  }

  static validateSeatsLock(freeSeatsL: number) {
    return (control: AbstractControl) => {
      return control.value && +control.value > freeSeatsL ? { invalidSeatsL: true } : null;
    };
  }

  static validateSeatsUnLock(lock: number) {
    return (control: AbstractControl) => {
      return control.value && +control.value > lock ? { invalidSeatsU: true } : null;
    };
  }


  static validateSeats(freeSeats: number, numberP: any, lockSeat: number) {
    if (numberP === '0') {
      return (control: AbstractControl) => {
        return control.value && +control.value > freeSeats ? { invalidSeats: true } : null;
      };
    } else {
      return (control: AbstractControl) => {
        return control.value && +control.value > lockSeat ? { invalidSeats: true } : null;
      };
    }
  }

  static validateSeatsValid(type: number) {
    if (type === 1) {
      return (control: AbstractControl) => {
        return (control.value !== null || control.value !== undefined) && +control.value < 1 ? { invalidSeats: true } : null;
      };
    } else {
      return (control: AbstractControl) => {
        return (control.value !== null || control.value !== undefined) && +control.value < 2 ? { invalidSeats: true } : null;
      };
    }
  }

  static validateDiscount(total: number, user: number) {
    if (user === 1) {
      return (control: AbstractControl) => {
        return control.value && +control.value > 0.1 * total ? { max: true } : null;
      };
    } else {
      if (user === 0) {
        return (control: AbstractControl) => {
          return control.value && +control.value > total ? { maxTotal: true } : null;
        };
      }
    }
  }

  //   return (control: AbstractControl) => {
  //     return control.value && +control.value > total ? { maxTotal: true } : null;
  //   };
  // }

  static validateDiscountUser(total: number) {
    return (control: AbstractControl) => {
      return control.value && +control.value > 0.1 * total ? { max: true } : null;
    };
  }

  static validateRefund(maxRefund: number) {
    return (control: AbstractControl) => {
      return control.value && +control.value > maxRefund ? { max: true } : null;
    };
  }
}

