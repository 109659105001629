import { Component, OnInit, Inject } from '@angular/core';
import { Cbu } from 'src/app/shared/class/cbu';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookingService } from 'src/app/shared/service/booking.service';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { LoginAuthService } from 'src/app/auth/providers/login-auth.service';

@Component({
  selector: 'app-pax-form-pay-bank',
  templateUrl: './pax-form-pay-bank.component.html',
  styleUrls: ['./pax-form-pay-bank.component.scss']
})
export class PaxFormPayBankComponent implements OnInit {
  public cbu: Cbu;
  public user: any;
  public id: number;
  public idPax: number;
  public email: string;

  constructor(
    private bookingService: BookingService,
    private providerDialog: MatDialogRef<PaxFormPayBankComponent>,
    public dialog: MatDialog,
    private router: Router,
    public notificationService: NotificationsService,
    private loginAuthService: LoginAuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    //this.cbu = null;
    //this.user = this.loginAuthService.getUser();
    this.idPax = this.data.booking.bookingDetail.find(resp => resp.reservationContact === 1).idPax;
    this.id = this.data.booking.idBooking;
    this.bookingService.getCbu(this.id).subscribe(resp => {
      this.cbu = resp.data;
    });
    this.bookingService.getMailPax(this.idPax).subscribe(resp => {
      this.email = resp.data;
    });
  }

  closed(action: boolean) {
    this.providerDialog.close(action);
  }

  sendEmail() {
    this.bookingService.getCbuEmail(this.id).subscribe(() => {
      this.closed(true);
    });
  }
}
