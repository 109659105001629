import { NotificationsService } from 'angular2-notifications';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookingService } from 'src/app/shared/service/booking.service';
import { Router } from '@angular/router';
import { LoginAuthService } from 'src/app/auth/providers/login-auth.service';

@Component({
  selector: 'app-pax-form-pay-mp',
  templateUrl: './pax-form-pay-mp.component.html',
  styleUrls: ['./pax-form-pay-mp.component.scss']
})
export class PaxFormPayMpComponent implements OnInit {
  public mp: any;
  public user: any;
  public id: number;
  public idPax: number;
  public email: string;
  userType: number;
  view = false;
  public rol: string;
  public isLoad = true;

  constructor(
    private providerDialog: MatDialogRef<PaxFormPayMpComponent>,
    private bookingService: BookingService,
    private router: Router,
    public dialog: MatDialog,
    public notificationService: NotificationsService,
    private loginAuthService: LoginAuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.user = this.loginAuthService.getUser();
    this.id = this.data.booking.idBooking;
    this.userType = this.data.userType;
    this.idPax = this.data.booking.bookingDetail.find(resp => resp.reservationContact === 1).idPax

    this.bookingService.getMercado(this.id).subscribe(resp => {
      this.view = true;
      this.mp = resp.data;
      this.isLoad = false;
    });
    this.bookingService.getMailPax(this.idPax).subscribe(resp => {
      this.email = resp.data;
    });
  }

  sendEmail() {
    const mail = {
      Url: this.mp,
      IdBooking: this.id,
    };
    this.bookingService.postMailMP(mail).subscribe(resp => {
      this.closed(true);
    });
  }

  closed(action: boolean) {
    this.providerDialog.close(action);
  }

  onNavigate() {
    window.open(this.mp);
  }

  redirectUser() {
    if (this.userType === 0) {
      if (this.user.rol === 'Admin') {
        this.router.navigate(['/excursions-admin']);
      } else {
        this.router.navigate(['/excursions-ofic']);
      }
    } else if (this.userType === 1) {
      this.router.navigate(['/excursions-user']);
    } else {
      this.router.navigate(['/excursions']);
    }
  }
}
