<div class="focused-content">
  <div class="container-fluid">
    <div class="card">
      <h5 class="card-header">Lista de reservas</h5>
      <div class="card-body">
        <h5 style="padding-bottom: 10px;">{{ detail?.title }}</h5>
        <div class="row">
          <div class="col-sm-6">
            <p class="text-header"><b>Fecha de impresión: </b>{{ now | date:'dd/MM/yyyy hh:mm a' }}</p>
            <p class="text-header"><b>Fecha de excursión:
              </b>{{ detail.startDate | date:'dd/MM/yyyy hh:mm a' }}</p>
          </div>

          <div class="col-sm-6" style="text-align: right;">
            <button mat-raised-button class="new-buttom" (click)="openBookingNUser()"
            *ngIf="disableBooking()"
            style="background-color: #1cac67; color: white;">
              <mat-icon>add_circle_outline</mat-icon> Cargar Reserva
            </button>
          </div>
          
          <div class="col-sm-12">
            <div class="table-responsive custom-table-insside">
              <table class="table table-striped">
                <thead>
                  <tr>
                  <tr>
                    <th class="focus-text">Fecha Reserva</th>
                    <th class="focus-text">PickUp</th>
                    <th class="focus-text">DropOff</th>
                    <th class="focus-text">Vendedor</th>
                    <th class="focus-text">Reserva</th>
                    <th class="focus-text">Teléfono de contacto</th>
                    <th class="align" style="font-weight: bold; width: 23%;">Pasajeros</th>
                    <th class="focus-text">Estado</th>
                    <th class="focus-text">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let deta of detail?.bookings | paginate: {itemsPerPage: 5, currentPage: pageActual, id: randomBKU}; let i=index">
                    <th class="focus-text">{{deta.bookingDate | date:'EE dd/MM h:mm a' }}</th>
                    <td class="focus-text"> {{deta.pickUp}}</td>
                    <td class="focus-text">{{deta.dropOff}}</td>
                    <td class="focus-text">{{deta.firstName}} {{deta.lastName}}</td>
                    <td class="align">{{ deta.contactFirstName }} {{ deta.contactLastName }} <span
                        style="font-weight: bold;">x{{ deta?.listPaxs?.length }}</span>
                    </td>
                    <td class="focus-text">{{deta.mobile}}</td>
                    <td class="align">
                      <div *ngFor="let paxs of deta?.listPaxs; let i = index" style="text-align: left;">
                        <span style="font-weight: bold;">{{i + 1 }}.</span> {{ paxs.lastName }},
                        {{ paxs.firstName }} | {{ paxs.observations }}
                      </div>
                    </td>
                    <td class="focus-text">{{deta.statusBooking}}</td>
                    <td>
                      <button mat-icon-button mat-raised-button matTooltip="Historial de Pago" class="example-button"
                        (click)="paymentHistory(i)">
                        <mat-icon>request_quote</mat-icon>
                      </button>
                      <button mat-icon-button mat-raised-button matTooltip="Descuento" class="example-button"
                        (click)="openDiscountDialog(i)"
                        *ngIf="deta.statusBooking !== 'PAGADO' && deta.allowDiscount === true">
                        <mat-icon>remove_circle_outline</mat-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <pagination-controls (pageChange)="pageActual = $event" id="{{randomBKU}}" style="text-align: right;"
          previousLabel="Anterior" nextLabel="Siguiente">
        </pagination-controls>
      </div>
      <div class="row">

        <div class="col">
          <div style="text-align: right; padding: 0 18px 18px;">
            <button type="button" mat-raised-button class="button" (click)="returnExcursion()">Salir
            </button>&nbsp;
            <button mat-raised-button class="btn btn-primary" style="background-color: #1cac67;color: white;" (click)="print()" [disabled]="isPaxs">Imprimir</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
