import { AdnValidators } from './../../shared/validators/adn-validators';
import { LayoutService } from './../../shared/service/layout.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookingService } from 'src/app/shared/service/booking.service';
import { NotificationsService } from 'angular2-notifications';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginAuthService } from 'src/app/auth/providers/login-auth.service';

@Component({
  selector: 'app-lock-seats',
  templateUrl: './lock-seats.component.html',
  styleUrls: ['./lock-seats.component.scss']
})
export class LockSeatsComponent implements OnInit {
  formRow = this.formBuilder.array([]);
  public lockSeat: any;
  public lockSeatForm: UntypedFormGroup;
  public lock: UntypedFormGroup;
  public idLayout: number;
  public user: any;
  public pageActual = 1;
  public randomBKA: string;
  public idLockSeat: number;
  public totalLock: number;
  public free: number;
  public lockS: boolean;
  public lockSeats: number;
  public number: string;
  public quantityUnLock: number;

  constructor(
    private providerDialog: MatDialogRef<LockSeatsComponent>,
    private bookingService: BookingService,
    private layoutService: LayoutService,
    private route: Router,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationsService,
    private loginAuthService: LoginAuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.idLayout = this.data.idLayout;
    this.free = this.data.freeSeats;
  }

  ngOnInit() {
    this.user = this.loginAuthService.getUser();
    this.randomBKA = 'id' + this.getRandom();
    this.getLockSeats();
    this.lockSeatForm = this.getFormGroup();
    this.getLockSeats();
  }

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      data: [''],
      quantity: ['', AdnValidators.validateSeatsLock(this.free)],
      quantity1: ['']
    });
  }

  pushInFormArray(locks: any[]) {
    locks.forEach(x => {
      const form = this.getFormGroup();
      form.patchValue({
        data: x,
      });
      this.formRow.push(form);
    });
  }

  resetFormArray() {
    while (this.formRow.length) {
      this.formRow.removeAt(0);
    }
  }

  getLockSeats() {
    this.bookingService.getLock(this.idLayout).subscribe(resp => {
      this.resetFormArray();
      this.pushInFormArray(resp.data);
      this.lockSeat = resp.data;
      this.totalLock = resp.data.length !== 0 ? resp.data.find((resp: any) => resp.idUser === this.user.idUser).totalQuantity : 0;
    }, error => console.error(error));
  }

  getRandom() {
    return Math.random();
  }

  cancel(action: any) {
    this.providerDialog.close(action);
  }

  saveLockSeats() {
    const lockS = {
      IdLayout: this.idLayout,
      IdUser: this.user.idUser,
      Quantity: this.lockSeatForm.value.quantity,
    };
    this.bookingService.lockSeats(lockS).subscribe(resp => {
      this.getLockSeats();
      this.lockSeatForm.get('quantity').setValue('');
    });
  }

  saveUnlockSeats(quantity: number, idLock: number) {
    const unLockS = {
      IdLockSeat: idLock,
      IdLayout: this.idLayout,
      IdUser: this.user.idUser,
      Quantity: quantity,
    };
    this.bookingService.unLockSeats(unLockS).subscribe(resp => {
      this.getLockSeats();
    });
  }

  openBookingN(idLayout: number) {
    if (this.user.rol === 'Admin') {
      this.route.navigate(['/admin-page/forms-admin'], { queryParams: { number: 1, idLayout: idLayout } });
      this.providerDialog.close();
    } else if (this.user.rol === 'Ofic') {
      this.route.navigate(['/ofic-page/forms-ofic'], { queryParams: { number: 1, idLayout: idLayout } });
      this.providerDialog.close();
    } else {
      this.route.navigate(['/recep-page/forms-recep'], { queryParams: { number: 1, idLayout: idLayout } });
      this.providerDialog.close();
    }
  }

  disableUnlock(idUserLock) {
    if (this.user.rol === 'Ofic') {
      if (this.user.idUser === idUserLock) {
        this.lockS = true;
      } else {
        this.lockS = false;
      }
    } else {
      this.lockS = true;
    }
    return this.lockS;
  }
}
