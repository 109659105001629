import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExcursionsDetailPaxComponent } from './excursions-detail-pax/excursions-detail-pax.component';
import { ExcursionsDetailPaxContainerComponent } from './excursions-detail-pax-container/excursions-detail-pax-container.component';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../shared/material.module';
import { ExcursionsDetailPaxRoutingModule } from './excursions-routing.module';
import { DialogImagePaxComponent } from './dialog-image-pax/dialog-image-pax.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    ExcursionsDetailPaxComponent,
    ExcursionsDetailPaxContainerComponent,
    DialogImagePaxComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    SharedModule,
    ReactiveFormsModule,
    RouterModule,
    ExcursionsDetailPaxRoutingModule,
    MaterialModule,
  ],
  exports: [
    ExcursionsDetailPaxComponent
  ],
})
export class ExcursionsDetailPaxModule { }
