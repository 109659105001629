import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaxFormsComponent } from './pax-forms/pax-forms.component';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BodyModule } from '../body/body.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { MatGridListModule } from '@angular/material/grid-list';
import { PaxFormsRoutingModule } from './pax-forms-routing.module';
import { PaxFormPayBankComponent } from './pax-form-pay-bank/pax-form-pay-bank.component';
import { PaxFormExitComponent } from './pax-form-exit/pax-form-exit.component';
import { MaterialModule } from '../shared/material.module';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { PaxFormPayMpComponent } from './pax-form-pay-mp/pax-form-pay-mp.component';
import { PaxFormsAdminComponent } from './pax-forms-admin/pax-forms-admin.component';
import { PaxFormPayCashComponent } from './pax-form-pay-cash/pax-form-pay-cash.component';
import { PaxFormPayPostnetComponent } from './pax-form-pay-postnet/pax-form-pay-postnet.component';
import { PaxFormsHomeComponent } from './pax-forms-home/pax-forms-home.component';
import { PaxFormConfirmationComponent } from './pax-form-confirmation/pax-form-confirmation.component';
import { ConfirmationComponent } from '../shared/confirmation/confirmation.component';
import { PaxComponent } from './pax/pax.component';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    PaxFormsComponent,
    PaxFormPayBankComponent,
    PaxFormExitComponent,
    PaxFormPayMpComponent,
    PaxFormsAdminComponent,
    PaxFormPayCashComponent,
    PaxFormPayPostnetComponent,
    PaxFormsHomeComponent,
    PaxFormConfirmationComponent,
    PaxComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    SharedModule,
    ReactiveFormsModule,
    BodyModule,
    FormsModule,
    MatStepperModule,
    MatExpansionModule,
    MatGridListModule,
    PaxFormsRoutingModule,
    MaterialModule,
    NgxMaskDirective,
    NgxMaskPipe,
    SimpleNotificationsModule.forRoot(
      // position: ['top', 'center'],
      // timeOut: 3000,
      // showProgressBar: true,
      // pauseOnHover: true,
      // clickToClose: true,
      // clickIconToClose: true
    ),
  ],
  providers: [
    provideNgxMask()
  ],
  exports: [PaxFormsComponent],

 
})
export class PaxFormsModule { }
