import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExcursionsComponent } from './excursions/excursions.component';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BodyModule } from '../body/body.module';
import { ExcursionsRoutingModule } from './excursions-routing.module';
import { MaterialModule } from '../shared/material.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ExcursionsContainerComponent } from './excursions-container/excursions-container.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    ExcursionsComponent,
    ExcursionsContainerComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    SharedModule,
    ReactiveFormsModule,
    BodyModule,
    ExcursionsRoutingModule,
    MaterialModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatButtonModule
  ],
  exports: [
    ExcursionsComponent
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class ExcursionsModule { }
