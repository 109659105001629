import { CreditcardTicket } from './creditcard-ticket';
import { BankTransfers } from './bank-transfers';

export class PaymentDetails {

  public idPaymentDetail: number;
  public IdPaymentMethod: number;
  public Amount: number;
  public IdUser: number;
  public PaymentDetailDate: Date;
  public IdPaymentHeader: number;
  public CreditcardTicket: CreditcardTicket[] = [];
  public Vps: number;
  public Surcharges: number;
  public Observations: string;
  public Discount: number;
  public DiscountReason: string;
  public Refund: number;
  public postPaymentRemarks: string;
  public BankTransfers: BankTransfers[] = [];

  constructor() { }
}
