import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaxFormsComponent } from './pax-forms/pax-forms.component';

const routes: Routes = [{
  path: '', component: PaxFormsComponent,
  children: [
    { path: '', component: PaxFormsComponent, data: { title: 'Reservas', status: true }, },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaxFormsRoutingModule { }
