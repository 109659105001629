import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logout-guest',
  templateUrl: './logout-guest.component.html',
  styleUrls: ['./logout-guest.component.scss']
})
export class LogoutGuestComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
