<simple-notifications></simple-notifications>
<div class="dialog-body">
  <div class="row">
    <div class="col">
      <h2 mat-dialog-title>Registrar Pagos </h2>
    </div>

    <div class="col-sm-2">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()"
        style="float: right;">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

  </div>

  <hr />
  <div class="mat-typography" style="padding-top: 1px;">
    <form [formGroup]="paymentForm">
      <p style="font-size: 16px;margin-left: 10px;margin-top: 10px;">Fecha: <strong
          style="font-size: 16px;margin-left: 10px;">{{date | date:'dd/MM/yyyy'}}</strong></p>

      <div class="form-group" style="margin-top: -10px;">
        <label for="card" style="font-size: 16px;margin-left: 10px">Forma de pago:</label>
        <mat-form-field style="margin-left: 10px;">
          <mat-select formControlName="IdPaymentMethod">
            <mat-option *ngFor="let op of payMethod" [value]="op.id">
              {{op.muestraValor}}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="paymentForm.controls['IdPaymentMethod'].invalid && (paymentForm.controls['IdPaymentMethod'].dirty || paymentForm.controls['IdPaymentMethod'].touched)">
            <span *ngIf="paymentForm.controls['IdPaymentMethod'].errors?.required">{{ getErrorMessage() }}</span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row" style="font-size: 16px;margin-left: 10px;margin-top: -15px;">
        <p> Total: <strong>$
            {{this.totalPrice}}</strong></p>

        <p style="font-size: 16px;margin-left: 20%;"> Saldo: <strong style="font-size: 16px;margin-left: 10px;">$
            {{this.total}}</strong></p>
      </div>

      <div class="row" style="font-size: 16px;margin-left: 10px;margin-top: 15px;"
        *ngIf="this.paymentForm.value.IdPaymentMethod === 1">
        <p>Se enviará el link al siguiente correo: </p>
        <strong style="margin-left: 10px; padding-right: 10px;">{{email}}</strong>
      </div>

      <div class="row" *ngIf="this.paymentForm.value.IdPaymentMethod !== 1">
        <p style="font-size: 16px;margin-left: 25px;margin-top: 10px;">Monto recibido: $</p>
        <mat-form-field class="col-sm-4" style="margin-left: 10px;margin-top: -10px;">
          <input matInput type="number" formControlName="Amount" min="0" maxlength="this.new.length"
            onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 || event.keyCode === 168 || event.keyCode === 190 || event.keyCode === 110 ? true : !isNaN(Number(event.key));">
          <mat-error *ngIf="paymentForm.controls['Amount'].invalid && paymentForm.controls['Amount'].touched">
            <span *ngIf="paymentForm.controls['Amount'].errors['required']">Por favor
              rellene el
              campo</span>
            <span *ngIf="this.paymentForm.value.Amount > this.total">Monto ingresado
              inválido</span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row" *ngIf="this.paymentForm.value.IdPaymentMethod === 4">
        <p style="font-size: 16px;margin-left: 25px;margin-top: 6px;">VPS: </p>
        <mat-form-field class="col-sm-4" style="margin-left: 10px;margin-top: -13px;">
          <input matInput mask="000-00000" formControlName="Vps"
            onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46  ? true : !isNaN(Number(event.key));">
        </mat-form-field>
      </div>
    </form>

    <form [formGroup]="bankTransferForm" *ngIf="this.paymentForm.value.IdPaymentMethod === 2">
      <div class="row">
        <p style="font-size: 16px;margin-left: 25px;margin-top: 10px;">Fecha de transferencia: </p>
        <mat-form-field class="col-sm-4" style="margin-left: 10px;margin-top: -10px;">
          <input matInput [matDatepicker]="picker1" formControlName="BankTransferDate">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <mat-error
            *ngIf="bankTransferForm.controls['BankTransferDate'].invalid && bankTransferForm.controls['BankTransferDate'].touched">
            <span *ngIf="bankTransferForm.controls['BankTransferDate'].errors['required']">Por favor
              rellene el campo</span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row">
        <p style="font-size: 16px;margin-left: 25px;margin-top: 10px;">Responsable de pago: </p>
        <mat-form-field class="col-sm-6" style="margin-left: 5px;margin-top: -10px;">
          <input matInput formControlName="ResponsiblePayment">
          <mat-error
            *ngIf="bankTransferForm.controls['ResponsiblePayment'].invalid && bankTransferForm.controls['ResponsiblePayment'].touched">
            <span *ngIf="bankTransferForm.controls['ResponsiblePayment'].errors['required']">Por favor
              rellene el campo</span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row">
        <p style="font-size: 16px;margin-left: 25px;margin-top: 10px;">Banco: </p>
        <mat-form-field class="col-sm-8" style="margin-left: 5px;margin-top: -10px;">
          <input matInput formControlName="Bank">
          <mat-error *ngIf="bankTransferForm.controls['Bank'].invalid && bankTransferForm.controls['Bank'].touched">
            <span *ngIf="bankTransferForm.controls['Bank'].errors['required']">Por favor
              rellene el campo</span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row">
        <p style="font-size: 16px;margin-left: 25px;margin-top: 10px;">Cbu: </p>
        <mat-form-field class="col-sm-10" style="margin-left: 5px;margin-top: -10px;">
          <input matInput formControlName="Cbu" mask="0000000000000000000000"
            onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 || event.keyCode === 37 || event.keyCode === 39 ? true : !isNaN(Number(event.key));">
          <mat-error *ngIf="bankTransferForm.controls['Cbu'].invalid && bankTransferForm.controls['Cbu'].touched">
            <span *ngIf="bankTransferForm.controls['Cbu'].errors['required']">Por favor
              rellene el campo</span>
          </mat-error>
        </mat-form-field>
      </div>
    </form>

    <form [formGroup]="paymentPosnetForm"
      *ngIf="this.paymentForm.value.IdPaymentMethod === 3 || this.paymentForm.value.IdPaymentMethod === 5">
      <div class="row">
        <p style="font-size: 16px;margin-left: 25px;margin-top: 10px;">Recargo: $</p>
        <mat-form-field class="col-sm-2" style="margin-left: 10px;margin-top: -10px;">
          <input matInput type="number" formControlName="Surcharges" min="0"
            onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 || event.keyCode === 168 || event.keyCode === 190 || event.keyCode === 110 ? true : !isNaN(Number(event.key));">
        </mat-form-field>
        <p style="font-size: 16px;margin-top: 10px;">Cuotas:</p>
        <mat-form-field class="col-sm-2" style="margin-left: 5px;margin-top: -10px;">
          <mat-select formControlName="NoDues">
            <mat-option>0</mat-option>
            <mat-option *ngFor="let type of quotes" [value]="type.value">
              {{type.show}}</mat-option>
          </mat-select>
        </mat-form-field>
        <p style="font-size: 16px;margin-top: 10px;" *ngIf="this.paymentPosnetForm.value.NoDues === '0'">
          Observaciones:
        </p>
        <mat-form-field class="col-sm-4" *ngIf="this.paymentPosnetForm.value.NoDues === '0'" style="margin-top: -10px;">
          <textarea matInput formControlName="Observations"></textarea>
        </mat-form-field>
      </div>

      <!-- <div class="row">
        <p style="font-size: 16px;margin-left: 25px;margin-top: 10px;">N° de lote: </p>
        <mat-form-field class="col-sm-2" style="margin-left: 10px;margin-top: -15px;">
          <input matInput formControlName="lot" maxlength="3" min="0" mask="000"
            onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))">
          <mat-error *ngIf="paymentPosnetForm.controls['lot'].invalid && paymentPosnetForm.controls['lot'].touched">
            <span *ngIf="paymentPosnetForm.controls['lot'].errors['required']">Por favor
              rellene el
              campo</span>
          </mat-error>
        </mat-form-field>

        <p style="font-size: 16px;margin-left: 25px;margin-top: 10px;">N° de cupón: </p>
        <mat-form-field class="col-sm-2" style="margin-left: 10px; margin-top: -10px;">
          <input matInput formControlName="coupon" min="0" mask="0000"
            onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))"
            maxlength="4">
          <mat-error
            *ngIf="paymentPosnetForm.controls['coupon'].invalid && paymentPosnetForm.controls['coupon'].touched">
            <span *ngIf="paymentPosnetForm.controls['coupon'].errors['required']">Por favor
              rellene el
              campo</span>
          </mat-error>
        </mat-form-field>
      </div> -->

      <div class="row">
        <p style="font-size: 16px;margin-left: 25px;margin-top: 10px;">N° de operación: </p>
        <mat-form-field class="col-sm-4" style="margin-left: 10px; margin-top: -10px;">
          <input matInput formControlName="NumberOperation" min="0" maxlength="25">
        </mat-form-field>
      </div>

      <div class="row">
        <p style="font-size: 16px;margin-left: 25px;margin-top: 10px;">Tipo de Tarjeta:</p>
        <mat-form-field class="col-sm-3" style="margin-left: 5px;margin-top: -10px;">
          <mat-select formControlName="typeCard">
            <mat-option>Ninguno</mat-option>
            <mat-option *ngFor="let type of cardTypes" [value]="type">
              {{type}}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="paymentPosnetForm.controls['typeCard'].invalid && paymentPosnetForm.controls['typeCard'].touched">
            <span *ngIf="paymentPosnetForm.controls['typeCard'].errors['required']">Por favor
              rellene el
              campo</span>
          </mat-error>
        </mat-form-field>

        <p style="font-size: 16px;margin-left: 25px;margin-top: 10px;">Tarjeta:</p>
        <mat-form-field class="col-sm-3" style="margin-top: -10px;">
          <mat-select formControlName="card">
            <mat-option>Ninguno</mat-option>
            <mat-option *ngFor="let card of cards" [value]="card">
              {{card}}</mat-option>
          </mat-select>
          <mat-error *ngIf="paymentPosnetForm.controls['card'].invalid && paymentPosnetForm.controls['card'].touched">
            <span *ngIf="paymentPosnetForm.controls['card'].errors['required']">Por favor
              rellene el
              campo</span>
          </mat-error>
        </mat-form-field>
      </div>
    </form>

    <form [formGroup]="paymentForm" *ngIf="this.startDate < this.time">
      <div class="row">
        <p style="font-size: 16px;margin-left: 25px;margin-top: 10px">
          Motivo:
        </p>
        <mat-form-field class="col-sm-8" style="margin-top: -15px;">
          <textarea matInput formControlName="PostPaymentRemarks"></textarea>
          <mat-error
            *ngIf="paymentForm.controls['PostPaymentRemarks'].invalid && paymentForm.controls['PostPaymentRemarks'].touched">
            <span *ngIf="paymentForm.controls['PostPaymentRemarks'].errors['required']">Por favor
              rellene el
              campo</span>
          </mat-error>
        </mat-form-field>
      </div>
    </form>


    <div class="col-sm-12" style="text-align: right;" *ngIf="userType !== 2 && allowDiscount === true">
      <mat-checkbox color="primary" (change)="changeCheck($event)" [formControl]="checkControl">
        Descuentos
      </mat-checkbox>
    </div>

    <form [formGroup]="paymentDiscountForm"
      *ngIf="this.isCheckDisc === true && allowDiscount === true && isDiscount === true">
      <div class="card">
        <div class="row">
          <p style="font-size: 14px;margin-left: 25px;margin-top: 20px;">Monto: $</p>
          <mat-form-field class="col-sm-3" style="margin-left: 10px;">
            <input matInput type="number" formControlName="Discount" min="0" maxlength="this.new.length"
              onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 || event.keyCode === 168 || event.keyCode === 190 || event.keyCode === 110 ? true : !isNaN(Number(event.key));">
            <mat-error
              *ngIf="paymentDiscountForm.controls['Discount'].invalid && paymentDiscountForm.controls['Discount'].touched">
              <span *ngIf="paymentDiscountForm.controls['Discount'].errors['required']">Por favor
                rellene el
                campo</span>
              <span *ngIf="this.paymentDiscountForm.value.Discount > this.totalPrice">Monto
                ingresado
                inválido</span>
            </mat-error>
          </mat-form-field>

          <!-- <div class="row"> -->
          <p style="font-size: 14px;margin-left: 25px;margin-top: 20px;">Motivo: </p>
          <mat-form-field style="margin-left: 5px;">
            <mat-select formControlName="DiscountReason">
              <mat-option *ngFor="let type of reasons" [value]="type.show">
                {{type.show}}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="paymentDiscountForm.controls['DiscountReason'].invalid && paymentDiscountForm.controls['DiscountReason'].touched">
              <span *ngIf="paymentDiscountForm.controls['DiscountReason'].errors['required']">Por favor
                rellene el
                campo</span>
            </mat-error>
          </mat-form-field>
          <!-- </div> -->
        </div>

        <div class="row">
          <p style="font-size: 14px;margin-left: 20px;margin-top: 10px;">
            Observaciones:
          </p>
          <mat-form-field class="col-sm-4" *ngIf="this.paymentDiscountForm.value.DiscountReason !== 'Otros'"
            style="margin-top: -10px;">
            <textarea matInput formControlName="Observations"></textarea>
            <mat-error
              *ngIf="paymentDiscountForm.controls['Observations'].invalid && paymentDiscountForm.controls['Observations'].touched">
              <span *ngIf="paymentDiscountForm.controls['Observations'].errors['required']">Por favor
                rellene el
                campo</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-sm-4" *ngIf="this.paymentDiscountForm.value.DiscountReason === 'Otros'"
            style="margin-top: -10px;">
            <textarea matInput formControlName="Observations"></textarea>
            <mat-error
              *ngIf="paymentDiscountForm.controls['Observations'].invalid && paymentDiscountForm.controls['Observations'].touched">
              <span *ngIf="paymentDiscountForm.controls['Observations'].errors['required']">Por favor
                rellene el
                campo</span>
            </mat-error>
          </mat-form-field>
        </div>

        <button class="btn btn-primary col-sm-3"
          style="background-color: #1cac67;color: white;border: #1cac67;margin-top: -10px;font-size: 14px;margin-left: auto;"
          (click)="saveDiscount()" *ngIf="!isLoadD" [disabled]="!this.paymentDiscountForm.valid">Aceptar</button>
        <button class="btn btn-primary col-sm-3"
          style="background-color: #1cac67; color: white;border: #1cac67;margin-left: auto;" *ngIf="isLoadD"
          [disabled]="isLoadD">
          <span class="spinner-border" style="width: 1rem; height: 1rem; border-width: 0.15em;" role="status"
            aria-hidden="true"></span> Cargando...

        </button>
      </div>
    </form>
  </div>
  <br>
  <div *ngIf="getSaldo(this.paymentForm.value.IdPaymentMethod) === 0">
    <p><strong>*Se enviara el Voucher por E-mail</strong></p>
  </div>

  <hr />
  <div style="text-align: right;">
    <button type="button" class="btn btn-success" (click)="sendEmail()" cdkFocusInitial
      *ngIf="!isLoad && this.paymentForm.value.IdPaymentMethod === 1">Aceptar
    </button>
    <button type="button" class="btn btn-success" (click)="paymentConfirmation(this.paymentForm.value.IdPaymentMethod)"
      cdkFocusInitial *ngIf="!isLoad && this.paymentForm.value.IdPaymentMethod !== 1">Aceptar
    </button>

    <button type="button" class="btn btn-success" cdkFocusInitial *ngIf="isLoad" [disabled]="isLoad">
      <span class="spinner-border" style="width: 1rem; height: 1rem; border-width: 0.15em;" role="status"
        aria-hidden="true"></span> Cargando...
    </button>
  </div>