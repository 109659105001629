import { element } from 'protractor';
import { LoginAuthService } from './../../auth/providers/login-auth.service';
import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { CodeAdminComponent } from 'src/app/excursions-admin/code-admin/code-admin.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../service/login.service';
import { LayoutService } from '../service/layout.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

  private listTitle: any[];
  public location: Location;
  public mobile_menu_visible: any = 0;
  private toggleButton: any;
  private sidebarVisible: boolean;
  public tempState = [];
  public breadcrumbs: Array<any>;
  @Input() menuItems = [];
  totalOpenExcursion = 0;
  currentUser: any;
  code: any;
  user: any;
  ruta: any;
  ruteSepared: any;
  public userDataType: any;

  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    private loginService: LoginService,
    public layoutService: LayoutService,
    public dialog: MatDialog,
    private loginAuthService: LoginAuthService
  ) {
    this.currentUser = this.loginAuthService.getUser();

  }

  ngOnInit() {
    this.ruta = this.router.url;
    this.hiddenNavOption();
  }

  logout() {
    var separedRoute = this.ruta.split("/");
    this.ruteSepared = separedRoute[1];
    //console.log(this.ruteSepared);
    if (this.ruteSepared == "guest-page") {
      this.loginAuthService.logout(1); //usuario portal cliente
    }else{
      this.loginAuthService.logout(0);
    }
    
  }

  /** open pop-up code */
  openCodeDialog() {
    const customerDialogConfig = new MatDialogConfig();
    customerDialogConfig.disableClose = true;
    customerDialogConfig.autoFocus = true;
    //customerDialogConfig.width = '400px';
    customerDialogConfig.data = this.code;

    const customerDialogRef = this.dialog.open(CodeAdminComponent, customerDialogConfig);
    customerDialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.user = data.user;
        }
      });
  }

  redirectPortalCliente() {
    this.router.navigate(['/']);
  }

  hiddenNavOption(){
    let rutaSplit = this.ruta.split("/");
    let rutaTemp = rutaSplit[1];
    if (rutaTemp == "guest-page") {
      this.userDataType = 2; //si tiene dos (2) quiere decir que esta dentro del portal cliente, independientemente del tipo de user
    }else{
      this.userDataType = 0; //si es cero es porque es portal vendedor (admin/ofic/reseller/recep)
    }
  }

  // code: any;
  // user: any;
  // currentUser: any;

  // constructor(
  //   public dialog: MatDialog,
  //   private loginAuthService: LoginAuthService
  // ) {
  //   this.currentUser = this.loginAuthService.getUser();
  // }

  // ngOnInit() {
  // }

  // /** open pop-up code */
  // openCodeDialog() {
  //   const customerDialogConfig = new MatDialogConfig();
  //   customerDialogConfig.disableClose = true;
  //   customerDialogConfig.autoFocus = true;
  //   //customerDialogConfig.width = '400px';
  //   customerDialogConfig.data = this.code;

  //   const customerDialogRef = this.dialog.open(CodeAdminComponent, customerDialogConfig);
  //   customerDialogRef.afterClosed().subscribe(
  //     data => {
  //       if (data) {
  //         this.user = data.user;
  //       }
  //     });
  // }

  // logout() {
  //   this.loginAuthService.logout();
  // }
}
