import { AdnValidators } from 'src/app/shared/validators/adn-validators';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { PaymentDetails } from 'src/app/shared/class/paymentDetails';
import { BookingService } from 'src/app/shared/service/booking.service';
import { NotificationsService } from 'angular2-notifications';
import { LoginAuthService } from 'src/app/auth/providers/login-auth.service';

@Component({
  selector: 'app-dialog-discount',
  templateUrl: './dialog-discount.component.html',
  styleUrls: ['./dialog-discount.component.scss']
})
export class DialogDiscountComponent implements OnInit {
  public paymentForm: UntypedFormGroup;
  public date = new Date();
  public paymentDetail: PaymentDetails = new PaymentDetails();
  public idPayment: number;
  public user: any;
  public isLoad: boolean;
  public totalPrice: number;
  public saldo: number;
  public porcentaje: number;

  public reasons = [{ show: 'Convenios', value: 'convenios' }, { show: 'Devolución', value: 'devolución' }, { show: 'Discapacidad', value: 'discapacidad' },
  { show: 'Gremios', value: 'gremios' }, { show: 'Grupo familiar', value: 'grupo familiar' }, { show: 'Jubilados', value: 'jubilados' }, { show: 'Menores', value: 'menores' },
  { show: 'Otros', value: 'otros' }, { show: 'Pago en efectivo', value: 'pago en efectivo' }];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private bookingService: BookingService,
    private providerDialog: MatDialogRef<DialogDiscountComponent>,
    private notificationService: NotificationsService,
    private loginAuthService: LoginAuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.idPayment = this.data.booking.bookings[this.data.id].idPaymentHeader;
    this.totalPrice = this.data.booking.bookings[this.data.id].totalPrice;
    this.saldo = this.data.booking.bookings[this.data.id].balance;
  }

  ngOnInit() {
    this.user = this.loginAuthService.getUser();
    this.paymentForm = this.formBuilder.group({
      Discount: ['', [Validators.required, AdnValidators.validateDiscountUser(this.totalPrice)]],
      DiscountReason: ['', [Validators.required]],
      Observations: ['']
    });
    this.paymentForm.get('DiscountReason').valueChanges.subscribe(resp => {
      this.paymentForm.get('Observations').setValidators(resp === 'Otros' ? [Validators.required] : []);
    });
  }

  cancel() {
    this.providerDialog.close();
  }

  saveDiscount() {
    this.isLoad = true;
    if (this.paymentForm.valid) {
      this.paymentDetail.PaymentDetailDate = this.date;
      this.paymentDetail.Discount = this.paymentForm.value.Discount;
      this.paymentDetail.DiscountReason = this.paymentForm.value.DiscountReason;
      this.paymentDetail.Observations = this.paymentForm.value.Observations;
      this.paymentDetail.IdUser = this.user.idUser;
      this.paymentDetail.IdPaymentHeader = this.idPayment;

      this.bookingService.postPaymentDiscount(this.paymentDetail).subscribe((resp: any) => {
        this.notificationService.success('Éxito', 'El descuento se registró correctamente');
        this.providerDialog.close();
        this.isLoad = false;
      });
    } else {
      this.notificationService.warn('Error, debe completar todos los campos');
    }
  }

  getErrorMessage() {
    return 'Este campo es requerido';
  }

  getPorcetaje() {
    this.porcentaje = 0.1 * this.totalPrice;
    return this.porcentaje;
  }
}
