<div class="dialog-body">
  <div class="row justify-content-between">
    <div class="col">
      <h2 mat-dialog-title> El código generado es: </h2>
    </div>
    <div class="col-1">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()"
        style="float: right;">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>

  <div>
    <div>
      <strong style="font-size: 30px; color: #1cac67; margin-left: 25%">{{code?.code1}} </strong>
    </div>
    <br>
    <strong style="margin-top: 20px;">Fecha de generación:
      {{code?.generationDate | date:'dd/MM/yyyy hh:mm a'}}</strong>
    <br>
    <strong style="margin-top: 10px;">Fecha de expiración:
      {{code?.expirationDate | date:'dd/MM/yyyy hh:mm a'}}</strong>
  </div>

  <hr>

  <div class="input-group mb-2">
    <input type="text" name="send" class="form-control" placeholder="Ingrese email" aria-label="email" [formControl]="emailControl">
    <div class="input-group-append">
      <button class="btn btn-secondary" type="button" (click)="sendForEmail();" [disabled]="!emailControl.valid"
        cdkFocusInitial style="width: 40px; height: 38px;">
        <mat-icon style="margin-left: -6px;">mail_outline</mat-icon>
      </button>
    </div>
    <div class="invalid-feedback" style="display: block;"
      *ngIf="emailControl.invalid && (emailControl.dirty || emailControl.touched)">
      <span *ngIf="emailControl.errors?.required">Este campo es requerido</span>
      <span *ngIf="emailControl.errors?.pattern">Ingrese un email valido</span>
    </div>
  </div>
</div>